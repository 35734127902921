<!--步骤执行参数面板-->
<template>
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <VariableSetPane :isDisabled="isDisabled" :isNewAction="isNewAction"
                    :stepActionType="selectedStepActionType" :itemInfo="itemInfo" label="选择APP" title="选择APP"
                    name="targetAppID" type="VARIABLE_APP" tipInfo="需要打开或者关闭的应用"
                    @onFeildClick="gotoSelectAppInfo('targetAppID')">
                </VariableSetPane>
                <Cell class="bg-trans">
                    <template #title>
                        <!-- <Checkbox class="content-vcenter block-inline" v-if="!isDisabled"
                            v-model="selectedStepActionType.operateType" shape="square"
                            @change="onParamValueChanged('operateType', selectedStepActionType.operateType)">
                        </Checkbox> -->
                        <!-- <Checkbox class="content-vcenter block-inline" disabled v-if="isDisabled"
                            v-model="selectedStepActionType.operateType" shape="square"></Checkbox> -->
                        <Checkbox class="content-vcenter block-inline" disabled 
                            v-model="selectedStepActionType.operateType" shape="square"></Checkbox>
                        <span class="content-vcenter block-inline margin-left">打开/关闭应用</span>
                        <Icon class="content-vcenter padding-left" name="info-o" size="20"
                            @click="showTips('勾选后，为关闭对应应用步骤，默认为打开应用')" />
                    </template>
                </Cell>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import {getCurrentInstance,ref,defineProps,toRefs,defineExpose} from "vue";
import { showToast } from "vant";
import { useRouter } from "vue-router";
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const props =defineProps({
    itemInfo:{
        type:Object
    },
    isNewAction:{
        type:Boolean,
        default:false
    },
    isDisabled:{
        type:Boolean,
        default:false
    },
});
const { itemInfo,isNewAction,isDisabled} = toRefs(props);

/**
 * 提示信息
 * @param {*} message 
 */
const showTips = (message) => {
    showToast({
        message: message,
        position: 'top'
    });
};

const selectedStepActionType=ref({});

const tabIndex = ref(0);

const gotoSelectAppInfo = (paramName) => {
    //构建当前页面的页面信息
    let pageDatas = {
        itemInfo: itemInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas,
        tabIndex: tabIndex.value,
    }
    let datas = {
        backTargetPage: hanlder.getBackPageName(),
        backTargetPageDatas: pageDatas,
        eventName: "AUTOSPIRIT_APPCONFIG_APPINFOSELECT_EVENT",
        stepInfo: itemInfo.value,
    };
    //处理步骤类型改变动作
    const actionMethod = async (eventDatas) => {
        let selectedAppID = eventDatas.itemInfo.appID;
        onParamValueChanged(paramName, selectedAppID);
        router.push({
            name: datas.backTargetPage,
            state: {
                pageDatas: JSON.stringify(pageDatas)
            }
        });
    };
    datas.listenerID = wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "AppInfoSelect",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};

const onParamValueChanged = (title, value) => {
    let eventData = {
        paramName: title,
        paramValue: value,
    }
    onActionStepParamChanged(eventData);
}


let hanlder={};

const onActionStepParamChanged = (eventData) => {
    hanlder.onActionStepParamChanged(eventData);
};

const onMappingChanged=(eventData)=>{
    hanlder.onMappingChanged(eventData);
}

/**
 * 重置面板数据
 * @param {*} stepActionTypeInfo 
 */
const resetData=async(tempHandler,index)=>{
    tabIndex.value = index;
    hanlder = tempHandler;
    selectedStepActionType.value = hanlder.getStepActionInfos();
};

const getID=()=>{
    return "AUTOSPIRIT_APPLOAD_PARAMETER";
};

defineExpose({
    resetData,
    getID,
});
</script>
<style scoped>
.rightIcon {
    line-height: 2;
}
</style>

