<!--执行步骤成功之后的参数控制面板-->
<template>
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <List class="border none bg-trans" v-model:loading="loading" :finished="finished" finished-text="没有更多了">
                    <Cell v-for="condition in conditionDatas" :key="condition.id" class="bg-trans">
                        <template #title>
                            <VariableControlPane :itemInfo="itemInfo"
                            :condition="condition"
                            :isNewAction="isNewAction"
                            :isDisabled="isDisabled"
                            @onValueChange="onConditionValueChanged"
                            ></VariableControlPane>
                        </template>
                        <template #right-icon>
                            <div class="flex">
                                <Icon v-if="!isDisabled" class="rightIcon" :name="crossIcon" size="20"
                                    @click="deleteConditionData(condition)" />
                            </div>
                        </template>
                    </Cell>
                </List>
                <Cell class="bg-trans">
                    <template #title>
                        <div v-if="!isDisabled" class="text-center conditionBtn" @click="addCondtionData">添加变量控制</div>
                    </template>
                </Cell>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import {getCurrentInstance,ref, onMounted,defineProps,toRefs,defineExpose} from "vue";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const loading = ref(true);
const finished = ref(false);
//控制条件集
const conditionDatas = ref([]);
const crossIcon = ref();
const props =defineProps({
    itemInfo:{
        type:Object
    },
    isDisabled:{
        type:Boolean,
        default:false
    },
    isNewAction:{
        type:Boolean,
        default:false
    },
});
const { itemInfo,isDisabled,isNewAction } = toRefs(props);

const selectedStepActionType =ref({});

onMounted(async () => {
    loading.value = false;
    finished.value = true;
    crossIcon.value = wsClient.getIconBase64Str("ROUND_CROSS");
});

let handler={};

const onConditionValueChanged = async (eventData) => {
    await handler.onConditionValueChanged(eventData);
    conditionDatas.value =handler.getVariableControlConditions();
};

//删除控制条件数据
const deleteConditionData = async(condtion) => {
    handler.deleteConditionData(condtion);
    conditionDatas.value =handler.getVariableControlConditions();
};

/**
 * 添加控制条件
 */
const addCondtionData = () => {
    handler.addCondtionData();
    conditionDatas.value =handler.getVariableControlConditions();
};

/**
 * 重置面板数据
 * @param {*} stepActionTypeInfo 
 */
const resetData=async(tempHandler,tabIndex)=>{
    handler =tempHandler;
    selectedStepActionType.value == handler.getStepActionInfos();
    await handler.refreshOperateConditions();
    conditionDatas.value =handler.getVariableControlConditions();
};

const getID=()=>{
    return "AUTOSPIRIT_PARAMETERS";
};

defineExpose({
    resetData,
    getID,
});
</script>
<style scoped>
.rightIcon {
    align-self: center;
    display: inline-flex;
    margin-left: 5px;
}

.conditionBtn {
    color: #0080ff;
    cursor: pointer;
}

.controlBtn {
    width: 80px;
}

.controlText {
    width: 260px;
}
</style>