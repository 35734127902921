<template>
    <div class="bg-lightGrey height-full">
        <BackableNavBar leftText="返回" title="新建应用配置" :autoControl="false" :listenerState="backListenerState"
            @onBack="onClickLeft" />
        <WizardPane ref="newAppWizard" :stepOptions="stepOptions"
            @onDataSubmit="doCreateAppInfo">
        </WizardPane>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onActivated, watch } from "vue";
import {
    useRouter,
} from 'vue-router';
import AppConfig from "@/components/app/views/config/newAppInfo/AppConfig.vue";
import PageConfig from "@/components/app/views/config/newAppInfo/PageConfig.vue";
import AppFullConfig from "@/components/app/views/config/newAppInfo/AppFullConfig.vue";
import AppConfigResult from "@/components/app/views/config/newAppInfo/AppConfigResult.vue";
import RouterType from "@/components/app/views/common/configPane/itemType/RouterType.js";
import { showToast } from "vant";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let stepOptions = ref([]);
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let isNeedPageDatasUpdate = false;
let getWizardDatas = () => {
    return [
        {
            stepName: "基础配置",
            component: AppConfig,
        },
        {
            stepName: "关联配置",
            component: PageConfig,
        },
        {
            stepName: "全局配置",
            component: AppFullConfig,
        },
        {
            stepName: "创建完毕",
            component: AppConfigResult,
        },
    ];
};
stepOptions = getWizardDatas();
const backListenerState = ref(true);
const newAppWizard = ref(null);

onActivated(() => {
    if (isNeedPageDatasUpdate) {
        newAppWizard.value.updateDatas();
        isNeedPageDatasUpdate = false;
    }
});

const doCreateAppInfo = async (itemInfo) => {
    const responseDatas = await wsClient.sendActionToCloud("CREATE_USER_INFO", itemInfo);
    if (responseDatas.resultCode == 1) {
        let newRouteItemMappings =itemInfo.newRouteItemMappings;
        for(let newRouteItemMapping of newRouteItemMappings)
        {
            let routeItemInfo = newRouteItemMapping.itemInfo;
            
            const routeType = new RouterType();
            routeType.init(wsClient, router);
            routeType.saveData(routeItemInfo);
        }
        if (responseDatas.resultCode == 1) {
            onClickLeft();
        }
    }
    let params = {
        message: responseDatas.message,
        position: "bottom",
    };
    showToast(params);
};
const onClickLeft=()=> {
    newAppWizard.value.resetDatas();
    backListenerState.value = false;
    isNeedPageDatasUpdate = true;
    router.push({
        name: backTargetPage,
        state: {
            pageDatas: JSON.stringify(backTargetPageDatas)
        }
    });
};
</script>
<style scoped></style>
  