<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar
      class="sencneNavbar"
      leftText="返回"
      title="新建精灵"
      :autoControl="false"
      :listenerState="backListenerState"
      @onBack="onClickLeft"
    />
    <WizardPane
      ref="newSpiritWizard"
      :stepOptions="stepOptions"
      @onDataSubmit="createSpirit"
    >
    </WizardPane>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref,onActivated} from "vue";
import {
    useRouter,
} from 'vue-router';
import FirstStep from "@/components/app/views/setting/newSpirit/FirstStep.vue";
import SecondStep from "@/components/app/views/setting/newSpirit/SecondStep.vue";
import ThirdStep from "@/components/app/views/setting/newSpirit/ThirdStep.vue";
import FourthStep from "@/components/app/views/setting/newSpirit/FourthStep.vue";
import {showToast} from "vant";
const wsClient =getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router =useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let stepOptions = ref([]);
const newSpiritWizard =ref(null);
let isNeedPageDatasUpdate=false;
onActivated(()=>{
  if(isNeedPageDatasUpdate)
  {
    newSpiritWizard.value.updateDatas();
    isNeedPageDatasUpdate =false;
  }
});
const getWizardDatas = () => {
      return [
        {
          stepName: "基础配置",
          component: FirstStep,
        },
        {
          stepName: "关联配置",
          component: SecondStep,
        },
        {
          stepName: "全局配置",
          component: ThirdStep,
        },
        {
          stepName: "创建完毕",
          component: FourthStep,
        },
      ];
    };
stepOptions = getWizardDatas();
const backListenerState =ref(true);
const createSpirit=async(datas)=>{
      let responseDatas = await wsClient.sendActionToCloud(
        "CREATE_SPIRIT",
        datas
      );
      let resultCode = responseDatas.resultCode;
      let params = {
        message: responseDatas.message,
        position: "bottom",
      };
      showToast(params);
      if (resultCode == 1) {
        onClickLeft();
      }
    };
  const onClickLeft=()=>{
      newSpiritWizard.value.resetDatas();
      backListenerState.value=false;
      isNeedPageDatasUpdate =true;
      router.push({
        name: backTargetPage,
        state:{
            pageDatas:JSON.stringify(backTargetPageDatas)
        }
      });
    };
</script>
<style scoped>
</style>
