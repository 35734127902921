<template>
    <!--元素选择页面，基于配置选择对应的元素-->
  <div class="bg-lightGrey height-full">
    <BackableNavBar :title="pageTitle" leftText="返回" @onBack="onClickLeft" />
    <div>
      <ConfigPane :itemsOption="itemsType" :pageType="pageType" 
      :singleSelectedMode="singleSelectedMode" 
      :selectedMode="selectedMode"
      :elementsHideConfig="elementsHideConfig"
      :itemsExclude="itemsExclude"
       @onItemsSelected="doItemSelectedChanged"/>
    </div>
  </div>
  <div id="autoSpirit_selectItem_sureBtn">
      <div>
        <div v-if="buttomDisabled">
          <Button disabled type="primary" class="sureBtn">确定</Button>
        </div>
        <div v-else>
          <Button type="primary" class="sureBtn" @click="doItemsSelected">确定</Button>
        </div>
      </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref,onMounted,onUnmounted} from "vue";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import {
    useRouter,
} from 'vue-router';
const itemTypeConfig ={
  "ALL_TYPE":typeUtil.getItemTypeObj('ALL_TYPE'),
  "SPIRIT_TYPE":typeUtil.getItemTypeObj('SPIRIT_TYPE'),
  "TASK_TYPE":typeUtil.getItemTypeObj('TASK_TYPE'),
  "STEP_TYPE":typeUtil.getItemTypeObj('STEP_TYPE'),
  "APP_TYPE":typeUtil.getItemTypeObj('APP_TYPE'),
  "PAGE_TYPE":typeUtil.getItemTypeObj('PAGE_TYPE'),
  "ROUTE_TYPE":typeUtil.getItemTypeObj('ROUTE_TYPE'),
}
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router =useRouter();
let pageDatas = history.state.pageDatas;
if(pageDatas)pageDatas = JSON.parse(pageDatas);
const backTargetPage =pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas=pageDatas.backTargetPageDatas?pageDatas.backTargetPageDatas:{};
//元素选择事件名，用于回调其他页面的方法
const itemSelectedEventName =pageDatas.eventName ? pageDatas.eventName : "";
const listenerID =pageDatas.listenerID ? pageDatas.listenerID : "";
const itemsOption =pageDatas.itemsType?pageDatas.itemsType:[];
//元素隐藏规则，一般基于元素的类型
const elementsHideConfig =pageDatas.elementsHideConfig?pageDatas.elementsHideConfig:{};
//是否时可以选择元素模式，在此模式下元素可以选择
const selectedMode =pageDatas.selectedMode?pageDatas.selectedMode:false;
//是否时单元操作模式
const singleSelectedMode =pageDatas.singleSelectedMode?pageDatas.singleSelectedMode:false;
//判断页面的类型，看是流程配置面板还是页面配置面板
const pageType =pageDatas.pageType?pageDatas.pageType:"0";
//页面标题
const pageTitle =pageDatas.pageTitle?pageDatas.pageTitle:"元素选择";
//排除显示元素
const itemsExclude =pageDatas.itemsExclude?pageDatas.itemsExclude:[];
let itemTypesArr =[];
//初始化元素类型对象
for(let itemType of itemsOption)
{
  let tempType =itemTypeConfig[itemType];
  if(tempType)
  {
    tempType.init(wsClient,router);
    itemTypesArr.push(tempType);
  }
}
const itemsType = ref(itemTypesArr);
const buttomDisabled =ref(true);
let selectedItems =[];
/**
 * 选择对象时更新按钮状态和已选择的对象缓存
 * @param {已选择的对象} tempSelectedItems 
 */
const doItemSelectedChanged =(tempSelectedItems)=>{
  buttomDisabled.value =!(tempSelectedItems.length>0);
  selectedItems =tempSelectedItems;
};

onUnmounted(()=>{
  wsClient.removeEventListener(itemSelectedEventName,listenerID);
});
/**
 * 元素选择确认时的动作
 */
const doItemsSelected = () => {
  wsClient.broadcastEvents(itemSelectedEventName, selectedItems);
};
const onClickLeft = () => {
  router.push({
      name: backTargetPage,
      state:{
            pageDatas:JSON.stringify(backTargetPageDatas)
        }
    });
};
onMounted(()=>{
  let btnWapper = document.getElementById("autoSpirit_selectItem_sureBtn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
});
</script>
<style scoped>
#autoSpirit_selectItem_sureBtn {
  position: absolute;
}
.sureBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>