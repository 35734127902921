const EventEmitter = require('events');
module.exports =class EventMgr
{
    constructor()
    {
        this.initListeners();
    }
    initListeners() {
        this.boadCaster = new EventEmitter();
    }
    broadcastEvents(eventName, params,callback) {
        this.boadCaster.emit(eventName, params,callback);
    }
    addEventListener(eventName, fun) {
        this.boadCaster.on(eventName, fun);
    }
    removeEventListener(eventName, funID) {
        this.boadCaster.removeListener(eventName, funID);
    }

     //释放内存并退出
     dispose() {
        if (this.boadCaster) {
            let events = this.boadCaster.eventNames();
            events.forEach((eventName) => {
                this.boadCaster.removeAllListeners(eventName);
            });
        }
    }
}