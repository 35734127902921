<template>
    <Cell v-if="!isDisabled" center is-link :title="title" :label="label">
        <template #title>
            <div class="flex">
                <div>{{ title }}</div>
                <Icon class="content-vcenter rightIcon" name="info-o" size="20"
                    @click="showTips(tipInfo ? tipInfo : title)" />
            </div>
        </template>
        <template #value>
            <div v-if="type == 'textarea'" @click="showInputProsetPane(title, value)">设置</div>
            <div v-if="type == 'text'" @click="showInputProsetPane(title, value)">{{ runValue?runValue:"设置" }}</div>
            <div v-if="type == 'placeArea'" @click="openScreemAreaCutAction">{{ runValue?runValue:"设置" }}</div>
            <div class="content-vcenter block-inline bg-black" v-if="type == 'imageCut'" @click="doClick">
                <Image class="border flex center as-avatar xl" fit="contain" :src="convert2Url(runValue)"></Image>
            </div>
            <div v-if="type == 'relateVariable'" @click="showInputProsetPane(title, value)">{{ runValue?runValue:"设置" }}
            </div>
            <div v-if="type == 'relateStep'"  @click="doClick">{{ runValue?runValue:"设置" }}
            </div>
        </template>
        <template #right-icon v-if="(type == 'imageCut'|| type == 'placeArea')">
            <div class="content-vcenter block-inline"  @click="showInputProsetPane(title, value)">
                <Button class="content-vcenter block-inline margin-left" 
                type="primary" size="small">...</Button>
            </div>
        </template>
    </Cell>
    <Cell v-if="isDisabled" center :title="title" :label="label">
        <template #title>
            <div class="flex">
                <div>{{ title }}</div>
                <Icon class="content-vcenter rightIcon" name="info-o" size="20"
                    @click="showTips(tipInfo ? tipInfo : title)" />
            </div>
        </template>
        <template #value>
            <div v-if="type == 'textarea'">设置</div>
            <div v-if="type == 'text'">{{ runValue?runValue:"设置" }}</div>
            <div v-if="type == 'placeArea'">{{ runValue?runValue:"设置" }}</div>
            <div class="content-vcenter block-inline" v-if="type == 'imageCut'">
                <Image class="border flex center as-avatar xl" :src="convert2Url(runValue)"></Image>
            </div>
            <div v-if="type == 'relateVariable'">{{ runValue?runValue:"设置" }}
            </div>
            <div v-if="type == 'relateStep'" >{{ runValue?runValue:"设置" }}
            </div>
        </template>
        <template #right-icon v-if="(type == 'imageCut'|| type == 'placeArea')">
            <div class="content-vcenter block-inline">
                <Button disabled class="content-vcenter block-inline margin-left" 
                type="primary" size="small">...</Button>
            </div>
        </template>
    </Cell>
    <Field v-if="type == 'textarea' && runValue" readonly autosize type="textarea" show-word-limit class="bg-trans"
        v-model="runValue" />
</template>
<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, ref, watch } from "vue";
import util from "@/utils/util";
import { showToast } from "vant";
let wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
    title: {
        type: String
    },
    label: {
        type: String
    },
    tipInfo: {
        type: String
    },
    validate: {
        type: Function
    },
    value: {
        type: String
    },
    type: {
        type: String,
        default: "text"
    },
    name: {
        type: String
    },
    maxLength: {
        type: String,
        default: "100"
    },
    isNewAction: {
        type: Boolean,
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    hanlder: {
        type: Object
    }
});
const emit = defineEmits(["onSubmit", "onCancel","onClick"]);
const {
    title,
    label,
    value,
    type,
    maxLength,
    validate,
    tipInfo,
    name,
    isNewAction,
    hanlder,
    isDisabled
} = toRefs(props);
watch(value, () => {
    runValue.value = value.value;
});
const runValue = ref("");
runValue.value = value.value;

const doClick =async()=>{
    emit("onClick");
};


const openScreemAreaCutAction=async()=>{
    let areaText =await wsClient.sendActionToUI("AUTOSPIRIT_OPERATE_EVENT_ACTION", {
        opeatorType:"AutoSpiritOperator",
        eventKey:"openScreemAreaCut",
        eventDatas:{
            stepActionType:hanlder.value.getStepActiopnType(),
            cutOptions:runValue.value}
     });
     let componentDatas = hanlder.value.getComponentData();
    emit("onSubmit", {
        componentDatas: areaText,
        conditions:componentDatas.conditions
    });
};

const onSubmit = (tempVame) => {
    switch(type.value)
    {
        case "relateVariable":
        case "placeArea":
        case "imageCut":
            emit("onSubmit", tempVame);
            break;
        default:
            if (validate.value) {
                if (validate.value(tempVame)) {
                    runValue.value = tempVame;
                    emit("onSubmit", tempVame);
                }
            }
            else {
                runValue.value = tempVame;
                emit("onSubmit", tempVame);
            }
            break;
    }
};
/**
 * 提示信息
 * @param {*} message 
 */
const showTips = (message) => {
    showToast({
        message: message,
        position: 'top'
    });
};
const convert2Url = (imageBase64) => {
    return util.getImageDataUrlFromBase64(imageBase64);
}
const onCancel = () => {
    emit("onCancel", {});
}
const showInputProsetPane = (tempTitle, tempValue) => {
    switch (type.value) {
        case "imageCut":
        case "placeArea":
        case "relateVariable":
            if (hanlder.value) {
                let componentDatas = hanlder.value.getComponentData();
                wsClient.broadcastEvents("AUTOSPIRIT_PROSETPANE_ACTION", {
                    "title": title.value,
                    "value": runValue.value,
                    'type': "pageComponent",
                    'componentPath': 'components/app/views/common/VariableSelectPane',
                    'componentDatas': componentDatas,
                    'submit': onSubmit,
                    "cancel": onCancel
                });
            }
            break;
        case "text":
        case "textarea":
        default:
            wsClient.broadcastEvents("AUTOSPIRIT_PROSETPANE_ACTION", {
                "title": tempTitle,
                "value": tempValue,
                'type': type.value,
                'maxLength': maxLength.value,
                "submit": onSubmit,
                "cancel": onCancel
            });
            break;

    }
}
</script>
<style>
.bg-trans .van-field__control {
    color: #969799
}

.rightIcon {
    align-self: center;
    display: inline-flex;
    margin-left: 5px;
}
</style>