<template>
  <!--app登录面板-->
  <div class="as-vcenter">
    <Row justify="space-around">
      <Image class="as-avatar" round :src="logoIcon" />
    </Row>
    <Row justify="space-around" class="margin-top xxl">
      <div class="flex-inline" :style="{ 'height': computeHeight }">
        <Form @submit="onSubmit">
          <CellGroup inset>
            <Field v-model="username" name="phone" label="用户名" placeholder="请输入手机号码" type="tel" :rules="[
              { required: true, message: '请输入手机号码' },
              { validator: validatorPhone },
            ]" />
            <Field v-model="password" type="password" name="password" label="密码" placeholder="请输入密码"
              :rules="[{ required: true, message: '请输入密码' }]" />
          </CellGroup>
          <Button block class="margin-top" type="primary" native-type="submit">
            登录
          </Button>
          <Button class="margin-top" block plain type="primary" @click="gotoRegistPage">
            新注册
          </Button>
        </Form>
      </div>
    </Row>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, computed, onMounted } from "vue";
import { showToast } from "vant";
import {
  useRouter,
} from 'vue-router';
const username = ref("");
const password = ref("");
const router = useRouter();
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const computeHeight = computed(() => {
  return window.innerHeight * 0.35 + "px";
});
const logoIcon =ref("");

onMounted(async()=>{
   logoIcon.value = wsClient.getIconBase64Str("LOGO_ICON");
   let datas = await wsClient.sendActionToCloud("VALIDATE_TOKEN", {});
    let isNeedLogin = datas.resultCode !== 1;
    let routePath = isNeedLogin ? "Login" : "Content";
    router.push({
      name: routePath,
      state:{
        pageDatas:JSON.stringify({ active: 0 })
      }
    });
    let params = {
      message: datas.message,
      position: "bottom",
    };
    showToast(params);
});
/**
 * 响应登录提交
 * @param {登录信息} values 
 */
const onSubmit = async (values) => {
  let datas = await wsClient.sendActionToCloud("USER_LOGIN", values);
  let isLoginSuccess = datas.resultCode == 1;
  //成功则跳转到主页面
  let routePath = !isLoginSuccess ? "Login" : "Content";
  let params = {
    message: datas.message,
    position: "bottom",
  };
  showToast(params);
  if (isLoginSuccess) {
    router.push({
      name: routePath,
      state: {
        pageDatas: JSON.stringify({ active: 0 })
      }
    });
  }
};
/**
 * 号码格式校验
 * @param {号码} value 
 */
const validatorPhone = (value) => {
  let validator =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  return !validator.test(value) ? "请输入正确格式的手机号码！" : "";
};
/**
 * 跳转到注册页面
 */
const gotoRegistPage = () => {
  router.push({
    name: "Regist"
  });
};
</script>
<style scoped>
</style>