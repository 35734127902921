<template>
    <div class="height-full">
        <div class="padding">
            待配置
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose,toRefs,defineProps } from "vue";
import { useRouter } from "vue-router";
import util from "@/utils/util";
import optionsConfig from "@/components/app/views/common/stepType/OptionsConfig.js";
import NewRelateItemOperator from "@/components/app/views/common/configPane/itemAction/NewRelateItemOperator.js";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const operator = new NewRelateItemOperator();
operator.init(wsClient, router);
//当前任务下所关联的步骤映射集
const itemRelateMappings = ref([]);
const loadingState = ref(false);
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let parentItemInfo = firsData.itemInfo ? firsData.itemInfo : {};

/**
* 相应添加映射关联的动作
*/
const doAddRelateTask = async () => {
  let itemsExcludeArr = [];
  for (let relateMapping of itemRelateMappings.value) {
    let key = relateMapping.itemInfo.key;
    itemsExcludeArr.push(key);
  }
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "NewAppConfig",
    eventName: "AUTOSPIRIT_NEWAPPCONFIG_PAGESSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsExclude: itemsExcludeArr,
    itemsType: ["PAGE_TYPE"],
    selectedMode: true,
    pageType: "1",
    pageTitle: "页面选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let sequence = parentItemInfo.sequence;
    let ids = sequence ? sequence.split(",") : [];
    let index = ids.length + 1;
    let timestamp = new Date().getTime();
    //基于选择的元素，遍历构建查询对象
    for (let selectedItem of selectedItems) {
      let tempMapping = {
        id: selectedItem.id + "@" + selectedItem.type + "@" + timestamp,
        parentID: parentItemInfo.id,
        parentType: parentItemInfo.type,
        relateID: selectedItem.id,
        relateType: selectedItem.type,
        itemInfo: selectedItem,
        index: index,
        operateOptions: util.cloneObject(optionsConfig.newItemRelateMappingOperateOptions),
        parentItemInfo: parentItemInfo,
        disable: false
      };
      itemRelateMappings.value.push(tempMapping);
      ids.push(tempMapping.id);
      index++;
    }
    parentItemInfo.sequence = util.makeSequenceStr(ids);
    router.push({
      name: datas.backTargetPage,
    });
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
const validateOnNext = () => {
    let isPass = true;
    return {
        resultCode: isPass ? 1 : 0,
        message: isPass ? "" : "请先选择应用！",
    };
};
const getOperateDatas = () => {
    return {};
};
defineExpose({
    validateOnNext,
    getOperateDatas
});
</script>