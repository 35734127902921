const BaseStepActionType = require("./BaseStepActionType");
module.exports = class BaseMatchActionType extends BaseStepActionType {
    id = "AUTOSPIRIT_STEP_BASEMATCH";
    name = "默认匹配对象";
    define="这是一个不然匹配步骤基类";

    getDefaultData(){
        let data={
            actionTypeByMatch:"MATCH_SUCCESS_CLICK",
            actionDataByUnMatch:"MATCH_FAILED_DONEXT",
        };
        let resultData={...data,...super.getDefaultData()};
        return resultData;
    }

    getTabOptions(){
        let option=[
            {
                id: "AUTOSPIRIT_MATCH_CONTORL",
                name: "匹配结果控制",
                viewPath: "MatchContorlParameter",
                index:2,
            }
        ];
        let resultOption =[...option,...super.getTabOptions()];
        return resultOption;
    }
    
}