// const util = require("@/utils/util");
const BaseRelateItemOperator = require('./BaseRelateItemOperator');
module.exports = class SpiritTypeRelateOperator extends BaseRelateItemOperator {
  id = "SPIRITINFO_TYPE_OPERATOR";
    /**
   * 更新当前任务最新的映射队列，并重新排序
   * @param {映射队列字符串} newSequence 
   */
  updateSequenceAndSort = async (newSequence) => {
    let appInfo =this.handler.getItemInfo();
    appInfo.sequence = newSequence;
    let taskRelateMappings = this.handler.getRelateMappings();
    let queryData = {
      id: appInfo.id,
      sequence: newSequence
    };
    let responseDatas = await this.handler.getClientServer().sendActionToCloud(
      "UPDATE_SPIRIT_SEQUENCE",
      queryData
    );
    if (responseDatas.resultCode == 1) {
      appInfo.sequence = newSequence;
      this.sortRelateMappings(newSequence, taskRelateMappings);
    }
  }

  getOptionVisableState(relateOperateOption, relateMappingObj) {
    if (relateOperateOption.id == "RELATEMAPPING_INSERT_STEP_BEFORE"||
    relateOperateOption.id == "RELATEMAPPING_INSERT_STEP_BEFORE_COPY"||
    relateOperateOption.id == "RELATEMAPPING_INSERT_STEP_AFTER_COPY"||
    relateOperateOption.id == "RELATEMAPPING_STEP_COPY"||
    relateOperateOption.id == "RELATEMAPPING_STEP_CHANGE_DISABLE"||
    relateOperateOption.id == "RELATEMAPPING_STEP_CHANGE_ENABLE"||
    relateOperateOption.id == "RELATEMAPPING_STEP_RUN"
    )
    {
      return false;
    }
    return super.getOptionVisableState(relateOperateOption, relateMappingObj);;
  };
}