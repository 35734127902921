<template>
   <div class="padding">
        <div class="bg-white border solid radius">
            <ParameterSetting ref="newVaraiblePane" :isNewAction="true" 
            :itemVariables="spiritVariableDatas"
            :itemInfo="parentItemInfo"></ParameterSetting>
        </div>
    </div>
</template>
<script setup>
import {defineProps, defineExpose,toRefs,ref} from "vue";

const props = defineProps({
  operateDatas: {
    type: Object
  }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let parentItemInfo = firsData.itemInfo ? firsData.itemInfo : {};
const spiritVariableDatas =ref([]);
const newVaraiblePane = ref(null);

const validateOnNext = () => {
  let variableDatas =newVaraiblePane.value.getVariableDatas();
  let isPass = true;
  let resultData={};
  for(let variableData of variableDatas)
  {
     resultData = variableData.validate();
    if(resultData.resultCode==0)
    {
       isPass =false;
       break;
    }
  }
  return {
    resultCode: isPass ? 1 : 0,
    message: isPass ? "" : resultData.message,
  }
};
const getOperateDatas = () => {
  let variableDatas =newVaraiblePane.value.getVariableDatas();
  return {
    variableDatas: variableDatas,
  };
};

const resetDatas = async () => {
  spiritVariableDatas.value=[];
  newVaraiblePane.value.resetDatas();
};

defineExpose({
  validateOnNext,
  getOperateDatas,
  resetDatas,
});
</script>
<style scoped>

</style>