<template>
    <div class="height-full">
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础配置："></Cell>
                    <ProsetCell title="页面名称" :value="pageInfo.name"
                        @onSubmit="changePageName"></ProsetCell>
                    <ProsetCell  title="页面描述" :value="pageInfo.define" type="textarea"
                        @onSubmit="changePageDefine">
                    </ProsetCell>
                    <Cell v-show="sencneType.name != '常用'" :title="pageInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
                        <template #right-icon>
                            <Switch v-model="pageInfo.commom" size="24" />
                        </template>
                    </Cell>
                    <Cell v-show="sencneType.name == '常用'" :title="pageInfo.commom ? '常用状态：开启' : '常用状态：关闭'"
                        class="bg-trans">
                        <template #right-icon>
                            <Switch disabled v-model="pageInfo.commom" size="24" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景配置："></Cell>
                    <Cell center is-link title="场景类别" :value="sencneTitle"
                        @click="gotoSencneTypeSelect"></Cell>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, defineProps,reactive,onMounted} from "vue";
import {
    useRouter,
} from 'vue-router';

const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
let pageInfo = reactive(pageDatas.itemInfo ? pageDatas.itemInfo : {type: "PAGE_TYPE"});
let sencne = pageDatas.sencne ? pageDatas.sencne : {};
const sencneType = ref(pageDatas.sencneType ? pageDatas.sencneType : {});
pageInfo.sencneTypeID = sencneType.value.id;
const sencneTitle = ref("");
const router = useRouter();
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
onMounted(async () => {
    sencneTitle.value = getItemInfoSencneString();
    pageInfo.commom=(sencneType.value.name=="常用");
});
const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.value.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.value.name + '】';
}
const changePageName = (newValue) => {
    pageInfo.name = newValue;
};
const changePageDefine = (newValue) => {
    pageInfo.define = newValue;
};
const gotoSencneTypeSelect = () => {
    let datas = {
        sencnePageType: 1,
        backTargetPage: "NewPageConfig",
        eventName: "AUTOSPIRIT_PAGECONFIG_SENCNETYPESELECT_EVENT",
        selectedSencneType: sencneType.value,
        selectedSencne: sencne
    };
    const actionMethod = (eventDatas) => {
        sencne = eventDatas.sencne;
        sencneType.value = eventDatas.sencneType;
        pageInfo.sencneTypeID = sencneType.value.id;
        sencneTitle.value = getItemInfoSencneString();
        router.push({
            name: datas.backTargetPage,
        });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "SencneTypeSelected",
        state:{
            pageDatas:JSON.stringify(datas)
        }
    });
};
const validateOnNext = () => {
    let isPass = pageInfo.name ? true : false;
    return {
        resultCode: isPass ? 1 : 0,
        message: isPass ? "" : "请先输入页面信息！",
    };
};
const getOperateDatas = () => {
    let resultData = {
        itemInfo: pageInfo,
        sencne: sencne,
        sencneType: sencneType.value
    };
    return resultData;
};
const resetDatas = async () => {
    pageInfo.name = "";
    pageInfo.define = "";
    pageInfo.commom = false;
    sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : {};
    sencne = pageDatas.sencne ? pageDatas.sencne : {};
    pageInfo.sencneTypeID = sencneType.value.id;
    sencneTitle.value = getItemInfoSencneString();
    pageInfo.commom=(sencneType.value.name=="常用");
};
const updateDatas = () => {
    let pageDatas = history.state.pageDatas;
    if (pageDatas) pageDatas = JSON.parse(pageDatas);
    sencne = pageDatas.sencne ? pageDatas.sencne : {};
    sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : {};
    pageInfo.sencneTypeID = sencneType.value.id;
    sencneTitle.value = getItemInfoSencneString();
    pageInfo.commom=(sencneType.value.name=="常用");
};
defineExpose({
    resetDatas,
    validateOnNext,
    getOperateDatas,
    updateDatas
});

</script>
<style scoped></style>