<template>
  <!--应用配置页面，用于配置操作app，page，route等对象组合-->
  <ConfigPane :itemsOption="itemsType" pageType="1"/>
</template>
<script setup>
import {getCurrentInstance, ref} from "vue";
import {
    useRouter,
} from 'vue-router';
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
let wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const allType = typeUtil.getItemTypeObj('ALL_TYPE');
const appType = typeUtil.getItemTypeObj('APP_TYPE');
const pageType = typeUtil.getItemTypeObj('PAGE_TYPE');
const routerType = typeUtil.getItemTypeObj('ROUTE_TYPE');
const itemsType = ref([
  allType.init(wsClient,router),
  appType.init(wsClient,router),
  pageType.init(wsClient,router),
  routerType.init(wsClient,router)
]);
</script>
<style>
</style>