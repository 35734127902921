<template>
    <div class="padding">
        <div class="bg-white border solid radius padding">
            <CellGroup>
                <Cell center is-link title="选择步骤类型:" :value="selectedItemType.name ? selectedItemType.name : '设置'"
                    @click="gotoStepActionTypeSelect"></Cell>
                <!-- <Cell class="bg-trans" title="" v-show="selectedItemType.name">
                    <template #right-icon>
                        <div class="flex">
                            <Icon class="rightIcon color-blue content-vcenter block-inline" name="replay" size="20"
                                @click="resetStepActionInfos">
                                <div class="rightText">重置</div>
                            </Icon>
                        </div>
                    </template>
</Cell> -->
            </CellGroup>
            <!--步骤信息设置面板，用于编辑操作高级内容-->
            <Tabs v-show="selectedTabOptions.length > 0" shrink v-model:active="active" background="transparent">
                <Tab v-for="tabOption in selectedTabOptions" :key="tabOption.id" :title="tabOption.name">
                    <template #title> <span class="stepTab">{{ tabOption.name }}</span></template>
                    <component ref="tabInfoPanes" :is="tabOption.viewPath" :itemInfo="itemInfo" :isNewAction="true">
                    </component>
                </Tab>
            </Tabs>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, toRefs, ref, defineExpose, defineProps, watch, nextTick } from "vue";
import {
    useRouter,
} from 'vue-router';
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import NewStepActionTypeOperator from "@/components/app/views/common/stepType/tabOptionViews/NewStepActionTypeOperator.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const { operateDatas } = toRefs(props);
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let itemInfo = firsData.itemInfo ? firsData.itemInfo : {};
let secondData = tempOperateDatas[1];
const selectedItemType = ref({});
//当前步骤类型下的操作页签数据
const selectedTabOptions = ref([]);
const active = ref(-1);
const tabInfoPanes = ref(null);
const stepActionInfos = ref({});
//控制条件集
const conditionOperateDatas = ref([]);
const occusConditionMappings = ref([]);
const variableProsMappings = ref([]);
itemInfo.variables = secondData.variableDatas ? secondData.variableDatas : [];

const tabOperateHanlder = new NewStepActionTypeOperator();
tabOperateHanlder.init({
    stepActionInfos: stepActionInfos,
    conditionOperateDatas: conditionOperateDatas,
    occusConditionMappings: occusConditionMappings,
    variableProsMappings: variableProsMappings,
    backPageName: "NewStepConfig",
    wsClient: wsClient
})

watch(selectedItemType, () => {
    if (selectedItemType.value.id) {
        refreshStepActionInfos();
    }
});

const updateDatas = () => {
    tempOperateDatas = operateDatas.value;
    firsData = tempOperateDatas[0];
    itemInfo = firsData.itemInfo ? firsData.itemInfo : {};
    secondData = tempOperateDatas[1];
    itemInfo.variables = secondData.variableDatas ? secondData.variableDatas : [];
};

const refreshStepActionInfos = async () => {

    if (selectedItemType.value.getTabOptions) {
        let options = selectedItemType.value.getTabOptions();
        selectedTabOptions.value = options ? options : [];
    }
    //如果没有设置步骤动作信息，则取默认的数据
    selectedItemType.value.init(wsClient);
    stepActionInfos.value = selectedItemType.value.getDefaultStepActionTypeInfos(itemInfo);
    //控制条件集
    conditionOperateDatas.value = [];
    occusConditionMappings.value = [];
    variableProsMappings.value = [];
    resetStepActionTypeInfo(active.value)
};


const resetStepActionTypeInfo = (active) => {
    if (active == -1) return;
    let tabOptions = selectedItemType.value.getTabOptions();
    let tabID = tabOptions[active].id;
    nextTick(() => {
        if (tabInfoPanes.value) {
            let viewIdex = 0;
            for (let tabInfoPane of tabInfoPanes.value) {
                let id = tabInfoPane.getID();
                if (tabID == id) {
                    break;
                }
                viewIdex++
            }
            let subTabInfoPane = tabInfoPanes.value[viewIdex];
            if (subTabInfoPane) {
                console.log(`resetStepActionTypeInfo success,active:${active},viewIdex:${viewIdex}`);
                subTabInfoPane.resetData(tabOperateHanlder, active);
            }
            else {
                console.log(`resetStepActionTypeInfo failed,active:${active},viewIdex:${viewIdex}`);
            }
        }
    });
}

watch(active, (newValue) => {
    if (selectedItemType.value.id) {
        resetStepActionTypeInfo(newValue);
    }
});
/**
 * 响应步骤类型选择事件
 */
const gotoStepActionTypeSelect = () => {
    //构建当前页面的页面信息
    let pageDatas = {
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas,
    }
    let datas = {
        backTargetPage: "NewStepConfig",
        backTargetPageDatas: pageDatas,
        eventName: `AUTOSPIRIT_STEPINFO_STEPTYPESELECT_EVENT_${itemInfo.id}`,
        selectedStepActionType: selectedItemType.value
    };
    //处理步骤类型改变动作
    const actionMethod = async (eventDatas) => {
        selectedItemType.value = eventDatas.selectStepActionType ? eventDatas.selectStepActionType : {};
        // await refreshStepActionInfos();
        router.push({
            name: datas.backTargetPage,
            state: {
                pageDatas: JSON.stringify(pageDatas)
            }
        });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "StepActionTypeSelected",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};
const validateOnNext = () => {
    let isPass = true;
    return {
        resultCode: isPass ? 1 : 0,
        message: isPass ? "" : "请先选择应用！",
    };
};
const getOperateDatas = () => {
    return {
        selectedItemType: selectedItemType.value,
        selectedTabOptions: selectedTabOptions.value,
        stepActionInfos: stepActionInfos.value,
        conditionOperateDatas: conditionOperateDatas.value,
        occusConditionMappings: occusConditionMappings.value,
        variableProsMappings: variableProsMappings.value,
    };
};
const resetDatas = async () => {
    selectedItemType.value = {};
    selectedTabOptions.value = [];
    conditionOperateDatas.value = [];
    occusConditionMappings.value = [];
    variableProsMappings.value = [];
    active.value = -1;
};
defineExpose({
    updateDatas,
    resetDatas,
    validateOnNext,
    getOperateDatas
});
</script>
<style scoped>
.stepTab {
    font-size: 15px;
}

.rightText {
    font-size: 14px;
    align-self: center;
}

.rightIcon {
    align-self: center;
    display: inline-flex;
}
</style>