const SpiritServerUtil = require('./SpiritServerUtil');
const { showToast } = require("vant");
const axios = require('axios').default;
const util = require("@/utils/util");
module.exports = class WebEventClient extends SpiritServerUtil {
  serverName = "AUTOSPIRIT_VIEW";
  // cloudUrl = "http://192.168.31.61:8085";
  // cloudUrl = "http://192.168.43.244:8085"; 
  cloudUrl = "https://www.autospiritx.com";

  // nodeWSUrl = "ws://192.168.31.61:8181";
  // nodeWSUrl = "ws://192.168.43.244:8181"; 
  nodeWSUrl = "wss://www.autospiritx.com/vueWs";

  clientID = "com.jianluo.autospirit";
  heartCheckIntervalTime = 20 * 1000; //毫秒
  heartCheckIntervalID = null;
  serverReconnectID = null;
  messageCache = new Array();
  lockReconnect = false;
  reTryConnectServerNum = 10;
  reconectCount = 0;
  isDispose = false;
  serverActionOptions =
    {
      VALIDATE_TOKEN: {
        path: "/autoSpirit/validateToken",
        preformDatas: () => {
          let params = {
            "token": this.getItemFromStorage("token", true),
            "phone": this.getItemFromStorage("phone", true),
          }
          return params;
        },
        customAction: (responseDatas) => {
          this._dealUserLoginResponse(responseDatas);
        }
      },
      USER_LOGIN: {
        path: "/autoSpirit/userLogin",
        customAction: (responseDatas) => {
          this._dealUserLoginResponse(responseDatas);
        },
        unTokens: true,
      },
      USER_REGIST: {
        path: "/autoSpirit/userRegist",
      },
      USER_LOGOUT: {
        path: "/autoSpirit/userLogout",
        customAction: (responseDatas) => {
          let resultCode = responseDatas.resultCode;
          if (resultCode == 1) {
            this.addItemToStorage("phone", "", true);
          }
        }
      },
      GET_SENCNE_BY_ID: {
        path: "/autoSpirit/getSencneByID",
      },
      GET_USER_SENCNES: {
        path: "/autoSpirit/getSencnesByName",
      },
      GET_DEFAULT_STEP_SENCNETYPE: {
        path: "/autoSpirit/getDefaultStepSencneType",
      },
      CREATE_USER_SENCNES: {
        path: "/autoSpirit/createUserSencne",
      },
      UPDATE_SENCNE_NAME: {
        path: "/autoSpirit/updateSencneName",
      },
      DELETE_SENCNE: {
        path: "/autoSpirit/deleteSencne",
      },
      GET_SENCNETYPES: {
        path: "/autoSpirit/getSencneTypes",
      },
      CREATE_SENCNETYPE: {
        path: "/autoSpirit/createSencneType",
      },
      GET_SENCNETYPE_BY_ID: {
        path: "/autoSpirit/getSencneTypeByID",
      },
      UPDATE_SENCNETYPE_NAME_BY_ID: {
        path: "/autoSpirit/updateSencneTypeName",
      },
      DELETE_SENCNETYPE: {
        path: "/autoSpirit/deleteSencneType",
      },
      GET_SPIRIT_BY_ID: {
        path: "/autoSpirit/getSpiritByID",
      },
      CREATE_SPIRIT: {
        path: "/autoSpirit/createSpirit",
      },
      DELETE_SPIRIT: {
        path: "/autoSpirit/deleteSpirits",
      },
      UPDATE_SPIRIT_INFO: {
        path: "/autoSpirit/updateSpiritInfo",
      },
      UPDATE_SPIRIT_NAME: {
        path: "/autoSpirit/updateSpiritName",
      },
      UPDATE_SPIRIT_IMAGE: {
        path: "/autoSpirit/updateSpiritImage",
      },
      UPDATE_SPIRIT_DISCRIPE: {
        path: "/autoSpirit/updateSpiritDiscripe",
      },
      UPDATE_SPIRIT_COMMOM: {
        path: "/autoSpirit/updateSpiritsCommom",
      },
      UPDATE_SPIRIT_SEQUENCE: {
        path: "/autoSpirit/updateSpiritSequence",
      },
      UPDATE_SPIRITINFOS_SENCNETYPEID: {
        path: "/autoSpirit/updateSpiritsSecneTypeID",
      },
      GET_SPIRITS_BY_SENCNETYPEID: {
        path: "/autoSpirit/getSpiritsBySencneTypeID",
      },
      UPLOAD_TASK_FILE: {
        path: "/autoSpirit/uploadTaskFile",
      },
      UPDATE_TASK_INFO: {
        path: "/autoSpirit/updateTaskInfo",
      },
      UPDATE_TASK_SENCNETYPEID: {
        path: "/autoSpirit/updateTasksSecneTypeID",
      },
      UPDATE_TASK_INFO_SEQUENCE: {
        path: "/autoSpirit/updateTaskSequence",
      },
      GET_APP_INFOS_BY_TASKS_ID: {
        path: "/autoSpirit/getAppInfosByTaskID",
      },
      DELETE_TASK_BY_ID: {
        path: "/autoSpirit/deleteTaskInfos",
      },
      CREATE_USER_INFO: {
        path: "/autoSpirit/insertAppInfo",
      },
      GET_APPINFOS_BY_SENCNETYPEID: {
        path: "/autoSpirit/getAppInfosBySencneTypeID",
      },
      GET_APPINFOS_BY_ID: {
        path: "/autoSpirit/getAppInfoByID",
      },
      UPDATE_APPINFOS_BY_ID: {
        path: "/autoSpirit/updateAppInfo",
      },
      UPDATE_APPINFOS_SENCNETYPEID: {
        path: "/autoSpirit/updateAppInfoSecneTypeID",
      },
      UPDATE_APPINFOS_SEQUENCE: {
        path: "/autoSpirit/updateAppInfoSequence",
      },
      UPDATE_APPINFOS_MAINPAGEID: {
        path: "/autoSpirit/updateAppInfoMainPageID",
      },
      DELETE_APPINFOS_BY_ID: {
        path: "/autoSpirit/deleteAppInfos",
      },
      CREATE_PAGE_INFO: {
        path: "/autoSpirit/insertPageInfo",
      },
      GET_PAGEINFO_BY_ID: {
        path: "/autoSpirit/getPageInfoByID",
      },
      GET_PAGE_INFOS_BY_SENCNETYPEID: {
        path: "/autoSpirit/getPageInfoBySencneTypeID",
      },
      UPDATE_PAGEINFOS_BY_ID: {
        path: "/autoSpirit/updatePageInfo",
      },
      UPDATE_PAGEINFOS_SENCNETYPEID: {
        path: "/autoSpirit/updatePagesSecneTypeID",
      },
      UPDATE_PAGEINFOS_SEQUENCE: {
        path: "/autoSpirit/updatePageInfoSequence",
      },
      DELETE_PAGEINFOS_BY_ID: {
        path: "/autoSpirit/deletePagesByID",
      },
      GET_ROUTE_INFOS_BY_SENCNETYPEID: {
        path: "/autoSpirit/getRouteInfoBySencneTypeID",
      },
      GET_ROUTE_INFO_BY_ID: {
        path: "/autoSpirit/getRouteInfoByID",
      },
      UPDATE_ROUTE_INDO_BY_ID: {
        path: "/autoSpirit/updateRouteInfo",
      },
      UPDATE_ROUTEINFOS_SENCNETYPEID: {
        path: "/autoSpirit/updateRoutesSecneTypeID",
      },
      UPDATE_ROUTEINFOS_SEQUENCE: {
        path: "/autoSpirit/updateRouteInfoSequence",
      },
      CREATE_ROUTE_INFO: {
        path: "/autoSpirit/insertRouteInfo",
      },
      DELETE_ROUTEINFOS_BY_ID: {
        path: "/autoSpirit/deleteRoutesByID",
      },
      CREATE_STEP_INFO: {
        path: "/autoSpirit/insertStep",
      },
      CREATE_TASK_INFO: {
        path: "/autoSpirit/insertAppTask",
      },
      GET_TASK_INFOS_BY_SENCNETYPEID: {
        path: "/autoSpirit/getTaskInfoBySencneTypeID",
      },
      GET_TASK_INFOS_BY_ID: {
        path: "/autoSpirit/getTaskInfoByID",
      },
      GET_STEP_INFOS_BY_SENCNETYPEID: {
        path: "/autoSpirit/getStepInfoBySencneTypeID",
      },
      GET_STEP_INFOS_BY_ID: {
        path: "/autoSpirit/getStepInfoByID",
      },
      UPDATE_STEP_INFOS_BY_ID: {
        path: "/autoSpirit/updateStep",
      },
      UPDATE_STEP_SENCNETYPEID: {
        path: "/autoSpirit/updateStepsSecneTypeID",
      },
      DELETE_STEP_INFOS_BY_ID: {
        path: "/autoSpirit/deleteStepsByID",
      },
      CREATE_VARIABLE_INFO: {
        path: "/autoSpirit/insertVariable",
      },
      UPDATE_VARIABLE_INFO: {
        path: "/autoSpirit/updateVariableInfo",
      },
      GET_VARIABLE_INFOS_BY_RELATEID: {
        path: "/autoSpirit/getVariablesByRelateID"
      },
      GET_VARIABLE_BY_ID: {
        path: "/autoSpirit/getVariableByID"
      },
      GET_VARIABLEMAPPINGS_BY_ITEMID: {
        path: "/autoSpirit/getVariablesMappingsByItemInfo"
      },
      GET_VARIABLEPROMAPPING_BY_ITEMID: {
        path: "/autoSpirit/getVariableProMappingByItemInfo"
      },
      GET_RELATE_PARENT_VARIABLE_INFOS_BY_RELATEID: {
        path: "/autoSpirit/getRelateParentVariablesByRelateID"
      },
      DELETE_VARIABLE_INFO: {
        path: "/autoSpirit/deleteVariable",
      },
      GET_RELATE_MAPPING_BY_PARENTID: {
        path: "/autoSpirit/getRelateMappingByParentID",
      },
      UPDATE_RELATE_MAPPING_DISABLE_BY_PARENTID: {
        path: "/autoSpirit/updateRelateMappingDisable",
      },
      GET_RELATE_MAPPING_BY_RELATEID: {
        path: "/autoSpirit/getRelateMappingByRelateID",
      },
      CREATE_RELATE_MAPPINGS: {
        path: "/autoSpirit/insertRelateMapping"
      },
      DELETE_RELATE_MAPPINGS: {
        path: "/autoSpirit/deleteRelateMappingsByID"
      },
      INSERT_EMPTYSTEPTYPE_INFOS: {
        path: "/autoSpirit/insertEmptyStepType",
      },
      INSERT_IMAGEMATCHSTEPTYPE_INFOS: {
        path: "/autoSpirit/insertImageMatchStepTypes",
      },
      INSERT_APPLOADSTEPTYPE_INFOS: {
        path: "/autoSpirit/insertAppLoadStepType",
      },
      UPDATE_EMPTYSTEPTYPE_INFOS: {
        path: "/autoSpirit/updateEmptyStepType",
      },
      UPDATE_IMAGEMATCHSTEPTYPE_INFOS: {
        path: "/autoSpirit/updateImageMatchStepType",
      },
      UPDATE_APPLOADSTEPTYPE_INFOS: {
        path: "/autoSpirit/updateAppLoadStepType",
      },
      GET_EMPTYSTEPTYPE_INFOS: {
        path: "/autoSpirit/getEmptyStepTypeByID",
      },
      GET_IMAGEMATCHSTEPTYPE_INFOS: {
        path: "/autoSpirit/getImageMatchStepTypeByID",
      },
      GET_APPLOADSTEPTYPE_INFOS: {
        path: "/autoSpirit/getAppLoadStepTypeByID",
      },
      DELETE_EMPTYSTEPTYPE_INFOS: {
        path: "/autoSpirit/deleteEmptyStepTypeByID",
      },
      DELETE_IMAGEMATCHSTEPTYPE_INFOS: {
        path: "/autoSpirit/deleteImageMatchStepTypeByID",
      },
      DELETE_APPLOADSTEPTYPE_INFOS: {
        path: "/autoSpirit/deleteAppLoadStepTypeByID",
      },
      CREATE_VARIABLEPROMAPPING_INFOS: {
        path: "/autoSpirit/insertVariableProMappings",
      },
      UPDATE_VARIABLEPROMAPPING_INFOS: {
        path: "/autoSpirit/updateVariableProMappings",
      },
      DELETE_VARIABLEPROMAPPING_INFOS: {
        path: "/autoSpirit/deleteVariableProMappings",
      },
      GET_VARIABLEPROMAPPING_BY_ITEMNAME: {
        path: "/autoSpirit/getVariableProMappingByItemAndName"
      },
      GET_RUNABLE_ITEM_INFOS_BY_ITEMID: {
        path: "/autoSpirit/getRunableInfosByItemID"
      },
      DELETE_RUNABLE_ITEM_INFOS_BY_ITEMID: {
        path: "/autoSpirit/deleteItemRunableInfosByID"
      },
      GET_VARIABLE_OPERATEMAPPINGS_BY_ITEMID: {
        path: "/autoSpirit/getVariableOperatorMappingsByItemInfo"
      },
      INSERT_VARIABLE_OPERATEMAPIINGS: {
        path: "/autoSpirit/insertVariableOperatorMappings"
      },
      DELETE_VARIABLE_OPERATEMAPIINGS: {
        path: "/autoSpirit/deleteVariableOperatorMappings"
      },
      UPDATE_VARIABLE_OPERATEMAPIINGS: {
        path: "/autoSpirit/updateVariableOperatorMappings"
      },
      GET_VARIABLE_OCCUSEVENTCONDITIONMAPPINGS_BY_ITEMID: {
        path: "/autoSpirit/getOccusConditionMappingsByItemInfo"
      },
      CREATE_OCCUSEVENTCONDITIONMAPPINGS: {
        path: "/autoSpirit/insertOccusConditionMappings"
      },
      DELETE_OCCUSEVENTCONDITIONMAPPINGS: {
        path: "/autoSpirit/deleteOccusConditionMappings"
      },
      UPDATE_OCCUSEVENTCONDITIONMAPPINGS: {
        path: "/autoSpirit/updateOccusConditionMappings"
      },
    }

  iconDatas = {};

  getIconBase64Str = (key) => {
    return this.iconDatas[key];
  }

  init = async () => {
    super.init();
    await this.initIconDatas();
  }

  initServer=(clientTimestamp)=>{
    if(clientTimestamp)
      this.serverName = `AUTOSPIRIT_VIEW${clientTimestamp}`;
    this.installActionsDispatchListeners();
    this.initEventHanlders();
    this.createWebSocket();
  }

  initIconDatas = async () => {
    this.iconDatas = {
      "LOGO_ICON": await util.getIconBase64(require("@/assets/logo.jpeg")),
      "APP_ICON": await util.getIconBase64(require("@/assets/app.png")),
      "PAGE_ICON": await util.getIconBase64(require("@/assets/page.png")),
      "PAGE_ICON_GRAY": await util.getIconBase64(require("@/assets/page_gray.png")),
      "ROUTE_ICON": await util.getIconBase64(require("@/assets/route.png")),
      "ROUTE_ICON_GREY": await util.getIconBase64(require("@/assets/route_grey.png")),
      "STEP_ICON": await util.getIconBase64(require("@/assets/step.png")),
      "STEP_ICON_GRAY": await util.getIconBase64(require("@/assets/step_gray.png")),
      "TASK_ICON": await util.getIconBase64(require("@/assets/task.png")),
      "TASK_ICON_GRAY": await util.getIconBase64(require("@/assets/task_gray.png")),
      "SPIRIT_ICON": await util.getIconBase64(require("@/assets/spirit.png")),
      "ROUND_CROSS": await util.getIconBase64(require("@/assets/round-cross.png")),
      "RIGHT_ARROW": await util.getIconBase64(require("@/assets/rightArrow.png")),
      "RIGHT_ARROW_GREY": await util.getIconBase64(require("@/assets/rightArrow_grey.png")),
      "FORBIT_ICON": await util.getIconBase64(require("@/assets/forbit.png")),
      "BACKUP_ICON": await util.getIconBase64(require("@/assets/beian.png")),
    }
  }

  initEventHanlders() {
    this.pushToEventActionOptionsPool("AUTOSPIRIT_SHOWTOAST_EVENT", (params) => {
      showToast(params);
    });
    this.pushToEventActionOptionsPool("AUTOSPIRIT_STEPACTIONTYPE_UPDATE_EVENT", (stepActionType) => {
      let typeUtil = require("@/components/app/views/common/configPane/itemType/itemTypeUtil.js");
      let stepActionTypeObj = typeUtil.getStepActionTypeObj(stepActionType.stepActionTypeID);
      stepActionTypeObj.init(this);
      stepActionTypeObj.saveStepActionTypeInfos(stepActionType);
    });
    this.pushToEventActionOptionsPool("AUTOSPIRIT_SHOWTOAST_EVENT", () => {
    });
  }

  getItemFromStorage(key, isSystemParam) {
    let datas = isSystemParam ? localStorage.getItem(this.clientID) : sessionStorage.getItem(this.clientID);
    datas = datas ? JSON.parse(datas) : {};
    if (isSystemParam) key = this.makeKey(key);
    return datas[key] ? datas[key] : '';
  }

  addItemToStorage(key, storeObj, isSystemParam) {
    try {
      let datas = isSystemParam ? localStorage.getItem(this.clientID) : sessionStorage.getItem(this.clientID);
      datas = datas ? JSON.parse(datas) : {};
      if (isSystemParam) key = this.makeKey(key);
      datas[key] = storeObj;
      isSystemParam ? localStorage.setItem(this.clientID, JSON.stringify(datas)) : sessionStorage.setItem(this.clientID, JSON.stringify(datas));
      return true;
    } catch (e) {
      // localstorage容量不够，根据保存的时间删除已缓存到ls里的js代码
      if (e.name.toUpperCase().indexOf('QUOTA') >= 0) {
        let datas = isSystemParam ? localStorage.getItem(this.clientID) : sessionStorage.getItem(this.clientID);
        datas = datas ? JSON.parse(datas) : {};
        let newData = {};
        for (let tempkey in datas) {
          if (tempkey.indexOf("AUTOSPIRIT") > 0) {
            newData[tempkey] = datas[tempkey];
          }
        }
        isSystemParam ? localStorage.clear() : sessionStorage.clear();
        isSystemParam ? localStorage.setItem(this.clientID, JSON.stringify(newData)) : sessionStorage.setItem(this.clientID, JSON.stringify(newData));
        // 删除后在再添加，利用递归完成
        return this.addItemToStorage(key, storeObj, isSystemParam);
      } else {
        // 已经没有可以删除的缓存对象了，证明这个将要缓存的目标太大了。返回undefined。
        return;
      }
    }
  };

  _dealUserLoginResponse(responseDatas) {
    let resultCode = responseDatas.resultCode;
    let datas = responseDatas.datas;
    if (resultCode == 1) {
      this.saveUserDatas(datas);
      datas.clientID = this.clientID;
      this.sendActionToUI("AUTOSPIRIT_USER_LOGIN_EVENT", datas);
    }
  }

  saveUserDatas(datas) {
    let userAccount = datas["phone"];
    let tokenKey = datas["token"];
    this.addItemToStorage("phone", userAccount, true);
    this.addItemToStorage("token", tokenKey, true);
  }

  makeKey(propID) {
    return "AUTOSPIRIT" + "@" + propID;
  }



  resetHeartCheck = () => {
    if (this.heartCheckIntervalID)
      clearInterval(this.heartCheckIntervalID);
    if (this.serverReconnectID)
      clearTimeout(this.serverReconnectID);
    this.lockReconnect = false;
    return this;
  };

  startHeartCheck = () => {
    let count = 0;
    this.heartCheckIntervalID = setInterval(() => {
      //3次心跳检测发现都没有连上，则开始重新连接
      if (count < 3) {
        if (this.ws.readyState !== 1) {
          count++;
          console.log(`${this.serverName}:第${count}次心跳检测，未发现连接！`);
        }
        else {
          this.sendActionToNode('AUTOSPIRIT_HEARTBEAT_EVENT').then((response) => {
            if (response == "AUTOSPIRIT_HEARTBEAT_EVENT_HEART_BEAT") {
              count = 0;
            }
          });
        }
      }
      else {
        count = 0;
        console.log(`${this.serverName}:超3次心跳检测无果，开始重新连接服务器！`);
        this.resetHeartCheck().reconnectToServer();
      }
    }, this.heartCheckIntervalTime);
  }

  reconnectToServer() {
    if (this.lockReconnect) return;
    this.lockReconnect = true
    //没连接上会一直重连，设置延迟避免请求过多
    this.serverReconnectID = setTimeout(() => {
      this.createWebSocket();
      console.log(`${this.serverName}:正在重连第${this.reconectCount + 1}次`);
      this.reconectCount++;
      this.lockReconnect = false;
    }, 5000); //这里设置重连间隔(ms)
  }

  onHeartConnected(e) {
    // console.log("发现心跳连接！持续进行心跳检测");
    this.reconectNum = 0;
    this.resetHeartCheck().startHeartCheck();
    // console.log=(datas)=>{
    //   this.sendActionToUI("AUTOSPIRIT_LOG_EVENT",datas);
    // };
    // setInterval(() => {
    //   console.log("test");
    // }, 2000);
  }

  createWebSocket() {
    console.log("开始链接服务端:" + this.nodeWSUrl);

    let connectUrl = `${this.nodeWSUrl}/${this.serverName}`;
    this.ws = new WebSocket(connectUrl);
    this.ws.onopen = (e) => {
      console.log("链接上了ws");
      this.onHeartConnected(e);
      if (this.messageCache.length != 0) {
        for (let data of this.messageCache) {
          this.sendData(data);
        }
        this.messageCache = [];
      }
    };
    this.ws.onmessage = (e) => {
      let obj = e.data;
      this.onReciveDatas(obj);
      this.resetHeartCheck().startHeartCheck();
    };
    this.ws.onclose = (e) => {
      if (!this.isDispose) {
        console.log("链接关闭,开始重连检测");
        this.resetHeartCheck().reconnectToServer();
      }
      else {
        console.log("链接关闭!不再连接");
      }
    };
    this.ws.onerror = (e) => {
      console.log("链接发生错误:" + e.stack);
      this.resetHeartCheck().reconnectToServer();
    };
  }

  sendData(datas) {
    if (this.ws) {
      if (this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify(datas));
      }
      else {
        this.messageCache.push(datas);
      }
    }
  }
  async sendActionToNode(actionID, datas) {
    datas = datas ? datas : {};
    return await this.sendActionByServerKey("AUTOSPIRIT_NODE", actionID, datas);
  }

  async sendActionToUI(actionID, datas) {
    // datas.clientKey = this.serverName;
    return await this.sendActionToNode("AUTOSPIRIT_VIEW2UI_EVENT", {
      actionKey: actionID,
      clientKey:this.serverName,
      datas,
    });
  }

  postJsonByActionName(actionPath, params, callback) {
    let path = this._convertUrlPath(actionPath);
    let header = {
      "clientID": this.clientID,
    }
    this.postJsonByUrl(path, params, header, callback);
  }

  postJsonByActionNameSecurity(actionPath, params, callback) {
    let path = this._convertUrlPath(actionPath);
    let header = {
      "clientID": this.clientID,
      "token": this.getItemFromStorage("token", true),
    }
    this.postJsonByUrl(path, params, header, callback);
  }

  postJsonByUrl(url, params, header, callback) {
    let tempHeader = {
      "Content-Type": "application/json",
    };
    let options = {};
    tempHeader = { ...tempHeader, ...header };
    if (header != null) {
      options["headers"] = tempHeader;
    }
    options["method"] = "POST";
    options["url"] = url;
    options["data"] = JSON.stringify(params);
    options["timeout"] = this.httpTimeout;
    // options["json"] = JSON.stringify(params);
    options["responseType"] = "json";
    let gotResult = axios(options);
    gotResult.then(function (response) {
      callback(response);
    }).catch(function (error) {
      console.log("访问服务链接时发生错误，url：" + url);
      console.log("访问服务链接时发生错误，url：" + error.message);
    });
  }

  convertResponseDatas(response) {
    if (response) {
      let responseDatas = response.data;
      return responseDatas
    }
    return response;
  }

  //释放内存并退出
  dispose() {
    this.resetHeartCheck();
    this.messageCache = [];
    if (this.ws) {
      this.isDispose = true;
      this.ws.close();
    }
    super.dispose();
  }
}