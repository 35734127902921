<template>
  <div class="height-full bg-lightGrey">
    <BackableNavBar class="bg-trans border none" title="个人资料" leftText="返回" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell center title="基础资料:"></Cell>
          <Cell center title="头像">
            <template #right-icon>
              <Image :src="logoIcon" class="flex center as-avatar sm" />
            </template>
          </Cell>
          <Cell center title="账号" :value="userAccount"></Cell>
        </CellGroup>
      </div>
    </div>
    <div id="autospirit_my_personalSetting_logoutBtn" class="padding">
      <div>
        <Button type="primary" class="logoutBtn" @click="doLogout">注销登录</Button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, onMounted,ref} from "vue";
import {
  useRouter,
} from 'vue-router';
const router = useRouter();
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const userAccount = wsClient.getItemFromStorage("phone",true);
const logoIcon =ref(null);
onMounted(async () => {
  let btnWapper = document.getElementById("autospirit_my_personalSetting_logoutBtn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
  logoIcon.value = wsClient.getIconBase64Str("LOGO_ICON");
});
const onClickLeft = () => {
  router.push({
    name: backTargetPage,
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
const doLogout = async () => {
  //通过本地服务来进行登出操作
  let responseDatas = await wsClient.sendActionToCloud("USER_LOGOUT", {
    phone: userAccount,
  });
  let resultCode = responseDatas.resultCode;
  if (resultCode == 1) {
    router.push({ name: "Login" });
  }
};
</script>
<style scoped>
#autospirit_my_personalSetting_logoutBtn {
  position: absolute;
}

.logoutBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>