<template>
    <!--任务信息面板，用于编辑设计任务的相关信息-->
    <div class="bg-lightGrey height-full">
        <!--任务快捷信息面板-->
        <div class="padding">
            <div class="flex bg-white border solid radius padding">
                <div>
                    <Image class="flex center as-avatar xl" :src="defaultIcon"></Image>
                </div>
                <div>
                    <Cell center :title="taskInfo.name" :label="taskInfo.define"></Cell>
                </div>
            </div>
        </div>
        <!--任务基础信息面板-->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础信息："></Cell>
                    <Cell center title="名称" :value="taskInfo.name"></Cell>
                    <Cell center title="描述">
                    </Cell>
                    <Field v-show="taskInfo.define" readonly autosize type="textarea" show-word-limit class="bg-trans"
                        v-model="taskInfo.define" />
                    <Cell center title="场景归属" :value="getItemInfoSencneString()"></Cell>
                    <Cell v-show="sencneType.name == '常用'" v-bind:title="taskInfo.commom ? '常用状态：开启' : '常用状态：关闭'"
                        class="bg-trans">
                        <template #right-icon>
                            <Switch disabled v-model="taskInfo.commom" size="24" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <!--任务场景信息面板-->
        <!-- <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景信息："></Cell>
                    <Cell center title="场景归属" :value="getItemInfoSencneString()"></Cell>
                </CellGroup>
            </div>
        </div> -->
        <!--任务高级信息面板，用于编辑操作高级内容-->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="高级信息:"></Cell>
                    <Tabs shrink v-model:active="active" background="transparent">
                        <!--步骤操作页签面板-->
                        <Tab title="步骤设置">
                            <template #title> <span class="stepTab">步骤设置</span></template>
                            <RelateSelectPane ref="relateSelectPane" :itemRelateMappings="taskRelateMappings"
                                :elementsHideConfig="elementsHideConfig" />
                        </Tab>
                        <Tab title="变量设置">
                            <template #title> <span class="stepTab">变量设置</span></template>
                            <!--变量设置面板-->
                            <ParameterSetting ref="variablePane" :elementsHideConfig="elementsHideConfig" :isNewAction="true"
                                :itemVariables="variableDatas" :itemInfo="taskInfo"></ParameterSetting>
                        </Tab>
                    </Tabs>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance,ref, defineExpose, defineProps, toRefs, onMounted } from "vue";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let taskInfo = firsData.itemInfo ? firsData.itemInfo : {};
let sencne = firsData.sencne ? firsData.sencne : {};
let sencneType = firsData.sencneType ? firsData.sencneType : {};
let secondData = tempOperateDatas[1];
taskInfo.sequence = secondData.sequence ? secondData.sequence : "";
let thirdData = tempOperateDatas[2];
const variableDatas = ref(thirdData.variableDatas ? thirdData.variableDatas : []);
const taskRelateMappings = ref(secondData.taskRelateMappings ? secondData.taskRelateMappings : []);
const active = ref(0);
const elementsHideConfig = {
    operateBar: true,
    addVariableBtn: true,
    variableOperateBtns: true,
};
//默认的Task图标
const defaultIcon = ref("");
const variablePane =ref(null);

const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.name + '】';
}

onMounted(async () => {
    defaultIcon.value = wsClient.getIconBase64Str("TASK_ICON");
});

const validateOnFinish = () => {
    let relateMappings = [];
    for (let tempRelateMapping of taskRelateMappings.value) {
        let tempMapping = {
            parentType: taskInfo.type,
            relateID: tempRelateMapping.relateID,
            relateType: tempRelateMapping.relateType,
            disable: tempRelateMapping.disable
        }
        relateMappings.push(tempMapping);
    }
    let variables = [];
    for (let tempVariableData of variableDatas.value) {
        let tempVariable = {
            name: tempVariableData.name,
            value: tempVariableData.value,
            dataValue: tempVariableData.dataValue,
            type: tempVariableData.variableType.id,
            relateItemType: taskInfo.type,
        }
        variables.push(tempVariable);
    }
    taskInfo.relateMappings = relateMappings;
    taskInfo.variables = variables;
    return taskInfo;
};
const resetDatas=()=>{
  taskRelateMappings.value=[];
  variableDatas.value=[];
  if(variablePane.value)
  {
     variablePane.value.resetDatas();
  }
}

const updateDatas = () => {
    let tempOperateDatas = operateDatas.value;
    let firsData = tempOperateDatas[0];
    taskInfo = firsData.itemInfo ? firsData.itemInfo : {};
    sencne = firsData.sencne ? firsData.sencne : {};
    sencneType = firsData.sencneType ? firsData.sencneType : {};
    let secondData = tempOperateDatas[1];
    taskInfo.sequence = secondData.sequence ? secondData.sequence : "";
    let thirdData = tempOperateDatas[2];
    variableDatas.value = thirdData.variableDatas ? thirdData.variableDatas : [];
    taskRelateMappings.value = secondData.taskRelateMappings ? secondData.taskRelateMappings : [];
};
defineExpose({
    resetDatas,
    updateDatas,
    validateOnFinish,
});
</script>
<style scoped>
.stepTab {
    font-size: 15px;
}
</style>