<template>
  <div class="height-full">
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="基础配置："></Cell>
          <ProsetCell title="精灵名称" :value="spiritInfo.name" @onSubmit="changeSpiritName"></ProsetCell>
          <ProsetCell title="功能描述" :value="spiritInfo.define" type="textarea" @onSubmit="changeSpiritDefine">
          </ProsetCell>
          <Cell center is-link title="图标" value="修改" @click="gotoSpiritIconSelect">
            <template #icon>
              <div class="padding">
                <Image :src="spiritInfo.iconDataBase64" class="as-avatar sm" />
              </div>
            </template>
          </Cell>
          <Cell v-show="sencneType.name!='常用'" v-bind:title="spiritInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
						<template #right-icon>
							<Switch v-model="spiritInfo.commom" size="24" />
						</template>
					</Cell>
          <Cell v-show="sencneType.name=='常用'" v-bind:title="spiritInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
            <template #right-icon>
							<Switch disabled v-model="spiritInfo.commom" size="24" />
						</template>
          </Cell>
        </CellGroup>
      </div>
    </div>
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="场景配置："></Cell>
          <Cell center is-link title="场景类别" :value="sencneTitle" @click="gotoSencneTypeSelect"></Cell>
        </CellGroup>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineProps, toRefs, defineExpose, reactive, onMounted } from "vue";
import {
  useRouter,
} from 'vue-router';
import util from "@/utils/util";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
let spiritInfo = reactive(pageDatas.itemInfo ? pageDatas.itemInfo : { type: "SPIRIT_TYPE" });
const sencneType = ref(pageDatas.sencneType ? pageDatas.sencneType : {});
let sencne = pageDatas.sencne ? pageDatas.sencne : {};
spiritInfo.sencneTypeID = sencneType.value.id;
const sencneTitle = ref("");
const props = defineProps({
  operateDatas: {
    type: Object
  }
});
const { operateDatas } = toRefs(props);
const resetDatas = async () => {
  spiritInfo.iconDataBase64 = wsClient.getIconBase64Str("SPIRIT_ICON");
  spiritInfo.name = "";
  spiritInfo.define = "";
  spiritInfo.commom=false;
  sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : {};
  sencne = pageDatas.sencne ? pageDatas.sencne : {};
  spiritInfo.sencneTypeID = sencneType.value.id;
  spiritInfo.commom=(sencneType.value.name=="常用");
  sencneTitle.value = getItemInfoSencneString();
};
onMounted(async () => {
  spiritInfo.iconDataBase64 = spiritInfo.iconDataBase64 ? spiritInfo.iconDataBase64 : wsClient.getIconBase64Str("SPIRIT_ICON");
  sencneTitle.value = getItemInfoSencneString();
  spiritInfo.commom=(sencneType.value.name=="常用");
});
const changeSpiritName = (newValue) => {
  spiritInfo.name = newValue;
};
const changeSpiritDefine = (newValue) => {
  spiritInfo.define = newValue;
};
const getItemInfoSencneString = () => {
  if (!sencne.sencneName || !sencneType.value.name) {
    return "加载中...";
  }
  return sencne.sencneName + '【' + sencneType.value.name + '】';
}
const getSelectedAppInfoIcons = () => {
  let icons = [spiritInfo.iconDataBase64];
  return icons;
};
const gotoSpiritIconSelect = () => {
  let tempDatas = {
    operateTitle: "修改图片",
    currentImageSource: spiritInfo.iconDataBase64,
    supportImageType: "jpg、png、jpeg",
    maxImageSize: "10MB",
    suggestImages: getSelectedAppInfoIcons(),
    eventName: "AUTOSPIRIT_SPIRITINFO_MODIFYIMAGE_EVENT",
    backTargetPage: "NewSpirit",
  };
  const actionMethod = async (eventDatas) => {
    spiritInfo.iconDataBase64 = util.getImageDataUrlFromBase64(eventDatas.iconData);
    router.push({
      name: tempDatas.backTargetPage,
    });
  };
  datas.listenerID= wsClient.addEventListener(tempDatas.eventName, actionMethod);
  router.push({
    name: "SencneTypeImageSelected",
    state: {
      pageDatas: JSON.stringify(tempDatas)
    }
  });
};
const gotoSencneTypeSelect = () => {
  let datas = {
    sencnePageType: 0,
    backTargetPage: "NewSpirit",
    eventName: "AUTOSPIRIT_FIRSTSTEP_SENCNETYPESELECT_EVENT",
    selectedSencneType: sencneType.value,
    selectedSencne: sencne
  };
  const actionMethod = (eventDatas) => {
    sencne = eventDatas.sencne,
    sencneType.value = eventDatas.sencneType,
    spiritInfo.sencneTypeID = eventDatas.sencneType.id;
    sencneTitle.value = getItemInfoSencneString();
    router.push({
      name: "NewSpirit",
    });
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "SencneTypeSelected",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
const validateOnNext = () => {
  return {
    resultCode: spiritInfo.name ? 1 : 0,
    message: spiritInfo.name ? "" : "请输入精灵名称!",
  }
};
const getOperateDatas = () => {
  return {
    itemInfo: spiritInfo,
    sencne: sencne,
    sencneType: sencneType.value
  };
}
const updateDatas = async() => {
  let pageDatas = history.state.pageDatas;
  if (pageDatas) pageDatas = JSON.parse(pageDatas);
  sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : {};
  sencne = pageDatas.sencne ? pageDatas.sencne : {};
  spiritInfo.sencneTypeID = sencneType.value.id;
  spiritInfo.commom=(sencneType.value.name=="常用");
  sencneTitle.value = getItemInfoSencneString();
};
defineExpose({
  updateDatas,
  resetDatas,
  validateOnNext,
  getOperateDatas,
});
</script>


