<template>
  <div class="padding">
    <div class="bg-white border solid">
      <!--步骤操作页签面板--步骤列表-->
      <List v-model:loading="relateLoading" :finished="relateFinished" finished-text="没有更多了">
        <CellGroup>
          <Collapse v-model="activeNames">
            <PageRouteCell v-if="!isReload" v-for="tempStepItem in itemRelateMappingsObj" :key="tempStepItem.key"
              :relateMapping="tempStepItem" :elementsHideConfig="elementsHideConfig"
              :relateItemOperator="relateItemOperator"
              @onRelateOperate="doRelateOperateAction">
            </PageRouteCell>
          </Collapse>
        </CellGroup>
      </List>
    </div>
  </div>
</template>
<script setup>
import { nextTick } from "vue";
import {getCurrentInstance,ref, defineEmits, defineExpose, defineProps, toRefs, watch, reactive,onMounted} from "vue";

const emits = defineEmits(["onRelateOperate"]);
const props = defineProps({
  //元素显示配置项
  elementsHideConfig: {
    type: Object
  },
  itemRelateMappings: {
    type: Array,
    default: []
  },
  loadingState: {
    type: Boolean,
  },
  relateItemOperator: {
    type: Object
  },
});
const { itemRelateMappings, loadingState, elementsHideConfig, relateItemOperator} = toRefs(props);
const itemRelateMappingsObj = ref(itemRelateMappings.value);
const isReload = ref(false);
const activeNames = ref(["1"]);
const relateLoading = ref(true);
const relateFinished = ref(false);
relateLoading.value = loadingState.value;
relateFinished.value = !loadingState.value;

watch([loadingState], () => {
  relateLoading.value = loadingState.value;
  relateFinished.value = !loadingState.value;
});
watch([itemRelateMappings], () => {
  refreshItems();
});

/**
 * 刷新缓存的元素
 */
const refreshItems=async()=>{
  isReload.value = true;
  itemRelateMappingsObj.value = itemRelateMappings.value;
  nextTick(() => {
    isReload.value = false;
  })
}

/**
 * 响应操作事件
 * @param {*} optionData 
 */
const doRelateOperateAction = (optionData) => {
  relateItemOperator.value.doRelateOperateAction(optionData);
}

/**
 * 获取选择的元素对象
 */
const getCheckedItemInfos = () => {
  let selectedInfos = [];
  for (let tempMapping of itemRelateMappings.value) {
    selectedInfos.push(tempMapping.itemInfo);
  }
  return selectedInfos;
};

defineExpose({
  refreshItems,
  getCheckedItemInfos,
});
</script>
<style scoped></style>