<template>
    <!--步骤信息面板，用于编辑设计步骤的相关信息-->
    <div class="bg-lightGrey height-full">
        <BackableNavBar leftText="返回" title="步骤信息" @onBack="onClickLeft" />
        <!--快捷信息面板-->
        <!-- <div class="padding">
            <div class="flex bg-white border solid radius padding">
                <div>
                    <Image class="flex center as-avatar xl" :src="defaultIcon"></Image>
                </div>
                <div>
                    <Cell center :title="stepInfo.name + '【#' + stepInfo.id + '】'" :label="stepInfo.define"></Cell>
                </div>
            </div>
        </div> -->
        <!--基础信息面板-->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础信息："></Cell>
                    <ProsetCell title="名称" :value="stepInfo.name" @onSubmit="onStepRenameSubmit">
                    </ProsetCell>
                    <ProsetCell title="描述" type="textarea" :value="stepInfo.define" @onSubmit="onStepDefineSubmit">
                    </ProsetCell>
                    <Cell center is-link title="场景归属" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect">
                    </Cell>
                    <Cell v-bind:title="stepInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
                        <template #right-icon>
                            <Switch v-model="stepInfo.commom" size="24" @change="changeCommom" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <!-- 场景信息面板
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景信息："></Cell>
                    <Cell center is-link title="场景归属" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect">
                    </Cell>
                </CellGroup>
            </div>
        </div> -->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="高级信息:"></Cell>
                    <Tabs shrink background="transparent">
                        <!--步骤操作页签面板-->
                        <Tab title="步骤设置">
                            <template #title> <span class="stepTab">步骤设置</span></template>
                            <!--步骤类型选项，选中后基于不同的步骤类型展示不同的步骤设置内容-->
                            <CellGroup>
                                <Cell center is-link title="选择步骤类型:"
                                    :value="selectedItemActionType.name ? selectedItemActionType.name : '设置'"
                                    @click="gotoStepActionTypeSelect"></Cell>
                                <!-- <Cell class="bg-trans" title="" v-show="selectedItemActionType.name">
                                    <template #right-icon>
                                        <div class="flex">
                                            <Icon class="rightIcon color-blue content-vcenter block-inline hand-cursor"
                                                name="replay" size="20" @click="resetStepActionInfos">
                                                <div class="rightText hand-cursor">重置</div>
                                            </Icon>
                                            <Icon
                                                class="rightIcon color-blue content-vcenter block-inline padding-left hand-cursor"
                                                name="points" size="20" @click="saveStepActionInfos">
                                                <div class="rightText hand-cursor">保存</div>
                                            </Icon>
                                        </div>
                                    </template>
                                </Cell> -->
                            </CellGroup>
                            <!--步骤信息设置面板，用于编辑操作高级内容-->
                            <Tabs v-show="selectedTabOptions.length > 0" shrink v-model:active="active"
                                background="transparent">
                                <Tab v-for="tabOption in selectedTabOptions" :key="tabOption.id"
                                    :title="tabOption.name">
                                    <template #title> <span class="stepTab">{{ tabOption.name }}</span></template>
                                    <component ref="tabInfoPanes" :is="tabOption.viewPath" :itemInfo="stepInfo">
                                    </component>
                                </Tab>
                            </Tabs>
                        </Tab>
                        <Tab title="变量设置">
                            <template #title> <span class="stepTab">变量设置</span></template>
                            <!--变量设置面板-->
                            <ParameterSetting :itemInfo="stepInfo"></ParameterSetting>
                        </Tab>
                        <Tab title="执行日志">
                            <template #title> <span class="stepTab">执行日志</span></template>
                            <!--变量设置面板-->
                            <RunableInfosPane :itemInfo="stepInfo"></RunableInfosPane>
                        </Tab>
                    </Tabs>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, watch, nextTick } from "vue";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import StepActionTypeOperator from "@/components/app/views/common/stepType/tabOptionViews/StepActionTypeOperator.js";
import {
    useRouter,
} from 'vue-router';
import { showToast } from "vant";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const stepType = typeUtil.getItemTypeObj('STEP_TYPE');
stepType.init(wsClient, router);
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const stepInfo = ref(pageDatas.itemInfo ? pageDatas.itemInfo : {});
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const tabIndex = pageDatas.tabIndex > 0 ? pageDatas.tabIndex : 0;
const selectedItemActionType = ref({});
//当前步骤类型下的操作页签数据
const selectedTabOptions = ref([]);
const active = ref(tabIndex);
const tabInfoPanes = ref([]);
const stepActionInfos = ref({});
const varaiableProsMappings = ref([]);
const variables = ref([]);

//步骤所在的子场景
const sencneType = ref({});
const sencne = ref({});
const defaultIcon = ref("");
//控制条件集
const conditionOperateDatas = ref([]);

const occusConditionMappings = ref([]);

watch(active, async (newValue) => {
    await refreshVariablesData();
});
const changeCommom = () => {
    stepType.saveData(stepInfo.value);
};



const tabOperateHanlder=new StepActionTypeOperator();
tabOperateHanlder.init({
    stepActionInfos:stepActionInfos,
    conditionOperateDatas:conditionOperateDatas,
    occusConditionMappings:occusConditionMappings,
    backPageName:"StepInfo",
    wsClient:wsClient
})

/**
 * 响应页面回退动作
 */
const onClickLeft = () => {
    router.push({
        name: backTargetPage,
        state: {
            pageDatas: JSON.stringify(backTargetPageDatas)
        }
    });
};

/**
 * 响应页面名称更新动作
 * @param {名称} newName 
 */
const onStepRenameSubmit = (newName) => {
    stepInfo.value.name = newName;
    stepType.saveData(stepInfo.value);
};

/**
 * 响应更新描述动作
 * @param {描述} newDiscripe 
 */
const onStepDefineSubmit = (newDiscripe) => {
    stepInfo.value.define = newDiscripe;
    stepType.saveData(stepInfo.value);
};

/**
 * 页面加载时初始化相关数据
 */
onMounted(async () => {
    defaultIcon.value = wsClient.getIconBase64Str("STEP_ICON");
    //从云端获取最新的数据
    stepInfo.value = await stepType.getItemInfoByID(stepInfo.value.id);
    sencneType.value = await stepType.getItemSencneType(stepInfo.value);
    sencne.value = await stepType.getItemSencne(sencneType.value);
    let typeObj =typeUtil.getStepActionTypeObj(stepInfo.value.stepActionType);
    selectedItemActionType.value = typeObj;
    selectedItemActionType.value.init(wsClient);
    if (stepInfo.value.stepActionType) {
        selectedTabOptions.value = selectedItemActionType.value.getTabOptions();
    }
    if (stepInfo.value.stepActionTypeID) {
        await refreshVariablesData();
        resetStepActionTypeInfo(active.value);
    }
});

const refreshVariablesData = async () => {
    let wapper = await selectedItemActionType.value.getStepActionTypeInfosByID(stepInfo.value.stepActionTypeID);
    stepActionInfos.value = wapper.stepActionType;
    varaiableProsMappings.value = wapper.mappings;
    variables.value = wapper.variables;
    let datas = await selectedItemActionType.value.getRelateVariableOperateMappings(stepActionInfos.value);
    stepActionInfos.value.conditions = datas ? datas : [];
    resetStepActionTypeInfo(active.value);
}



const resetStepActionTypeInfo = (active) => {
    if (active == -1) return;
    let tabOptions=selectedItemActionType.value.getTabOptions();
    let tabID = tabOptions[active].id;
    nextTick(() => {
        if (tabInfoPanes.value) {
            let viewIdex = 0;
            for(let tabInfoPane of tabInfoPanes.value)
            {
                let id = tabInfoPane.getID();
                if(tabID==id)
                {
                    break;
                }
                viewIdex++
            }
            let subTabInfoPane = tabInfoPanes.value[viewIdex];
            if (subTabInfoPane) {
                console.log(`resetStepActionTypeInfo success,active:${active},viewIdex:${viewIdex}`);
                subTabInfoPane.resetData(tabOperateHanlder,active);
            }
            else {
                console.log(`resetStepActionTypeInfo failed,active:${active},viewIdex:${viewIdex}`);
            }
        }
    });
}

const getItemInfoSencneString = () => {
    if (!sencne.value.sencneName || !sencneType.value.name) {
        return "加载中...";
    }
    return sencne.value.sencneName + '【' + sencneType.value.name + '】';
}

/**
 * 响应步骤类型选择事件
 */
const gotoStepActionTypeSelect = () => {
    //构建当前页面的页面信息
    let pageDatas = {
        itemInfo: stepInfo.value,
        selectedItemType: selectedItemActionType.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas
    }
    let datas = {
        backTargetPage: "StepInfo",
        backTargetPageDatas: pageDatas,
        eventName: "AUTOSPIRIT_STEPINFO_STEPTYPESELECT_EVENT",
        selectedStepActionType: selectedItemActionType.value,
    };
    //处理步骤类型改变动作
    const actionMethod = async (eventDatas) => {
        let newStepActionType = eventDatas.selectStepActionType ? eventDatas.selectStepActionType.id : "";
        let responseDatas = {};
        //当当前设置的步骤类型不等于最新选择的步骤类型
        if (stepInfo.value.stepActionType != newStepActionType) {
            //不为空代表之前设置过变量，先删除历史变量
            if (stepInfo.value.stepActionType && stepInfo.value.stepActionTypeID) {
                responseDatas = await selectedItemActionType.value.deleteStepActionTypeInfos(stepInfo.value.stepActionTypeID);
                if (responseDatas.resultCode == 1) {
                    stepInfo.value.stepActionTypeID = "";
                }
            }
            stepInfo.value.stepActionType = newStepActionType;
            selectedItemActionType.value = typeUtil.getStepActionTypeObj(stepInfo.value.stepActionType);
            selectedItemActionType.value.init(wsClient);
        }
        let actionInfos = selectedItemActionType.value.getDefaultStepActionTypeInfos(stepInfo.value);
        responseDatas = await selectedItemActionType.value.createStepActionTypeInfos(actionInfos);
        //刷新当前选择的步骤信息id
        stepActionInfos.value = responseDatas.resultData;
        //刷新当前步骤的信息并保存
        stepInfo.value.stepActionTypeID = stepActionInfos.value ? stepActionInfos.value.id : "";
        responseDatas = await stepType.saveData(stepInfo.value);
        let params = {
            message: responseDatas.message,
            position: "bottom",
        };
        showToast(params);
        router.push({
            name: datas.backTargetPage,
            state: {
                pageDatas: JSON.stringify(pageDatas)
            }
        });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "StepActionTypeSelected",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};

/**
 * 响应场景子类选择事件
 */
const gotoSencneTypeSelect = () => {
    let pageDatas = {
        itemInfo: stepInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas
    }
    let datas = {
        sencnePageType: 0,
        backTargetPage: "StepInfo",
        backTargetPageDatas: pageDatas,
        eventName: "AUTOSPIRIT_STEPINFO_SENCNETYPESELECT_EVENT",
        selectedSencneType: sencneType.value,
        selectedSencne: sencne.value
    };
    //处理场景子类改变动作
    const actionMethod = async (eventDatas) => {
        stepInfo.value.sencneTypeID = eventDatas.sencneType.id;
        let responseDatas = await stepType.saveData(stepInfo.value);
        let params = {
            message: responseDatas.message,
            position: "bottom",
        };
        showToast(params);
        if (responseDatas.resultCode == 1) {
            router.push({
                name: datas.backTargetPage,
                state: {
                    pageDatas: JSON.stringify(pageDatas)
                }
            });
        }
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "SencneTypeSelected",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};
</script>
<style scoped>
.stepTab {
    font-size: 15px;
}

.rightText {
    font-size: 14px;
    align-self: center;
}

.rightIcon {
    align-self: center;
    display: inline-flex;
}
</style>