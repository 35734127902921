import App from './App';
import router from './router';
import {
	createApp
} from 'vue';
import WebEventClient from './servers/SpiritClient';
import {
	Image,
	Col,
	Row,
	Form,
	Field,
	Cell,
	CellGroup,
	Button,
	Tabbar,
	TabbarItem,
	Circle,
	Switch,
	Popup,
	Popover,
	Tab,
	Tabs,
	Grid,
	GridItem,
	RadioGroup,
	Loading,
	NavBar,
	CheckboxGroup,
	Icon,
	Badge,
	Search,
	List,
	Collapse,
	CollapseItem,
	Radio,
	PullRefresh,
	Checkbox,
	DropdownMenu,
	DropdownItem,
	DatePicker,
	TimePicker,
	Tag,
	Steps,
	Step,
	Toast,
	Picker,
	Highlight,
} from "vant";
import { Doughnut, Line } from "vue-chartjs";
import Home from "@/components/app/views/Home.vue";
import My from "@/components/app/views/My.vue";
import Setting from "@/components/app/views/Setting.vue";
import Config from "@/components/app/views/Config.vue";

import SpiritPane from "@/components/app/views/common/SpiritPane.vue";
import OperatorPane from "@/components/app/views/common/OperatePane.vue";
import BackableNavBar from "@/components/app/views/common/BackableNavBar.vue";
import TouchablePane from "@/components/app/views/common/TouchablePane.vue";
import PopupProSetPane from "@/components/app/views/common/PopupProSetPane.vue";
import ItemInfoPane from "@/components/app/views/common/ItemInfoPane.vue";
import AppInfoPane from "@/components/app/views/common/AppInfoPane.vue";
import PageInfoPane from "@/components/app/views/common/PageInfoPane.vue";
import RouteInfoPane from "@/components/app/views/common/RouteInfoPane.vue";
import RunableInfosPane from "@/components/app/views/common/RunableInfosPane.vue";
import WizardPane from "@/components/app/views/common/WizardPane.vue";
import ConfigPane from "@/components/app/views/common/ConfigPane.vue";
import ProsetCell from "@/components/app/views/common/ProsetCell.vue";
import RelateCell from "@/components/app/views/common/RelateCell.vue";
import PageRouteCell from "@/components/app/views/common/PageRouteCell.vue";
import RelateSelectPane from "@/components/app/views/common/RelateSelectPane.vue";
import StepInfoPane from "@/components/app/views/common/StepInfoPane.vue";
import TaskInfoPane from "@/components/app/views/common/TaskInfoPane.vue";
import ImageUpload from "@/components/app/views/common/ImageUpload.vue";

import RunParameter from "@/components/app/views/common/stepType/tabOptionViews/EmptyStepActionType/RunParameter.vue";
import OccusConditions from "@/components/app/views/common/stepType/tabOptionViews/EmptyStepActionType/OccusConditions.vue";
import ParameterSetting from "@/components/app/views/common/stepType/tabOptionViews/ParameterSetting.vue";
import ParameterControlSetting from "@/components/app/views/common/stepType/tabOptionViews/EmptyStepActionType/ParameterControlSetting.vue";
import ImageMatchParameter from "@/components/app/views/common/stepType/tabOptionViews/ImageMatchActionType/ImageMatchParameter.vue";
import MatchContorlParameter from "@/components/app/views/common/stepType/tabOptionViews/ImageMatchActionType/MatchContorlParameter.vue";
import AppLoadParameter from "@/components/app/views/common/stepType/tabOptionViews/AppLoadStepActionType/AppLoadParameter.vue";
import VariablePane from "@/components/app/views/common/stepType/tabOptionViews/VariablePane.vue";
import VariableControlPane from "@/components/app/views/common/stepType/tabOptionViews/VariableControlPane.vue";
import VariableSetPane from "@/components/app/views/common/stepType/tabOptionViews/VariableSetPane.vue";
import RelateVariablePane from "@/components/app/views/common/stepType/tabOptionViews/RelateVariablePane.vue";
import AppPageRouteSetting from "@/components/app/views/common/AppPageRouteSetting.vue";
import PageConfigPane from "@/components/app/views/common/PageConfigPane.vue";

const wsClient = new WebEventClient();
wsClient.init().then(() => {
	const app = createApp(App);
	app.use(router);
	app.use(Toast);

	app.component("Doughnut", Doughnut);
	app.component("Line", Line);

	app.component("Steps", Steps);
	app.component("Step", Step);
	app.component("Tag", Tag);
	app.component("Col", Col);
	app.component("Row", Row);
	app.component("Cell", Cell);
	app.component("Image", Image);
	app.component("Form", Form);
	app.component("Field", Field);
	app.component("CellGroup", CellGroup);
	app.component("Button", Button);
	app.component("Tabbar", Tabbar);
	app.component("TabbarItem", TabbarItem);
	app.component("Circle", Circle);
	app.component("Switch", Switch);
	app.component("Icon", Icon);
	app.component("Badge", Badge);
	app.component("Search", Search);
	app.component("Popup", Popup);
	app.component("Popover", Popover);
	app.component("Tab", Tab);
	app.component("Tabs", Tabs);
	app.component("Grid", Grid);
	app.component("GridItem", GridItem);
	app.component("RadioGroup", RadioGroup);
	app.component("Loading", Loading);
	app.component("NavBar", NavBar);
	app.component("CheckboxGroup", CheckboxGroup);
	app.component("List", List);
	app.component("Collapse", Collapse);
	app.component("CollapseItem", CollapseItem);
	app.component("Radio", Radio);
	app.component("PullRefresh", PullRefresh);
	app.component("Checkbox", Checkbox);
	app.component("DropdownMenu", DropdownMenu);
	app.component("DropdownItem", DropdownItem);
	app.component("DatePicker", DatePicker);
	app.component("TimePicker", TimePicker);
	app.component("Picker", Picker);
	app.component("Highlight", Highlight);



	app.component("Home", Home);
	app.component("My", My);
	app.component("Setting", Setting);
	app.component("Config", Config);


	app.component("SpiritPane", SpiritPane);
	app.component("StepInfoPane", StepInfoPane);
	app.component("TaskInfoPane", TaskInfoPane);
	app.component("OperatorPane", OperatorPane);
	app.component("BackableNavBar", BackableNavBar);
	app.component("TouchablePane", TouchablePane);
	app.component("PopupProSetPane", PopupProSetPane);
	app.component("AppInfoPane", AppInfoPane);
	app.component("PageInfoPane", PageInfoPane);
	app.component("RouteInfoPane", RouteInfoPane);
	app.component("WizardPane", WizardPane);
	app.component("ItemInfoPane", ItemInfoPane);
	app.component("ConfigPane", ConfigPane);
	app.component("ProsetCell", ProsetCell);
	app.component("RelateCell", RelateCell);
	app.component("PageRouteCell", PageRouteCell);
	app.component("RelateSelectPane", RelateSelectPane);
	app.component("ImageUpload", ImageUpload);

	app.component("RunParameter", RunParameter);
	app.component("OccusConditions", OccusConditions);
	app.component("ParameterSetting", ParameterSetting);
	app.component("ParameterControlSetting", ParameterControlSetting);
	app.component("ImageMatchParameter", ImageMatchParameter);
	app.component("MatchContorlParameter", MatchContorlParameter);
	app.component("AppLoadParameter", AppLoadParameter);
	app.component("VariablePane", VariablePane);
	app.component("VariableControlPane", VariableControlPane);
	app.component("VariableSetPane", VariableSetPane);
	app.component("RelateVariablePane", RelateVariablePane);
	app.component("AppPageRouteSetting", AppPageRouteSetting);
	app.component("PageConfigPane", PageConfigPane);
	app.component("RunableInfosPane", RunableInfosPane);
	app.config.globalProperties.$wsClient = wsClient;
	app.mount('#app');
	
});




