<template>
    <!--步骤类型选择面板-->
    <div class="bg-lightGrey height-full">
        <BackableNavBar class="border none bg-trans" leftText="返回" :userDefineTiltle="true" @onBack="onClickLeft">
            <template #title>
                <DropdownMenu active-color="#1989fa" class="bg-trans width-popupGroup">
                    <DropdownItem v-model="selectedIndex" :options="taskOptions" @change="onTaskChanged" />
                </DropdownMenu>
            </template>
        </BackableNavBar>
        <div class="padding">
            <div class="bg-white border solid radius">
                <!--搜索栏-->
                <Search class="seachBar" v-model="searchValue" placeholder="请输入搜索关键词" />
            </div>
        </div>
        <div class="padding-left padding-right">
            <div class="bg-white border solid radius">
                <Cell class="bg-trans" title="选择步骤:"></Cell>
                <!--步骤操作页签面板--步骤列表-->
                <List class="tasksPane" v-model:loading="relateLoading" :finished="relateFinished"
                    finished-text="没有更多了">
                    <CellGroup>
                        <Collapse v-model="activeNames">
                            <RelateCell v-for="tempStepItem in taskRelateMappings"
                                :key="tempStepItem.key" :relateMapping="tempStepItem" :isSelectMode="true"
                                :selectedHanlder="hanlder"
                                >
                            </RelateCell>
                        </Collapse>
                    </CellGroup>
                </List>
            </div>
        </div>
        <div id="autoSpirit_sencneSetting_sureBtn">
            <div>
                <div v-if="!selectedStepMapping.id">
                    <Button disabled type="primary" class="sureBtn">确定</Button>
                </div>
                <div v-else>
                    <Button type="primary" class="sureBtn" @click="dealStepSelectedAction">确定</Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, watch, onMounted, nextTick, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import TaskTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/TaskTypeRelateOperator.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const eventName = pageDatas.eventName ? pageDatas.eventName : "";
const listenerID = pageDatas.listenerID ? pageDatas.listenerID : "";
const stepInfo = pageDatas.stepInfo ? pageDatas.stepInfo : {};
const taskOptions = ref([]);
const selectedIndex = ref(0);
const taskRelateMappings = ref([]);
const loadingState = ref(true);
const relateLoading =ref(true);
const relateFinished=ref(false);
const activeNames = ref(["1"]);
const searchValue =ref("");
const selectedStepMapping =ref({});
const hanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  updateRelateMappings:(relateItemMappings)=>{
    taskRelateMappings.value = relateItemMappings;
  },
  getRelateMappings:()=>{
    return taskRelateMappings.value;
  },
  changeRelateState:(relateMapping)=>{
    changeRelateState(relateMapping);
  }
};
const operator = new TaskTypeRelateOperator();
operator.init(hanlder);

const dealStepSelectedAction=()=>{
    wsClient.broadcastEvents(eventName, {
        selectedStepMapping: selectedStepMapping.value,
    });
};

const changeRelateState = (relateMapping) => {
    if(selectedStepMapping.value.id ==relateMapping.id)
    {
        selectedStepMapping.value.selected=relateMapping.selected;
        if(!relateMapping.selected)
        {
            selectedStepMapping.value={};
        }
    }
    else
    {
        selectedStepMapping.value.selected =false;
        selectedStepMapping.value=relateMapping;
    }
};

onUnmounted(() => {
    wsClient.removeEventListener(eventName, listenerID);
});

/**
 * 加载时刷新按钮位置，并且从配置文件中加载步骤选项
 */
onMounted(async () => {
    let btnWapper = document.getElementById("autoSpirit_sencneSetting_sureBtn");
    btnWapper.style.top =
        window.innerHeight - btnWapper.offsetHeight - 40 + "px";
    btnWapper.style.left =
        (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
    await initStepItemDatas();
    onTaskChanged(selectedIndex.value);
});

const onTaskChanged = async (index) => {
    loadingState.value = true;
    relateLoading.value=true;
    let taskInfo = taskOptions.value[index].itemInfo;
    await operator.initRelateMappingDatas(taskInfo);
    loadingState.value = false;
    relateLoading.value=false;
    relateFinished.value=true;
};

const initStepItemDatas = async () => {
    let stepType = typeUtil.getItemTypeObj(stepInfo.type);
    stepType.init(wsClient, router);
    let taskMappings = await stepType.getItemRelateParentMappings(stepInfo);
    let index = 0;
    for (let mapping of taskMappings) {
        let tempItemInfo = mapping.parentItemInfo;
        taskOptions.value.push({
            text: tempItemInfo.name,
            value: index,
            itemInfo: tempItemInfo
        });
        index++;
    }
};


/***
 * 响应回退
 */
const onClickLeft = () => {
    router.push({
        name: backTargetPage,
        state: {
            pageDatas: JSON.stringify(backTargetPageDatas)
        }
    });
};

</script>
<style scoped>
#autoSpirit_sencneSetting_sureBtn {
    position: absolute;
}

.sureBtn {
    border-radius: 10px;
    height: 40px;
    width: 200px;
}
</style>