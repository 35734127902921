<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar class="border none bg-trans" leftText="返回" :userDefineTiltle="true" @onBack="onClickLeft">
      <template #title>
        <DropdownMenu active-color="#1989fa" class="bg-trans width-popupGroup">
          <DropdownItem v-model="selectedSencneIndex" :options="sencneItems" @change="updateSencneType" />
        </DropdownMenu>
      </template>
    </BackableNavBar>
    <div class="padding">
      <div class="bg-white border solid radius">
        <Cell class="bg-trans" title="选择场景分类:"></Cell>
        <List class="border none bg-trans" v-model:loading="loading" :finished="finished" finished-text="没有更多了">
          <RadioGroup v-model="checked">
            <Cell center is-link v-for="sencneType in sencneTypeList" :key="sencneType.id" :title="sencneType.name"
              v-show="getSencneTypeVisableState(sencneType)" @click="checked = sencneType.id">
              <template #right-icon>
                <Radio :name="sencneType.id" />
              </template>
            </Cell>
          </RadioGroup>
        </List>
      </div>
    </div>
    <div id="autoSpirit_sencneSetting_sureBtn">
      <div>
        <div v-if="buttomDisabled">
          <Button disabled type="primary" class="sureBtn">确定</Button>
        </div>
        <div v-else>
          <Button type="primary" class="sureBtn" @click="dealSenceSelectedAction">确定</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, watch, onMounted,onUnmounted} from "vue";
import { useRouter } from "vue-router";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const sencnePageType = pageDatas.sencnePageType ? pageDatas.sencnePageType : 0;
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const sencneTypeChangeEventName = pageDatas.eventName ? pageDatas.eventName : "";
const listenerID = pageDatas.listenerID ? pageDatas.listenerID : "";
const selectedSencneType = pageDatas.selectedSencneType ? pageDatas.selectedSencneType : {};
let selectedSencne = pageDatas.selectedSencne ? pageDatas.selectedSencne : {};
const loading = ref(false);
const finished = ref(false);
const sencneItems = ref([]);
const selectedSencneIndex = ref(0);
const sencneTypeList = ref([]);
const checked = ref(0);
const buttomDisabled = ref(true);
onUnmounted(()=>{
  wsClient.removeEventListener(sencneTypeChangeEventName,listenerID);
});
onMounted(async () => {
  let btnWapper = document.getElementById("autoSpirit_sencneSetting_sureBtn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
  let params = await wsClient.sendActionToCloud("GET_USER_SENCNES", {
    userAccount: wsClient.getItemFromStorage("phone",true),
    type: sencnePageType
  });
  let sencnes = params.resultData;
  let tempSencneItems = [];
  let index = 0;
  for (let sencne of sencnes) {
    let sencneItem = {
      text: sencne.sencneName,
      value: index,
      sencne: sencne,
    };
    if (sencne.id == selectedSencne.id) {
      selectedSencneIndex.value = index;
    }
    tempSencneItems.push(sencneItem);
    index++;
  }
  sencneItems.value = tempSencneItems;
  updateSencneType(selectedSencneIndex.value);
});
const getSencneTypeVisableState=(sencneType)=>{
  if(sencneType.name =="常用")
  {
    return false;
  }
  if(sencneType.id == selectedSencneType.id)
  {
    return false;
  }
  return true;
};
watch(checked, (newValue, oldValue) => {
  let sencneType = getSelectedSencneType();
  buttomDisabled.value = sencneType ? false : true;
});
const dealSenceSelectedAction = () => {
  wsClient.broadcastEvents(sencneTypeChangeEventName, {
    sencne: getSelectedSencne(),
    sencneType: getSelectedSencneType(),
  });
};
const onClickLeft = () => {
  router.push({
    name: backTargetPage,
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
const getSelectedSencneType = () => {
  let result;
  for (let sencneType of sencneTypeList.value) {
    if (checked.value == sencneType.id) {
      result = sencneType;
      break;
    }
  }
  return result;
};
const getSelectedSencne = () => {
  return sencneItems.value[selectedSencneIndex.value].sencne;
};
const updateSencneType = async (index) => {
  selectedSencne = sencneItems.value[index].sencne;
  loading.value = true;
  finished.value = false;
  let params = await wsClient.sendActionToCloud("GET_SENCNETYPES", selectedSencne);
  sencneTypeList.value = params.resultData;
  loading.value = false;
  finished.value = true;
};
</script>
<style scoped>
#autoSpirit_sencneSetting_sureBtn {
  position: absolute;
}

.sureBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>