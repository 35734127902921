const BaseItemFilter = require("./BaseItemFilter");
/**
 * 选择模式过滤器
 */
module.exports = class SelectModeFilter extends BaseItemFilter {
    excludeKeys=[];
    filter=(itemInfo)=>
    {
       let key =itemInfo.key;
       let index =this.excludeKeys.indexOf(key);
       return index>=0;
    }
    init(params)
    {
        if(params.excludeKeys)this.excludeKeys =params.excludeKeys;
    }
}