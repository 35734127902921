<template>
    <!--变量设置面板，面板中的tab页签会根据变量的类型生成-->
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <List class="border none bg-trans" v-model:loading="itemLoading"
                    :finished="itemFinished" finished-text="没有更多了">
                    <VariablePane v-if="!isReload" v-for="variableData in itemVariableDatas" :key="variableData.id"
                        :variableData="variableData"
                        :isNewAction="isNewAction"
                        :isDisabled="isDisabled"
                        :elementsHideConfig="elementsHideConfig"
                         @onVariableDelete="onVariableDelete" />
                </List>
                <Cell class="bg-trans" v-show="elementsHideConfig?!elementsHideConfig.addVariableBtn:true">
                    <template #title>
                        <div class="text-center conditionBtn" @click="addVariableData()">添加变量
                        </div>
                    </template>
                </Cell>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, defineProps, toRefs,defineExpose,watch,nextTick} from "vue";
import StepType from "@/components/app/views/common/configPane/itemType/StepType.js";
import BaseVariableData from "../../variableType/BaseVariableData";
import NewItemVariableData from "../../variableType/NewItemVariableData";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
    itemInfo: {
        type: Object
    },
    isNewAction:{
        type:Boolean,
        default:false
    },
    isDisabled:{
        type:Boolean,
        default:false
    },
     //元素显示配置项
    elementsHideConfig: {
        type: Object
    },
    itemVariables:{
        type:Array,
        default:[]
    }
});
//步骤操作对象，用于获取变量值或关联关系
const stepType = new StepType();
stepType.init(wsClient);
const { itemInfo,elementsHideConfig,isNewAction,isDisabled,itemVariables} = toRefs(props);
const itemVariableDatas =ref(itemVariables.value);
const itemLoading = ref(true);
const itemFinished = ref(false);
const isReload = ref(false);
const crossIcon = ref();
watch(itemVariables, () => {
    isReload.value = true;
    itemVariableDatas.value = itemVariables.value;
    nextTick(()=>{
      isReload.value = false;
    });
});
/**
 * 加载时初始化相关变量
 */
onMounted(() => {
    crossIcon.value = wsClient.getIconBase64Str("ROUND_CROSS");
    refreashVariableDatas();
});


/**
 * 响应VariablePane面板的删除变量事件，刷新缓存
 * @param {变量值} variableData 
 */
const onVariableDelete = async (variableData) => {
    let index = 0;
    //遍历寻找被删除对象的索引
    for (let tempVariableData of itemVariableDatas.value) {
        if (tempVariableData.id == variableData.id) {
            break;
        }
        index++;
    }
    itemVariableDatas.value.splice(index, 1);
};

/**
 * 根据变量选择标签的key动态刷新变量值
 */
const refreashVariableDatas = async () => {
    let queryData = {};
    queryData.relateID = itemInfo.value.id;
    queryData.relateItemType = itemInfo.value.type;
    if(!isNewAction.value)
    {
        itemVariableDatas.value = await stepType.getItemVarables(itemInfo.value);
    }
    itemLoading.value = false;
    itemFinished.value = true;
};


/**
 * 响应变量增加按钮的事件
 * @param {变量集} variableDatas 
 */
const addVariableData = () => {
    let timestamp = new Date().getTime();
    let VariableDataType =getVarableDataType();
    let basetempData = new VariableDataType();
    basetempData.init({
        id: timestamp,
        variableType: {
            id: "VARIABLE_SELECT_TYPE",
            name: "选择类型"
        },
        name: "",
        value: "",
        isNewData: true,
        relateID: itemInfo.value.id,
        relateItemType: itemInfo.value.type
    }, wsClient);
    itemVariableDatas.value.push(basetempData);
};

const getVarableDataType =()=>{
    if(isNewAction.value)
    {
        return NewItemVariableData;
    }
    else
    {
        return BaseVariableData;
        
    }
}
const getVariableDatas=()=>{
    return itemVariableDatas.value;
}


const resetDatas=()=>{
  itemVariableDatas.value = [];
}

defineExpose({
    resetDatas,
    getVariableDatas,
});
</script>
<style scoped>
.conditionBtn {
    color: #0080ff;
    cursor: pointer;
}
</style>