<template>
  <div class="TouchablePane" @touchstart="down" @touchend="up">
    <slot></slot>
  </div>
</template>
<script>
import {ref} from "vue";
export default {
  name: "TouchablePane",
  components: {
  },
  emits: ["onLongTouch"],
  setup(props) {
    let timer =ref(0);
    return {
        timer
    };
  },
  methods: {
    down() {
        this.timer=0   // 重置timer
        this.timer=setTimeout(()=>{
            this.$emit("onLongTouch");
            this.timer=0
            },1000);
    },
    up(){
      if(this.timer){clearTimeout(this.timer)}
    },
    // move()
    // {
    //     clearTimeout(this.timer)    // 如果来到这里，说明是滑动
    //     this.timer=0;
    // }
  },
};
</script>
<style scoped>
</style>