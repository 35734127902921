<template>
  <!--任务信息面板，用于编辑设计任务的相关信息-->
  <div class="bg-lightGrey height-full">
    <BackableNavBar leftText="返回" title="任务信息" :autoControl="false" @onBack="onClickLeft" />
    <!--任务快捷信息面板-->
    <!-- <div class="padding">
      <div class="flex bg-white border solid radius padding">
        <div>
          <Image class="flex center as-avatar xl" :src="defaultIcon"></Image>
        </div>
        <div>
          <Cell center :title="taskInfo.name+'【#'+taskInfo.id+'】'" :label="taskInfo.define"></Cell>
        </div>
      </div>
    </div> -->
    <!--任务基础信息面板-->
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="基础信息："></Cell>
          <ProsetCell title="名称" :value="taskInfo.name" @onSubmit="onTaskRenameSubmit"></ProsetCell>
          <ProsetCell type="textarea" title="描述" :value="taskInfo.define" @onSubmit="onTaskDefineSubmit"></ProsetCell>
          <Cell center is-link title="场景归属" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect"></Cell>
          <Cell v-bind:title="taskInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
            <template #right-icon>
              <Switch v-model="taskInfo.commom" size="24" @change="changeCommom" />
            </template>
          </Cell>
        </CellGroup>
      </div>
    </div>
    <!--任务场景信息面板-->
    <!-- <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="场景信息："></Cell>
          <Cell center is-link title="场景归属" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect"></Cell>
        </CellGroup>
      </div>
    </div> -->
    <!--任务高级信息面板，用于编辑操作高级内容-->
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="高级信息:"></Cell>
          <Tabs shrink v-model:active="active" background="transparent">
            <!--步骤操作页签面板-->
            <Tab title="步骤设置">
              <template #title> <span class="s15">步骤设置</span></template>
              <RelateSelectPane ref="relateSelectPane" :itemRelateMappings="taskRelateMappings"
                :loadingState="loadingState" :relateItemOperator="operator"
                @onAddRelateItem="doAddRelateStepAndTask(taskRelateMappings.length)" />
            </Tab>
            <Tab title="变量设置">
              <template #title> <span class="s15">变量设置</span></template>
              <!--变量设置面板-->
              <ParameterSetting :itemInfo="taskInfo"></ParameterSetting>
            </Tab>
            <Tab title="执行日志">
              <template #title> <span class="s15">执行日志</span></template>
              <!--变量设置面板-->
              <RunableInfosPane :itemInfo="taskInfo"></RunableInfosPane>
            </Tab>
          </Tabs>
        </CellGroup>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import {
  useRouter
} from 'vue-router';
import { showToast } from "vant";
import TaskType from "@/components/app/views/common/configPane/itemType/TaskType.js";
import TaskTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/TaskTypeRelateOperator.js";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
//任务类对象，用于从云端获取最新任务信息，并且做一些数据转化的辅助工作
const taskType = new TaskType();
taskType.init(wsClient, router);
const hanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getItemInfo:()=>{
    return taskInfo.value;
  },
  getWatchedPageName: (relateMapping) => {
    let type = relateMapping.relateType;
    //查看的是步骤，则传递相关信息到步骤页面
    if (type == "TASK_TYPE") {
        return "TaskInfo";
    }
    else if(type == "STEP_TYPE")
    {
        return "StepInfo";
    }
  },
  getBackPageName:()=>{
    return "TaskInfo";
  },
  getBackPageDatas:()=>{
    let datas ={
        itemInfo: taskInfo.value,
        backTargetPage: backTargetPage,
        backTargetPages:backTargetPages,
        backTargetPageDatas: backTargetPageDatas
      };
    return datas;
  },
  updateRelateMappings:(relateItemMappings)=>{
    taskRelateMappings.value = relateItemMappings;
    loadingState.value = false;
  },
  getRelateMappings:()=>{
    return taskRelateMappings.value;
  },
  reloadItemInfos:(datas)=>{
     //将当前页面信息加入到缓存，回退时从缓存获取信息回退
     backTargetPages.push({
      itemInfo: taskInfo.value,
      backTargetPage: backTargetPage
    });
    //重新加载数据
    reloadPageDatas(datas);
  },
  /**
   * 响应映射复制
   * @param mapping 
   */
  onMappingCopy:(mapping)=>{
    taskInfo.value.copyMapping =mapping;
  },
  getCopiedMapping:()=>{
    return taskInfo.value.copyMapping;
  },
  /**
   * 是否有映射被复制，当推出此页面后，清除
   */
  isMappingCopied:()=>{
    return taskInfo.value.copyMapping?true:false;
  },
  /**
   * 响应步骤增加事件
   * @param insertIndex 
   */
  onTaskAndStepAdded:(insertIndex)=>{
    doAddRelateStepAndTask(insertIndex);
  },
  /**
   * 响应复制的步骤增加事件
   * @param copyStepInfo 
   * @param insertIndex 
   */
  onCopyTaskAndStepAdded:async(copyStepInfo,insertIndex)=>{
    await _createRelateMappingByAddAction([copyStepInfo],insertIndex);
    reloadPageDatas({
      itemInfo:taskInfo.value,
      backTargetPage:backTargetPage
    });
  },
  /**
   * 响应步骤新建事件
   * @param insertIndex 
   */
   onTaskAndStepCreated:async(itemData,insertIndex)=>{
    await _createRelateMappingByAddAction([itemData],insertIndex);
    reloadPageDatas({
      itemInfo:taskInfo.value,
      backTargetPage:backTargetPage
    });
  },
};
const operator = new TaskTypeRelateOperator();
operator.init(hanlder);
//读取页面传递过来的参数
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const taskInfo = ref(pageDatas.itemInfo);
//返回的页面
let backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
//返回的页面路径集，当从Task页面跳转到Task页面，将原页面的参数封装在路径集中，确保回退到正确页面
const backTargetPages = pageDatas.backTargetPages ? pageDatas.backTargetPages : [];
//当前页面返回时传递的页面参数
const backTargetPageDatas=pageDatas.backTargetPageDatas?pageDatas.backTargetPageDatas:{};
//默认的Task图标
const defaultIcon = ref("");
//当前task所在的子场景
const sencneType = ref({});
const sencne =ref({});
//高级操作栏的选项卡索引
const active = ref(0);
//当前任务下所关联的步骤映射集
const taskRelateMappings = ref([]);

const loadingState =ref(true);

/**
 * 加载时初始化图标，任务信息，子场景信息，映射信息等
 */
onMounted(async () => {
  defaultIcon.value = wsClient.getIconBase64Str("TASK_ICON");
  await reloadItemData();
  operator.initRelateMappingDatas(taskInfo.value);
});

const getItemInfoSencneString =()=>{
  if(!sencne.value.sencneName||!sencneType.value.name)
  {
    return "加载中...";
  }
  return sencne.value.sencneName+'【'+sencneType.value.name+'】';
}

const changeCommom=()=>{
  taskType.saveData(taskInfo.value);
};

/**
 * 响应页面名称更新动作
 * @param {名称} newName 
 */
 const onTaskRenameSubmit = (newName) => {
    taskInfo.value.name = newName;
    taskType.saveData(taskInfo.value);
};

/**
 * 响应更新描述动作
 * @param {描述} newDiscripe 
 */
const onTaskDefineSubmit = (newDiscripe) => {
    taskInfo.value.define = newDiscripe;
    taskType.saveData(taskInfo.value);
};

/**
 * 重新从云端加载相关信息
 */
const reloadItemData =async()=>{
  let copyMapping =taskInfo.value.copyMapping;
  //从云端获取最新的数据
  taskInfo.value = await taskType.getItemInfoByID(taskInfo.value.id);
  taskInfo.value.copyMapping =copyMapping;
  sencneType.value =await taskType.getItemSencneType(taskInfo.value);
  sencne.value =await taskType.getItemSencne(sencneType.value);
}

/**
 * 重新加载页面信息
 * @param {页面信息} pageDatas 
 */
const reloadPageDatas = async (pageDatas) => {
  taskInfo.value = pageDatas.itemInfo;
  backTargetPage = pageDatas.backTargetPage;
  await reloadItemData();
  operator.initRelateMappingDatas(taskInfo.value);
};

/**
 * 响应页面回退事件
 */
const onClickLeft = () => {
  //当存在页面回退缓存时，说明上一页面也是任务页面，先回退到上一页面
  if (backTargetPages.length > 0) {
    let index = backTargetPages.length - 1;
    let lastPageData = backTargetPages[index];
    backTargetPages.splice(index, 1);
    reloadPageDatas(lastPageData);
  }
  //否则直接回跳到上一页面
  else {
    router.push({
      name: backTargetPage,
      state:{
            pageDatas:JSON.stringify(backTargetPageDatas)
        }
    });
  }
};

const _createRelateMappingByAddAction =async(selectedItems,insertIndex)=>{
  let queryData = {
      relateMappings: [],
      insertIndex: insertIndex
    };
    //基于选择的元素，遍历构建查询对象
    for (let selectedItem of selectedItems) {
      let tempMapping = {
        parentID: taskInfo.value.id,
        parentType:taskInfo.value.type,
        relateID: selectedItem.id,
        relateType: selectedItem.type,
        disable: false
      };
      queryData.relateMappings.push(tempMapping);
    }
    let responseDatas = await wsClient.sendActionToCloud(
      "CREATE_RELATE_MAPPINGS",
      queryData
    );
    return responseDatas;
};

/**
 * 相应添加映射关联的动作
 */
const doAddRelateStepAndTask = async (insertIndex) => {
  //自身和选择自身的祖先任务无法被添加关联，避免循环引用
  let itemsExcludeArr = [taskInfo.value.key];
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "TaskInfo",
    backTargetPageDatas:{
        itemInfo: taskInfo.value,
        backTargetPage: backTargetPage,
        backTargetPages:backTargetPages,
        backTargetPageDatas:backTargetPageDatas
    },
    eventName: "AUTOSPIRIT_TASKINFO_STEPSSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsType: ["ALL_TYPE", "TASK_TYPE", "STEP_TYPE"],
    selectedMode: true,
    itemsExclude: itemsExcludeArr,
    pageType: "0",
    pageTitle: "步骤选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let responseDatas = await _createRelateMappingByAddAction(selectedItems,insertIndex);
    //成功后返回之前的选择页面
    if (responseDatas.resultCode == 1) {
      let pageDatas = {
        itemInfo: taskInfo.value,
        backTargetPage: backTargetPage,
        //之前任务页面的页面缓存路径
        backTargetPages:backTargetPages,
        //之前任务页面的backPagedatas对象
        backTargetPageDatas:backTargetPageDatas
      }
      router.push({
        name: datas.backTargetPage,
        state:{
          pageDatas:JSON.stringify(pageDatas)
        }
      });
    }
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state:{
      pageDatas:JSON.stringify(datas)
    }
  });
};

/**
 * 相应场景子类选择按钮事件
 */
const gotoSencneTypeSelect = () => {
  let datas = {
    sencnePageType: 0,
    backTargetPage: "TaskInfo",
    backTargetPageDatas:{
        itemInfo: taskInfo.value,
        backTargetPage: backTargetPage,
        backTargetPages:backTargetPages,
        backTargetPageDatas:backTargetPageDatas
    },
    eventName: "AUTOSPIRIT_TASKINFO_SENCNETYPESELECT_EVENT",
    selectedSencneType: sencneType.value,
    selectedSencne:sencne.value
  };
  //响应更新任务场景子类事件
  const actionMethod = async (eventDatas) => {
    taskInfo.value.sencneTypeID = eventDatas.sencneType.id;
    let responseDatas = await taskType.saveData(taskInfo.value);
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
    if (responseDatas.resultCode == 1) {
      let pageDatas = {
        itemInfo: taskInfo.value,
        backTargetPage: backTargetPage,
        backTargetPages:backTargetPages,
        backTargetPageDatas:backTargetPageDatas,
        selectedSencne:sencne.value
      }
      router.push({
        name: datas.backTargetPage,
        state:{
          pageDatas:JSON.stringify(pageDatas)
        }
      });
    }
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "SencneTypeSelected",
    state:{
          pageDatas:JSON.stringify(datas)
      }
  });
};

</script>
<style scoped>

</style>