<!--步骤触发条件设置面板 -->
<template>
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans">
                    <template #title>
                        <Checkbox class="content-vcenter block-inline" v-if="!isDisabled"
                            v-model="selectedStepActionType.repeatRunWithoutCheck" shape="square"
                            @change="onParamValueChanged('repeatRunWithoutCheck', selectedStepActionType.repeatRunWithoutCheck)">
                        </Checkbox>
                        <Checkbox class="content-vcenter block-inline" disabled v-if="isDisabled"
                            v-model="selectedStepActionType.repeatRunWithoutCheck" shape="square"></Checkbox>
                        <span class="content-vcenter block-inline margin-left">满足条件后重复运行不检查条件</span>
                        <Icon class="content-vcenter padding-left" name="info-o" size="20"
                            @click="showTips('勾选后，当条件满足后再次重复运行时不再检查条件')" />
                    </template>
                </Cell>
                <Cell class="bg-trans">
                    <template #title>
                        <Checkbox class="content-vcenter block-inline" v-if="!isDisabled"
                            v-model="selectedStepActionType.checkBeforeDelay" shape="square"
                            @change="onParamValueChanged('checkBeforeDelay', selectedStepActionType.checkBeforeDelay)">
                        </Checkbox>
                        <Checkbox class="content-vcenter block-inline" disabled v-if="isDisabled"
                            v-model="selectedStepActionType.checkBeforeDelay" shape="square"></Checkbox>
                        <span class="content-vcenter block-inline margin-left">在延迟时间之前检查条件</span>
                        <Icon class="content-vcenter padding-left" name="info-o" size="20"
                            @click="showTips('勾选后，无论步骤中是否设置延迟启动时间，都会直接检查启动条件，成功后再执行步骤的延迟启动')" />
                    </template>
                </Cell>
                <Cell class="bg-trans">
                    <template #title>
                        <Checkbox class="content-vcenter block-inline" v-if="!isDisabled"
                            v-model="selectedStepActionType.runWithPartCondition" shape="square"
                            @change="onParamValueChanged('runWithPartCondition', selectedStepActionType.runWithPartCondition)">
                        </Checkbox>
                        <Checkbox class="content-vcenter block-inline" disabled v-if="isDisabled"
                            v-model="selectedStepActionType.runWithPartCondition" shape="square"></Checkbox>
                        <span class="content-vcenter block-inline margin-left">满足部分条件即可执行</span>
                        <Icon class="content-vcenter padding-left" name="info-o" size="20"
                            @click="showTips('勾选后，只需要满足指定的部分条件数即可执行任务，默认是需要全部满足')" />
                    </template>
                </Cell>
                <VariableSetPane v-if="selectedStepActionType.runWithPartCondition" :isDisabled="isDisabled"
                    :isNewAction="isNewAction" :stepActionType="selectedStepActionType" :itemInfo="itemInfo"
                    label="要满足的条件数量" title="要满足的条件数量" name="partConditionCount" type="VARIABLE_NUMBER"
                    tipInfo="要满足的条件的区间数量，默认是1个及以上，填2表示一定要满足2个条件，2-5表示满足的条件数要在2和5区间，这里指得是满足条件的个数而不是第几个条件，前提是你有添加条件"
                    @onValueChange="onActionStepParamChanged" @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <Cell class="bg-trans">
                    <template #title>
                        <Checkbox class="content-vcenter block-inline" v-if="!isDisabled"
                            v-model="selectedStepActionType.runUntilConditionChecked" shape="square"
                            @change="onParamValueChanged('runUntilConditionChecked', selectedStepActionType.runUntilConditionChecked)">
                        </Checkbox>
                        <Checkbox class="content-vcenter block-inline" disabled v-if="isDisabled"
                            v-model="selectedStepActionType.runUntilConditionChecked" shape="square"></Checkbox>
                        <span class="content-vcenter block-inline margin-left">无限重复检测条件直到满足为止</span>
                        <Icon class="content-vcenter padding-left" name="info-o" size="20"
                            @click="showTips('勾选后，会忽略重复检查次数，如果设置的启动条件不满足，则会一直进行检查，直到条件满足')" />
                    </template>
                </Cell>
                <VariableSetPane v-if="!selectedStepActionType.runUntilConditionChecked" :isDisabled="isDisabled"
                    :isNewAction="isNewAction" :stepActionType="selectedStepActionType" :itemInfo="itemInfo"
                    label="检查次数" title="检查次数" name="conditionCheckTime" type="VARIABLE_NUMBER"
                    tipInfo="条件不成立的情况下的重复检查次数，如果设置了无限重复检测，则此参数设置失效" @onValueChange="onActionStepParamChanged"
                    @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <VariableSetPane :isDisabled="isDisabled" :isNewAction="isNewAction"
                    :stepActionType="selectedStepActionType" :itemInfo="itemInfo" label="每次间隔" title="每次间隔"
                    name="conditionCheckInterval" type="VARIABLE_NUMBER" tipInfo="条件每次重复检查的时间间隔，默认为2秒，前提是有添加重复检查条件"
                    @onValueChange="onActionStepParamChanged" @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <Cell class="bg-trans" v-show="!selectedStepActionType.runUntilConditionChecked">
                    <template #title>
                        <span class="content-vcenter block-inline">多次检查失败后</span>
                        <Icon class="content-vcenter padding-left" name="info-o" size="20"
                            @click="showTips('重复检测次数满足之后，条件还是不满足的情况下执行的动作，如果勾选了无限重复检测，则此参数不生效')" />
                        <Popover placement="top" :actions="operateActions" v-model:show="actionPopupShow">
                            <CellGroup class="width-popupGroup">
                                <Cell clickable v-for="action in operateActions" :key="action.id"
                                    v-model="action.selected" :title="action.name"
                                    :class="action.selected ? 'selected' : ''" @click="onActionSelected(action)">
                                    <template v-if="action.selected" #right-icon>
                                        <Icon name="success" class="selected" />
                                    </template>
                                </Cell>
                            </CellGroup>
                            <template #reference>
                                <Button v-if="!isDisabled"
                                    class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertOptionName(selectedStepActionType.nextOptionByFailed)
                                    }}</Button>
                                <Button disabled v-if="isDisabled"
                                    class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertOptionName(selectedStepActionType.nextOptionByFailed)
                                    }}</Button>
                            </template>
                        </Popover>
                    </template>
                </Cell>
                <VariableSetPane v-if="!isNewAction && selectedStepActionType.nextOptionByFailed == 'GOTO_OTHER_STEP'"
                    :isDisabled="isDisabled" :isNewAction="isNewAction" :stepActionType="selectedStepActionType"
                    :itemInfo="itemInfo" label="待跳转步骤" title="跳转步骤" name="nextItemIDByCheckFailed" type="VARIABLE_STEP"
                    tipInfo="需要跳转的步骤，该步骤在指定任务下" @onFeildClick="gotoNextStepItemSelect('nextItemIDByCheckFailed')">
                </VariableSetPane>
                <Cell class="bg-trans" v-if="!isDisabled">
                    <template #title>
                        <span class="content-vcenter block-inline">触发条件列表</span>
                        <Icon class="content-vcenter padding-left" name="info-o" size="20"
                            @click="showTips('条件列表，如果设置了条件，则只有条件满足的情况下才会执行此步骤')" />
                        <span class="content-vcenter block-inline conditionBtn"
                            @click="gotoAddOccusEventCondition">添加条件</span>
                        <span v-if="copyedOccusConditionMapping.id" class="content-vcenter block-inline conditionBtn" @click="doCopyOccusConditionAction">粘贴条件</span>
                        <span v-if="!copyedOccusConditionMapping.id" class="content-vcenter block-inline disableBtn">粘贴条件</span>
                    </template>
                </Cell>
                <CellGroup>
                    <Cell v-for="mapping in conditionMappings" :key="mapping.id" class="bg-trans">
                        <template #title>
                            {{ getOccusMappingName(mapping) }}
                        </template>
                        <template #right-icon>
                            <div class="flex">
                                <Popover placement="top-end" v-model:show="mapping.conditionShow">
                                    <CellGroup class="width-popupGroup">
                                        <Cell clickable v-for="relateOperateOption in occuseCondtionOperateOptions"
                                            :key="relateOperateOption.id" :title="relateOperateOption.name"
                                            @click="doCondtionRelateOperate(relateOperateOption,mapping)">
                                        </Cell>
                                    </CellGroup>
                                    <template #reference>
                                        <div class="flex content-vcenter"
                                            @click.stop="mapping.conditionShow = !mapping.conditionShow">
                                            <Icon class="rightIcon" name="bars" size="20" />
                                        </div>
                                    </template>
                                </Popover>
                                <Icon v-if="!isDisabled" class="rightIcon" :name="crossIcon" size="20"
                                    @click="deleteOccusCondtionMapping(mapping)" />
                            </div>
                        </template>
                    </Cell>
                </CellGroup>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, defineProps, toRefs, defineExpose, reactive } from "vue";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import util from "@/utils/util";
import { useRouter } from "vue-router";
import { showToast } from "vant";
import optionsConfig from "../../OptionsConfig";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const props = defineProps({
    itemInfo: {
        type: Object
    },
    isNewAction: {
        type: Boolean,
        default: false
    },
    isDisabled: {
        type: Boolean,
        default: false
    },
});
const { itemInfo, isNewAction, isDisabled } = toRefs(props);

const crossIcon = ref();

const conditionMappings = ref([]);

let pageDatas = history.state.pageDatas;

if (pageDatas) pageDatas = JSON.parse(pageDatas);

const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
//多次检查条件失败后的下一步动作
const operateActions = optionsConfig.stepOperateOptionsBySuccess;

const actionPopupShow = ref(false);

const selectedStepActionType = ref({});

const tabIndex = ref(0);

const copyedOccusConditionMapping =ref({});

const occuseCondtionOperateOptions=[
    {
        id:"OCCUSCONDITION_WATCH_ITEM",
        name:"查看"
    },
    {
        id:"OCCUSCONDITION_COPY_ITEM",
        name:"复制"
    }
];

const doCopyOccusConditionAction=async()=>{
    let tempMapping =util.cloneObject(copyedOccusConditionMapping.value);
    delete tempMapping.id;
    await hanlder.createOccusCondtionMapping(tempMapping);
    conditionMappings.value = hanlder.getOccusConditionMappings();
};

const doCondtionRelateOperate =(relateOperateOption,occusConditionMapping)=>{
    let operateType = relateOperateOption.id;
    switch(operateType)
    {
        case "OCCUSCONDITION_WATCH_ITEM":
            gotoWatchOccusEventCondition(occusConditionMapping);
            break;
        case "OCCUSCONDITION_COPY_ITEM":
            copyedOccusConditionMapping.value =occusConditionMapping;
            break;
    }
    occusConditionMapping.conditionShow =false;
};

const deleteOccusCondtionMapping = async (occusConditionMapping) => {
    await hanlder.deleteOccusCondtionMapping(occusConditionMapping);
    conditionMappings.value = hanlder.getOccusConditionMappings();
};

const onAddOccusEventCondition = async (eventDatas) => {
    return await hanlder.onAddOccusEventCondition(eventDatas);
};

const getOccusMappingName = (mapping) => {
    let stepActionType = typeUtil.getStepActionTypeObj(mapping.actionStepItemType);
    return `${stepActionType.name}【${mapping.name}】【${mapping.id}】`;
};

const gotoNextStepItemSelect = (paramName) => {
    //构建当前页面的页面信息
    let pageDatas = {
        itemInfo: itemInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas,
        tabIndex: tabIndex.value,
    }
    let datas = {
        backTargetPage: hanlder.getBackPageName(),
        backTargetPageDatas: pageDatas,
        eventName: "AUTOSPIRIT_STEPINFO_STEPNEXTSTEPITEM_SELECT_EVENT",
        stepInfo: itemInfo.value,
    };
    //处理步骤类型改变动作
    const actionMethod = async (eventDatas) => {
        let selectedStepMapping = eventDatas.selectedStepMapping;
        let itemInfo = selectedStepMapping.itemInfo;
        let typeObj = typeUtil.getItemTypeObj(itemInfo.type);
        let newValue = `【${selectedStepMapping.id}:${typeObj.name}】${itemInfo.name}`
        onParamValueChanged(paramName, newValue);
        router.push({
            name: datas.backTargetPage,
            state: {
                pageDatas: JSON.stringify(pageDatas)
            }
        });
    };
    datas.listenerID = wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "NextStepItemSelect",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};

/**
 * 响应步骤类型选择事件
 */
 const gotoWatchOccusEventCondition = (occusConditionMapping) => {
    //构建当前页面的页面信息
    let pageDatas = {
        itemInfo: itemInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas,
        tabIndex: tabIndex.value,
    }
    let datas = {
        backTargetPage: hanlder.getBackPageName(),
        backTargetPageDatas: pageDatas,
        eventName: "AUTOSPIRIT_STEPINFO_STEPTYPEOCCUSCONDITION_WATCH_EVENT",
        stepActionTypeInfo: selectedStepActionType.value,
        selectConditonMapping:occusConditionMapping,
        itemInfo: itemInfo.value,
        isNewAction: isNewAction.value,
        isWatchAction:true,
    };
    //处理步骤类型改变动作
    const actionMethod = (eventDatas) => {
        router.push({
            name: datas.backTargetPage,
            state: {
                pageDatas: JSON.stringify(pageDatas)
            }
        });
    };
    datas.listenerID = wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "OccusEventConditionSelect",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};

/**
 * 响应步骤类型选择事件
 */
const gotoAddOccusEventCondition = () => {
    //构建当前页面的页面信息
    let pageDatas = {
        itemInfo: itemInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas,
        tabIndex: tabIndex.value,
    }
    let datas = {
        backTargetPage: hanlder.getBackPageName(),
        backTargetPageDatas: pageDatas,
        eventName: "AUTOSPIRIT_STEPINFO_STEPTYPEOCCUSCONDITION_SELECT_EVENT",
        stepActionTypeInfo: selectedStepActionType.value,
        itemInfo: itemInfo.value,
        isNewAction: isNewAction.value
    };
    //处理步骤类型改变动作
    const actionMethod = async (eventDatas) => {
        await onAddOccusEventCondition(eventDatas);
        router.push({
            name: datas.backTargetPage,
            state: {
                pageDatas: JSON.stringify(pageDatas)
            }
        });
    };
    datas.listenerID = wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "OccusEventConditionSelect",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};

/**
 * 显示提示信息
 * @param {*} message 
 */
const showTips = (message) => {
    showToast({
        message: message,
        position: 'top'
    });
};

const onParamValueChanged = (title, value) => {
    let eventData = {
        paramName: title,
        paramValue: value,
    }
    onActionStepParamChanged(eventData);
}


/**
 * 响应多次检查失败后操作事件选择的事件
 * @param {*} action 
 */
const onActionSelected = (action) => {
    if (selectedStepActionType.value.selectedAction) {
        selectedStepActionType.value.selectedAction.selected = false;
    }
    selectedStepActionType.value.selectedAction = action;
    selectedStepActionType.value.selectedAction.selected = true;
    selectedStepActionType.value.nextOptionByFailed = action.id;
    actionPopupShow.value = false;
    onParamValueChanged("nextOptionByFailed", action.id);
}

const convertOptionName = (id) => {
    //初始化设置默认选择的操作事件
    for (let action of operateActions) {
        if (action.id == id) {
            return action.name;
        }
    }
    return id;
}

onMounted(() => {
    crossIcon.value = wsClient.getIconBase64Str("ROUND_CROSS");
});


let hanlder = {};

const onActionStepParamChanged = (eventData) => {
    hanlder.onActionStepParamChanged(eventData);
};

const onMappingChanged = (eventData) => {
    hanlder.onMappingChanged(eventData);
}

/**
 * 重置面板数据
 * @param {*} stepActionTypeInfo 
 */
const resetData = async (tempHander, index) => {
    tabIndex.value = index;
    hanlder = tempHander;
    selectedStepActionType.value = hanlder.getStepActionInfos();
    await hanlder.refreshOccusConditions();
    conditionMappings.value = hanlder.getOccusConditionMappings();
    //初始化设置默认选择的操作事件
    for (let action of operateActions) {
        if (selectedStepActionType.value.nextOptionByFailed == action.id) {
            selectedStepActionType.value.selectedAction = action;
            selectedStepActionType.value.selectedAction.selected = true;
        }
    }
};

const getID = () => {
    return "AUTOSPIRIT_OCCUSCONDITIONS";
};

defineExpose({
    resetData,
    getID,
});

</script>
<style scoped>
.rightIcon {
    align-self: center;
    display: inline-flex;
    margin-left: 5px;
}

.conditionBtn {
    padding-left: 20px;
    color: #0080ff;
    cursor: pointer;
}
.disableBtn{
    padding-left: 20px;
}
</style>