const util =require("@/utils/util");
const optionsConfig =require("../stepType/OptionsConfig");
module.exports = class BaseVariableData {
    id = "";
    variableType = null;
    name = "";
    value = "";
    relateID = "";
    variableTypeOptions = util.cloneObject(optionsConfig.variableTypeOptions);
    modify = false;
    isNewData = false;
    relateItemType ="";
    tempStrID="";
    errorType="";
    init(params, wsClient, router) {
        if (wsClient) this.wsClient = wsClient;
        if (router) this.router = router;
        if (params.id) this.id = params.id;
        if (params.variableType) this.variableType = params.variableType;
        if (params.name) this.name = params.name;
        if (params.value) this.value = params.value;
        // if (params.variableTypeOptions) this.variableTypeOptions = params.variableTypeOptions;
        if (params.relateID) this.relateID = params.relateID;
        if (params.isNewData != "undefine") this.isNewData = params.isNewData;
        if (params.relateItemType) this.relateItemType = params.relateItemType;
        if (params.tempStrID) this.tempStrID = params.tempStrID;
        if (this.isNewData) this.modify = true;
        return this;
    }

    validate = () => {
        let resultData = {
            resultCode: 1,
            message: "校验成功"
        };
        if (this.variableType && this.variableType.id == "VARIABLE_SELECT_TYPE") {
            resultData.resultCode = 0;
            resultData.message = "请选择变量类型！";
            return resultData;
        }
        if (!this.name) {
            resultData.resultCode = 0;
            resultData.message = "请输入变量名称！";
            return resultData;
        }
        return resultData;
    }

    saveData = async () => {
        if (this.isNewData) {
            let queryData = {
                name: this.name,
                value: this.value,
                type: this.variableType.id,
                relateID: this.relateID,
                relateItemType:this.relateItemType
            }
            let responseDatas = await this.wsClient.sendActionToCloud(
                "CREATE_VARIABLE_INFO",
                queryData
            );
            if (responseDatas.resultCode == 1) {
                this.id = responseDatas.resultData.id;
                this.isNewData = false;
            }
            return responseDatas;
        }
        else {
            let queryData = {
                id: this.id,
                name: this.name,
                value: this.value,
                type: this.variableType.id,
                relateID: this.relateID,
                relateItemType:this.relateItemType
            }
            let responseDatas = await this.wsClient.sendActionToCloud(
                "UPDATE_VARIABLE_INFO",
                queryData
            );
           
            return responseDatas;
        }
    }

    deleteData=async()=>{
        let resultData={
            resultCode:1,
            noNeedShowToast:true
        };
        if (!this.isNewData) {
            let queryData ={
                ids:[this.id]
            }
            resultData = await this.wsClient.sendActionToCloud(
                "DELETE_VARIABLE_INFO",
                queryData
            );
        }
        return resultData;
    }

    getVariableTypeByID=(id)=>{
        for(let tempType of this.variableTypeOptions)
        {
            if(tempType.id ==id)
            {
                return tempType;
            }
        }
    }

}