<template>
  <div class="autospirit_taskMgr_selectSpirit_content">
    <BackableNavBar class="sencneNavbar" leftText="返回" title="任务管理" @onBack="onClickLeft" />
    <Search v-model="searchValue" placeholder="请输入搜索关键词" />
    <List v-model:loading="loading" :finished="finished" finished-text="没有更多了">
      <Collapse v-model="activeNames">
        <CollapseItem v-for="appInfo in appInfos" :key="appInfo.id" :name="appInfo.name"
          class="autospirit_my_taskManage_list">
          <template #title>
            <Icon class="autospirit_my_taskManage_list_icon" :name="getIconBase64Url(appInfo.appInfoIconData)"
              size="20">
            </Icon>
            {{ appInfo.name }}
          </template>
          <List>
            <Cell center v-for="task in appInfo.tasks" :key="task.id" :title="task.aliasName">
              <template #right-icon>
                <Switch v-model="task.checked" size="20px" @change="changeDelateBtnStatus" />
              </template>
            </Cell>
          </List>
        </CollapseItem>
      </Collapse>
    </List>
    <div id="autoSpirit_taskMgr_deleteBtn">
      <div>
        <div v-if="!showDeleteBtn">
          <Button disabled color="linear-gradient(to right, #DF0029, #ee0a24)" round icon="cross" type="primary"
            class="deleteBtn"></Button>
        </div>
        <div v-else>
          <Button color="linear-gradient(to right, #DF0029, #ee0a24)" round icon="cross" type="primary"
            class="deleteBtn" @click="doDeleteTask"></Button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { getCurrentInstance, ref, nextTick } from "vue";
import { List, Search, Collapse, CollapseItem, Cell, Icon, Switch, showToast, Button, showConfirmDialog } from "vant";
import BackableNavBar from "@/components/app/views/common/BackableNavBar.vue";
import utils from "@/utils/util";
export default {
  name: "TaskManagement",
  components: {
    List,
    Search,
    Collapse,
    CollapseItem,
    Cell,
    Icon,
    Switch,
    BackableNavBar,
    Button,
  },
  setup() {
    const searchValue = ref("");
    const appInfos = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const activeNames = ref(["1"]);
    const showDeleteBtn = ref(false);
    return {
      searchValue,
      appInfos,
      loading,
      finished,
      activeNames,
      showDeleteBtn,
    };
  },
  mounted() {
    let addBtn = document.getElementById("autoSpirit_taskMgr_deleteBtn");
    addBtn.style.top = window.innerHeight - addBtn.offsetHeight - 40 + "px";
    addBtn.style.left = window.innerWidth - addBtn.offsetWidth - 20 + "px";
    this.resetDatas();
    this.changeDelateBtnStatus();
  },
  created() {
    this.wsClient =
      getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
  },
  methods: {
    doDeleteTask() {
      showConfirmDialog({
        title: '警告',
        message:
          '该操作将删除任务且无法撤回，请确认！',
      }).then(async () => {
        let appInfos = this.getCheckedTasksAppInfo();
        let responseDatas = await this.wsClient.sendActionToCloud(
          "DELETE_TASK_BY_ID",
          {
            appInfos,
          }
        );
        let resultCode = responseDatas.resultCode;
        if (resultCode == 1) {
          let params = {
            message: responseDatas.message,
            position: "bottom",
          };
          this.resetDatas();
          showToast(params);
        }
      });
    },
    changeDelateBtnStatus() {
      let appInfos = this.getCheckedTasksAppInfo();
      this.showDeleteBtn = appInfos.length > 0;
    },
    getIconBase64Url(base64) {
      return utils.getImageDataUrlFromBase64(base64);
    },
    onClickLeft() {
      this.$router.push({
        name: "ManagerSetting",
      });
    },
    getCheckedTasksAppInfo() {
      let selectedAppInfos = [];
      for (let appInfo of this.appInfos) {
        let tasks = appInfo.tasks;
        let tempAppInfo = { ...appInfo };
        let selectedTasks = [];
        for (let tempTask of tasks) {
          if (tempTask.checked) {
            selectedTasks.push(tempTask);
          }
        }
        if (selectedTasks.length > 0) {
          tempAppInfo.tasks = selectedTasks;
          selectedAppInfos.push(tempAppInfo);
        }
      }
      return selectedAppInfos;
    },
    async resetDatas() {
      this.searchValue = "";
      let responseDatas = await this.wsClient.sendActionToCloud(
        "GET_USER_APP_INFOS",
        {
          account: this.wsClient.loginUser,
          appAcount: this.wsClient.loginUser,
          downloadFile: false,
        }
      );
      let resultCode = responseDatas.resultCode;
      if (resultCode == 1) {
        let appInfos = responseDatas.resultData;
        this.appInfos = appInfos;
        this.loading = false;
        this.finished = true;
        nextTick(() => {
          this.activeNames = [appInfos[0].name];
        });
      }
    },
  },
};
</script>
<style scoped>
.autospirit_taskMgr_selectSpirit_content {
  background-color: #fcfcfc;
  height: 100%;
}

#autoSpirit_taskMgr_deleteBtn {
  position: absolute;
}

.autospirit_taskMgr_selectSpirit_content .autospirit_my_taskManage_list {
  background-color: #ecf5ff;
}

.autospirit_my_taskManage_list .autospirit_my_taskManage_list_icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
</style>