<template>
    <div class="padding">
        <div class="bg-white border solid radius">
            <ParameterSetting ref="newVaraiblePane" :isNewAction="true" 
            :itemVariables="itemVariableDatas"
            :itemInfo="parentItemInfo"></ParameterSetting>
        </div>
    </div>
</template>
<script setup>
import { ref, toRefs, defineExpose, defineProps } from "vue";
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let parentItemInfo = firsData.itemInfo ? firsData.itemInfo : {};
const newVaraiblePane = ref(null);
const itemVariableDatas=ref([]);
const validateOnNext = () => {
    let variableDatas = newVaraiblePane.value.getVariableDatas();
    let isPass = true;
    let resultData = {};
    for (let variableData of variableDatas) {
        resultData = variableData.validate();
        if (resultData.resultCode == 0) {
            isPass = false;
            break;
        }
    }
    return {
        resultCode: isPass ? 1 : 0,
        message: isPass ? "" : resultData.message,
    }
};
const getOperateDatas = () => {
    let variableDatas = newVaraiblePane.value.getVariableDatas();
    return {
        variableDatas: variableDatas,
    };
};
const resetDatas = async () => {
    itemVariableDatas.value=[];
    newVaraiblePane.value.resetDatas();
};

defineExpose({
    resetDatas,
    validateOnNext,
    getOperateDatas
});
</script>