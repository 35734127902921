<template>
    <!--步骤信息面板，用于编辑设计步骤的相关信息-->
    <div class="bg-lightGrey height-full">
        <!--快捷信息面板-->
        <div class="padding">
            <div class="flex bg-white border solid radius padding">
                <div>
                    <Image class="flex center as-avatar xl" :src="defaultIcon"></Image>
                </div>
                <div>
                    <Cell center :title="stepInfo.name" :label="stepInfo.define"></Cell>
                </div>
            </div>
        </div>
        <!--基础信息面板-->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础信息："></Cell>
                    <Cell class="bg-trans" title="名称" :value="stepInfo.name">
                    </Cell>
                    <Cell class="bg-trans" title="描述">
                    </Cell>
                    <Field v-show="stepInfo.define" readonly autosize type="textarea" show-word-limit class="bg-trans"
                        v-model="stepInfo.define" />
                    <Cell center title="场景归属" :value="getItemInfoSencneString()"></Cell>
                    <Cell v-show="sencneType.name == '常用'" v-bind:title="stepInfo.commom ? '常用状态：开启' : '常用状态：关闭'"
                        class="bg-trans">
                        <template #right-icon>
                            <Switch disabled v-model="stepInfo.commom" size="24" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <!--场景信息面板-->
        <!-- <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景信息："></Cell>
                    <Cell center title="场景归属" :value="getItemInfoSencneString()">
                    </Cell>
                </CellGroup>
            </div>
        </div> -->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="高级信息:"></Cell>
                    <Tabs shrink background="transparent">
                        <!--步骤操作页签面板-->
                        <Tab title="步骤设置">
                            <template #title> <span class="stepTab">步骤设置</span></template>
                            <!--步骤类型选项，选中后基于不同的步骤类型展示不同的步骤设置内容-->
                            <CellGroup>
                                <Cell center title="选择步骤类型:" :value="selectedItemType.name ? selectedItemType.name : '设置'">
                                </Cell>
                            </CellGroup>
                            <!--步骤信息设置面板，用于编辑操作高级内容-->
                            <Tabs v-show="selectedTabOptions.length > 0" shrink v-model:active="active"
                                background="transparent">
                                <Tab v-for="tabOption in selectedTabOptions" :key="tabOption.id" :title="tabOption.name">
                                    <template #title> <span class="stepTab">{{ tabOption.name }}</span></template>
                                    <component ref="tabInfoPanes" :is="tabOption.viewPath" :itemInfo="stepInfo" :isNewAction="true" :isDisabled="true"></component>
                                </Tab>
                            </Tabs>
                        </Tab>
                        <Tab title="变量设置">
                            <template #title> <span class="stepTab">变量设置</span></template>
                            <!--变量设置面板-->
                            <ParameterSetting ref="variablePane" :elementsHideConfig="elementsHideConfig" :isNewAction="true"
                             :isDisabled="true" :itemVariables="variableDatas" :itemInfo="stepInfo"></ParameterSetting>
                        </Tab>
                    </Tabs>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance,ref, defineExpose, defineProps, toRefs, watch, nextTick, onMounted } from "vue";
import NewStepActionTypeOperator from "@/components/app/views/common/stepType/tabOptionViews/NewStepActionTypeOperator.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const { operateDatas } = toRefs(props);
const active = ref(-1);
const tabInfoPanes = ref(null);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let stepInfo = firsData.itemInfo ? firsData.itemInfo : {};
let sencne = firsData.sencne ? firsData.sencne : {};
let sencneType = firsData.sencneType ? firsData.sencneType : {};
let thirdData = tempOperateDatas[2];
const selectedItemType = ref(thirdData.selectedItemType ? thirdData.selectedItemType : {});
let selectedTabOptions = thirdData.selectedTabOptions ? thirdData.selectedTabOptions : [];
const stepActionInfos = ref(thirdData.stepActionInfos ? thirdData.stepActionInfos : {});
const variableProsMappings = ref(thirdData.variableProsMappings ? thirdData.variableProsMappings : []);
const conditionOperateDatas =ref(thirdData.conditionOperateDatas ? thirdData.conditionOperateDatas : []);
const occusConditionMappings =ref(thirdData.occusConditionMappings ? thirdData.occusConditionMappings : []);
let secondData = tempOperateDatas[1];
const variableDatas = ref(secondData.variableDatas ? secondData.variableDatas : []);
//默认的step图标
const defaultIcon = ref("");
const elementsHideConfig = {
    operateBar: true,
    addVariableBtn: true,
    variableOperateBtns: true,
};
const variablePane =ref(null);

const tabOperateHanlder=new NewStepActionTypeOperator();
tabOperateHanlder.init({
    stepActionInfos:stepActionInfos,
    conditionOperateDatas:conditionOperateDatas,
    occusConditionMappings:occusConditionMappings,
    variableProsMappings:variableProsMappings,
    backPageName:"NewStepConfig",
    wsClient:wsClient
})
watch(active, (newValue) => {
    resetStepTypeInfo(newValue);
});
onMounted(async () => {
    defaultIcon.value = wsClient.getIconBase64Str("STEP_ICON");
});

const resetStepTypeInfo = (active) => {
    if (active == -1) return;
    let tabOptions=selectedItemType.value.getTabOptions();
    let tabID = tabOptions[active].id;
    nextTick(() => {
        if (tabInfoPanes.value) {
            let viewIdex = 0;
            for(let tabInfoPane of tabInfoPanes.value)
            {
                let id = tabInfoPane.getID();
                if(tabID==id)
                {
                    break;
                }
                viewIdex++
            }
            let subTabInfoPane = tabInfoPanes.value[viewIdex];
            if (subTabInfoPane) {
                console.log(`resetStepActionTypeInfo success,active:${active},viewIdex:${viewIdex}`);
                subTabInfoPane.resetData(tabOperateHanlder,active);
            }
            else {
                console.log(`resetStepActionTypeInfo failed,active:${active},viewIdex:${viewIdex}`);
            }
        }
    });
}

const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.name + '】';
}
const validateOnFinish = () => {
    let variables = [];
    // let variableProMappingCache ={};
    //遍历所有映射构建映射缓存
    // for(let mapping of variableProsMappings)
    // {
    //     let tempKey =mapping.variableID;
    //     variableProMappingCache[tempKey] = mapping;
    // }
    //遍历所有的遍历，检测缓存中是否关联了此变量，如果关联了，需要在该变量中增加一个创建的反响标识字段tempStrID，
    //用于创建后反响获取变量ID，关联到映射对象上
    for (let tempVariableData of variableDatas.value) {

        let tempVariable = {
            name: tempVariableData.name,
            value: tempVariableData.value,
            dataValue: tempVariableData.dataValue,
            type: tempVariableData.variableType.id,
            relateItemType: stepInfo.type,
            //反向关联ID字段，保存的是当前变量创建的时间戳，仅在创建时使用
            tempStrID:tempVariableData.id
        }
        variables.push(tempVariable);
    }
    stepInfo.variables = variables;
    stepInfo.stepActionType = selectedItemType.value.id;
    let result = {
        stepInfo: stepInfo,
        stepActionInfos: stepActionInfos.value,
        variableProsMappings:variableProsMappings.value,
        variableOperateMappings:conditionOperateDatas.value,
        occusConditionMappings:occusConditionMappings.value,
    }
    return result;
};

const resetDatas=()=>{
  variableDatas.value=[];
  if(variablePane.value)
  {
     variablePane.value.resetDatas();
  }
}
/**
 * 页面跳转时刷新
 */
const updateDatas = () => {
    let tempOperateDatas = operateDatas.value;
    let firsData = tempOperateDatas[0];
    stepInfo = firsData.itemInfo ? firsData.itemInfo : {};
    sencne = firsData.sencne ? firsData.sencne : {};
    sencneType = firsData.sencneType ? firsData.sencneType : {};
    let thirdData = tempOperateDatas[2];
    selectedItemType.value = thirdData.selectedItemType ? thirdData.selectedItemType : {};
    selectedTabOptions = thirdData.selectedTabOptions ? thirdData.selectedTabOptions : [];
    stepActionInfos.value = thirdData.stepActionInfos ? thirdData.stepActionInfos : {};
    conditionOperateDatas.value =thirdData.conditionOperateDatas ? thirdData.conditionOperateDatas : [];
    occusConditionMappings.value =thirdData.occusConditionMappings ? thirdData.occusConditionMappings : [];
    variableProsMappings.value = thirdData.variableProsMappings ? thirdData.variableProsMappings : [];
    let secondData = tempOperateDatas[1];
    variableDatas.value = secondData.variableDatas ? secondData.variableDatas : [];
    resetStepTypeInfo(active.value);
};

defineExpose({
    resetDatas,
    updateDatas,
    validateOnFinish,
});
</script>
<style scoped>
.stepTab {
    font-size: 15px;
}
</style>