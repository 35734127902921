const EventMgr = require('./EventMgr');
module.exports = class SpiritServerUtil extends EventMgr {
    cloudUrl = "";
    serverName = "";
    httpTimeout = 1000 * 60;
    serverActionOptions = {};
    eventActionOptions={};
    funMapping ={};
    wapperFunMapping={};
    init() {
       
    }

    installActionsDispatchListeners() {
        let serverActionHanlder =(objData) => {
            // console.log("dispatchKey serverName:" + this.serverName);
            let eventKey = objData.eventKey;
            if (eventKey) {
                let responseKey = objData.responseKey;
                let callback = (responseDatas) => {
                    let callBackServerName = objData.sourceServerKey;
                    let data = {
                        "targetServerKey": callBackServerName,
                        "sourceServerKey": this.serverName,
                        "eventKey": responseKey,
                        "datas": responseDatas
                    }
                    this.sendDatas(data);
                }
                let datas = objData.datas ? objData.datas : {};
                this.broadcastServerActions(eventKey, datas, callback);
                this.broadcastEvents(eventKey, datas, callback);
            }
        };
        this.serverActionHanlderID=this.addEventListener(this.serverName, serverActionHanlder);
        //加载 eventActions
        for(let eventActionKey in this.eventActionOptions)
        {
            let eventAction = this.eventActionOptions[eventActionKey];
            let actionMethod =eventAction["customAction"];
            eventAction.methodID= this.addEventListener(eventActionKey,actionMethod);
        }
    }

    unInstallActionsDispatchListeners()
    {
        this.removeEventListener(this.serverName,this.serverActionHanlderID);
        //卸载 eventActions
        for(let eventActionKey in this.eventActionOptions)
        {
            let eventAction = this.eventActionOptions[eventActionKey];
            // let actionMethod =eventAction["customAction"];
            this.removeEventListener(eventActionKey,eventAction.methodID);
        }
    }

    pushToEventActionOptionsPool(actionKey, fun, options) {
        let eventAction = options ? { ...options } : {}
        eventAction["customAction"] = fun;
        this.eventActionOptions[actionKey] = eventAction;
        eventAction.methodID=this.addEventListener(actionKey, fun);
    }

    async broadcastServerActions(actionKey, datas, callback) {
        // console.log("recive actionKey:"+actionKey);
        let actionOption = this.serverActionOptions[actionKey];
        if (!actionOption) return;
        let preformDatas = actionOption.preformDatas ? await actionOption.preformDatas() : {};
        let params = { ...datas, ...preformDatas };
        let actionPath = this.getActionPath(actionKey);
        // console.log("actionPath:" + actionPath);
        let dealResponseFun = async (response) => {
            if (response) {
                if (response.body) {
                    let responseDatas = this.convertResponseDatas(response);
                    actionOption.customAction ? await actionOption.customAction(responseDatas) : "";
                    callback(responseDatas)
                }
            }
        }
        if (actionOption.unTokens) {
            this.postJsonByActionName(actionPath, params, dealResponseFun);
        }
        else {
            this.postJsonByActionNameSecurity(actionPath, params, dealResponseFun);
        }
    }

    sendActionToCloud(actionKey, datas) {
        return new Promise(async (resolve, reject) => {
            let actionOption = this.serverActionOptions[actionKey];
            if (!actionOption) return;
            let preformDatas = actionOption.preformDatas ? await actionOption.preformDatas() : {};
            let params = { ...datas, ...preformDatas };
            // console.log(JSON.stringify(params));
            let actionPath = this.getActionPath(actionKey);
            // console.debug("actionPath:" + actionPath);
            let dealResponseFun = async (response) => {
                if (response) {
                    let responseDatas = this.convertResponseDatas(response);
                    actionOption.customAction ? await actionOption.customAction(responseDatas) : "";
                    resolve(responseDatas);
                }
                resolve(response);
            }
            if (actionOption.unTokens) {
                this.postJsonByActionName(actionPath, params, dealResponseFun);
            }
            else {
                this.postJsonByActionNameSecurity(actionPath, params, dealResponseFun);
            }
        });
    }

    addEventListener(eventName, fun) {
        let wapperFun =async(datas,callback)=>{
            let result =await fun(datas);
            let response =result?result:"";
            if(typeof callback =="function")
                callback(response);
        };
        let timestamp = new Date().getTime();
        this.funMapping[timestamp]=fun;
        this.wapperFunMapping[timestamp]=wapperFun;
        this.boadCaster.addListener(eventName, wapperFun);
        // console.log(this.boadCaster._events[eventName]);
        return timestamp;
    }
    
    _findWapperFun(funID)
    {
        for(let timestamp in this.funMapping)
        {
            let tempFun = this.funMapping[timestamp];
            if(tempFun&&funID==timestamp)
            {
                // console.log("find wapper Fun"+this.wapperFunMapping[timestamp]);
                let wapperFun = this.wapperFunMapping[timestamp];
                delete this.wapperFunMapping[timestamp];
                delete this.funMapping[timestamp];
                return wapperFun;
            }
        }
    }

    removeEventListener(eventName, funID) {
        let wapperFun =this._findWapperFun(funID);
        // console.log(`remove eventKey:${eventName}`);
        if(wapperFun)
            this.boadCaster.removeListener(eventName, wapperFun);
    }


    convertResponseDatas(response) {
        if (response) {
            let responseDatas = response.body;
            return responseDatas
        }
        return response;
    }

    largeDatasCaches={};

    onReciveDatas(obj) {
        console.log(obj);
        let objData = JSON.parse(obj);
        let serverKey = objData.targetServerKey;
        // console.log(`${this.serverName}:${serverKey}`);
        if (serverKey == this.serverName) {
            //如果是超大型字符的传输，先加入缓存，等检测到传输完成标识之后，在进行响应
            if(objData.isLargeDatas)
            {
                //获取传输标识Key，一般为时间戳+随机生成ID
                let transKey = objData.transKey;
                let operateType =objData.operateType;
                let cacheDatas = this.largeDatasCaches[transKey];
                if(!cacheDatas)
                {
                    cacheDatas={};
                    cacheDatas.datas =new Map();
                    this.largeDatasCaches[transKey]=cacheDatas;
                }
                if(operateType=="AUTOSPIRIT_TRANS_START")
                {
                    let totalSize = objData.size;
                    cacheDatas.size = totalSize;
                }
                else if(operateType=="AUTOSPIRIT_TRANS_PROCESS")
                {
                    let dataIndex = objData.index;
                    cacheDatas.datas.set(dataIndex,objData.datas);
                }
                let currentSize = cacheDatas.datas.size();
                //说明所有的传输事件全部获取到
                if(currentSize==cacheDatas.size)
                {
                    let finalStr="";
                    for(let i=0;i<currentSize;i++)
                    {
                        let tempStr = cacheDatas.datas.get(i);
                        tempStr=tempStr?tempStr:"";
                        finalStr =finalStr+tempStr;
                    }
                    let finalDatas = JSON.parse(finalStr);
                    objData.datas =finalDatas;
                    delete this.largeDatasCaches[transKey];
                    this.broadcastEvents(serverKey, objData);
                }
            }
            else
            {
                this.broadcastEvents(serverKey, objData);
            }
        }
        else {
            if(serverKey)
            {
                if(serverKey != this.serverName)
                {
                    console.log(`访问目标的服务器${serverKey}和当前服务${this.serverName}不一致，不进行响应!`);
                }
            }
            else
            {
                console.log('serverKey为空，不进行响应!');
            }
        }
    }

    sendActionByServerKey(serverKey, actionID, datas) {
        return new Promise((resolve, reject) => {
            let operateKey = serverKey + "@" + actionID + "@" + Date.now();
            let reSponesFunID;
            let reSponesFun = (responseDatas) => {
                this.removeEventListener(operateKey, reSponesFunID);
                // clearTimeout(timeoutID);
                resolve(responseDatas);
            }
            let data = {
                "targetServerKey": serverKey,
                "sourceServerKey": this.serverName,
                "eventKey": actionID,
                "responseKey": operateKey,
                "datas": datas
            }
            reSponesFunID=this.addEventListener(operateKey, reSponesFun);
            //增加超时返回机制，当长期无返回时，消除无效的注册和一次性调用的注册
            // let timeoutID = setTimeout(()=>{
            //     console.log(`自动断开超时服务访问等待，targetServer:${serverKey},sourceServer:${this.serverName},actioID:${actionID}`);
            //     this.removeEventListener(operateKey, reSponesFunID);
            //     resolve("timeout-message");
            // },2*60*1000);
            this.sendDatas(data);
        });
    }

    sendDatas(data)
    {
        this.sendData(data);
    }

    postJsonByActionName(actionPath, params, callback) { }

    postJsonByActionNameSecurity(actionPath, params, callback) { }

    postJsonByUrl(url, params, header, callback) { }
    getBasePath() {
        return this.cloudUrl;
    }
    getActionPath(actionID) {
        return this.serverActionOptions[actionID].path;
    }
    _convertUrlPath(actionPath) {
        return this.cloudUrl + actionPath;
    }
    //释放内存并退出
    dispose() {
        this.unInstallActionsDispatchListeners();
        this.eventActionOptions ={};
        super.dispose();
    }
}