<template>
  <div class="height-full">
    <div class="padding">
      <div class="bg-white border solid radius">
        <Cell class="bg-trans" title="选择要关联的任务："></Cell>
        <RelateSelectPane ref="relateSelectPane" :itemRelateMappings="spiritRelateMappings"
        :loadingState="loadingState"
        :relateItemOperator="operator"
          @onAddRelateItem="doAddRelateTask" 
          />
      </div>
    </div>  
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineProps, defineExpose, toRefs } from "vue";
import { useRouter } from "vue-router";
import util from "@/utils/util";
import optionsConfig from "@/components/app/views/common/stepType/OptionsConfig.js";
import NewRelateItemOperator from "@/components/app/views/common/configPane/itemAction/NewRelateItemOperator.js";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const hanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getItemInfo:()=>{
    return parentItemInfo;
  },
  getWatchedPageName: (relateMapping) => {
    let type = relateMapping.relateType;
    //查看的是步骤，则传递相关信息到步骤页面
    if (type == "TASK_TYPE") {
        return "TaskInfo";
    }
  },
  getBackPageName:()=>{
    return "NewSpirit";
  },
  updateRelateMappings:(relateItemMappings)=>{
    spiritRelateMappings.value = relateItemMappings;
    loadingState.value = false;
  },
  getRelateMappings:()=>{
    return spiritRelateMappings.value;
  },
};
const operator = new NewRelateItemOperator();
operator.init(hanlder);
const props = defineProps({
  operateDatas: {
    type: Object
  }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let parentItemInfo = firsData.itemInfo ? firsData.itemInfo : {};
//当前任务下所关联的步骤映射集
const spiritRelateMappings = ref([]);
const loadingState =ref(false);
const validateOnNext = () => {
  return {
    resultCode:  1,
    message:  "" ,
  };
};
const getOperateDatas = () => {
  return {
    sequence: parentItemInfo.sequence,
    spiritRelateMappings:spiritRelateMappings.value
  };
};
const getCustomButtomOptions = () => {
  let customButtonOptions = [
    {
      name: "跳过",
      action: (source) => {
        console.log("actionBtn");
      },
    },
  ];
  return customButtonOptions;
};


/**
* 相应添加映射关联的动作
*/
const doAddRelateTask = async () => {
  //已经选中的任务不在增加
  let itemsExcludeArr = [];
  for (let relateMapping of spiritRelateMappings.value) {
    let key = relateMapping.itemInfo.key;
    itemsExcludeArr.push(key);
  }
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "NewSpirit",
    eventName: "AUTOSPIRIT_SPIRITINFO_STEPSSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsExclude: itemsExcludeArr,
    itemsType: ["TASK_TYPE"],
    selectedMode: true,
    pageType: "0",
    pageTitle: "步骤选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let sequence = parentItemInfo.sequence;
    let ids = sequence ? sequence.split(",") : [];
    let index = ids.length+1;
    //基于选择的元素，遍历构建查询对象
    for (let selectedItem of selectedItems) {
      let tempMapping = {
        id:selectedItem.id+"@"+selectedItem.type,
        parentID: parentItemInfo.id,
        parentType:parentItemInfo.type,
        relateID: selectedItem.id,
        relateType: selectedItem.type,
        itemInfo:selectedItem,
        index:index,
        operateOptions:util.cloneObject(optionsConfig.newItemRelateMappingOperateOptions),
        parentItemInfo:parentItemInfo,
        disable: false
      };
      spiritRelateMappings.value.push(tempMapping);
      ids.push(tempMapping.id);
      index++;
    }
    parentItemInfo.sequence = util.makeSequenceStr(ids);
    router.push({
      name: datas.backTargetPage,
    });
  };
  datas.listenerID=wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};

const resetDatas = async () => {
  spiritRelateMappings.value = [];
  parentItemInfo.sequence ="";
};
defineExpose({
  resetDatas,
  validateOnNext,
  getOperateDatas,
  getCustomButtomOptions,
});
</script>
<style scoped></style>