<template>
  <div class="autospirit_secneType_imageSelected">
    <BackableNavBar class="navbar" :title="operateTitle" leftText="返回" @onBack="onClickLeft" />
    <ImageUpload :defaultImageSource="currentImageSource" :maxImageSize="maxImageSize" :operateTitle="operateTitle"
      :suggestImages="suggestImages" :supportImageType="supportImageType" @imageSure="onImageSelected" />
  </div>
</template>
<script setup>
import { getCurrentInstance,onUnmounted} from "vue";
import { useRouter } from 'vue-router';
import util from "@/utils/util";
const wsClient =getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if(pageDatas)pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const imageSelectedChangeEventName = pageDatas.eventName ? pageDatas.eventName : "";
const listenerID =pageDatas.listenerID ? pageDatas.listenerID : "";
let currentImageSource = pageDatas.currentImageSource;
let supportImageType = pageDatas.supportImageType;
let maxImageSize = pageDatas.maxImageSize;
let suggestImages = pageDatas.suggestImages;
let operateTitle = pageDatas.operateTitle;

onUnmounted(()=>{
  wsClient.removeEventListener(imageSelectedChangeEventName,listenerID);
});
const onClickLeft = () => {
  router.push({
    name: backTargetPage,
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
const onImageSelected = (iconData) => {
  let tempIconData =util.getBase64FromDataURL(iconData);
  wsClient.broadcastEvents(imageSelectedChangeEventName, {
    iconData: tempIconData,
  });
};
</script>
<style scoped>
.autospirit_secneType_imageSelected {
  background-color: #fcfcfc;
}

.autospirit_secneType_imageSelected .navbar {
  background-color: transparent;
  border: none;
}
</style>