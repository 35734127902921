const BaseItemType = require("./BaseItemType");
const util = require("@/utils/util");
/**
 * 精灵类型的对象控制器
 */
module.exports = class SpiritType extends BaseItemType {
  id = "SPIRIT_TYPE";
  name = "精灵";
  /**
   * 响应资源加载事件，从云端加载对应子场景的精灵数据
   * @param {子场景类型} sencneType 
   * @param {元素类型} itemsType 
   * @returns 
   */
  sourceAction = async (sencneType, itemsType) => {
    let resultItems = [];
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_SPIRITS_BY_SENCNETYPEID",
      sencneType
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItems = this.converItemInfoDatas(responseDatas.resultData, itemsType);
    }
    return resultItems;
  }

  /**
   * 通过精灵ID，从云端获取精灵对象
   * @param {精灵对象ID} id 
   * @returns 
   */
  getItemInfoByID=async(id)=>
  {
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_SPIRIT_BY_ID",
      {
        "id": id
      }
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = this.converItemInfoData(responseDatas.resultData);
    }
    return resultItem;
  }
  
  /**
   * 转化云端加载信息至client端可理解的对象信息，主要是赋予key值和统一数据格式规范，转化图片格式等等
   * @param {云端加载的对象信息} itemInfo 
   * @returns 
   */
  converItemInfoData = (itemInfo) => {
    if(!itemInfo)return {};
    let tempAppInfo = {
      name: itemInfo.name,
      define: itemInfo.define,
      iconDataBase64: util.getImageDataUrlFromBase64(itemInfo.iconDataBase64),
      sencneTypeID: itemInfo.sencneTypeID,
      sequence:itemInfo.sequence,
      id: "" + itemInfo.id,
      type: itemInfo.type,
      commom:itemInfo.commom,
      key: this.makeItemInfoKey(itemInfo)
    };
    return tempAppInfo;
  }

  /**
   * 响应ConfigPane中的删除事件，批量删除选中的数据
   * @param {选中的需要删除的对象} selectedItem 
   * @param {操作actionkey} actionKey 
   * @returns 
   */
  doItemsDelete = async (selectedItem, actionKey) => {
    let queryIDs = [];
    for (let itemInfo of selectedItem) {
      queryIDs.push(itemInfo.id);
    }
    let responseDatas = await this.wsClient.sendActionToCloud(
      "DELETE_SPIRIT",
      {
        ids: queryIDs
      }
    );
    return responseDatas;
  }
  doItemsMove=async(selectedItem, sencneType)=>{
    let queryIDs = [];
    for (let itemInfo of selectedItem) {
      queryIDs.push(itemInfo.id);
    }
    let responseDatas = await this.wsClient.sendActionToCloud(
      "UPDATE_SPIRITINFOS_SENCNETYPEID",
      {
        ids: queryIDs,
        sencneTypeID:sencneType.id
      }
    );
    return responseDatas;
  }
  /**
   * 响应ConfigPane中的新增事件
   * @param {子场景类型} sencneType 
   * @param {场景类型} sencne 
   */
  onItemCreate = (sencneType,sencne) => {
    let datas = {
      sencneType: sencneType,
      sencne:sencne,
      backTargetPage:"Content",
      backTargetPageDatas:{active:1}
    };
    this.router.push({ 
      name: "NewSpirit",
      state:{
        pageDatas:JSON.stringify(datas)
      }
    });
  }
    /**
   * 更新信息到云端
   * @param {元素信息} itemInfo 
   */
    saveData=async(itemInfo)=>{
      let responseDatas = await this.wsClient.sendActionToCloud(
        "UPDATE_SPIRIT_INFO",
        itemInfo,
      );
      return responseDatas;
    };
}