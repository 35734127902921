const BaseItemType = require("./BaseItemType");
module.exports = class StepType extends BaseItemType {
  id = "STEP_TYPE";
  name = "步骤";
  sourceAction = async (sencneType, itemsType) => {
    let resultItems = [];
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_STEP_INFOS_BY_SENCNETYPEID",
      sencneType
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItems = this.converItemInfoDatas(responseDatas.resultData, itemsType);
    }
    return resultItems;
  }

  createData=async(itemInfo)=>{
    let responseDatas = await this.wsClient.sendActionToCloud(
      "CREATE_STEP_INFO",
      itemInfo,
    );
    return responseDatas;
  }

  /**
   * 更新信息到云端
   * @param {元素信息} itemInfo 
   */
  saveData=async(itemInfo)=>{
    let responseDatas = await this.wsClient.sendActionToCloud(
      "UPDATE_STEP_INFOS_BY_ID",
      itemInfo,
    );
    return responseDatas;
  };

    /**
   * 响应ConfigPane中的删除事件，批量删除选中的数据
   * @param {选中的需要删除的对象} selectedItem 
   * @param {操作actionkey} actionKey 
   * @returns 
   */
    doItemsDelete = async (selectedItem, actionKey) => {
      let queryIDs = [];
      for (let itemInfo of selectedItem) {
        queryIDs.push(itemInfo.id);
      }
      let responseDatas = await this.wsClient.sendActionToCloud(
        "DELETE_STEP_INFOS_BY_ID",
        {
          ids: queryIDs
        }
      );
      return responseDatas;
    }

    doItemsMove=async(selectedItem, sencneType)=>{
      let queryIDs = [];
      for (let itemInfo of selectedItem) {
        queryIDs.push(itemInfo.id);
      }
      let responseDatas = await this.wsClient.sendActionToCloud(
        "UPDATE_STEP_SENCNETYPEID",
        {
          ids: queryIDs,
          sencneTypeID:sencneType.id
        }
      );
      return responseDatas;
    }

  converItemInfoData = (itemInfo) => {
    if(!itemInfo)return {};
    let tempAppInfo = {
      name: itemInfo.name,
      define: itemInfo.define,
      appConfigID: itemInfo.appConfigID,
      id: "" + itemInfo.id,
      sencneTypeID: itemInfo.sencneTypeID,
      type: itemInfo.type,
      stepActionType:itemInfo.stepActionType,
      stepActionTypeID:itemInfo.stepActionTypeID,
      commom:itemInfo.commom,
      key: this.makeItemInfoKey(itemInfo)
    };
    return tempAppInfo;
  }
  onItemCreate = (sencneType,sencne) => {
    let datas = {
      sencneType: sencneType,
      sencne:sencne,
      backTargetPage:"Content",
      backTargetPageDatas:{active:1}
    };
    this.router.push({ 
      name: "NewStepConfig",
      state:{
        pageDatas:JSON.stringify(datas)
      }
    });
  }
  getItemInfoByID=async(id)=>
  {
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_STEP_INFOS_BY_ID",
      {
        "id": id
      }
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = this.converItemInfoData(responseDatas.resultData);
    }
    return resultItem;
  }

}