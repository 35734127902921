/**
 * 元素过滤管理器
 */
module.exports = class ItemFilterManager {

    //初始化
    init() {
       this.itemFilters = new Map();
    }

    /**
 * 判断元素对象是否需要被过滤
 * @param {被判断是否过滤的元素对象} itemInfo 
 */
    filterItem = (itemInfo) => {
        let result = false;
        for (let [key, tempFilter] of this.itemFilters) {
            let isNeedFilter = tempFilter.filter(itemInfo);
            //若过滤器中发现需要过滤的条件满足，则过滤并停止检测
            if (isNeedFilter) {
                result = true;
                // console.log(`元素${itemInfo.name}被过滤器${tempFilter.type}过滤`);
                break;
            }
        }
        return result;
    }

    /**
     * 添加过滤器到缓存
     * @param {过滤器} filter 
     */
    addItemFilter = (key,filter) => {
        this.itemFilters.set(key,filter);
    };

    /**
     * 移除缓存的过滤器
     * @param {*} key 
     */
    removeItemFilter =(key)=>{
        this.itemFilters.delete(key);
    };
}