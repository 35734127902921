<template>
    <div class="height-full bg-lightGrey">
        <BackableNavBar title="应用信息选择" leftText="返回" @onBack="onClickLeft" />
        <Search v-model="searchValue" placeholder="请输入搜索关键词" />
          <RadioGroup v-model="selectedAppID">
            <Collapse v-model="activeNames" accordion>
                <CollapseItem key="user" name="user">
                    <template #title>
                        <div :class="activeNames=='user'?'color-blue':''">
                            <Icon name="apps-o" size="20">
                            </Icon>
                            第三方应用：
                        </div>
                    </template>
                    <div class="height-half">
                            <PullRefresh v-model="refreshingUser" @refresh="onRefreshUser">
                                    <List v-model:loading="loadingUser" :finished="finishedUser" @load="onLoadUser" finished-text="没有更多了">
                                    <Cell center v-for="appinfo in userAppInfo" :key="appinfo.appID" :title="appinfo.name" :label="appinfo.appID">
                                        <template #icon>
                                            <div class="padding bg-white">
                                                <Image :src="appinfo.iconData" class="as-avatar sm" />
                                            </div>
                                        </template>
                                        <template #right-icon>
                                            <Radio :name="appinfo.appID" />
                                        </template>
                                    </Cell>
                                    </List>
                            </PullRefresh>
                        </div>
                </CollapseItem>
                <CollapseItem key="system" name="system">
                    <template #title>
                        <div :class="activeNames=='system'?'color-blue':''">
                            <Icon name="setting-o" size="20">
                            </Icon>
                            系统应用：
                        </div>
                    </template>
                    <PullRefresh v-model="refreshingSystem" @refresh="onRefreshSystem">
                        <List v-model:loading="loadingSystem" :finished="finishedSystem" @load="onLoadSystem" finished-text="没有更多了">
                            <Cell center v-for="appinfo in systemAppInfo" :key="appinfo.appID" :title="appinfo.name" :label="appinfo.appID">
                                <template #icon>
                                    <div class="padding bg-white">
                                        <Image :src="appinfo.iconData" class="as-avatar sm" />
                                    </div>
                                </template>
                                <template #right-icon>
                                    <Radio :name="appinfo.appID" />
                                </template>
                            </Cell>
                        </List>
                    </PullRefresh>
                </CollapseItem>
            </Collapse>
            </RadioGroup>
        <div class="padding text-center">
            <Button v-show="selectedAppID!=''" type="primary" class="button radius" @click="doAppInfoSelected">确定</Button>
            <Button disabled v-show="selectedAppID ==''" type="primary" class="button radius">确定</Button>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted,onUnmounted} from "vue";
import {
		useRouter,
	} from 'vue-router';
import utils from "@/utils/util.js";
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const selectedChangeEventName = pageDatas.eventName ? pageDatas.eventName : "";
const listenerID =pageDatas.listenerID ? pageDatas.listenerID : "";
const searchValue =ref("");
const selectedAppID =ref("");
const userAppInfo =ref([]);
const systemAppInfo = ref([]);
const activeNames =ref("user");
const loadingUser =ref(true);
const finishedUser =ref(false);
const loadingSystem =ref(true);
const finishedSystem =ref(false);
const refreshingUser =ref(false);
const refreshingSystem =ref(false);
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router =useRouter();
const tempUserInfos =[];
const tempSystemInfos =[];
let tempAllInfo=[];
onUnmounted(()=>{
  wsClient.removeEventListener(selectedChangeEventName,listenerID);
});
const doAppInfoSelected=()=>{
    let selectedAppInfo={};
    for(let appInfo of tempAllInfo)
    {
        if(selectedAppID.value==appInfo.appID)
        {
            selectedAppInfo =appInfo;
            break;
        }
    }
    let eventDatas ={
        itemInfo:selectedAppInfo
    }
    wsClient.broadcastEvents(selectedChangeEventName, eventDatas);
};

const onClickLeft=()=>{
    router.push({
        name: backTargetPage,
        state: {
            pageDatas: JSON.stringify(backTargetPageDatas)
        }
    });
};
const onRefreshUser=()=>{
    finishedUser.value = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    loadingUser.value = true;
    onLoadUser();
};
const onRefreshSystem=()=>{
    finishedSystem.value = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    loadingSystem.value = true;
    onLoadSystem();
};

const onLoadSystem = () => {
      setTimeout(() => {
        if (refreshingSystem.value) {
          systemAppInfo.value = [];
          refreshingSystem.value = false;
        }

        for (let i = 0; i < 10; i++) {
            let index = systemAppInfo.value.length;
            if(systemAppInfo.value.length<tempSystemInfos.length)
            {
                systemAppInfo.value.push(tempSystemInfos[index]);
            }
            else
            {
                finishedSystem.value = true;
                break;
            }
        }
        loadingSystem.value = false;
      }, 200);
    };

const onLoadUser = () => {
      setTimeout(() => {
        if (refreshingUser.value) {
          userAppInfo.value = [];
          refreshingUser.value = false;
        }

        for (let i = 0; i < 10; i++) {
            let index = userAppInfo.value.length;
            if(userAppInfo.value.length<tempUserInfos.length)
            {
                userAppInfo.value.push(tempUserInfos[index]);
            }
            else
            {
                finishedUser.value = true;
                break;
            }
        }
        loadingUser.value = false;
      }, 500);
    };

onMounted(async()=>{
    tempAllInfo = await wsClient.sendActionToUI("AUTOSPIRIT_OPERATE_EVENT_ACTION",{
        opeatorType:"DeviceOperator",
        eventKey:"getAllAppInfos",
        eventDatas:{
            type:"all"
        }
    });
    // (tempAllInfo);
    var arr = Object.keys(tempAllInfo);
    if (arr.length>0) {
        
        for (let tempInfo of tempAllInfo) {
            let base64 = await wsClient.sendActionToUI("AUTOSPIRIT_OPERATE_EVENT_ACTION", {
                opeatorType:"DeviceOperator",
                eventKey:"getAppIconByID",
                eventDatas:tempInfo.appID
            });
            tempInfo.iconData = utils.getImageDataUrlFromBase64(base64);
            if (tempInfo.isSystemInfo) {
                tempSystemInfos.push(tempInfo);
            }
            else {
                tempUserInfos.push(tempInfo);
            }
        }
    }
    onLoadUser();
    onLoadSystem();
    
});
</script>
<style scoped></style>