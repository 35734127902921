<template>
    <div class="height-full">
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="高级信息:"></Cell>
                    <Cell is-link value="设置" @click="changeRelatePageItem(true)">
                        <template #title>
                            <div class="flex width-xl">
                                <div>起始页面：</div>
                                <div>{{ getRoutePageName(true) }}</div>
                                <Icon v-show="startPageInfo.name" class="icon color-blue" name="more-o" size="20" @click.stop="gotoWatchPageInfo(true)"></Icon>
                            </div>
                        </template>
                    </Cell>
                    <Cell is-link value="设置" @click="changeRelatePageItem(false)">
                        <template #title>
                            <div class="flex width-xl">
                                <div>终止页面：</div>
                                <div>{{ getRoutePageName(false) }}</div>
                                <Icon v-show="endPageInfo.name" class="icon color-blue" name="more-o" size="20" @click.stop="gotoWatchPageInfo(false)"></Icon>
                            </div>
                        </template>
                    </Cell>
                    <Tabs shrink v-model:active="active" background="transparent">
                        <!--步骤操作页签面板-->
                        <Tab title="步骤设置">
                        <template #title> <span class="itemTab">步骤设置</span></template>
                        <RelateSelectPane ref="relateSelectPane" :itemRelateMappings="itemRelateMappings"
                            :loadingState="loadingState"
                            :relateItemOperator="operator"
                            @onAddRelateItem="doAddRelateStepAndTask"
                        />
                        </Tab>
                    </Tabs>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref,toRefs,defineExpose, defineProps } from "vue";
import {
    useRouter,
} from 'vue-router';
import NewPageTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/NewPageTypeRelateOperator.js";
import NewRelateItemOperator from "@/components/app/views/common/configPane/itemAction/NewRelateItemOperator.js";
import util from "@/utils/util";
import optionsConfig from "@/components/app/views/common/stepType/OptionsConfig.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const pageHanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getWatchedPageName: (relateMapping) => {
    return "PageInfo";
  },
  getBackPageName:()=>{
    return "NewRouteConfig";
  },
};
const pageTypeOperator = new NewPageTypeRelateOperator();
pageTypeOperator.init(pageHanlder);
const routeHanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getItemInfo:()=>{
    return parentItemInfo;
  },
  getWatchedPageName: (relateMapping) => {
    let type = relateMapping.relateType;
    //查看的是步骤，则传递相关信息到步骤页面
    if (type == "TASK_TYPE") {
        return "TaskInfo";
    }
    else if(type == "STEP_TYPE")
    {
        return "StepInfo";
    }
  },
  getBackPageName:()=>{
    return "NewRouteConfig";
  },
  updateRelateMappings:(relateItemMappings)=>{
    itemRelateMappings.value = relateItemMappings;
    loadingState.value = false;
  },
  getRelateMappings:()=>{
    return itemRelateMappings.value;
  },
};
const operator = new NewRelateItemOperator();
operator.init(routeHanlder);

const props =defineProps({
    operateDatas: {
        type: Object
    }
});

const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let parentItemInfo = firsData.itemInfo ? firsData.itemInfo : {};
const startPageInfo = ref({});
const endPageInfo = ref({});

//高级操作栏的选项卡索引
const active = ref(0);

//当前任务下所关联的步骤映射集
const itemRelateMappings = ref([]);

const loadingState =ref(false);

/**
* 相应添加映射关联的动作
*/
const doAddRelateStepAndTask = async () => {
  let itemsExcludeArr = [];
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "NewRouteConfig",
    eventName: "AUTOSPIRIT_ROUTERELATECONFIG_STEPSSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsExclude: itemsExcludeArr,
    itemsType: ["ALL_TYPE", "TASK_TYPE", "STEP_TYPE"],
    selectedMode: true,
    pageType: "0",
    pageTitle: "步骤选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let sequence = parentItemInfo.sequence;
    let ids = sequence ? sequence.split(",") : [];
    let index = ids.length + 1;
    let timestamp = new Date().getTime();
    //基于选择的元素，遍历构建查询对象
    for (let selectedItem of selectedItems) {
      let tempMapping = {
        id: selectedItem.id + "@" + selectedItem.type + "@" + timestamp,
        parentID: parentItemInfo.id,
        parentType: parentItemInfo.type,
        relateID: selectedItem.id,
        relateType: selectedItem.type,
        itemInfo: selectedItem,
        index: index,
        operateOptions: util.cloneObject(optionsConfig.newItemRelateMappingOperateOptions),
        parentItemInfo: parentItemInfo,
        disable: false
      };
      itemRelateMappings.value.push(tempMapping);
      ids.push(tempMapping.id);
      index++;
    }
    parentItemInfo.sequence = util.makeSequenceStr(ids);
    router.push({
      name: datas.backTargetPage,
    });
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};

const gotoWatchPageInfo = (isStartPage) => {
    let itemInfo =isStartPage?startPageInfo.value:endPageInfo.value;
    pageTypeOperator.doWatchRouteInfoAction(itemInfo);
};

const getRoutePageName=(isStartPage)=>{
    let tempPageInfo = isStartPage?startPageInfo.value:endPageInfo.value;
    if(tempPageInfo.name&&tempPageInfo.id)
    {
        return tempPageInfo.name+'【'+tempPageInfo.id+'】';
    }
    else
    {
        return "未设置"
    }
}
/**
* 相应添加映射关联的动作
*/
const changeRelatePageItem = async (isStartPage) => {
  let itemsExcludeArr = [startPageInfo.value.key,endPageInfo.value.key];
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "NewRouteConfig",
    eventName: "AUTOSPIRIT_ROUTERELATECONFIG_PAGESSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsExclude: itemsExcludeArr,
    itemsType: ["PAGE_TYPE"],
    selectedMode: true,
    singleSelectedMode: true,
    pageType: "1",
    pageTitle: "页面选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async(selectedItems) => {
    let selectPageInfo = selectedItems[0];
    if(isStartPage)
    {
        parentItemInfo.startPageID = selectPageInfo.id;
        startPageInfo.value =selectPageInfo;
    }
    else
    {
        parentItemInfo.endPageID = selectPageInfo.id;
        endPageInfo.value =selectPageInfo;
    }
    router.push({
      name: datas.backTargetPage,
    });
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
const validateOnNext = () => {
    let isPass = true;
    return {
        resultCode: isPass ? 1 : 0,
        message: isPass ? "" : "请先选择应用！",
    };
};
const getOperateDatas = () => {
    return {
        sequence: parentItemInfo.sequence,
        startPageID:startPageInfo.value.id,
        endPageID:endPageInfo.value.id,
        startPageInfo:startPageInfo.value,
        endPageInfo:endPageInfo.value,
        itemRelateMappings: itemRelateMappings.value
    };
};

const resetDatas = async () => {
  itemRelateMappings.value = [];
  parentItemInfo.sequence = "";
  parentItemInfo.startPageID = "";
  parentItemInfo.endPageID = "";
  startPageInfo.value={};
  endPageInfo.value={};
};

defineExpose({
    resetDatas,
    validateOnNext,
    getOperateDatas
});

</script>
<style scoped>
.itemTab {
    font-size: 15px;
}
.icon {
    align-self: center;
    align-items: center;
    display: inline-flex;
    padding-left: 5px;
}
</style>