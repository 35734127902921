const BaseVariableData =require("./BaseVariableData");
module.exports = class NewItemVariableData extends BaseVariableData {
    saveData = async () => {
        let responseDatas = {
            resultCode: 1
        };
        return responseDatas;
    }

    deleteData=async()=>{
        let resultData={
            resultCode:1,
            noNeedShowToast:true
        };
        return resultData;
    }
}