<template>
  <div class="text-center fixed width-full">
    <div class="myloginBar bg-trans">
      <Popover v-model:show="showPopover" placement="bottom-start" :offset="[5, 5]">
        <!-- <CellGroup class="width-popupGroup">
        <Cell clickable v-for="sencne in sencneList" :key="sencne.id" v-model="sencne.selected"
          :title="sencne.sencneName" :class="sencne.selected ? 'selected' : ''" @click="onSencneSelected(sencne, false)">
          <template v-if="sencne.selected" #right-icon>
            <Icon name="success" class="selected" />
          </template>
        </Cell>
        <Cell v-show="!selectedMode" clickable title="场景管理" @click="doManageSencne">
          <template #right-icon>
            <Icon name="setting" class="selected" />
          </template>
        </Cell>
      </CellGroup> -->
        <template #reference>
          <Cell title="请先登录" class="bg-trans"></Cell>
        </template>
      </Popover>
    </div>
  </div>
  <div class="text-center fixed bottom width-full padding">
    <div class="flex center">
      <Icon class="rightIcon block-inline" :name="backupIcon" />
      <a class="block-inline" href="https://beian.mps.gov.cn/#/query/webSearch?code=44190002007992" rel="noreferrer"
        target="_blank">粤公网安备44190002007992</a>
    </div>
  </div>
  <div :style="gradientStyle" class="text-center height-full">
    <div class="height-full flex center">
      <CellGroup class="mycell bg-trans border none">
        <Cell class="mycell width-full bg-trans border none">
          <div class="text-center padding">
            <Image class="as-avatar" round :src="logoIcon" />
          </div>
        </Cell>
        <Cell class="mycell width-full bg-trans border none">
          <div class="text-center color-black s28 padding">AutoSpiritx</div>
        </Cell>
        <Cell class="mycell width-full bg-trans border none">
          <div class="text-center color-black s18 padding"><b>AI自动精灵，</b>解放生产力，构建无限想象</div>
        </Cell>
        <Cell class="mycell width-full bg-trans border none">
          <div class="text-center padding">
            <Button plain hairline round type="primary" class="myBottom block-inline bg-trans button xl">社区
              <template #icon>
                <Icon name="friends" />
              </template>
            </Button>
            <Button plain hairline round type="primary" class="myBottom block-inline bg-trans button xl">文档
              <template #icon>
                <Icon name="bookmark" />
              </template>
            </Button>
            <Button plain hairline round type="primary" class="myBottom block-inline bg-trans button xl">下载
              <template #icon>
                <Icon name="down" />
              </template>
            </Button>
            <Button hairline round type="primary" class="myBottom block-inline button xl">登录
              <template #icon>
                <Icon name="manager" />
              </template>
            </Button>
          </div>
        </Cell>
        <Cell class="mycell width-full bg-trans border none">
          <div class="blockDiv"></div>
        </Cell>
      </CellGroup>
    </div>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, onMounted,computed } from "vue";
import util from "../utils/util";
const logoIcon = ref("");
const backupIcon = ref("");
const start =util.getRandomArbitrary(180,225);
// const end =util.getRandomArbitrary(150,255);
const end =start-util.getRandomArbitrary(25,50);
const startColor =`hsl(${start}, 100%, 85%) 0%`;
const endColor =`hsl(${end}, 100%, 85%) 100%`;
const showPopover =ref(false);
const gradientStyle =computed(()=>({
  background: `linear-gradient(to left bottom, ${startColor}, ${endColor})`,
}));
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
onMounted(async () => {
  logoIcon.value = wsClient.getIconBase64Str("LOGO_ICON");
  backupIcon.value = wsClient.getIconBase64Str("BACKUP_ICON");
});
</script>
<style scoped>
.rightIcon {
    align-self: center;
    display: inline-flex;
    margin-left: 5px;
}
.mycell:after {
  border: none;
}
.myBottom{
  margin-left: 25px;
  margin-bottom: 25px;
}
.blockDiv{
  min-height: 80px;
}
.myloginBar{
  position: fixed;
  right: 0px;
}
</style>