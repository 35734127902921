<template>
  <!--app主内容面板-->
  <div class="flex column height-full">
    <div id="autospirit_content_div">
      <keep-alive include="Home,Setting,Config">
        <component :is="pathIDs[active]"></component>
      </keep-alive>
    </div>
    <Tabbar id="autospirit_content_tabbar" class="bg-white" v-model="active">
      <TabbarItem icon="home-o">首页</TabbarItem>
      <TabbarItem icon="setting-o">流程</TabbarItem>
      <TabbarItem icon="apps-o">应用</TabbarItem>
      <TabbarItem icon="user-o">我</TabbarItem>
    </Tabbar>
  </div>
</template>
<script setup>
import { getCurrentInstance,ref,onActivated,onMounted} from "vue";
import { showToast } from "vant";
import {
  useRouter,
} from 'vue-router';
//选项卡当前页签的索引，用于控制显示哪个选项卡
const active = ref(0);
const pathIDs = ["Home", "Setting", "Config", "My"];
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();

onMounted(async()=>{
  let tabContainer = document.getElementById("autospirit_content_tabbar");
    if (tabContainer) {
        let tabBounds = tabContainer.getBoundingClientRect();
        let buttonDiv = document.getElementById("autospirit_content_div");
        buttonDiv.style.minHeight =tabBounds.y +"px";
        buttonDiv.style.maxHeight =tabBounds.y +"px";
        wsClient.addItemToStorage("itemsWapperHeight",tabBounds.y,true);
    }
    let datas = await wsClient.sendActionToCloud("VALIDATE_TOKEN", {});
    let isNeedLogin = datas.resultCode !== 1;
    if(isNeedLogin)
    {
        router.push({
        name: "Login",
        state:{
          pageDatas:JSON.stringify({ active: 0 })
        }
      });
      let params = {
        message: datas.message,
        position: "bottom",
      };
      showToast(params);
    }
   
});
/**
 * 由于此页面在路由配置文件index.js中配置成了持续保活页面，所以在页面再次激活的时候读取传递
 * 过来的参数，解析需要跳转到哪个选项卡
 */
onActivated(() => {
  let pageDatas = history.state.pageDatas;
  pageDatas = pageDatas ? JSON.parse(pageDatas) : { active: 0 }
  active.value = pageDatas.active;
});
</script>
<style scoped>
#autospirit_content_div{
  overflow: auto;
}
</style>