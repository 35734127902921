const BaseItemType = require("./BaseItemType");
module.exports = class PageType extends BaseItemType {
  id = "PAGE_TYPE";
  name = "页面";
  sourceAction = async (sencneType, itemsType) => {
    let resultItems = [];
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_PAGE_INFOS_BY_SENCNETYPEID",
      sencneType
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItems = this.converItemInfoDatas(responseDatas.resultData, itemsType);
    }
    return resultItems;
  }
  converItemInfoData = (itemInfo) => {
    if(!itemInfo)return {};
    let tempAppInfo = {
      name: itemInfo.name,
      define: itemInfo.define,
      id: "" + itemInfo.id,
      sencneTypeID: itemInfo.sencneTypeID,
      type: itemInfo.type,
      commom:itemInfo.commom,
      key: this.makeItemInfoKey(itemInfo)
    };
    return tempAppInfo;
  }
  onItemCreate = (sencneType,sencne) => {
    let datas = {
      sencneType: sencneType,
      sencne:sencne,
      backTargetPage:"Content",
      backTargetPageDatas:{active:2}
    };
    this.router.push({ 
      name: "NewPageConfig",
      state:{
        pageDatas:JSON.stringify(datas)
      }
    });
  }

  getItemInfoByID=async(id)=>
  {
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_PAGEINFO_BY_ID",
      {
        "id": id
      }
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = this.converItemInfoData(responseDatas.resultData);
    }
    return resultItem;
  }

   /**
   * 更新信息到云端
   * @param {元素信息} itemInfo 
   */
   saveData=async(itemInfo)=>{
    let responseDatas = await this.wsClient.sendActionToCloud(
      "UPDATE_PAGEINFOS_BY_ID",
      itemInfo,
    );
    return responseDatas;
  };

  /**
   * 响应ConfigPane中的删除事件，批量删除选中的数据
   * @param {选中的需要删除的对象} selectedItem 
   * @param {操作actionkey} actionKey 
   * @returns 
   */
  doItemsDelete = async (selectedItem, actionKey) => {
    let queryIDs = [];
    for (let itemInfo of selectedItem) {
      queryIDs.push(itemInfo.id);
    }
    let responseDatas = await this.wsClient.sendActionToCloud(
      "DELETE_PAGEINFOS_BY_ID",
      {
        ids: queryIDs
      }
    );
    return responseDatas;
  }
  doItemsMove=async(selectedItem, sencneType)=>{
    let queryIDs = [];
    for (let itemInfo of selectedItem) {
      queryIDs.push(itemInfo.id);
    }
    let responseDatas = await this.wsClient.sendActionToCloud(
      "UPDATE_PAGEINFOS_SENCNETYPEID",
      {
        ids: queryIDs,
        sencneTypeID:sencneType.id
      }
    );
    return responseDatas;
  }

}