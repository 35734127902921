<template>
    <div>
        <Cell v-show="itemFinished" class="width-full" @click="relateSelectPopupShow = !relateSelectPopupShow" center is-link
            :value="selectRelateItem.name" :title="selectTitle">
            <template #value>
                <Popover placement="top-end" v-model:show="relateSelectPopupShow">
                    <CellGroup class="relatePane width-popupGroup">
                       <Collapse>
                            <Cell v-for="parentItem in relateItems" :key="parentItem.id" :title="parentItem.name"
                                @click="onParentItemSelected(parentItem)">
                                <template #title>
                                    <div class="flex padding-left">
                                        <Icon class="icon" :name="parentItem.iconData" size="20">
                                        </Icon>
                                        <div class="padding-left">{{ parentItem.name }}【#{{ parentItem.id }}】</div>
                                    </div>
                                </template>
                                <template v-if="parentItem.selected" #right-icon>
                                    <Icon name="success" class="selected" />
                                </template>
                            </Cell>
                        </Collapse>
                    </CellGroup>
                    <template #reference>
                        <div @click="relateSelectPopupShow = !relateSelectPopupShow">{{ selectRelateItem.name }}</div>
                    </template>
                </Popover>
            </template>
        </Cell>
        <List class="border none bg-trans" v-model:loading="itemLoading" :finished="itemFinished" finished-text="没有更多了">
                <VariablePane v-for="variableData in itemVariableDatas" :key="variableData.id"
                        :variableData="variableData" @onVariableDelete="onVariableDelete" />
        </List>
    </div>
</template>
<script setup>
import { getCurrentInstance, onMounted, defineProps, defineExpose, toRefs, ref, defineEmits, watch } from "vue";
import TaskType from "@/components/app/views/common/configPane/itemType/TaskType.js";
import StepType from "@/components/app/views/common/configPane/itemType/StepType.js";
import BaseVariableData from "../../variableType/BaseVariableData";
import util from "@/utils/util";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const stepType = new StepType();
const taskType = new TaskType();
stepType.init(wsClient);
taskType.init(wsClient);
const emits = defineEmits([])
const props = defineProps({
    relateItemMappings: {
        type: Array,
    },
    selectTitle: {
        type: String,
    },
    relateType: {
        type: String,
        default: "PARENT_RELATE_TYPE"
    }
});
const { relateItemMappings, selectTitle, relateType } = toRefs(props);
const relateItems = ref([]);
const selectRelateItem = ref({});
const relateSelectPopupShow = ref(false);
const itemVariableDatas = ref([]);
const stepIcon = ref("");
const taskIcon = ref("");

const itemLoading = ref(true);
const itemFinished = ref(false);

watch(relateItemMappings, async () => {
    await refreshRelateItems();
    refreashPrentVarableDatas();
});

onMounted(() => {
    stepIcon.value = wsClient.getIconBase64Str("STEP_ICON");
    taskIcon.value = wsClient.getIconBase64Str("TASK_ICON");
});

const onParentItemSelected = (parentItem) => {
    selectRelateItem.value.selected = false;
    selectRelateItem.value = parentItem;
    selectRelateItem.value.selected = true;
    relateSelectPopupShow.value = false;
    refreashPrentVarableDatas();
}

const onVariableDelete = async (variableData) => {
    let index = 0;
    for (let tempVariableData of itemVariableDatas.value) {
        if (tempVariableData.id == variableData.id) {
            break;
        }
        index++;
    }
    itemVariableDatas.value.splice(index, 1);
};

const refreshRelateItems = async () => {
    relateItems.value.splice(0);
    let relateItemCache = {};
    for (let relateParentMapping of relateItemMappings.value) {
        // let parentID = relateParentMapping.parentID;
        let tempItemInfo = (relateType.value == "PARENT_RELATE_TYPE") ? relateParentMapping.parentItemInfo : relateParentMapping.itemInfo;
        let parentType = tempItemInfo.type;
        switch (parentType) {
            case "STEP_TYPE":
                tempItemInfo.iconData = wsClient.getIconBase64Str("STEP_ICON");
                break;
            case "TASK_TYPE":
                tempItemInfo.iconData = wsClient.getIconBase64Str("TASK_ICON");
                break;
        }
        if (!relateItemCache[tempItemInfo.key]) {
            relateItemCache[tempItemInfo.key] = tempItemInfo;
            relateItems.value.push(tempItemInfo);
        }
    }
    if (relateItems.value.length > 0) {
        let isFindSelected = false;
        for (let tempItem of relateItems.value) {
            if (tempItem.id == selectRelateItem.value.id) {
                tempItem.selected = true;
                isFindSelected = true;
                break;
            }
        }
        if (!isFindSelected) {
            relateItems.value[0].selected = true;
            selectRelateItem.value = relateItems.value[0];
        }
    }
    itemLoading.value = false;
    itemFinished.value = true;
};

const refreashPrentVarableDatas = async () => {
    let parentType = selectRelateItem.value.type;
    let variableDatas;
    switch (parentType) {
        case "TASK_TYPE":
            variableDatas = await taskType.getItemVarables(selectRelateItem.value);
            break;
    }
    itemVariableDatas.value = variableDatas;
};
const addVariableData = () => {
    let timestamp = new Date().getTime();
    let basetempData = new BaseVariableData();
    basetempData.init({
        id: timestamp,
        variableType: {
            id: "VARIABLE_SELECT_TYPE",
            name: "选择类型"
        },
        name: "",
        value: "",
        isNewData: true,
        relateID: selectRelateItem.value.id,
        relateItemType: selectRelateItem.value.type
    }, wsClient);
    itemVariableDatas.value.push(basetempData);
};
defineExpose({
    addVariableData,
});
</script>
<style scoped>
.relatePane {
    width: 260px;
}
</style>