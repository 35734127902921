<template>
    <!--快捷信息面板-->
    <div class="padding">
        <div class="flex bg-white border solid radius padding">
            <div>
                <Image class="flex center as-avatar xl" :src="defaultIcon"></Image>
            </div>
            <div>
                <Cell center :title="routeInfo.name" :label="routeInfo.define"></Cell>
            </div>
        </div>
    </div>
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans" title="基础配置："></Cell>
                <Cell title="名称" :value="routeInfo.name"></Cell>
                <Field v-show="routeInfo.define" readonly autosize type="textarea" show-word-limit class="bg-trans"
                    v-model="routeInfo.define" />
                <Cell center title="场景类别" :value="getItemInfoSencneString()">
                </Cell>
                <Cell :title="routeInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
                    <template #right-icon>
                        <Switch disabled v-model="routeInfo.commom" size="24" />
                    </template>
                </Cell>
            </CellGroup>
        </div>
    </div>
    <!-- <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans" title="场景配置："></Cell>
                <Cell center title="场景类别" :value="getItemInfoSencneString()">
                </Cell>
            </CellGroup>
        </div>
    </div> -->
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans" title="高级信息:"></Cell>
                <Cell is-link value="查看" title="起始页面：" :label="getSelectedPageName(startPageInfo)" @click="gotoWatchPageInfo(true)">
                </Cell>
                <Cell is-link value="查看" title="终止页面：" :label="getSelectedPageName(endPageInfo)" @click="gotoWatchPageInfo(true)">
                </Cell>
                <Tabs shrink v-model:active="active" background="transparent">
                    <!--步骤操作页签面板-->
                    <Tab title="步骤设置">
                        <template #title> <span class="itemTab">步骤设置</span></template>
                        <RelateSelectPane :itemRelateMappings="itemRelateMappings"
                                :elementsHideConfig="elementsHideConfig" />
                    </Tab>
                    <!--任务操作页签面板-->
                    <Tab title="变量设置">
                        <template #title> <span class="itemTab">变量设置</span></template>
                        <!--变量设置面板-->
                        <ParameterSetting ref="variablePane" :elementsHideConfig="elementsHideConfig" :isNewAction="true"
                                :itemVariables="variableDatas" :itemInfo="routeInfo"></ParameterSetting>
                    </Tab>
                </Tabs>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, defineProps, toRefs, onMounted } from "vue";
import NewPageTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/NewPageTypeRelateOperator.js";
import {
    useRouter,
} from 'vue-router';
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
const pageHanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getWatchedPageName: (relateMapping) => {
    return "PageInfo";
  },
  getBackPageName:()=>{
    return "NewRouteConfig";
  },
};
const pageTypeOperator = new NewPageTypeRelateOperator();
pageTypeOperator.init(pageHanlder);
let firsData = tempOperateDatas[0];
let routeInfo = firsData.itemInfo ? firsData.itemInfo : {};
let sencne = firsData.sencne ? firsData.sencne : {};
let sencneType = firsData.sencneType ? firsData.sencneType : {};
let secondData = tempOperateDatas[1];
const startPageInfo = ref(secondData.startPageInfo?secondData.startPageInfo:{});
const endPageInfo = ref(secondData.endPageInfo?secondData.endPageInfo:{});
routeInfo.sequence = secondData.sequence ? secondData.sequence : "";
routeInfo.startPageID =secondData.startPageID;
routeInfo.endPageID=secondData.endPageID;
const itemRelateMappings = ref(secondData.itemRelateMappings ? secondData.itemRelateMappings : []);
let thirdData = tempOperateDatas[2];
const variableDatas = ref(thirdData.variableDatas ? thirdData.variableDatas : []);
const elementsHideConfig = {
    operateBar: true,
    addVariableBtn: true,
    variableOperateBtns: true,
};
//高级操作栏的选项卡索引
const active = ref(0);
//默认的Task图标
const defaultIcon = ref("");
const variablePane =ref(null);
onMounted(async () => {
    defaultIcon.value = wsClient.getIconBase64Str("PAGE_ICON");
});

const gotoWatchPageInfo = (isStartPage) => {
    let itemInfo =isStartPage?startPageInfo.value:endPageInfo.value;
    pageTypeOperator.doWatchRouteInfoAction(itemInfo);
};

const getSelectedPageName=(startPageInfo)=>{
    return startPageInfo.name+"【"+startPageInfo.id+"】";
};

const getRoutePageName=(isStartPage)=>{
    let tempPageInfo = isStartPage?startPageInfo.value:endPageInfo.value;
    if(tempPageInfo.name&&tempPageInfo.id)
    {
        return tempPageInfo.name+'【'+tempPageInfo.id+'】';
    }
    else
    {
        return "未设置"
    }
}

const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.name + '】';
}

const validateOnFinish = () => {
    let relateMappings = [];
    for (let tempRelateMapping of itemRelateMappings.value) {
        let tempMapping = {
            parentType: routeInfo.type,
            relateID: tempRelateMapping.relateID,
            relateType: tempRelateMapping.relateType,
            disable: tempRelateMapping.disable
        }
        relateMappings.push(tempMapping);
    }
    let variables = [];
    for (let tempVariableData of variableDatas.value) {
        let tempVariable = {
            name: tempVariableData.name,
            value: tempVariableData.value,
            dataValue: tempVariableData.dataValue,
            type: tempVariableData.variableType.id,
            relateItemType: routeInfo.type,
        }
        variables.push(tempVariable);
    }
    routeInfo.relateMappings = relateMappings;
    routeInfo.variables = variables;
    return routeInfo;
};
const resetDatas = () => {
    itemRelateMappings.value=[];
    variableDatas.value=[];
    if(variablePane.value)
    {
        variablePane.value.resetDatas();
    }
}

const updateDatas = () => {
    let tempOperateDatas = operateDatas.value;
    let firsData = tempOperateDatas[0];
    routeInfo = firsData.itemInfo ? firsData.itemInfo : {};
    sencne = firsData.sencne ? firsData.sencne : {};
    sencneType = firsData.sencneType ? firsData.sencneType : {};
    let secondData = tempOperateDatas[1];
    startPageInfo.value = secondData.startPageInfo?secondData.startPageInfo:{};
    endPageInfo.value = secondData.endPageInfo?secondData.endPageInfo:{};
    routeInfo.sequence = secondData.sequence ? secondData.sequence : "";
    routeInfo.startPageID =secondData.startPageID;
    routeInfo.endPageID=secondData.endPageID;
    itemRelateMappings.value = secondData.itemRelateMappings ? secondData.itemRelateMappings : [];
    let thirdData = tempOperateDatas[2];
    variableDatas.value = thirdData.variableDatas ? thirdData.variableDatas : [];
};
defineExpose({
    resetDatas,
    updateDatas,
    validateOnFinish,
});
</script>
<style scoped>
.itemTab {
    font-size: 15px;
}
</style>