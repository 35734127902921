<template>
  <div :style="{ height: computeFullHeight }">
    <router-view #default="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>
  </div>
  <PopupProSetPane />
</template>
<script setup>
import { getCurrentInstance, computed, onUnmounted, onMounted } from "vue";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const computeFullHeight = computed(() => {
  return window.innerHeight + "px";
});
let listenerID;
onUnmounted(() => {
  wsClient.removeEventListener(
    "AUTOSPIRIT_APP_ACTION_EVENT_CLOSED",
    listenerID);
  closeWsClient();
});

const closeWsClient = () => {
  setTimeout(() => {
    wsClient.dispose();
  });
};
onMounted(() => {
  const params = new URLSearchParams(window.location.search);
  const clientTimestamp = params.get('clientTimestamp');
  wsClient.initServer(clientTimestamp);
  listenerID = wsClient.addEventListener(
    "AUTOSPIRIT_APP_ACTION_EVENT_CLOSED",
    closeWsClient);
});
</script>
