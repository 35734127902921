
const AllType = require("./AllType");
const StepType = require("./StepType");
const TaskType = require("./TaskType");
const SpiritType = require("./SpiritType");
const RouterType = require("./RouterType");
const AppType = require("./AppType");
const PageType = require("./PageType");
const BaseStepActionType =require("./StepActionTypes/BaseStepActionType");
const EmptyStepActionType = require("./StepActionTypes/EmptyStepActionType");
const ImageMatchActionType = require("./StepActionTypes/ImageMatchActionType");
const AppLoadStepActionType = require("./StepActionTypes/AppLoadStepActionType");
/**
 * 元素类型操作工具类
 */
class ItemTypeUtil {


    getActionTypes()
    {
        return {
            'AUTOSPIRIT_STEP_EMPTY':this.getStepActionTypeObj("AUTOSPIRIT_STEP_EMPTY"),
            'AUTOSPIRIT_STEP_IMAGEMATCH':this.getStepActionTypeObj("AUTOSPIRIT_STEP_IMAGEMATCH"),
            'AUTOSPIRIT_STEP_APPLOAD':this.getStepActionTypeObj("AUTOSPIRIT_STEP_APPLOAD")
          };
    }

    getOccusEventConditionTypes()
    {
        return {
            'AUTOSPIRIT_STEP_IMAGEMATCH':this.getStepActionTypeObj("AUTOSPIRIT_STEP_IMAGEMATCH")
          };
    }
    /**
   * 获取元素类型对象，但该对象未经过初始化
   */
    getStepActionTypeObj(stepActionTypeID){
        let stepActionTypeObj;
        switch(stepActionTypeID)
        {
            case 'AUTOSPIRIT_STEP_EMPTY':
                stepActionTypeObj = new EmptyStepActionType();
                break;
            case 'AUTOSPIRIT_STEP_IMAGEMATCH':
                stepActionTypeObj = new ImageMatchActionType();
                break;
            case 'AUTOSPIRIT_STEP_APPLOAD':
                stepActionTypeObj = new AppLoadStepActionType();
                break;
            default:
                stepActionTypeObj = new BaseStepActionType();
        }
        return stepActionTypeObj;
      }

  /**
   * 获取元素类型对象，但该对象未经过初始化
   */
  getItemTypeObj(itemType){
    let itemTypeObj;
    switch(itemType)
    {
        case 'APP_TYPE':
            itemTypeObj = new AppType();
            break;
        case 'PAGE_TYPE':
            itemTypeObj = new PageType();
            break;
        case 'ROUTE_TYPE':
            itemTypeObj = new RouterType();
            break;
        case 'SPIRIT_TYPE':
            itemTypeObj = new SpiritType();
            break;
        case 'TASK_TYPE':
            itemTypeObj = new TaskType();
            break;
        case 'STEP_TYPE':
            itemTypeObj = new StepType();
            break;
        default:
            itemTypeObj = new AllType();
    }
    return itemTypeObj;
  }
}
module.exports = new ItemTypeUtil();