<template>
     <!--元素配置面板-->
     <!--多选显示的面板头，顶部显示-->
    <Popup v-model:show="onItemTouchedShow" position="top" :overlay="false">
        <BackableNavBar :title="getItemSelectedTitle()" :userDefineLeft="true" :userDefineRight="true"
            :listenerState="backListenerState" :autoControl="false" @onBack="closeItemMutiSelectMode">
            <template #left>
                <Icon name="success" size="18" @click="closeItemMutiSelectMode" />
            </template>
            <template #right>
                <Icon name="wap-nav" size="18" />
            </template>
        </BackableNavBar>
    </Popup>
     <!--多选显示的面板尾，底部显示-->
    <Popup v-model:show="onItemTouchedShow" position="bottom" :overlay="false">
        <OperatorPane :buttonInfos="selectedItemButtonInfos" v-show="mutiSelectedItem.length > 0" />
    </Popup>
    <div class="flex column bg-lightGrey height-full">
        <div class="topRow">
            <div class="flex-inline width-full">
                 <!--场景选择菜单组，点击时显示-->
                <Popover v-model:show="showPopover" placement="bottom-start" :offset="[5, 5]">
                    <CellGroup class="width-popupGroup">
                        <Cell clickable v-for="sencne in sencneList" :key="sencne.id" v-model="sencne.selected"
                            :title="sencne.sencneName" :class="sencne.selected ? 'selected' : ''"
                            @click="onSencneSelected(sencne,false)">
                            <template v-if="sencne.selected" #right-icon>
                                <Icon name="success" class="selected" />
                            </template>
                        </Cell>
                        <Cell v-show="!selectedMode" clickable
                            title="场景管理" @click="doManageSencne">
                            <template #right-icon>
                                <Icon name="setting" class="selected" />
                            </template>
                        </Cell>
                    </CellGroup>
                    <template #reference>
                        <Cell :title="seletedSencne.sencneName" is-link class="sencneCell" ></Cell>
                    </template>
                </Popover>
                 <!--元素类型选择菜单组，点击时显示-->
                <Popover v-model:show="showItemTypePopover"  placement="bottom-start" :offset="[5, 5]">
                    <CellGroup class="width-popupGroup">
                        <Cell clickable v-for="itemType in itemsOption" :key="itemType.id" v-model="itemType.selected"
                            :title="itemType.name" :class="itemType.selected ? 'selected' : ''"
                            @click="onItemTypeSelected(itemType)">
                            <template v-if="itemType.selected" #right-icon>
                                <Icon name="success" class="selected" />
                            </template>
                        </Cell>
                    </CellGroup>
                    <template #reference>
                        <Cell v-show="elementsHideConfig ? !elementsHideConfig.typeCell : true" 
                            :title="selectedItemType.name" is-link class="typeCell"></Cell>
                    </template>
                </Popover>
            </div>
            <div class="topRow-operate">
                <!--系统消息提示标签-->
                <Badge class="badge" v-show="elementsHideConfig ? !elementsHideConfig.badge : true">
                    <Icon name="chat-o" size="20"></Icon>
                </Badge>
                <!--元素操作按钮组-->
                <Popover v-model:show="showOperatePopover"  placement="left-start">
                    <CellGroup class="width-popupGroup">
                        <Cell clickable v-show="tempItemType.canCreate" v-for="tempItemType in itemsOption"
                            :key="tempItemType.id" :title="'新建' + tempItemType.name" @click="onItemCreate(tempItemType)">
                        </Cell>
                    </CellGroup>
                    <template #reference>
                        <Button v-show="elementsHideConfig ? !elementsHideConfig.addBtn : true" class="addBtn" icon="plus"></Button>
                    </template>
                </Popover>
            </div>
        </div>
         <!--搜索栏-->
        <Search class="seachBar" v-model="searchValue" placeholder="请输入搜索关键词" />
         <!--场景子类选择tab-->
        <Tabs shrink id="itemInfo_setting_container" v-model:active="active" background="transparent">
            <Tab v-for="sencneType in sencneTypes" :key="sencneType.id" :title="sencneType.name"></Tab>
        </Tabs>
        <div v-if="refreshTabFinished" class="itemsContainer" id="Item_setting_itemsWapper">
            <div class="itemsWapper border solid">
                 <!--元素展示面板-->
                <Grid clickable class="gridContent" :column-num="2" :gutter="10">
                    <GridItem class="gridItem" v-for="itemInfo in visableitemInfos" :key="itemInfo">
                        <ItemInfoPane :itemInfo="itemInfo" :itemType="itemInfo.type"
                            :singleSelectedMode="singleSelectedMode" :showOpetateIcon="selectedMode || isOnMutiSelectMode"
                            @onLongSelected="dealItemInfoPaneLongTouchAction" @onItemSelected="dealItemInfoPaneClick" />
                    </GridItem>
                </Grid>
            </div>
        </div>
        <div v-else>
             <!--加载面板-->
            <Loading type="spinner" color="#1989fa" class="loading" />
        </div>
         <!--子场景切换按钮-->
        <div id="Item_setting_switchBtn">
            <Popover v-model:show="showTabPopover"  placement="left-start">
                <CellGroup class="width-popupGroup">
                    <Cell clickable v-for="sencneType in sencneTypes" :key="sencneType.id" :title="sencneType.name"
                        @click="onTabSelected(sencneType)">
                    </Cell>
                </CellGroup>
                <template #reference>
                    <Button class="switchBtn" icon="wap-nav" />
                </template>
            </Popover>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, toRefs, defineProps, onActivated, onMounted, watch, reactive,defineEmits,nextTick} from "vue";
import { useEventListener } from "@vant/use";
import ActionManager from "@/components/app/views/common/configPane/ActionManager.js";
import SelectModeFilter from "@/components/app/views/common/configPane/itemFilter/SelectModeFilter.js";
import ItemFilterManager from "@/components/app/views/common/configPane/itemFilter/ItemFilterManager.js";
import ItemNameFilter from "@/components/app/views/common/configPane/itemFilter/ItemNameFilter.js";
import {
    useRouter,
} from 'vue-router';
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
//元素操作事件响应管理器
const actionManager = new ActionManager(wsClient,router);
const props = defineProps({
    //元素显示配置项
    elementsHideConfig: {
        type: Object
    },
    //是否是选择模式，如果是选择模式，则不需要长按也可以展示选择按钮
    selectedMode: {
        type: Boolean
    },
    //指定选择的模式是单选还是多选，默认为多选
    singleSelectedMode: {
        type: Boolean,
        default: false
    },
    //元素操作选项
    itemsOption: {
        type: Array
    },
    //需要排除在选择清单中的Item
    itemsExclude:{
        type: Array
    },
    pageType: {
        type: String
    }
});
//元素选择时触发事件
const emits = defineEmits(["onItemsSelected"]);
const { elementsHideConfig,
    singleSelectedMode,
    selectedMode,
    itemsOption,
    itemsExclude,
    pageType
} = toRefs(props);


//搜索栏值，用于过滤元素
const searchValue = ref("");
//当前已经选择的场景
const seletedSencne = ref({});

const sencneList = ref([]);
//场景popup控制属性
const showPopover = ref(false);
//子场景tab选中索引
const active = ref(-1);
//当前已经选择的场景下面的子场景集
const sencneTypes = ref([]);
//子场景切换popup控制属性
const showTabPopover = ref(false);
//元素类型popup控制属性
const showItemTypePopover = ref(false);
//刷新结束属性
const refreshTabFinished = ref(false);
//元素操作选项popup控制属性
const showOperatePopover = ref(false);
//元素多选项操作面板popup控制属性
const onItemTouchedShow = ref(false);

//所有已经加载的数据源
const itemInfos = ref([]);
//过滤后需要显示的数据源
const visableitemInfos = ref([]);

//当前已经多选的元素集
const mutiSelectedItem = reactive([]);
//当前已经多选的元素ID集
const mutiSelectedItemIDs = reactive([]);
//当前已经多选的元素面板集
const mutiSelectedSource = reactive([]);

//当前面板的所有元素过滤器集
const itemFilters =new ItemFilterManager();
itemFilters.init();

//选择模式下元素的过滤器，在itemsExclude中的元素将被过滤掉
let selectModeFilter = new SelectModeFilter();
selectModeFilter.init({excludeKeys:itemsExclude.value});

//字符过滤器，主要基于元素的名称来进行过滤
let textFilter = new ItemNameFilter();
textFilter.itemName =searchValue.value;


//标识是否是多选的模式
const isOnMutiSelectMode = ref(false);
//按钮多元操作面板的元素项集，在选择元素时会动态刷新
const selectedItemButtonInfos = ref([]);

//多选模式下面板的监听对象值
const backListenerState = ref(true);

//当前选择的元素类型
const selectedItemType = ref({});

const instanceID =Date.now();


/**
 * 对页面进行监听，当页面大小变化时
 */
useEventListener("resize", () => {
    nextTick(()=>{
        adjustButton();
    });
});

onActivated(async() => {
    await initSettingMenu(true);
    nextTick(()=>{
        adjustButton();
    });
});

onMounted(() => {
    addItemFilter("ItemSelectedModeFilter",selectModeFilter);
    addItemFilter("itemNameFilter",textFilter);
    initSettingMenu(true);
    nextTick(()=>{
        adjustButton();
    });
});

watch(searchValue,(newValue)=>{
    textFilter.itemName =searchValue.value;
    initItemInfos();
});

watch(active, () => {
    //active状态改变时，记录上一次选中的场景类型，下次初始化之后选中之前选中的secnetype页签
    let seletedSencneType = getSelectedSencneType();
    wsClient.addItemToStorage(makePaneKey("SELECT_SENCNETYPE_ID"),seletedSencneType,false);
    initItemInfos();
});
watch(onItemTouchedShow, (newValue) => {
    backListenerState.value = newValue;
});

const makePaneKey=(key)=>{
    return key+"@"+pageType.value+"@"+instanceID;
}

/**
 * 选中上次选中的SencneTypeTab页签
 */
const selectedLastSencneTypeTab=()=>{
    let tempSencneType= wsClient.getItemFromStorage(makePaneKey("SELECT_SENCNETYPE_ID"),false);
    tempSencneType =tempSencneType?tempSencneType:{};
    let index=0;
    for(let tempType of sencneTypes.value)
    {
        if(tempType.id ==tempSencneType.id)
        {
            //如果相等的时候强制刷新
            if(active.value ==index)
            {
                initItemInfos();
            }
            else
            {
                active.value =index;
            }
            break;
        }
        index++;
    }
};


/**
 * 获取已经选择的元素title，被顶部弹框动态调用
 */
const getItemSelectedTitle = () => {
    return mutiSelectedItem.length > 0 ? `已选择${mutiSelectedItem.length}个元素` : "请选择元素";
};

/**
 * 结束多选模式
 */
const closeItemMutiSelectMode = () => {
    for (let source of mutiSelectedSource) {
        source.resetState();
    }
    mutiSelectedItem.splice(0);
    mutiSelectedItemIDs.splice(0);
    mutiSelectedSource.splice(0);
    onItemTouchedShow.value = false;
    isOnMutiSelectMode.value = false;
};
/**
 * 响应元素面板长按
 * @param {事件参数} eventDatas 
 */
const dealItemInfoPaneLongTouchAction = (eventDatas) => {
    //如果是选择模式面板，则不触发长按模式也能选择
    if (selectedMode.value) return;
    if (!isOnMutiSelectMode.value) {
        isOnMutiSelectMode.value = true;
        onItemTouchedShow.value = true;
    }
    dealItemInfoPaneClick(eventDatas);
};

/**
 * 多选操作元素动作后，中止选择模式，并且重新初始化元素
 */
const reInitItemInfos = () => {
    closeItemMutiSelectMode();
    initItemInfos();
}

/**
 * 刷新元素操作面板里的操作按钮
 */
const refreshItemsSelectedOperateBtns = () => {
    //获取元素事件Action元素，基于元素生成面板
    let tempActions = actionManager.refreshSelectedItemsOperateKey(mutiSelectedItem, itemsOption.value, reInitItemInfos,pageType.value);
    selectedItemButtonInfos.value = tempActions;
};

/**
 * 响应元素面板点击事件
 * @param {点击事件对象} eventDatas 
 */
const dealItemInfoPaneClick = (eventDatas) => {
    //在多选或单选模式下
    if (isOnMutiSelectMode.value || selectedMode.value) {
        let itemInfo = eventDatas["itemInfo"];
        let key = itemInfo.key;
        let index = mutiSelectedItemIDs.indexOf(key);
        //如果当前元素不再已经选择的清单中，进行添加，否则移除
        if (index < 0) {
            //多选模式，添加到缓存集中
            if (!singleSelectedMode.value) {
                mutiSelectedItem.push(itemInfo);
                mutiSelectedItemIDs.push(key);
                let source = eventDatas["source"];
                mutiSelectedSource.push(source)
            }
            //单选模式，先清空上次选的对象，再添加新对象
            else {
                if (mutiSelectedItem.length > 0) {
                    let source = mutiSelectedSource[0];
                    source.resetState();
                }
                mutiSelectedItemIDs.splice(0);
                mutiSelectedItem.splice(0);
                mutiSelectedSource.splice(0);
                mutiSelectedItem.push(itemInfo);
                mutiSelectedItemIDs.push(key);
                let source = eventDatas["source"];
                mutiSelectedSource.push(source)
            }
        }
        else {
            mutiSelectedItem.splice(index, 1);
            mutiSelectedItemIDs.splice(index, 1);
            mutiSelectedSource.splice(index, 1);
        }
        //触发元素选择对象变化事件
        emits("onItemsSelected",mutiSelectedItem);
        //刷新元素操作面板按钮
        refreshItemsSelectedOperateBtns();
    }
    //不在选择模式，直接显示元素面板详情，回调相应方法
    else {
        let source = eventDatas["source"];
        source.doPaneClick();
    }

};

/**
 * 获取当前tab页面展示的子场景
 */
const getSelectedSencneType = () => {
    return sencneTypes.value[active.value];
};

/**
 * 初始化元素
 */
const initItemInfos = async () => {
    refreshTabFinished.value = false;
    let sencneType = getSelectedSencneType();
    if (sencneType) {
        //刷新元素数据源，重新加载元素信息
        let resultItemInfos = await actionManager.doItemDatasRereshAction(sencneType, selectedItemType.value, itemsOption.value);
        //初始化元素类型定义事件
        actionManager.initItemsTypeCustomerActions(itemsOption.value);
        visableitemInfos.value=[];
        //更新状态和过滤
        for (let itemInfo of resultItemInfos) {
            let isNeedFilter =itemFilters.filterItem(itemInfo);
            let key = itemInfo.key;
            let index = mutiSelectedItemIDs.indexOf(key);
            itemInfo.checked = (index >= 0);
            //如果不需要过滤则添加
            if(!isNeedFilter)
            {
                visableitemInfos.value.push(itemInfo);
            }
        }
        itemInfos.value = resultItemInfos;
    }
    refreshTabFinished.value = true;
};


/**
 * 添加过滤器到缓存
 * @param {过滤器} filter 
 */
const addItemFilter =(key,filter)=>{
    itemFilters.addItemFilter(key,filter);
};

/**
 * 校验子场景切换按钮的位置
 */
const adjustButton = () => {
    let tabContainer = document.getElementById("itemInfo_setting_container");
    if (tabContainer) {
        let tabBounds = tabContainer.getBoundingClientRect();
        let tabWapper = tabContainer.firstElementChild;
        let switchBtn = document.getElementById("Item_setting_switchBtn");
        switchBtn.style.top = tabBounds.top + 5 + "px";
        switchBtn.style.left =
            tabBounds.right - switchBtn.offsetWidth - 5 + "px";
        tabWapper.style.width =
            tabBounds.right - switchBtn.offsetWidth - 5 + "px";
    }
    
};


/**
 * 响应元素添加事件
 */
const onItemCreate = (itemType) => {
    showOperatePopover.value = false;
    let sencneType = getSelectedSencneType();
    itemType.onItemCreate(sencneType,seletedSencne.value);
};

/**
 * 响应管理场景按钮事件，跳转到场景管理页面
 */
const doManageSencne = () => {
    showPopover.value = false;
    let datas = {
        type: pageType.value,
        backTargetPageDatas:{
            active:pageType.value=="0"?1:2
        }
    };
    router.push({ 
        name: "SencneSetting",
        state:{
            pageDatas:JSON.stringify(datas)
        }
    });
};

/**
 * 响应子场景tab页签切换按钮事件，刷新对应的active索引
 * @param {选中的子场景对象} sencneType 
 */
const onTabSelected = (sencneType) => {
    showTabPopover.value = false;
    let index = 0;
    for (let tempSencneType of sencneTypes.value) {
        if (tempSencneType.id == sencneType.id) {
            active.value = index;
            break;
        }
        index++;
    }
};

/**
 * 响应场景菜单选项选中
 * @param {场景对象} sencne 
 */
const onSencneSelected = async (sencne,isNeedAutoSelected) => {
    showPopover.value = false;
    seletedSencne.value.selected = false;
    seletedSencne.value = sencne;
    seletedSencne.value.selected = true;
    wsClient.addItemToStorage(makePaneKey("SELECT_SENCNE_ID"),seletedSencne.value,false);
    //获取当前场景下的子场景
    let params = await actionManager.doSencneTypeDatasRereshAction(sencne);
    refreshSencneTypeTabs(params,isNeedAutoSelected);

};

/**
 * 响应元素类型菜单选择事件
 * @param {元素类型对象} itemType 
 */
const onItemTypeSelected = async (itemType) => {
    showItemTypePopover.value = false;
    selectedItemType.value.selected = false;
    itemType.selected = true;
    selectedItemType.value = itemType;
    wsClient.addItemToStorage(makePaneKey("SELECT_ITEMTYPE_ID"),selectedItemType.value,false);
    refreshSencneTypeTabs(sencneTypes.value,false);
};

/**
 * 刷新子场景
 * @param {子场景集} params 
 */
const refreshSencneTypeTabs = (params,isNeedAutoSelected) => {
    sencneTypes.value = params;
    //如果需要自动选择上次选中的页签
    if(isNeedAutoSelected)
    {
        selectedLastSencneTypeTab();
    }
    else
    {
        initItemInfos();
    }
};

/**
 * 初始化当前页面选择中的菜单
 */
const initSettingMenu = async (isNeedAutoSelected) => {
    let findSelected = false;
    let selectedItemTypeObj = wsClient.getItemFromStorage(makePaneKey("SELECT_ITEMTYPE_ID"),false);
    let tempSelectedItemType;
    let tempSelectedSence;
    selectedItemType.value =selectedItemTypeObj?selectedItemTypeObj:{};
    //找到初始被选中的元素类型
    for (let tempType of itemsOption.value) {
        if (selectedItemType.value.id==tempType.id) {
            tempSelectedItemType =tempType;
            findSelected = true;
            break;
        }
    }
    //如果没找到，默认取第一个
    if (!findSelected) {
        let tempType = itemsOption.value[0];
        tempSelectedItemType =tempType;
    }
    //获取当前用户下面的所有可用场景
    let sencnes = await actionManager.doSencneDatasRereshAction(pageType.value);
    sencneList.value = sencnes;
    let selectedSencneObj = wsClient.getItemFromStorage(makePaneKey("SELECT_SENCNE_ID"),false);
    seletedSencne.value = selectedSencneObj?selectedSencneObj:{};
    let findSelectedSence = false;
    //找到默认被选择的场景
    for (let sencne of sencneList.value) {
        if (sencne.id == seletedSencne.value.id) {
            tempSelectedSence =sencne;
            findSelectedSence = true;
            break;
        }
    }
    //如果没找到，默认取第一个
    if (!findSelectedSence) {
        tempSelectedSence =sencneList.value[0];
    }
    nextTick(async() => {
       await onSencneSelected(tempSelectedSence, isNeedAutoSelected);
       await onItemTypeSelected(tempSelectedItemType);
    });
};
</script>
<style scoped>

.itemsContainer{
    flex:1;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.itemsWapper{
    padding-bottom: 10px;
    flex: 1;
}

.gridContent
{
    padding-top: 10px;
}
.gridContent ::after {
    border: none;
}

.topRow {
    max-height: 60px;
    width: 100%;
}

.loading {
    margin-left: 10px;
    margin-top: 10px;
}

.topRow-operate {
    position: fixed;
    right: 0%;
    display: inline-flex;
    justify-content: center;
}

.seachBar {
    padding: 5px;
    background-color: transparent;
}

.sencneCell {
    background-color: transparent;
    font-size: 16px;
    padding-right: 0px;
}

.typeCell {
    background-color: transparent;
    font-size: 16px;
    padding-left: 5px;
}

.badge {
    display: inline-block;
    align-self: center;
    height: 100%;
    width: 100%;
}

.addBtn {
    background-color: transparent;
    border: none;
}

#Item_setting_switchBtn {
    position: absolute;
}

.switchBtn {
    background-color: #e0e0e0;
    border-radius: 10px;
    border: none;
    padding: 10px;
    height: 30px;
}

.gridItem div {
    border-radius: 10px;
    border: 0.5px solid #d0d0d0;
    width: 100%;
    height: 120px;
    background-color: #fcfcfc;
}

</style>