<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar leftText="返回" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell center title="新建场景"></Cell>
          <ProsetCell title="场景名称" :label="sencneName" :validate="validateSencneName" @onSubmit="setSencneName" @onCancel="cancelPopup">
          </ProsetCell>
        </CellGroup>
      </div>
    </div>
    <div id="autospirit_newSencne_Btn">
      <div>
        <div v-if="sencneName == '请输入场景名称'">
          <Button disabled type="primary" class="newSencneBtn" @click="doNewSencne">确定</Button>
        </div>
        <div v-else>
          <Button type="primary" class="newSencneBtn" @click="doNewSencne">确定</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import {
  useRouter,
} from 'vue-router';
import { showToast } from "vant";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const sencneClass = pageDatas.type;
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const router = useRouter();
const sencneName = ref("请输入场景名称");

onMounted(() => {
  let btnWapper = document.getElementById("autospirit_newSencne_Btn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
});

const validateSencneName =(value)=>{
    if(value=="默认场景")
    {
      let params = {
        message: "场景名称不能和默认场景名相同！",
        position: "bottom",
      };
      showToast(params);
      return false;
    }
    return true;
};

const onClickLeft = () => {
  router.push({
    name: "SencneSetting",
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
const cancelPopup = () => {
};
const doNewSencne = async () => {
  let responseDatas = await wsClient.sendActionToCloud(
    "CREATE_USER_SENCNES",
    {
      userAccount: wsClient.getItemFromStorage("phone",true),
      sencneName: sencneName.value,
      isSelected: false,
      canDelete: true,
      type: sencneClass,
    }
  );
  let resultCode = responseDatas.resultCode;
  if (resultCode == 1) {
    router.push({
      name: "SencneSetting",
      state: {
        pageDatas: JSON.stringify(backTargetPageDatas)
      }
    });
  }
  let params = {
    message: responseDatas.message,
    position: "bottom",
  };
  showToast(params);
};
const setSencneName = (message) => {
  sencneName.value = message;
};
</script>
<style scoped>
#autospirit_newSencne_Btn {
  padding: 10px;
  text-align: center;
  position: absolute;
}

.newSencneBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>