<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar leftText="返回" title="精灵设置" @onBack="onClickLeft" />
    <div class="padding">
      <CellGroup>
        <Cell center title="基础信息:"></Cell>
        <ProsetCell title="名称" :value="spiritInfo.name" @onSubmit="onSpiritRenameSubmit"></ProsetCell>
        <ProsetCell title="描述" type="textarea" :value="spiritInfo.define" @onSubmit="onSpiritDiscripeSubmit">
        </ProsetCell>
        <Cell center is-link title="场景归属" :value="getItemInfoSencneString()" @click="gotoModifySencneType"></Cell>
        <Cell v-bind:title="spiritInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
          <template #right-icon>
            <Switch v-model="spiritInfo.commom" size="24" @change="changeSpiritCommom" />
          </template>
        </Cell>
      </CellGroup>
    </div>
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="高级信息:"></Cell>
          <Tabs shrink v-model:active="active" background="transparent">
            <!--任务操作页签面板-->
            <Tab title="任务设置">
              <template #title> <span class="taskTab">任务设置</span></template>
              <RelateSelectPane ref="relateSelectPane" :itemRelateMappings="taskRelateMappings"
                :loadingState="loadingState" @onAddRelateItem="doAddRelateTask"
                :relateItemOperator="operator"
                />
            </Tab>
            <Tab title="变量设置">
              <template #title> <span class="taskTab">变量设置</span></template>
              <!--变量设置面板-->
              <ParameterSetting :itemInfo="spiritInfo"></ParameterSetting>
            </Tab>
            <Tab title="执行日志">
              <template #title> <span class="taskTab">执行日志</span></template>
              <!--变量设置面板-->
              <RunableInfosPane :itemInfo="spiritInfo"></RunableInfosPane>
            </Tab>
          </Tabs>
        </CellGroup>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onActivated, onMounted } from "vue";
import { useRouter } from "vue-router";
import { showToast } from "vant";
import util from "@/utils/util";
import SpiritType from "@/components/app/views/common/configPane/itemType/SpiritType.js";
import SpiritTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/SpiritTypeRelateOperator.js";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const spiritType = new SpiritType();
spiritType.init(wsClient, router);
const hanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getItemInfo:()=>{
    return spiritInfo.value;
  },
  getWatchedPageName: (relateMapping) => {
    let type = relateMapping.relateType;
    //查看的是步骤，则传递相关信息到步骤页面
    if (type == "TASK_TYPE") {
        return "TaskInfo";
    }
  },
  getBackPageName:()=>{
    return "SpiritInfo";
  },
  getBackPageDatas:()=>{
    let datas ={
        itemInfo: spiritInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas
      };
    return datas;
  },
  updateRelateMappings:(relateItemMappings)=>{
    taskRelateMappings.value = relateItemMappings;
    loadingState.value = false;
  },
  getRelateMappings:()=>{
    return taskRelateMappings.value;
  },
};
const operator = new SpiritTypeRelateOperator();
operator.init(hanlder);
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const spiritInfo = ref(pageDatas.itemInfo ? pageDatas.itemInfo : {});
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let appInfos = ref([]);
const sencneType = ref({});
const sencne = ref({});
//当前任务下所关联的步骤映射集
const taskRelateMappings = ref([]);

const loadingState = ref(true);

//高级操作栏的选项卡索引
const active = ref(0);

onMounted(async () => {
  await reloadItemInfos();
  operator.initRelateMappingDatas(spiritInfo.value);
});

/**
 * 相应添加映射关联的动作
 */
const doAddRelateTask = async () => {
  //已经选中的任务不在增加
  let itemsExcludeArr = [];
  for (let relateMapping of taskRelateMappings.value) {
    let key = relateMapping.itemInfo.key;
    itemsExcludeArr.push(key);
  }
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "SpiritInfo",
    backTargetPageDatas: {
      itemInfo: spiritInfo.value,
      backTargetPage: backTargetPage,
      backTargetPageDatas: backTargetPageDatas
    },
    eventName: "AUTOSPIRIT_SPIRITINFO_STEPSSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsExclude: itemsExcludeArr,
    itemsType: ["TASK_TYPE"],
    selectedMode: true,
    pageType: "0",
    pageTitle: "步骤选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let queryData = {
      relateMappings: [],
      insertIndex: taskRelateMappings.value.length
    };
    //基于选择的元素，遍历构建查询对象
    for (let selectedItem of selectedItems) {
      let tempMapping = {
        parentID: spiritInfo.value.id,
        parentType: spiritInfo.value.type,
        relateID: selectedItem.id,
        relateType: selectedItem.type,
        disable: false
      };
      queryData.relateMappings.push(tempMapping);
    }
    let responseDatas = await operator.createRelateMapping(queryData);
    //成功后返回之前的选择页面
    if (responseDatas.resultCode == 1) {
      let pageDatas = {
        itemInfo: spiritInfo.value,
        backTargetPage: backTargetPage,
        //之前任务页面的backPagedatas对象
        backTargetPageDatas: backTargetPageDatas
      }
      router.push({
        name: datas.backTargetPage,
        state: {
          pageDatas: JSON.stringify(pageDatas)
        }
      });
    }
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};

const getItemInfoSencneString = () => {
  if (!sencne.value.sencneName || !sencneType.value.name) {
    return "加载中...";
  }
  return sencne.value.sencneName + '【' + sencneType.value.name + '】';
}

const reloadItemInfos = async () => {
  spiritInfo.value = await spiritType.getItemInfoByID(spiritInfo.value.id);
  sencneType.value = await spiritType.getItemSencneType(spiritInfo.value);
  sencne.value = await spiritType.getItemSencne(sencneType.value);
};

const gotoModifySencneType = () => {
  let datas = {
    sencnePageType: 0,
    backTargetPage: "SpiritInfo",
    backTargetPageDatas: {
      itemInfo: spiritInfo.value,
      backTargetPage: backTargetPage,
      backTargetPageDatas: backTargetPageDatas
    },
    eventName: "AUTOSPIRIT_SPIRITINFO_SENCNETYPESELECT_EVENT",
    selectedSencneType: sencneType.value,
    selectedSencne: sencne.value
  };
  const actionMethod = async (eventDatas) => {
    let queryParams = {
      ids: [spiritInfo.value.id],
      sencneTypeID: eventDatas.sencneType.id,
    };
    let responseDatas = await wsClient.sendActionToCloud("UPDATE_SPIRITINFOS_SENCNETYPEID", queryParams);
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
    if (responseDatas.resultCode == 1) {
      spiritInfo.value.sencneTypeID = queryParams.sencneTypeID;
      let pageDatas = {
        itemInfo: spiritInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas
      }
      router.push({
        name: datas.backTargetPage,
        state: {
          pageDatas: JSON.stringify(pageDatas)
        }
      });
    }
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "SencneTypeSelected",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
const getSelectedAppInfoIcons = () => {
  let icons = [spiritInfo.value.iconDataBase64];
  for (let appInfo of appInfos.value) {
    let iconData = getIconBase64Url(appInfo.appInfoIconData);
    icons.push(iconData);
  }
  return icons;
};

const gotoModifyImage = () => {
  let tempDatas = {
    operateTitle: "选择图片",
    currentImageSource: spiritInfo.value.iconDataBase64,
    supportImageType: "jpg、png、jpeg",
    maxImageSize: "10MB",
    suggestImages: getSelectedAppInfoIcons(),
    eventName: "AUTOSPIRIT_SPIRITINFO_MODIFYIMAGE_EVENT",
    backTargetPage: "SpiritInfo",
    backTargetPageDatas: {
      itemInfo: spiritInfo.value,
      backTargetPageDatas: backTargetPageDatas
    },
  };
  const actionMethod = async (eventDatas) => {
    let queryParams = {
      id: spiritInfo.value.id,
      iconDataBase64: eventDatas.iconData,
    };
    let responseDatas = await wsClient.sendActionToCloud(
      "UPDATE_SPIRIT_IMAGE",
      queryParams
    );
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
    if (responseDatas.resultCode == 1) {
      spiritInfo.value.iconDataBase64 = eventDatas.iconData;
      let pageDatas = {
        itemInfo: spiritInfo.value,
        backTargetPageDatas: backTargetPageDatas
      }
      router.push({
        name: tempDatas.backTargetPage,
        state: {
          pageDatas: JSON.stringify(pageDatas)
        }
      });
    }
  };
  datas.listenerID= wsClient.addEventListener(tempDatas.eventName, actionMethod);
  router.push({
    name: "SencneTypeImageSelected",
    state: {
      pageDatas: JSON.stringify(tempDatas)
    }
  });
};

const changeSpiritCommom = () => {
  spiritType.saveData(spiritInfo.value);
};

const onSpiritRenameSubmit = async (newName) => {
  spiritInfo.value.name = newName;
  spiritType.saveData(spiritInfo.value);
};
const onSpiritDiscripeSubmit = async (newDiscripe) => {
  spiritInfo.value.define = newDiscripe;
  spiritType.saveData(spiritInfo.value);
};

const getIconBase64Url = (base64) => {
  return util.getImageDataUrlFromBase64(base64);
};

const onClickLeft = () => {
  router.push({
    name: backTargetPage,
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
</script>
<style scoped>
.taskTab {
  font-size: 15px;
}
</style>