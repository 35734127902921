<!--应用的信息呈现面板-->
<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar leftText="返回" title="应用信息" :autoControl="false" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="基础信息："></Cell>
          <Field v-model="appInfo.name" readonly center label="名称：" placeholder="请选择应用"
            :rules="[{ required: true, message: '请选择应用' }]">
          </Field>
          <Field v-model="appInfo.appID" readonly center disable label="APP ID：" placeholder=""
            :rules="[{ required: true, message: '请选择应用' }]" />
          <ProsetCell title="描述：" type="textarea" :value="appInfo.define" @onSubmit="onDefineSubmit">
          </ProsetCell>
          <Cell center is-link title="场景类别" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect"></Cell>
          <Cell :title="appInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
            <template #right-icon>
              <Switch v-model="appInfo.commom" size="24" @change="changeCommom" />
            </template>
          </Cell>
        </CellGroup>
      </div>
    </div>
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="高级信息:"></Cell>
          <Tabs shrink v-model:active="active" background="transparent">
            <!--任务操作页签面板-->
            <Tab title="页面设置">
              <template #title> <span class="taskTab">页面设置</span></template>
              <RelateSelectPane ref="relateSelectPane" :itemRelateMappings="pageRelateMappings"
                :relateItemOperator="operator"
                :loadingState="loadingState" @onAddRelateItem="doAddRelateItem" />
            </Tab>
            <Tab title="路径设置">
              <template #title> <span class="taskTab">路径设置</span></template>
              <AppPageRouteSetting :itemRelateMappings="pageRelateObjectMappings"
                :relateItemOperator="pageTypeOperator"
                :relateOperatorOptions="optionsConfig.pageRouteSetOperateOptions"
                :loadingState="loadingState"/>
            </Tab>
            <Tab title="变量设置">
              <template #title> <span class="taskTab">变量设置</span></template>
              <!--变量设置面板-->
              <ParameterSetting :itemInfo="appInfo"></ParameterSetting>
            </Tab>
          </Tabs>
        </CellGroup>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import {
  useRouter,
} from 'vue-router';
import { showToast } from "vant";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import AppTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/AppTypeRelateOperator.js";
import PageTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/PageTypeRelateOperator.js";
import optionsConfig from "@/components/app/views/common/stepType/OptionsConfig.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const hanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getItemInfo:()=>{
    return appInfo.value;
  },
  getWatchedPageName: (relateMapping) => {
    let type = relateMapping.relateType;
    //查看的是步骤，则传递相关信息到步骤页面
    if (type == "PAGE_TYPE") {
        return "PageInfo";
    }
    else if(type == "ROUTE_TYPE")
    {
      return "RouteInfo";
    }
  },
  getBackPageName:()=>{
    return "AppInfo";
  },
  getBackPageDatas:()=>{
    let datas ={
        itemInfo: appInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas
      };
    return datas;
  },
  updateRelateMappings:async(relateItemMappings)=>{
    pageRelateMappings.value = relateItemMappings;
    pageRelateObjectMappings.value =await pageTypeOperator.convertToPageToPageObj(pageRelateMappings.value);
    loadingState.value = false;
  },
  getRelateMappings:()=>{
    return pageRelateMappings.value;
  },
};
const operator = new AppTypeRelateOperator();
operator.init(hanlder);
const pageTypeOperator = new PageTypeRelateOperator();
pageTypeOperator.init(hanlder);
const appType = typeUtil.getItemTypeObj('APP_TYPE');
appType.init(wsClient, router);
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const appInfo = ref(pageDatas.itemInfo ? pageDatas.itemInfo : {});
//步骤所在的子场景
const sencneType = ref({});
const sencne = ref({});
//高级操作栏的选项卡索引
const active = ref(0);
//当前任务下所关联的步骤映射集
const pageRelateMappings = ref([]);
const pageRelateObjectMappings = ref([]);

const loadingState = ref(true);
const onClickLeft = () => {
  router.push({
    name: backTargetPage,
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
onMounted(async () => {
  //从云端获取最新的数据
  appInfo.value = await appType.getItemInfoByID(appInfo.value.id);
  sencneType.value = await appType.getItemSencneType(appInfo.value);
  sencne.value = await appType.getItemSencne(sencneType.value);
  operator.initRelateMappingDatas(appInfo.value);
});

/**
 * 相应添加映射关联的动作
 */
const doAddRelateItem = async () => {
  //已经选中的任务不在增加
  let itemsExcludeArr = [];
  for (let relateMapping of pageRelateMappings.value) {
    let key = relateMapping.itemInfo.key;
    itemsExcludeArr.push(key);
  }
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "AppInfo",
    backTargetPageDatas: {
      itemInfo: appInfo.value,
      backTargetPage: backTargetPage,
      backTargetPageDatas: backTargetPageDatas
    },
    eventName: "AUTOSPIRIT_APPINFO_PAGESSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsExclude: itemsExcludeArr,
    itemsType: ["PAGE_TYPE"],
    selectedMode: true,
    pageType: "1",
    pageTitle: "页面选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let queryData = {
      relateMappings: [],
      insertIndex: pageRelateMappings.value.length
    };
    //基于选择的元素，遍历构建查询对象
    for (let selectedItem of selectedItems) {
      let tempMapping = {
        parentID: appInfo.value.id,
        parentType: appInfo.value.type,
        relateID: selectedItem.id,
        relateType: selectedItem.type,
        disable: false
      };
      queryData.relateMappings.push(tempMapping);
    }
    let responseDatas = await wsClient.sendActionToCloud(
      "CREATE_RELATE_MAPPINGS",
      queryData
    );
    //成功后返回之前的选择页面
    if (responseDatas.resultCode == 1) {
      let pageDatas = {
        itemInfo: appInfo.value,
        backTargetPage: backTargetPage,
        //之前任务页面的backPagedatas对象
        backTargetPageDatas: backTargetPageDatas
      }
      router.push({
        name: datas.backTargetPage,
        state: {
          pageDatas: JSON.stringify(pageDatas)
        }
      });
      pageRelateObjectMappings.value =await pageTypeOperator.convertToPageToPageObj(pageRelateMappings.value);
    }
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};

const getItemInfoSencneString = () => {
  if (!sencne.value.sencneName || !sencneType.value.name) {
    return "加载中...";
  }
  return sencne.value.sencneName + '【' + sencneType.value.name + '】';
}

const changeCommom = () => {
  appType.saveData(appInfo.value);
};

/**
 * 响应更新描述动作
 * @param {描述} newDiscripe 
 */
const onDefineSubmit = (newDiscripe) => {
  appInfo.value.define = newDiscripe;
  appType.saveData(appInfo.value);
};
const gotoSencneTypeSelect = () => {
  let datas = {
    sencnePageType: 1,
    eventName: "AUTOSPIRIT_APPINFO_SENCNETYPESELECT_EVENT",
    backTargetPage: "AppInfo",
    backTargetPageDatas: {
      itemInfo: appInfo.value,
      backTargetPage: backTargetPage,
      backTargetPageDatas: backTargetPageDatas
    },
    selectedSencneType: sencneType.value,
    selectedSencne: sencne.value
  };
  const actionMethod = async (eventDatas) => {
    let queryParams = {
      ids: [appInfo.value.id],
      sencneTypeID: eventDatas.sencneType.id,
    };
    let responseDatas = await wsClient.sendActionToCloud("UPDATE_APPINFOS_SENCNETYPEID", queryParams);
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
    if (responseDatas.resultCode == 1) {
      appInfo.value.sencneTypeID = queryParams.sencneTypeID;
      let pageDatas = {
        itemInfo: appInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas
      }
      router.push({
        name: datas.backTargetPage,
        state: {
          pageDatas: JSON.stringify(pageDatas)
        }
      });
    }
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "SencneTypeSelected",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
</script>
<style scoped>
.taskTab {
  font-size: 15px;
}
</style>
  