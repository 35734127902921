<template>
    <!--变量设置面板，面板中的tab页签会根据变量的类型生成-->
    <div class="padding">
        <div class="bg-white border solid radius">
            <!--任务操作页签面板--快捷操作按钮栏-->
            <Cell class="bg-trans" title="">
                <template #right-icon>
                    <div class="flex">
                        <Icon class="rightIcon color-blue content-vcenter block-inline" name="replay" size="20"
                           @click="doRefreshAction">
                            <div class="rightText">刷新</div>
                        </Icon>
                        <Icon class="rightIcon padding-left color-blue content-vcenter block-inline" name="cross" size="20"
                            @click="doClearAction">
                            <div class="rightText">清空</div>
                        </Icon>
                    </div>
                </template>
            </Cell>
            <div class="padding">
                <List class="border none bg-trans" v-model:loading="infosLoading" :finished="itemFinished"
                    finished-text="没有更多了">
                    <Highlight class="padding-top" v-for="runableInfoData in runableInfoDatas" :key="runableInfoData.id"
                        :keywords="convertRunnableInfos(runableInfoData.keywords)" :source-string="runableInfoData.infos"
                        ></Highlight>
                </List>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, defineProps, toRefs} from "vue";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
    itemInfo: {
        type: Object
    },
});
const { itemInfo} = toRefs(props);
const itemType = typeUtil.getItemTypeObj(itemInfo.value.type);
itemType.init(wsClient);
const infosLoading = ref(true);
const itemFinished = ref(false);
const runableInfoDatas =ref([]);
/**
 * 加载时初始化相关变量
 */
onMounted(async() => {
    runableInfoDatas.value =await itemType.getRunableItemInfos(itemInfo.value);
    infosLoading.value =false;
    itemFinished.value =true;
});

const convertRunnableInfos=(infos)=>{
    if(!infos) return "";
    if(infos.indexOf(","))
    {
        infos =infos.split(",");
        return infos;
    }
    else
    {
        return infos;
    }  
}

const doRefreshAction =async()=>{
    infosLoading.value =true;
    itemFinished.value =false;
    runableInfoDatas.value =await itemType.getRunableItemInfos(itemInfo.value);
    infosLoading.value =false;
    itemFinished.value =true;
}

const doClearAction =async()=>{
    let responseDatas =await itemType.clearRunableItemInfos(itemInfo.value);
    if (responseDatas.resultCode == 1) {
         doRefreshAction();
    }
}

</script>
<style scoped>
.rightText {
  font-size: 14px;
  align-self: center;
}
.rightIcon {
  align-self: center;
  display: inline-flex;
}
</style>