<template>
    <div class="bg-lightGrey height-full">
        <BackableNavBar leftText="返回" title="新建任务配置" :autoControl="false" :listenerState="backListenerState"
            @onBack="onClickLeft" />
        <WizardPane ref="newTaskWizard" :stepOptions="stepOptions"
            @onDataSubmit="doCreateTaskInfo">
        </WizardPane>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onActivated, watch } from "vue";
import {
    useRouter,
} from 'vue-router';
import TaskConfig from "@/components/app/views/setting/newTask/TaskConfig.vue";
import TaskRelateConfig from "@/components/app/views/setting/newTask/TaskRelateConfig.vue";
import TaskFullConfig from "@/components/app/views/setting/newTask/TaskFullConfig.vue";
import TaskConfigResult from "@/components/app/views/setting/newTask/TaskConfigResult.vue";
import { showToast } from "vant";

const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let stepOptions = ref([]);
let getWizardDatas = () => {
    return [
        {
            stepName: "基础配置",
            component: TaskConfig,
        },
        {
            stepName: "关联配置",
            component: TaskRelateConfig,
        },
        {
            stepName: "全局配置",
            component: TaskFullConfig,
        },
        {
            stepName: "创建完毕",
            component: TaskConfigResult,
        },
    ];
};
stepOptions = getWizardDatas();
const backListenerState = ref(true);
const newTaskWizard = ref(null);

let isNeedPageDatasUpdate=false;
onActivated(()=>{
  if(isNeedPageDatasUpdate)
  {
    newTaskWizard.value.updateDatas();
    isNeedPageDatasUpdate =false;
  }
});

const doCreateTaskInfo = async (datas) => {
    const responseDatas = await wsClient.sendActionToCloud("CREATE_TASK_INFO", datas);
    if (responseDatas.resultCode == 1) {
        onClickLeft();
    }
    let params = {
        message: responseDatas.message,
        position: "bottom",
    };
    showToast(params);
};
const onClickLeft=()=> {
    newTaskWizard.value.resetDatas();
    backListenerState.value = false;
    isNeedPageDatasUpdate =true;
    router.push({
        name: backTargetPage,
        state:{
            pageDatas:JSON.stringify(backTargetPageDatas)
        }
      });
};
</script>
<style scoped></style>
  