const BaseStepActionType = require("./BaseStepActionType");
module.exports = class AppLoadStepActionType extends BaseStepActionType {
    id = "AUTOSPIRIT_STEP_APPLOAD";
    name = "应用开关步骤";
    define="这是应用开关步骤，用于打开或者关闭应用";

    getDefaultData(){
        let data={
            targetAppID:"",
            operateType:0,
        };
        let resultData={...data,...super.getDefaultData()};
        return resultData;
    }

    getTabOptions(){
        let option=[
            {
                id: "AUTOSPIRIT_APPLOAD_PARAMETER",
                name: "应用参数",
                viewPath: "AppLoadParameter",
                index:1,
            }
        ];
        let resultOption =[...option,...super.getTabOptions()];
        resultOption.sort((option1,option2)=>{
            return option1.index-option2.index;
        });
        return resultOption;
    }
    /**
     * 获取对应步骤所映射的步骤活动类型的服务端保存的参数
     * @param {步骤活动类型的对象ID} id 
     * @returns 
     */
    getStepActionTypeInfosByID=async(id)=>{
        let resultData={};
        //基于类型调用对应接口获取对应的数据
        let url ="GET_APPLOADSTEPTYPE_INFOS"
        let queryData={
            stepActionType:{
            id:id
        }};
        //调用服务端接口获取对应对象的数据
        let responseDatas =await this.wsClient.sendActionToCloud(
            url,
            queryData
        );
        if(responseDatas.resultCode ==1)
        {
            resultData = responseDatas.resultData;
        }
        return resultData;
    };

    /**
     * 保存对应步骤所映射的步骤活动类型的参数到服务端
     * @param {步骤类型ID} stepActionTypeID 
     * @param {步骤活动类型对象} stepActionTypeInfos 
     * @returns 
     */
    saveStepActionTypeInfos=async(stepActionTypeInfos)=>{
        let responseDatas={};
        //基于类型调用对应接口保存对应的数据
        let url="UPDATE_APPLOADSTEPTYPE_INFOS"
        responseDatas =await this.wsClient.sendActionToCloud(
            url,
            stepActionTypeInfos
        );
        return responseDatas;
    };

    /**
     * 从服务器端删除对应步骤所映射的步骤活动对象
     * @param {步骤类型ID} stepActionTypeID 
     * @param {步骤活动类型的对象ID} id 
     * @returns 
     */
    deleteStepActionTypeInfos = async(id)=>
        {
            let responseDatas={};
            let url ="DELETE_APPLOADSTEPTYPE_INFOS"
            let queryData={
                id:id
            };
            responseDatas =await this.wsClient.sendActionToCloud(
                url,
                queryData
            );
            return responseDatas;
        };

      /**
     * 创建对应的步骤类型所映射的步骤活动对象到服务器
     * @param {步骤类型ID} stepActionTypeID 
     * @param {步骤活动类型对象} stepActionTypeInfos 
     * @returns 
     */
  createStepActionTypeInfos=async(stepActionTypeInfos)=>{
    let responseDatas={};
    let url="INSERT_APPLOADSTEPTYPE_INFOS"
    responseDatas =await this.wsClient.sendActionToCloud(
        url,
        stepActionTypeInfos
    );
    return responseDatas;
};       
}