<template>
    <!--变量面板-->
    <Cell class="bg-trans">
        <template #title>
             <!--变量类型选择按钮和弹出菜单-->
            <Popover :placement="popoverPlace" v-model:show="variableData.variableTypeShow" @open="adjustPopupPlace(placeBtn,variableData.variableTypeOptions)">
                <div ref="popover">
                    <CellGroup class="width-popupGroup">
                        <Cell clickable v-for="variableTypeOption in variableData.variableTypeOptions"
                            :key="variableTypeOption.id" v-model="variableTypeOption.selected" :title="variableTypeOption.name"
                            :class="variableTypeOption.selected ? 'selected' : ''"
                            @click="onVariableTypeSelected(variableData, variableTypeOption)">
                            <template v-if="variableTypeOption.selected" #right-icon>
                                <Icon name="success" />
                            </template>
                        </Cell>
                    </CellGroup>
                </div>
                <template #reference>
                    <div ref="placeBtn">
                        <Button v-if="!isDisabled" class="content-vcenter block-inline button ssm" type="primary" size="small">{{
                        variableData.variableType.name }}</Button>
                        <Button v-if="isDisabled" disabled class="content-vcenter block-inline button ssm" type="primary" size="small">{{
                        variableData.variableType.name }}</Button>
                    </div>
                </template>
            </Popover>
            <!--变量名称设置框-->
            <Field v-if="!isDisabled" :class="'content-vcenter block-inline border margin-left input-md ' + variableData.errorType"
                v-model="variableTitle" placeholder="变量名称"></Field>
            <Field v-if="isDisabled" readonly :class="'content-vcenter block-inline border margin-left input-md ' + variableData.errorType"
                v-model="variableTitle" placeholder="变量名称"></Field>
            <!--变量值设置框-->
            <Field  v-if="!isDisabled&&textFeildType==0" class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
               type="number" placeholder="数值"></Field>
            <Field v-if="isDisabled&&textFeildType==0" readonly class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
               type="number" placeholder="数值"></Field>
            <Field v-if="!isDisabled&&textFeildType==1" class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="字符串"></Field>
            <Field v-if="isDisabled&&textFeildType==1" readonly class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="字符串"></Field>
            <div v-if="!isDisabled&&textFeildType==2" class="content-vcenter block-inline">
                <Field class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="单坐标值"></Field>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small">...</Button>
            </div>
            <div v-if="isDisabled&&textFeildType==2" readonly class="content-vcenter block-inline">
                <Field class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="单坐标值"></Field>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small">...</Button>
            </div>
            <div v-if="!isDisabled&&textFeildType==3" class="content-vcenter block-inline">
                <Field  class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="多坐标值"></Field>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small">...</Button>
            </div>
            <div v-if="isDisabled&&textFeildType==3" readonly class="content-vcenter block-inline">
                <Field  class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="多坐标值"></Field>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small">...</Button>
            </div>
            <div v-if="!isDisabled&&textFeildType==4" class="content-vcenter block-inline">
                <Field  class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="截取图片"></Field>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small">选择图片区域</Button>
            </div>
            <div v-if="isDisabled&&textFeildType==4" readonly class="content-vcenter block-inline">
                <Field  class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="截取图片"></Field>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small">选择图片区域</Button>
            </div>
            <div v-if="!isDisabled&&textFeildType==5" class="content-vcenter block-inline">
                <Field class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="设置时间"></Field>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small" @click="showInputProsetPane(`日期`,dataValue,true)">日期</Button>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small" @click="showInputProsetPane(`时间`,timeValue,false)">分钟</Button>
            </div>
            <div v-if="isDisabled&&textFeildType==5" readonly class="content-vcenter block-inline">
                <Field class="content-vcenter block-inline border margin-left input-md" v-model="variableValue"
                placeholder="设置时间"></Field>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small" @click="showInputProsetPane(`日期`,dataValue,true)">日期</Button>
                <Button class="content-vcenter block-inlinebutton margin-left ssm" type="primary" size="small" @click="showInputProsetPane(`时间`,timeValue,false)">分钟</Button>
            </div>
        </template>
        <template #right-icon>
             <!--变量操作栏-->
            <div class="flex" v-if="!isDisabled">
                <Icon v-show="variableData.modify" class="rightIcon color-blue" name="passed" size="20"
                    @click="saveVariableData(variableData)" />
                <Icon class="rightIcon" :name="crossIcon" size="20"
                    @click="deleteVariableData(variableData)" />
            </div>
        </template>
    </Cell>
</template>
<script setup>
import {getCurrentInstance,watch, ref, onMounted, defineProps, toRefs, defineEmits, onUpdated } from "vue";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
import { showToast } from "vant";
import util from "@/utils/util";

const emits =defineEmits(['onVariableDelete']);

const props =defineProps({
    //元素显示配置项
    elementsHideConfig: {
        type: Object
    },
    isNewAction:{
        type:Boolean,
        default:false
    },
    isDisabled:{
        type:Boolean,
        default:false
    },
    variableData:{
        type:Object
    }
});
const {variableData,elementsHideConfig,isNewAction,isDisabled} =toRefs(props);
//变量名称
const variableTitle =ref(variableData.value.name);
//变量值
const variableValue =ref(variableData.value.value);
//日期值缓存，转换最终值使用
const dataValue =ref([]);
//时间值缓存，转换最终值使用
const timeValue =ref([]);

const placeBtn = ref(null);

const popover =ref(null);

const popoverPlace=ref("top-start");

/**
 * 变量输入字符类型
 * 0: 数字
 * 1：字符
 * 2：单坐标
 * 3：多坐标
 * 4：图片
 * 5：时间
*/
const textFeildType =ref(0);

watch(variableTitle,(newValue)=>{
    variableData.value.modify=true;
    variableData.value.name=newValue;
})
watch(variableValue,(newValue)=>{
    variableData.value.modify=true;
    variableData.value.value=newValue;
})

watch(variableData.value,(newValue)=>{
    variableTitle.value=variableData.value.name;
    variableValue.value=variableData.value.value;
})
const crossIcon = ref();

/**
 * 弹出日期时间选择输入对话框
 * @param {标题} tempTitle 
 * @param {默认值} tempValue 
 * @param {是否事日期弹框} isDate 
 */
const showInputProsetPane =(tempTitle, tempValue,isDate)=>{
    wsClient.broadcastEvents("AUTOSPIRIT_PROSETPANE_ACTION",{
        "title":tempTitle,
        "value":tempValue,
        'type': isDate?"datePicker":"timePicker",
        //格式
        'columnsType':isDate?['year','month','day']:['hour','minute','second'],
        "submit":isDate?onDateSubmit:onTimeSubmit,
    });
}

const adjustPopupPlace=(dom,options)=>{
    popoverPlace.value=util.adjustPopupPlace(dom,options);
}

/**
 * 日期弹框确认响应方法
 * @param {} tempVame 
 */
const onDateSubmit =(tempVame)=>{
    dataValue.value =tempVame;
    variableValue.value =getDateVairableValue();
}

/**
 * 时间弹框确认响应方法
 * @param {} tempVame 
 */
const onTimeSubmit =(tempVame)=>{
    timeValue.value =tempVame;
    variableValue.value=getDateVairableValue();
}

/**
 * 获取日期变量最终字符串
 */
const getDateVairableValue = () => {
    let resultStr=""
    let tempDateStr = conver2String(dataValue.value, true);
    let tempTimeStr = conver2String(timeValue.value, false);
    if (tempDateStr || tempTimeStr)
        resultStr = tempDateStr + "@" + tempTimeStr;
    return resultStr;
}

/**
 * 更新日期时间缓存变量，基于字符串解析
 */
const updateDateTimeValue=()=>{
    let tempText = variableValue.value;
    if(tempText&&tempText.indexOf("@")>0)
    {
        let values = tempText.split("@");
        let dateStr =values[0];
        dataValue.value =dateStr.split("/");
        let timeStr =values[1];
        timeValue.value=timeStr.split(":");
    }
    
};

/**
 * 将日期时间控件字符串转换成对应格式
 * @param {*} dateArr 
 * @param {*} isDate 
 */
const conver2String=(dateArr,isDate)=>{
    let resultStr="";
    let prefixStr =isDate?"/":":";
    for(let i =0;i<dateArr.length;i++)
    {
        let str =dateArr[i];
        if(i==0)
        {
            resultStr =resultStr+str;
        }
        else
        {
            resultStr=resultStr+prefixStr+str;
        }
        
    }
    return resultStr;
}


/**
 * 响应变量类型选择，修正新旧类型的选择状态，更新修正状态
 * @param {变量值} variableData 
 * @param {变量类型选项} variableTypeOption 
 */
const onVariableTypeSelected = (variableData, variableTypeOption) => {
    variableData.variableType.selected = false;
    variableData.variableType = variableTypeOption;
    variableData.variableType.selected = true;
    variableData.variableTypeShow = false;
    variableData.modify = true;
    updateTextFeildType(variableTypeOption);
};
/**
 * 响应保存变量动作
 * @param {变量值} variableData 
 */
const saveVariableData = async (variableData) => {
    //如果有校验动作
    if (variableData.validate) {
        let checkResult = variableData.validate();
        let resultCode = checkResult.resultCode;
        //代表成功
        if (resultCode == 1) {
            variableData
            let responseDatas = await variableData.saveData();
            if (responseDatas.resultCode == 1) {
                variableData.modify = false;
            }
            let params = {
                message: responseDatas.message,
                position: "bottom",
            };
            if(!isNewAction.value)showToast(params);
        }
        else {
            if(!isNewAction.value)
            {
                showToast({
                    message: checkResult.message,
                    position: "bottom",
                });
            }
            
        }
    }
};
/**
 * 响应变量删除动作，并触发事件
 * @param {变量值} variableData 
 */
const deleteVariableData = async (variableData) => {
    let resultData = await variableData.deleteData();
    if (resultData.resultCode == 1) {
        emits("onVariableDelete",variableData);
    }
    if (!resultData.noNeedShowToast) {
        showToast({
            message: resultData.message,
            position: "bottom",
        });
    }

};

/**
 * 基于选择的变量类型更新对应的输入区域空间类型的ID
 * @param {*} variableTypeOption 
 */
const updateTextFeildType=(variableTypeOption)=>{
    switch (variableTypeOption.id) {
        case "VARIABLE_NUMBER":
            textFeildType.value = 0;
            break;
        case "VARIABLE_CHAR":
            textFeildType.value = 1;
            break;
        case "VARIABLE_PLACE_POINT":
            textFeildType.value = 2;
            break;
        case "VARIABLE_PLACE_POINTS":
            textFeildType.value = 3;
            break;
        case "VARIABLE_IMAGE":
            textFeildType.value = 4;
            break;
        case "VARIABLE_DATE":
            textFeildType.value = 5;
            //如果是日期时间类，则更新缓存
            updateDateTimeValue();
            break;
        default:
            textFeildType.value = 0;
    }
};
onMounted(()=>{
    crossIcon.value = wsClient.getIconBase64Str("ROUND_CROSS");
    //遍历设置初始选中类型的状态
    for(let variableTypeOption of variableData.value.variableTypeOptions)
    {
        if(variableData.value.variableType.id==variableTypeOption.id)
        {
            variableData.value.variableType.selected = false;
            variableData.value.variableType = variableTypeOption;
            variableData.value.variableType.selected = true;
        }
    }
    variableValue.value =variableData.value.value;
    updateTextFeildType(variableData.value.variableType);
    adjustPopupPlace(placeBtn.value,variableData.value.variableTypeOptions);
});
onUpdated(()=>{
    adjustPopupPlace(placeBtn.value,variableData.value.variableTypeOptions);
});
</script>
<style scoped>
.rightIcon {
    align-self: center;
    display: inline-flex;
    margin-left: 5px;
}
</style>