<template>
    <!--快捷信息面板-->
    <div class="padding">
        <div class="flex bg-white border solid radius padding">
            <div>
                <Image class="flex center as-avatar xl" :src="defaultIcon"></Image>
            </div>
            <div>
                <Cell center :title="pageInfo.name" :label="pageInfo.define"></Cell>
            </div>
        </div>
    </div>
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans" title="基础配置："></Cell>
                <Cell title="名称" :value="pageInfo.name"></Cell>
                <Field v-show="pageInfo.define" readonly autosize type="textarea" show-word-limit class="bg-trans"
                    v-model="pageInfo.define" />
                <Cell center title="场景类别" :value="getItemInfoSencneString()"></Cell>
                <Cell :title="pageInfo.commom ? '常用状态：开启' : '常用状态：关闭'"
                    class="bg-trans">
                    <template #right-icon>
                        <Switch disabled v-model="pageInfo.commom" size="24" />
                    </template>
                </Cell>
            </CellGroup>
        </div>
    </div>
    <!-- <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans" title="场景配置："></Cell>
                <Cell center title="场景类别" :value="getItemInfoSencneString()"></Cell>
            </CellGroup>
        </div>
    </div> -->
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans" title="高级信息:"></Cell>
                <Tabs shrink v-model:active="active" background="transparent">
                    <Tab title="变量设置">
                        <template #title> <span class="itemTab">变量设置</span></template>
                        <!--变量设置面板-->
                        <ParameterSetting ref="variablePane" :elementsHideConfig="elementsHideConfig" :isNewAction="true"
                            :itemVariables="variableDatas" :itemInfo="pageInfo"></ParameterSetting>
                    </Tab>
                </Tabs>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, defineProps, toRefs, onMounted } from "vue";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let pageInfo = firsData.itemInfo ? firsData.itemInfo : {};
let sencne = firsData.sencne ? firsData.sencne : {};
let sencneType = firsData.sencneType ? firsData.sencneType : {};
let secondData = tempOperateDatas[1];
const variableDatas = ref(secondData.variableDatas ? secondData.variableDatas : []);
const active = ref(0);
const elementsHideConfig = {
    operateBar: true,
    addVariableBtn: true,
    variableOperateBtns: true,
};
//默认的Task图标
const defaultIcon = ref("");
const variablePane =ref(null);

const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.name + '】';
}

onMounted(async () => {
    defaultIcon.value = wsClient.getIconBase64Str("PAGE_ICON");
});

const validateOnFinish = () => {
    let variables = [];
    for (let tempVariableData of variableDatas.value) {
        let tempVariable = {
            name: tempVariableData.name,
            value: tempVariableData.value,
            dataValue: tempVariableData.dataValue,
            type: tempVariableData.variableType.id,
            relateItemType: pageInfo.type,
        }
        variables.push(tempVariable);
    }
    pageInfo.variables = variables;
    return pageInfo;
}

const resetDatas=()=>{
  variableDatas.value=[];
  if(variablePane.value)
  {
     variablePane.value.resetDatas();
  }
}

const updateDatas = () => {
    let tempOperateDatas = operateDatas.value;
    let firsData = tempOperateDatas[0];
    pageInfo = firsData.itemInfo ? firsData.itemInfo : {};
    sencne = firsData.sencne ? firsData.sencne : {};
    sencneType = firsData.sencneType ? firsData.sencneType : {};
    let secondData = tempOperateDatas[1];
    variableDatas.value = secondData.variableDatas ? secondData.variableDatas : [];
};

defineExpose({
    resetDatas,
    updateDatas,
    validateOnFinish,
});
</script>
<style scoped>
.itemTab {
    font-size: 15px;
}
</style>