// const typeUtil = require("@/components/app/views/common/configPane/itemType/itemTypeUtil.js");
const StepActionTypeOperator =require("./StepActionTypeOperator");
module.exports = class NewStepActionTypeOperator extends StepActionTypeOperator {
    refreshOccusConditions = async () => {
        this.handler.occusConditionMappings.value = this.handler.occusConditionMappings.value;
    };
    deleteOccusCondtionMapping = async (occusConditionMapping) => {
        let occusConditionMappings = this.handler.occusConditionMappings.value;
        let index = 0;
        for (let tempConditionMapping of occusConditionMappings) {
            if (tempConditionMapping.id == occusConditionMapping.id) {
                break;
            }
            index++;
        }
        this.handler.occusConditionMappings.value.splice(index, 1);
    };
    onAddOccusEventCondition = async (eventDatas) => {
        let mapping = eventDatas.occusEventConditionMapping;
        this.handler.occusConditionMappings.value.push(mapping);
        // let newOccusCondition = mapping.stepActionInfo;
        // let tempID = newOccusCondition.id;
        // newOccusCondition.tempStrID = tempID;
        // delete newOccusCondition.id;
        // let itemActionType = typeUtil.getStepActionTypeObj(newOccusCondition.stepActionTypeID);
        // itemActionType.init(this.handler.wsClient);
        //创建条件判断对象
        // let responseDatas = await itemActionType.createStepActionTypeInfos(newOccusCondition);
        // if (responseDatas.resultCode == 1) {
        //     let conditionID = responseDatas.resultData.id;
        //     //条件映射ID
        //     mapping.actionStepItemID = conditionID;
        //     let conditionProMappings = mapping.varaiableProMappings;
        //     //更新条件属性映射ID
        //     for (let variableProMapping of conditionProMappings) {
        //         variableProMapping.itemID = conditionID;
        //     }
        //     //创建条件属性映射
        //     responseDatas = await this.handler.wsClient.sendActionToCloud("CREATE_VARIABLEPROMAPPING_INFOS", {
        //         variableProMappings: conditionProMappings
        //     });
        // }
        // delete mapping.id;
        // responseDatas = await this.handler.wsClient.sendActionToCloud("CREATE_OCCUSEVENTCONDITIONMAPPINGS", {
        //     occusConditionMappings: [mapping]
        // });
        // return responseDatas;
    };
    //删除控制条件数据
    deleteConditionData = async (condtion) => {
            let index = 0;
            for (let tempCondition of this.handler.conditionOperateDatas.value) {
                if (tempCondition.id == condtion.id) {
                    break;
                }
                index++;
            }
            this.handler.conditionOperateDatas.value.splice(index, 1);
    };
    
    refreshOperateConditions = async () => {
        this.handler.conditionOperateDatas.value = this.handler.conditionOperateDatas.value
    };
    onConditionValueChanged = async (eventData) => {
        let conditionData = this.getConditionDataByID(eventData.id);
        let changedParamName = eventData.changedParamName;
        let value = eventData.value;
        conditionData[changedParamName] = value;
    };
    onActionStepParamChanged=(eventData)=>{
        let key = eventData.paramName;
        let value = eventData.paramValue;
        this.handler.stepActionInfos.value[key] = value;
    };
    onMappingChanged=(eventData) => {
        let key = eventData.paramName;
        let value = eventData.paramValue;
        let mapping = eventData.mapping;
        this.handler.stepActionInfos.value[key] = value;
        // console.log(mapping);
        //如果有ID，说明这个属性之前就有变量关联，直接更新即可
        if (mapping.id) {
            //如果存在映射ID，则更新，否则删除
            if (mapping.variableID) {
                let index=0;
                for(let tempMapping of this.handler.variableProsMappings.value)
                {
                    if(mapping.id ==tempMapping.id)
                    {
                        this.handler.variableProsMappings.value[index]=mapping;
                        break;
                    }
                    index++;
                }
            }
            else {
                let index=0;
                for(let tempMapping of this.handler.variableProsMappings.value)
                {
                    if(mapping.id ==tempMapping.id)
                    {
                        break;
                    }
                    index++;
                }
                this.handler.variableProsMappings.value.splice(index, 1);
            }
        }
        else {
            let timestamp = new Date().getTime();
            mapping.id =timestamp;
            mapping.itemID = this.handler.stepActionInfos.value.id;
            mapping.itemType = this.handler.stepActionInfos.value.stepActionTypeID;
            mapping.variableProName = key;
            this.handler.variableProsMappings.value.push(mapping);
            // console.log(this.handler.variableProsMappings.value);
        }
    };

}