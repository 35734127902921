<template>
  <!--用户信息页面，用于设置用户的一些相关配置-->
  <div class="height-full bg-lightGrey">
    <div class="padding">
      <div class="bg-white border solid radius padding">
				<div class="flex-inline content-vcenter padding">
					<Image class="as-avatar xl" round :src="logoIcon" />
				</div>
				<div class="flex-inline content-vcenter padding">
						<Cell class="bg-trans" :border="false" center :title="'用户名: '+userAccount" :label="vipMsg"></Cell>
				</div>
			</div>
    </div>
    <div class="padding">
      <div class="bg-white border solid radius padding">
        <CellGroup class="cellItem">
          <Cell class="cellItem" center is-link title="账户" icon="contact" @click="goPersonalSetting"></Cell>
          <Cell class="cellItem" center is-link title="设置" icon="setting-o"></Cell>
          <Cell class="cellItem" center is-link title="管理员设置" icon="manager-o" @click="goManagerSetting"></Cell>
        </CellGroup>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance,ref,onMounted} from "vue";
import {
  useRouter,
} from 'vue-router';
const router = useRouter();
const goPersonalSetting = () => {
  router.push({ 
    name: "PersonalSetting",
    state:{
      pageDatas:JSON.stringify({
        backTargetPageDatas:{active:3},
        backTargetPage:"Content"
      })
    }
  });
};
const goManagerSetting = () => {
  router.push({ 
    name: "ManagerSetting",
    state:{
      pageDatas:JSON.stringify({
        backTargetPageDatas:{active:3},
        backTargetPage:"Content"
      })
    }
  });
};
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const userAccount = wsClient.getItemFromStorage("phone",true);
const logoIcon =ref("");
//用户的vip信息
const vipMsg = ref("VIP剩余时间:永久");
onMounted(()=>{
   logoIcon.value = wsClient.getIconBase64Str("LOGO_ICON");
});
</script>
<style scoped>
</style>