<template>
  <!--app注册面板-->
  <div class="as-vcenter">
    <Row justify="space-around">
      <Image class="as-avatar" round :src="logoIcon" />
    </Row>
    <Row justify="space-around" class="margin-top xxl">
      <div class="lex-inline" :style="{ height: computeHeight }">
        <Form @submit="onSubmit">
          <CellGroup inset>
            <Field v-model="username" name="phone" label="用户名" placeholder="请输入手机号码" type="tel" :rules="[
              { required: true, message: '请输入手机号码' },
              { validator: validatorPhone },
            ]" />
            <Field v-model="password" type="password" name="password" label="密码" placeholder="请输入密码"
              :rules="[{ required: true, message: '请输入密码' }]" />
            <Field v-model="password_sure" type="password" name="password_sure" label="确认密码" placeholder="请输入密码" :rules="[
              { required: true, message: '请再次输入密码' },
              { validator: validatorPassword },
            ]" />
          </CellGroup>
          <Button block class="margin-top" type="primary" native-type="submit">
            注册
          </Button>
          <Button class="margin-top" block plain type="primary" @click="gotoLoginPage">
            返回登录
          </Button>
        </Form>
      </div>
    </Row>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance,computed,onMounted} from "vue";
import { showToast } from "vant";
import {
  useRouter,
} from 'vue-router';
const username = ref("");
const password = ref("");
const password_sure = ref("");
const computeHeight = computed(() => {
  return window.innerHeight * 0.35 + "px";
});
const router = useRouter();
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const logoIcon =ref("");

onMounted(()=>{
   logoIcon.value = wsClient.getIconBase64Str("LOGO_ICON");
});
/**
 * 响应注册提交
 * @param {注册信息} values 
 */
const onSubmit = async (values) => {
  let responseDatas = await wsClient.sendActionToCloud(
    "USER_REGIST",
    values
  );
  let isSuccess = responseDatas.resultCode == 1;
  //注册成功后跳转到登录页面
  if (isSuccess) {
    gotoLoginPage();
  }
  let params = {
    message: responseDatas.message,
    position: "bottom",
  };
  showToast(params);
};
/**
 * 校验号码格式
 * @param {号码字符} value 
 */
const validatorPhone = (value) => {
  let validator =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  return !validator.test(value) ? "请输入正确格式的手机号码！" : "";
};
/**
 * 校验输入密码
 * @param {密码} value 
 */
const validatorPassword = (value) => {
  return password.value == value ? "" : "两次输入的密码不一致！";
};
/**
 * 跳转到登录页面
 */
const gotoLoginPage = () => {
  router.push({
    name: "Login"
  });
};
</script>
<style scoped></style>