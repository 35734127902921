const BaseRelateItemOperator = require('./BaseRelateItemOperator');
const util = require("@/utils/util");
const optionsConfig = require("@/components/app/views/common/stepType/OptionsConfig.js");
const typeUtil = require("@/components/app/views/common/configPane/itemType/itemTypeUtil.js");
const { showToast } = require("vant");
/**
 * 页面关联元素操作器
 */
module.exports = class PageTypeRelateOperator extends BaseRelateItemOperator {
  id = "PAGEINFO_TYPE_OPERATOR";

  /**
   * 获取页面操作元素
   * @returns 
   */
  getOperateOptions(){
    return util.cloneObject(optionsConfig.pageRouteSetOperateOptions);
  }
  /**
* 响应关联按钮操作事件
* @param {事件对象} eventData 
*/
  doRelateOperateAction(eventData) {
    let operateOption = eventData.operateOption;
    let relateMapping = eventData.relateMapping;
    let operateID = operateOption.id;
    switch (operateID) {
      //设置关联路径事件
      case "RELATEMAPPING_ITEM_SET_ROUTE":
        this.doItemPageRouteSetAction(relateMapping, eventData);
        break;
      //查看关联路径事件
      case "RELATEMAPPING_ITEM_WATCH_ROUTE_INFOS":
        this.doWatchRouteInfoActionByMapping(relateMapping, eventData);
        break;
      default:
        super.doRelateOperateAction(eventData);
        break;
    };
  };

  /**
   * 响应查看关联路径事件
   * @param {关联页面映射} relateMapping 
   * @param {*} eventData 
   */
  doWatchRouteInfoActionByMapping = (relateMapping, eventData) => {
     let relatePage2PageMapping =eventData.relatePage2PageMapping;
     let routeInfoMapping =relatePage2PageMapping.routeInfoMapping;
     let itemInfo =relatePage2PageMapping.routeInfoMapping.itemInfo;
     this.doWatchRouteInfoAction(itemInfo,routeInfoMapping);
  };

  /**
   * 查看关联的路径事件
   * @param {*} itemInfo 
   * @param {*} relateMapping 
   */
  doWatchRouteInfoAction = (itemInfo,relateMapping) => {
     let datas = {
       itemInfo: itemInfo,
       backTargetPage: this.handler.getBackPageName(),
       backTargetPageDatas: this.handler.getBackPageDatas()
     };
     //查看的是步骤，则传递相关信息到步骤页面
     this.handler.getRouter().push({
       name: this.handler.getWatchedPageName(relateMapping),
       state: {
         pageDatas: JSON.stringify(datas)
       }
     });
  };
  
   /**
    * 获取显示的操作元素信息状态
    * @param {*} relateOperateOption 
    * @param {*} eventData 
    * @returns 
    */
   getOptionVisableState=(relateOperateOption,eventData)=>{
      let tempPage2PageMapping =eventData.page2PageMapping;
      let relateMappingObj =eventData.relateMappingObj;
      //判断查看路径信息和测试路径信息的操作状态
      if(relateOperateOption.id =="RELATEMAPPING_ITEM_WATCH_ROUTE_INFOS"||relateOperateOption.id =="RELATEMAPPING_ITEM_TEST_ROUTE")
      {
          return tempPage2PageMapping.routeInfoMapping?true:false;
      }
      return super.getOptionVisableState(relateOperateOption,relateMappingObj);
  };

  /**
   * 响应页面路径设置事件
   * @param {*} relateMapping 
   * @param {*} eventData 
   */
  doItemPageRouteSetAction = (relateMapping, eventData) => {
    //过滤规则
    let itemsExcludeArr = [];
    let relatePage2PageMapping = eventData.relatePage2PageMapping;
    let startPageInfo = relatePage2PageMapping.startPage;
    let endPageInfo = relatePage2PageMapping.endPage;
    const wsClient = this.handler.getClientServer();
    const router = this.handler.getRouter();

    //构建元素选择页面数据对象
    let datas = {
      backTargetPage: this.handler.getBackPageName(),
      backTargetPageDatas: this.handler.getBackPageDatas(),
      eventName: "AUTOSPIRIT_APPINFO_PAGEROUTESELECT_EVENT",
      elementsHideConfig: {
        badge: true,
        addBtn: true,
      },
      itemsExclude: itemsExcludeArr,
      itemsType: ["ROUTE_TYPE"],
      selectedMode: true,
      singleSelectedMode: true,
      pageType: "1",
      pageTitle: "路径选择",
    };
    //相应选择确认事件的方法，执行关联增加动作
    const actionMethod = async (selectedItems) => {
      let selectedRouteItem = selectedItems[0];
      // selectedRouteItem.appID = appInfo.id;
      selectedRouteItem.startPageID = startPageInfo.id;
      selectedRouteItem.endPageID = endPageInfo.id;
      let lastRouteMapping = relatePage2PageMapping.routeInfoMapping;
      const routeType = typeUtil.getItemTypeObj('ROUTE_TYPE');
      routeType.init(wsClient, router);
      let responseDatas = {
        resultCode: 1
      };
      let newRelateMapping = {
        parentID: relateMapping.itemInfo.id,
        parentType: relateMapping.itemInfo.type,
        relateID: selectedRouteItem.id,
        relateType: selectedRouteItem.type,
        disable: false
      };
      //先删除旧的关联，再关联新的
      if (lastRouteMapping) {
        responseDatas = await this.deleteRelateMapping(lastRouteMapping);
        let tempRelateMapping = {
          parentID: lastRouteMapping.parentID,
          parentType: lastRouteMapping.parentType,
        };
        newRelateMapping = { ...newRelateMapping, ...tempRelateMapping };
      }
      let queryData = {
        relateMappings: [newRelateMapping],
        insertIndex: 0
      };
      responseDatas = await this.createRelateMapping(queryData);
      responseDatas = await routeType.saveData(selectedRouteItem);
      //成功后返回之前的选择页面
      if (responseDatas.resultCode == 1) {
        router.push({
          name: datas.backTargetPage,
          state: {
            pageDatas: JSON.stringify(datas.backTargetPageDatas)
          }
        });
      }
      let params = {
        message: responseDatas.message,
        position: "bottom",
      };
      showToast(params);
    };
    datas.listenerID=wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
      name: "ItemSelect",
      state: {
        pageDatas: JSON.stringify(datas)
      }
    });
  };

  /**
   * 转换生成页面到页面的映射对象
   * @param {*} itemRelateMappings 
   * @param {*} relateOperatorOptions 
   * @returns 
   */
  async convertToPageToPageObj(itemRelateMappings,relateOperatorOptions){
    let resultRelateMapping =[];
    const wsClient = this.handler.getClientServer();
    const router = this.handler.getRouter();
    
    const pageType = typeUtil.getItemTypeObj('PAGE_TYPE');
    pageType.init(wsClient,router);
    //遍历页面映射元素
    for(let itemRelateMapping of itemRelateMappings)
    {
      let relateMappings=[];
      let page2pageMapping=[];
      if (itemRelateMapping.relateType == "PAGE_TYPE") {
          //获取该页面下的路径关联信息
          relateMappings=await pageType.getItemRelateChildMapping(itemRelateMapping.itemInfo);
          //页面到页面缓存信息
          let routeCache ={};
          //遍历路径，生成页面到页面Key值，缓存
          for(let tempRouteMapping of relateMappings)
          {
              let key =tempRouteMapping.itemInfo.startPageID+"@"+tempRouteMapping.itemInfo.endPageID;
              routeCache[key] =tempRouteMapping;
          }
          let appItemInfo =itemRelateMapping.parentItemInfo;
          let parentPageMappings=itemRelateMappings;
           //再次遍历所有页面信息，生成各个页面之间的路径映射对象，如两个页面A,B，则会生成 A->B,B->A两条路径
          for(let parentPageMapping of parentPageMappings)
          {
              //不同的两个页面才生成页面路径
              if(parentPageMapping.relateID!=itemRelateMapping.itemInfo.id)
              {
                //构建页面到页面映射对象
                  let page2pageMappingObj ={
                      id:itemRelateMapping.itemInfo.id+"@"+parentPageMapping.relateID,
                      index:itemRelateMapping.index+"."+(page2pageMapping.length+1),
                      appInfo:appItemInfo,
                      startPage:itemRelateMapping.itemInfo,
                      endPage:parentPageMapping.itemInfo,
                      variableTypeShow:false
                  }
                  let key = page2pageMappingObj.startPage.id+"@"+page2pageMappingObj.endPage.id;
                   //如果缓存中存在，说明该页面下面的页面映射原来就存在
                  if(routeCache[key])
                  {
                      page2pageMappingObj.oldRouteInfoMapping =routeCache[key];
                      page2pageMappingObj.routeInfoMapping =routeCache[key];
                  }
                  page2pageMapping.push(page2pageMappingObj);
              }
          }
      }
      itemRelateMapping.page2pageMapping =page2pageMapping;
      resultRelateMapping.push(itemRelateMapping);
    }
    return resultRelateMapping;
  }

  /**
* 更新当前任务最新的映射队列，并重新排序
* @param {映射队列字符串} newSequence 
*/
  updateSequenceAndSort = async (newSequence) => {
    let itemInfo =this.handler.getItemInfo();
    itemInfo.sequence = newSequence;
    let taskRelateMappings = this.handler.getRelateMappings();
    let queryData = {
      id: itemInfo.id,
      sequence: newSequence
    };
    let responseDatas = await this.handler.getClientServer().sendActionToCloud(
      "UPDATE_PAGEINFOS_SEQUENCE",
      queryData
    );
    if (responseDatas.resultCode == 1) {
      itemInfo.sequence = newSequence;
      this.sortRelateMappings(newSequence, taskRelateMappings);
    }
  }

}