<template>
    <div class="bg-lightGrey height-full">
        <div class="padding">
            <div class="flex bg-white border solid radius padding">
                <div>
                    <Image class="flex center as-avatar xl" :src="appInfo.iconDataBase64"></Image>
                </div>
                <div>
                    <Cell center :title="appInfo.name" :label="appInfo.define"></Cell>
                </div>
            </div>
        </div>
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础信息："></Cell>
                    <Cell center title="名称" :value="appInfo.name"></Cell>
                    <Cell center title="描述">
                    </Cell>
                    <Field v-show="appInfo.define" readonly autosize type="textarea" show-word-limit class="bg-trans"
                        v-model="appInfo.define" />
                    <Cell center title="场景类别" :value="getItemInfoSencneString()">
                    </Cell>
                    <Cell v-show="sencneType.name == '常用'" v-bind:title="appInfo.commom ? '常用状态：开启' : '常用状态：关闭'"
                        class="bg-trans">
                        <template #right-icon>
                            <Switch disabled v-model="appInfo.commom" size="24" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <!-- <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景信息："></Cell>
                    <Cell center title="场景类别" :value="getItemInfoSencneString()">
                    </Cell>
                </CellGroup>
            </div>
        </div> -->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="高级信息:"></Cell>
                    <Tabs shrink v-model:active="active" background="transparent">
                        <!--任务操作页签面板-->
                        <Tab title="任务设置">
                            <template #title> <span class="itemTab">任务设置</span></template>
                            <RelateSelectPane :itemRelateMappings="itemRelateMappings"
                                :elementsHideConfig="elementsHideConfig" />
                        </Tab>
                        <Tab title="路径设置">
                            <template #title> <span class="itemTab">路径设置</span></template>
                            <AppPageRouteSetting :itemRelateMappings="pageRelateObjectMappings"
                                :elementsHideConfig="elementsHideConfig"
                                />
                        </Tab>
                        <Tab title="变量设置">
                            <template #title> <span class="itemTab">变量设置</span></template>
                            <!--变量设置面板-->
                            <ParameterSetting ref="variablePane" :elementsHideConfig="elementsHideConfig" :isNewAction="true"
                                :itemVariables="variableDatas" :itemInfo="appInfo"></ParameterSetting>
                        </Tab>
                    </Tabs>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, defineExpose, defineProps, toRefs} from "vue";
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let appInfo = firsData.itemInfo ? firsData.itemInfo : {};
let sencne = firsData.sencne ? firsData.sencne : {};
let sencneType = firsData.sencneType ? firsData.sencneType : {};
let secondData = tempOperateDatas[1];
appInfo.sequence = secondData.sequence ? secondData.sequence : "";
appInfo.mainPageID =secondData.mainPageID;
let thirdData = tempOperateDatas[2];
const variableDatas = ref(thirdData.variableDatas ? thirdData.variableDatas : []);
const itemRelateMappings = ref(secondData.itemRelateMappings ? secondData.itemRelateMappings : []);
const pageRelateObjectMappings = ref(secondData.pageItemRelateMappings ? secondData.pageItemRelateMappings : []);
const active = ref(0);
const elementsHideConfig = {
    operateBar: true,
    addVariableBtn: true,
    variableOperateBtns: true,
};
const variablePane =ref(null);

const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.name + '】';
}


const validateOnFinish = () => {
    let relateMappings = [];
    for (let tempRelateMapping of itemRelateMappings.value) {
        let tempMapping = {
            parentType: appInfo.type,
            relateID: tempRelateMapping.relateID,
            relateType: tempRelateMapping.relateType,
            disable: tempRelateMapping.disable
        }
        relateMappings.push(tempMapping);
    }
    let variables = [];
    for (let tempVariableData of variableDatas.value) {
        let tempVariable = {
            name: tempVariableData.name,
            value: tempVariableData.value,
            dataValue: tempVariableData.dataValue,
            type: tempVariableData.variableType.id,
            relateItemType: appInfo.type,
        }
        variables.push(tempVariable);
    }
    let deleteRouteItemMappings = [];
    let newRouteItemMappings = [];
    for (let pageRelateObjectMapping of pageRelateObjectMappings.value) {
        let page2pageMappings =pageRelateObjectMapping.page2pageMapping;
        for(let page2pageMapping of page2pageMappings)
        {
            let oldRouteItemMapping =page2pageMapping.oldRouteInfoMapping;
            let newRouteItemMapping =page2pageMapping.routeInfoMapping;
            //当新的路径设置存在的情况下，代表可能产生了设置的变化，也有可能是之前就有设置
            if(newRouteItemMapping)
            {
                //如果旧的设置存在
                if(oldRouteItemMapping)
                {
                    //当旧的设置存在时，如果新旧设置的id不一致，则代表产生了设置变化
                    if(oldRouteItemMapping.id !=newRouteItemMapping.id)
                    {
                        //旧的设置添加到删除队列
                        deleteRouteItemMappings.push(oldRouteItemMapping);
                        //新的设置添加到新增队列
                        newRouteItemMappings.push(newRouteItemMapping);
                    }
                }
                //如果旧的设置不存在，说明之前没有设置路径，直接将新的设置添加到队列
                else
                {
                    newRouteItemMappings.push(newRouteItemMapping);
                }
            }
            //当新的路径设置不存在，则有两种可能，一种是旧设置就未设置任何的路径，无需处理，一种可能是有旧设置，但产生了新的设置，原路径被清空了
            else
            {
                //旧的设置存在，则说明原来是有设置的，但是产生了新设置，旧设置newRouteItemMappings被清空了
                if(oldRouteItemMapping)
                {
                    //旧的设置添加到删除队列
                    deleteRouteItemMappings.push(oldRouteItemMapping);
                }
            }
        }
    }
    appInfo.relateMappings = relateMappings;
    appInfo.variables = variables;
    appInfo.deleteRouteItemMappings=deleteRouteItemMappings;
    appInfo.newRouteItemMappings=newRouteItemMappings;
    return appInfo;
};

const resetDatas=()=>{
  itemRelateMappings.value=[];
  pageRelateObjectMappings.value=[];
  variableDatas.value=[];
  if(variablePane.value)
  {
     variablePane.value.resetDatas();
  }
}

const updateDatas = () => {
    let tempOperateDatas = operateDatas.value;
    let firsData = tempOperateDatas[0];
    appInfo = firsData.itemInfo ? firsData.itemInfo : {};
    sencne = firsData.sencne ? firsData.sencne : {};
    sencneType = firsData.sencneType ? firsData.sencneType : {};
    let secondData = tempOperateDatas[1];
    appInfo.sequence = secondData.sequence ? secondData.sequence : "";
    appInfo.mainPageID = secondData.mainPageID;
    let thirdData = tempOperateDatas[2];
    variableDatas.value = thirdData.variableDatas ? thirdData.variableDatas : [];
    itemRelateMappings.value = secondData.itemRelateMappings ? secondData.itemRelateMappings : [];
    pageRelateObjectMappings.value =secondData.pageItemRelateMappings ? secondData.pageItemRelateMappings : [];
};

defineExpose({
    resetDatas,
    updateDatas,
    validateOnFinish,
});
</script>
<style scoped>
.itemTab {
    font-size: 15px;
}
</style>