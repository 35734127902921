
/**
 * 工具类
 */
class Util {

  adjustPopupPlace=(dom,options)=>{
    const rect= dom.getBoundingClientRect();
    let optionNumber= options.length+1;
    let popupHeight = optionNumber*44;
    let popupWidth = 180;
    let top =rect.top;
    let left =rect.left;
    let prefix =top-popupHeight<0?"bottom":"top";
    let afrfix =left-popupWidth<0?"start":"end";
    let result =prefix+"-"+afrfix;
    return result;
}

  getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }
  /**
 * 将id数组转化成字符串队列
 * @param {id的数组} ids 
 */
  makeSequenceStr(ids) {
    let result = "";
    let index = 0;
    for (let id of ids) {
      if (index == 0) {
        result = id;
      }
      else if (index <= ids.length) {
        result = result + "," + id;
      }
      index++;
    }
    return result;
  };

  cloneObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  setRgbTo16(str) {
    let reg = /^(rgb|RGB)/;
    if (!reg.test(str)) { return; }
    var arr = str.slice(4, str.length - 1).split(",")
    let color = '#';
    for (var i = 0; i < arr.length; i++) {
      var t = Number(arr[i]).toString(16)
      if (t == "0") {   //如果为“0”的话，需要补0操作,否则只有5位数
        t = t + "0"
      }
      color += t;
    }
    return color;
  }

  handleColors() {
    let color = ''
    let r = Math.floor(Math.random() * 256)
    let g = Math.floor(Math.random() * 256)
    let b = Math.floor(Math.random() * 256)
    color = `rgb(${r},${g},${b})`
    return this.setRgbTo16(color);// 所有方法的拼接都可以用ES6新特性`其他字符串{$变量名}`替换
  }
  // 将base64转换为blob
  dataURLtoBlob(dataurl) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = window.atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  blobToUrl(blob) {
    let url = window.URL.createObjectURL(blob)
    return url;
  }
  getImageBase64FromBytes(data) {
    return new Promise((resolve, reject) => {
      const blob = new Blob([data], { type: "image/jpg" }); // 必须指定type类型
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  getIconBase64(iconPath) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = iconPath;
      image.setAttribute("crossOrigin", 'anonymous');
      image.onload = () => {
        let base64 = this.getBase64FromImage(image);
        resolve(base64);
      };
    });
  }
  getBase64FromImage(img) {
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    let dataURL = canvas.toDataURL("image/" + ext);
    return dataURL;
  }
  getImageDataUrlFromBase64(base64) {
    if(!base64)return "";
    let type = this.getBase64IconType(base64);
    let DataUrl = "data:image/" + type + ";base64," + base64;
    return DataUrl;
  }
  getBase64FromDataURL(dataURL) {
    let base64 = dataURL.substring(dataURL.lastIndexOf(",") + 1);
    return base64;
  }
  getBytesFromBase64(base64) {
    var binary_string = window.atob(base64);//解码使用base64编码的字符串
    var len = binary_string.length;			//获取长度
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  getBase64IconType(fileBase64) {
    let fileHeader = new Map();
    //获取不同文件的文件头前3个字作为判断依据
    fileHeader.set("/9j", "JPEG")
    fileHeader.set("iVB", "PNG")
    fileHeader.set("Qk0", "BMP")
    fileHeader.set("SUk", "TIFF")
    fileHeader.set("JVB", "PDF")
    fileHeader.set("UEs", "OFD")
    let res = ""
    //遍历map中所提及的文件头特征
    fileHeader.forEach((v, k) => {
      if (k == fileBase64.substr(0, 3)) {
        res = v
      }
    })
    //如果不在map中返回unknown file
    if (res == "") {
      res = "unknown file"
    }
    //否则返回map中的value值
    return res;
  }
}
module.exports = new Util();