<template>
  <div class="autospirit_updateImage_content">
    <div class="columnWapper imageWapper">
        <Image :src="currentImageSource" class="selectedImage"></Image>
    </div>
    <div class="columnWapper">
      <div class="btnWapper">
        <Button type="primary" class="buttom" @click="modifyImage">确定</Button>
      </div>
    </div>
     <div class="title">图片格式{{supportImageType}},大小不超过{{maxImageSize}}</div>
     <div class="maxTitle">推荐图片</div>
     <div class="columnWapper">
         <Grid :border="false" clickable :column-num="4" class="grid">
            <GridItem  v-for="imageSource in suggestImages" :key="imageSource" class="gridItem" @click="selectedImage(imageSource)">
                <Image :src="imageSource" class="suggestImage"></Image>
            </GridItem>
        </Grid>
     </div>
  </div>
</template>
<script>
import { getCurrentInstance, ref, toRefs} from "vue";
import { Button,Image,Grid,GridItem} from "vant";
import { useRoute } from "vue-router";
export default {
  name: "ImageUpload",
  props:{
    defaultImageSource:String,
    supportImageType:String,
    maxImageSize:String,
    suggestImages:Array,
    operateTitle:String
  },
  components: {
    Button,
    Image,
    Grid,
    GridItem,
  },
  setup(props) {
    let {defaultImageSource,supportImageType,maxImageSize,suggestImages,operateTitle} =toRefs(props);
    let currentImageSource=ref(defaultImageSource.value);
    return {
      currentImageSource,
      supportImageType,
      maxImageSize,
      suggestImages,
      operateTitle,
    };
  },
  methods: {
    modifyImage()
    {
      this.$emit("imageSure",this.currentImageSource);
    },
    selectedImage(imageSource)
    {
      this.currentImageSource =imageSource;
    }
  },
};
</script>
<style scoped>
.autospirit_updateImage_content {
  background-color: #fcfcfc;
  height: 100%;
}

.autospirit_updateImage_content .columnWapper {
  padding: 10px;
  text-align: center;
}
.autospirit_updateImage_content .btnWapper
{
  display: inline-block;
}
.autospirit_updateImage_content .imageWapper
{
    height: 100px;
    margin-top: 30px;
}
.autospirit_updateImage_content .grid
{
    background-color: transparent;
    border: none;
}
.autospirit_updateImage_content .gridItem
{
    background-color: transparent;
    border: none;
    padding: 5px;
}
.autospirit_updateImage_content .buttom {
  border-radius: 10px;
  display: block;
  height: 40px;
  width: 240px;
  margin: 5px;
}
.autospirit_updateImage_content .selectedImage {
  height: 80px;
  width: 80px;
}
.autospirit_updateImage_content .suggestImage
{
    height: 70px;
    width: 70px;
}
.autospirit_updateImage_content .maxTitle {
  font-size: 16px;
  padding: 10px;
}
.autospirit_updateImage_content .title {
  padding: 10px;
}
</style>