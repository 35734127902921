<template>
  <!--流程配置页面，用于配置操作spirit，Task，step等对象组合-->
  <ConfigPane :itemsOption="itemsType" pageType="0"/>
</template>
<script setup>
import {getCurrentInstance, ref } from "vue";
import {
    useRouter,
} from 'vue-router';
import AllType from "@/components/app/views/common/configPane/itemType/AllType.js"
import SpiritType from "@/components/app/views/common/configPane/itemType/SpiritType.js"
import TaskType from "@/components/app/views/common/configPane/itemType/TaskType.js"
import StepType from "@/components/app/views/common/configPane/itemType/StepType.js"
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const allType = new AllType();
const spiritType = new SpiritType();
const taskType = new TaskType();
const stepType = new StepType();
const itemsType = ref([
  allType.init(wsClient,router),
  spiritType.init(wsClient,router),
  taskType.init(wsClient,router),
  stepType.init(wsClient,router)
]);
</script>
<style></style>