<template>
  <div>
    <NavBar class="border none" :left-text="leftText" :title="title" left-arrow @click-left="onClickLeft">
      <template #left v-if="userDefineLeft">
        <slot name="left"></slot>
      </template>
      <template #right v-if="userDefineRight">
        <slot name="right"></slot>
      </template>
      <template #title v-if="userDefineTiltle">
        <slot name="title">{{ title }}</slot>
      </template>
    </NavBar>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, toRefs, defineProps, defineEmits, watch, onMounted, onUnmounted } from "vue";
const wsClient =
    getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
  leftText: {
    type: String
  },
  title: {
    type: String
  },
  autoControl: {
    type: Boolean,
    default: true
  },
  listenerState: {
    type: Boolean
  },
  userDefineTiltle: {
    type: Boolean
  },
  userDefineLeft: {
    type: Boolean
  },
  userDefineRight: {
    type: Boolean
  },
});
const emits = defineEmits(["onBack"]);
let handerKey;
const { leftText, title, listenerState, autoControl } = toRefs(props);
watch(listenerState, (newValue, oldValue) => {
  if (newValue) {
    wsClient.sendActionToUI("AUTOSPIRIT_NAVBAR_REGIST_EVENT", {
      "eventKey": handerKey,
    });
  }
  else {
    wsClient.sendActionToUI("AUTOSPIRIT_NAVBAR_UNREGIST_EVENT", {
      "eventKey": handerKey,
    });
  }
});
let navbarHanlder;
onMounted(async () => {
  handerKey = "NavBar@" + Date.now();
  navbarHanlder= wsClient.addEventListener("AUTOSPIRIT_NAVBAR_EVENT", onClickLeft);
  if (autoControl.value) {
    wsClient.sendActionToUI("AUTOSPIRIT_NAVBAR_REGIST_EVENT", {
      "eventKey": handerKey,
    });
  }
  else {
    if (listenerState.value) {
      wsClient.sendActionToUI("AUTOSPIRIT_NAVBAR_REGIST_EVENT", {
        "eventKey": handerKey,
      });
    }
  }
});
onUnmounted(() => {
  wsClient.removeEventListener("AUTOSPIRIT_NAVBAR_EVENT", navbarHanlder);
  if (autoControl.value) {
    wsClient.sendActionToUI("AUTOSPIRIT_NAVBAR_UNREGIST_EVENT", {
      "eventKey": handerKey,
    });
  }
});
const onClickLeft = () => {
  console.log("onClickLeft");
  emits("onBack");
};

</script>
<style scoped></style>