<template>
    <div>
        <div class="width-auto">
            <Popover :placement="eventPopoverPlace" v-model:show="condition.selectEventShow"
                @open="adjustEventPopupPlace(eventPlaceBtn,eventOccusOptions)">
                <CellGroup class="width-popupGroup">
                    <Cell clickable v-for="eventCondtion in eventOccusOptions" :key="eventCondtion.id"
                        v-model="eventCondtion.selected" :title="eventCondtion.name"
                        :class="eventCondtion.selected ? 'selected' : ''"
                        @click="onEventConditionSelected(eventCondtion)">
                        <template v-if="eventCondtion.selected" #right-icon>
                            <Icon name="success" class="selected" />
                        </template>
                    </Cell>
                </CellGroup>
                <template #reference>
                    <div ref="eventPlaceBtn">
                        <Button v-if="!isDisabled" class="content-vcenter block-inline margin-left controlBtn"
                            type="primary" size="small">{{
                            eventTypeName }}</Button>
                        <Button v-if="isDisabled" disabled class="content-vcenter block-inline margin-left controlBtn"
                            type="primary" size="small">{{
                            eventTypeName }}</Button>
                    </div>
                </template>
            </Popover>
            <Button v-if="!isDisabled" class="content-vcenter block-inline margin-left" type="primary" size="small"
                @click="doVariableSet('设置变量参数')">{{ variableName }}</Button>
            <Button v-if="isDisabled" disabled class="content-vcenter block-inline margin-left" type="primary"
                size="small">{{ variableName }}</Button>
            <Popover :placement="operatePopoverPlace" v-model:show="condition.selectActionShow"
            @open="adjustOperatePopupPlace(operatePlaceBtn,operateOptions)">
                <CellGroup class="width-popupGroup">
                    <Cell clickable v-for="opeateOption in operateOptions" :key="opeateOption.id"
                        v-model="opeateOption.selected" :title="opeateOption.name"
                        :class="opeateOption.selected ? 'selected' : ''" @click="onOperateOptionSelected(opeateOption)">
                        <template v-if="opeateOption.selected" #right-icon>
                            <Icon name="success" class="selected" />
                        </template>
                    </Cell>
                </CellGroup>
                <template #reference>
                    <div ref="operatePlaceBtn">
                        <Button v-if="!isDisabled" class="content-vcenter block-inline margin-left controlBtn"
                            type="primary" size="small">{{
                                operateName }}</Button>
                        <Button v-if="isDisabled" disabled class="content-vcenter block-inline margin-left controlBtn"
                            type="primary" size="small">{{
                                operateName }}</Button>
                    </div>
                </template>
            </Popover>
        </div>
        <div class="padding" v-if="selectedOperateOptionType.id=='CHANG_TO_FIX_VALUE'">
            <Field v-if="!isDisabled" class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
            <Field v-if="isDisabled" readonly class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
        </div>
        <div class="padding" v-if="selectedOperateOptionType.id=='ADD_OTHOR_FIX_VALUE'">
            <Field v-if="!isDisabled" class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
            <Field v-if="isDisabled" readonly class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
        </div>
        <div class="padding" v-if="selectedOperateOptionType.id=='CUT_OTHOR_FIX_VALUE'">
            <Field v-if="!isDisabled" class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
            <Field v-if="isDisabled" readonly class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
        </div>
        <div class="padding" v-if="selectedOperateOptionType.id=='MULTIPLY_OTHOR_FIX_VALUE'">
            <Field v-if="!isDisabled" class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
            <Field v-if="isDisabled" readonly class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
        </div>
        <div class="padding" v-if="selectedOperateOptionType.id=='DIVIDE_OTHOR_FIX_VALUE'">
            <Field v-if="!isDisabled" class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
            <Field v-if="isDisabled" readonly class="border controlText" placeholder="请输入值" v-model="fixNum"></Field>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, ref, watch, onMounted } from "vue";
import util from "@/utils/util";
import optionsConfig from "../OptionsConfig";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const eventOccusOptions =ref(util.cloneObject(optionsConfig.variableContorlEventOccusOptions));
const emit = defineEmits(["onValueChange"]);
const props = defineProps({
    itemInfo: {
        type: Object
    },
    condition:{
        type: Object
    },
    isNewAction:{
        type:Boolean,
        default:false,
    },
    isDisabled:{
        type:Boolean,
        default:false,
    }
});
const {
    itemInfo,
    condition,
    isNewAction,
    isDisabled,
} = toRefs(props);

const fixNum =ref(condition.value.fixNum);

watch(fixNum,()=>{
    fireValueChangeEvent("fixNum",fixNum.value);
});

const eventPopoverPlace=ref("top-start");
const eventPlaceBtn =ref(null);
const operatePopoverPlace=ref("top-start");
const operatePlaceBtn =ref(null);

const adjustEventPopupPlace=(dom,options)=>{
    eventPopoverPlace.value=util.adjustPopupPlace(dom,options);
}

const adjustOperatePopupPlace=(dom,options)=>{
    operatePopoverPlace.value=util.adjustPopupPlace(dom,options);
}

const relateVariable =ref({});

const selectedEventOccusType =ref({});

const selectedOperateOptionType =ref({});

const getVariabeOperateOptions=()=>{
    let result=[];
    let variableType = relateVariable.value.variableType;
    if(variableType)
    {
        result = variableType.operateTypeOptions;
    }
    return result;
};

const operateOptions = ref(util.cloneObject(getVariabeOperateOptions()));

const convertEventTypeName=(key)=>{
    let result;
    for(let tempObj of eventOccusOptions.value)
    {
       let tempID = tempObj.id;
       if(tempID==key)
       {
        result= tempObj.name;
       }
    }
    return result?result:"选择事件"
}

const fireValueChangeEvent=(paramName,value)=>{
    emit("onValueChange",{
        id:condition.value.id,
        changedParamName:paramName,
        value:value
    })
}

const eventTypeName=ref(convertEventTypeName(condition.value.eventOccsType));

const convertVaiableName=(variableData)=>{
    let result =variableData?variableData.name:"";
    return result?result:"选择变量"
}

const variableName=ref(convertVaiableName(relateVariable.value));

const convertOperateName=(key)=>{
    let result;
    let variableType = relateVariable.value.variableType;
    if(variableType)
    {
        let operateOptions = variableType.operateTypeOptions;
        for(let tempObj of operateOptions)
        {
            let tempID = tempObj.id;
            if(tempID==key)
            {
                result= tempObj.name;
            }
        }
    }
    return result?result:"选择动作"
}

const operateName=ref(convertOperateName(condition.value.variableOperateType));

onMounted(async()=>{
    await initRelateDatas();
    //初始化设置默认选择的操作事件
    for(let action of eventOccusOptions.value)
      {
        if(action.id==condition.value.eventOccsType)
        {
            selectedEventOccusType.value=action;
            selectedEventOccusType.value.selected=true;
        }
      }
      for(let action of operateOptions.value)
      {
        if(action.id==condition.value.variableOperateType)
        {
            selectedOperateOptionType.value=action;
            selectedOperateOptionType.value.selected=true;
        }
      }
    updateText();
    adjustEventPopupPlace(eventPlaceBtn.value,eventOccusOptions.value);
    adjustOperatePopupPlace(operatePlaceBtn.value,operateOptions.value);
});

const updateText=()=>{
    eventTypeName.value = convertEventTypeName(selectedEventOccusType.value.id);
    operateName.value = convertOperateName(selectedOperateOptionType.value.id);
    variableName.value = convertVaiableName(relateVariable.value);
};

const initRelateDatas=async()=>{
    let variableID = condition.value.variableID;
    if(variableID)
    {
      let itemTypeObj= typeUtil.getItemTypeObj(itemInfo.value.type);
      itemTypeObj.init(wsClient);
      if(isNewAction.value)
      {
        let variables =itemInfo.value.variables;
       
        for(let tempVariable of variables)
        {
            if(tempVariable.id ==variableID)
            {
                relateVariable.value =tempVariable?tempVariable:{};
                variableName.value = convertVaiableName(relateVariable.value);
                operateOptions.value = util.cloneObject(getVariabeOperateOptions())
            }
        }
      }
      else
      {
        let variableData =await itemTypeObj.getItemVarableByID(variableID)
        relateVariable.value=variableData?variableData:{};
      }
      operateOptions.value = util.cloneObject(getVariabeOperateOptions());
    }
};

//响应事件动作选择
const onEventConditionSelected =(eventCondtion)=>{
    selectedEventOccusType.value.selected=false;
    selectedEventOccusType.value=eventCondtion;
    selectedEventOccusType.value.selected=true;
    condition.value.selectEventShow=false;
    eventTypeName.value = convertEventTypeName(selectedEventOccusType.value.id);
    fireValueChangeEvent("eventOccsType",selectedEventOccusType.value.id);
};

//响应事件动作选择
const onOperateOptionSelected =(opeateOption)=>{
    selectedOperateOptionType.value.selected=false;
    selectedOperateOptionType.value=opeateOption;
    selectedOperateOptionType.value.selected=true;
    condition.value.selectActionShow=false;
    operateName.value = convertOperateName(selectedOperateOptionType.value.id);
    fireValueChangeEvent("variableOperateType",selectedOperateOptionType.value.id);
};

//响应变量选择设置
const doVariableSet=(title)=>{
    let val = {
        itemParamName: "",
        itemVariableType: "",
        isNewAction:isNewAction.value,
        eventVariable: relateVariable.value,
        selectType:"VARIABLE"
    }
    if(isNewAction.value)
    {
        val.variables =util.cloneObject(itemInfo.value.variables);
    }
    wsClient.broadcastEvents("AUTOSPIRIT_PROSETPANE_ACTION",{
        "title":title,
        "value":{},
        'type': "pageComponent",
        'componentPath':'components/app/views/common/VariableSelectPane',
        'componentDatas':{
            "itemInfo":itemInfo.value,
            "conditions":val,
        },
        'submit':onVariableSelected
    });
}

//变量选择响应事件，遍历所有缓存的控制条件，并更新对应的参数
const onVariableSelected=(datas)=>{
    // console.log("onVariableSelected");
    // console.log(datas);
    let variableData =datas.componentDatas;
    relateVariable.value =variableData;
    variableName.value = convertVaiableName(relateVariable.value);
    operateOptions.value = util.cloneObject(getVariabeOperateOptions());
    fireValueChangeEvent("variableID",relateVariable.value.id);
};

</script>
<style scoped>
.controlBtn {
    width: 100px;
}

.controlText {
    width: 280px;
}
</style>