const util = require("@/utils/util");
const NewRelateItemOperator = require('./NewRelateItemOperator');
module.exports = class NewAppTypeRelateItemOperator extends NewRelateItemOperator {
  id = "NEWITEM_APPTYPE_TYPE_OPERATOR";

  getOptionVisableState(relateOperateOption,relateMappingObj){
    if(relateOperateOption.id == "RELATEMAPPING_PAGE_SET_MAINPAGE")
    {
        let parentItemInfo =relateMappingObj.parentItemInfo;
        let itemInfo =relateMappingObj.itemInfo;
        return parentItemInfo.mainPageID!=itemInfo.id;
    }
    return super.getOptionVisableState(relateOperateOption,relateMappingObj);
  };

   /**
* 响应关联按钮操作事件
* @param {事件对象} eventData 
*/
doRelateOperateAction(eventData) {
  let operateOption = eventData.operateOption;
  let relateMapping = eventData.relateMapping;
  let operateID = operateOption.id;
  switch (operateID) {
    //查看步骤信息事件
    case "RELATEMAPPING_PAGE_SET_MAINPAGE":
      this.doItemMainPageSetAction(relateMapping, eventData);
      break;
    default:
      super.doRelateOperateAction(eventData);
      break;
  };
};

  doItemMainPageSetAction = async (relateMapping, eventData) => {
    let parentItemInfo = relateMapping.parentItemInfo;
    let itemInfo = relateMapping.itemInfo;
    parentItemInfo.mainPageID = itemInfo.id;
    let appInfo = this.handler.getItemInfo();
    appInfo.mainPageID = itemInfo.id;
  };
}