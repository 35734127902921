<template>
    <div class="bg-lightGrey height-full">
        <BackableNavBar leftText="返回" title="新建路径配置" :autoControl="false" :listenerState="backListenerState"
            @onBack="onClickLeft" />
        <WizardPane ref="newItemWizard" :stepOptions="stepOptions"
            @onDataSubmit="doCreateRouteInfo">
        </WizardPane>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onActivated, watch } from "vue";
import {
    useRouter,
} from 'vue-router';
import RouteConfig from "@/components/app/views/config/newRouteInfo/RouteConfig.vue";
import RouteRelateConfig from "@/components/app/views/config/newRouteInfo/RouteRelateConfig.vue";
import RouteFullConfig from "@/components/app/views/config/newRouteInfo/RouteFullConfig.vue";
import RouteConfigResult from "@/components/app/views/config/newRouteInfo/RouteConfigResult.vue";
import { showToast } from "vant";

const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let stepOptions = ref([]);
let isNeedPageDatasUpdate = false;
let getWizardDatas = () => {
    return [
        {
            stepName: "基础配置",
            component: RouteConfig,
        },
        {
            stepName: "关联配置",
            component: RouteRelateConfig,
        },
        {
            stepName: "全局配置",
            component: RouteFullConfig,
        },
        {
            stepName: "创建完毕",
            component: RouteConfigResult,
        },
    ];
};
stepOptions = getWizardDatas();
const backListenerState = ref(true);
const newItemWizard = ref(null);

onActivated(() => {
    if (isNeedPageDatasUpdate) {
        newItemWizard.value.updateDatas();
        isNeedPageDatasUpdate = false;
    }
});

const doCreateRouteInfo = async (datas) => {
    const responseDatas = await wsClient.sendActionToCloud("CREATE_ROUTE_INFO", datas);
    if (responseDatas.resultCode == 1) {
        onClickLeft();
    }
    let params = {
        message: responseDatas.message,
        position: "bottom",
    };
    showToast(params);
};
const onClickLeft=()=> {
    newItemWizard.value.resetDatas();
    backListenerState.value = false;
    isNeedPageDatasUpdate = true;
    router.push({
        name: backTargetPage,
        state: {
            pageDatas: JSON.stringify(backTargetPageDatas)
        }
    });
};
</script>
<style scoped></style>
  