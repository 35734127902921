<template>
    <div class="bg-lightGrey height-full">
        <BackableNavBar leftText="返回" title="路径信息" :autoControl="false" @onBack="onClickLeft" />
        <!--快捷信息面板-->
        <!-- <div class="padding">
            <div class="flex bg-white border solid radius padding">
                <div class="padding">
                    <Image class="flex center as-avatar xl" :src="defaultIcon"></Image>
                </div>
                <div>
                    <Cell center :title="routeInfo.name + '【#' + routeInfo.id + '】'" :label="routeInfo.define"></Cell>
                </div>
            </div>
        </div> -->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础配置："></Cell>
                    <ProsetCell title="名称" :value="routeInfo.name" @onSubmit="onNameSubmit"></ProsetCell>
                    <ProsetCell title="描述" type="textarea" :value="routeInfo.define" @onSubmit="onDefineSubmit">
                    </ProsetCell>
                    <Cell center is-link title="场景类别" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect">
                    </Cell>
                    <Cell :title="routeInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
                        <template #right-icon>
                            <Switch v-model="routeInfo.commom" size="24" @change="changeCommom" />
                        </template>
                    </Cell>
                    <Cell is-link value="设置" @click="changeRelatePageItem(true)">
                        <template #title>
                            <div class="flex width-xl">
                                <div>起始页面：{{ getRoutePageName(true) }}</div>
                                <Icon v-show="startPageInfo.name" class="icon color-blue" name="more-o" size="20" @click.stop="gotoWatchPageInfo(true)"></Icon>
                            </div>
                        </template>
                    </Cell>
                    <Cell is-link value="设置" @click="changeRelatePageItem(false)">
                        <template #title>
                            <div class="flex width-xl">
                                <div>终止页面：{{ getRoutePageName(false) }}</div>
                                <Icon v-show="endPageInfo.name" class="icon color-blue" name="more-o" size="20" @click.stop="gotoWatchPageInfo(false)"></Icon>
                            </div>
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <!-- <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景配置："></Cell>
                    <Cell center is-link title="场景类别" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect">
                    </Cell>
                </CellGroup>
            </div>
        </div> -->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="高级信息:"></Cell>
                    <Tabs shrink v-model:active="active" background="transparent">
                        <!--步骤操作页签面板-->
                        <Tab title="步骤设置">
                        <template #title> <span class="itemTab">步骤设置</span></template>
                        <RelateSelectPane ref="relateSelectPane" :itemRelateMappings="itemRelateMappings"
                            :loadingState="loadingState"
                            :relateItemOperator="routeTypeOperator"
                            @onAddRelateItem="doAddRelateStepAndTask"
                        />
                        </Tab>
                        <!--任务操作页签面板-->
                        <Tab title="变量设置">
                            <template #title> <span class="itemTab">变量设置</span></template>
                            <!--变量设置面板-->
                            <ParameterSetting :itemInfo="routeInfo"></ParameterSetting>
                        </Tab>
                        <Tab title="执行日志">
                            <template #title> <span class="itemTab">执行日志</span></template>
                            <!--变量设置面板-->
                            <RunableInfosPane :itemInfo="routeInfo"></RunableInfosPane>
                          </Tab>
                    </Tabs>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import RouterType from "@/components/app/views/common/configPane/itemType/RouterType.js";
import PageType from "@/components/app/views/common/configPane/itemType/PageType.js";
import PageTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/PageTypeRelateOperator.js";
import RouteTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/RouteTypeRelateOperator.js";
import {
    useRouter,
} from 'vue-router';
import { showToast } from "vant";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const routeType = new RouterType();
routeType.init(wsClient, router);
const pageType = new PageType();
pageType.init(wsClient, router);
const pageHanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getWatchedPageName: (relateMapping) => {
    return "PageInfo";
  },
  getBackPageName:()=>{
    return "RouteInfo";
  },
  getBackPageDatas:()=>{
    let datas ={
        itemInfo: routeInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas
      };
    return datas;
  },
};
const routeHanlder ={
  getRouter:()=>{
    return router;
  },
  getClientServer:()=>{
    return wsClient;
  },
  getItemInfo:()=>{
    return routeInfo.value;
  },
  getWatchedPageName: (relateMapping) => {
    let type = relateMapping.relateType;
    //查看的是步骤，则传递相关信息到步骤页面
    if (type == "TASK_TYPE") {
        return "TaskInfo";
    }
    else if(type == "STEP_TYPE")
    {
        return "StepInfo";
    }
  },
  getBackPageName:()=>{
    return "RouteInfo";
  },
  getBackPageDatas:()=>{
    let datas ={
        itemInfo: routeInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas
      };
    return datas;
  },
  updateRelateMappings:(relateItemMappings)=>{
    itemRelateMappings.value = relateItemMappings;
    loadingState.value = false;
  },
  getRelateMappings:()=>{
    return itemRelateMappings.value;
  },
  /**
   * 响应映射复制
   * @param mapping 
   */
   onMappingCopy:(mapping)=>{
    routeInfo.value.copyMapping =mapping;
  },
  getCopiedMapping:()=>{
    return routeInfo.value.copyMapping;
  },
  /**
   * 是否有映射被复制，当推出此页面后，清除
   */
  isMappingCopied:()=>{
    return routeInfo.value.copyMapping?true:false;
  },
  /**
   * 响应步骤增加事件
   * @param insertIndex 
   */
  onTaskAndStepAdded:(insertIndex)=>{
    doAddRelateStepAndTask(insertIndex);
  },
  /**
   * 响应复制的步骤增加事件
   * @param copyStepInfo 
   * @param insertIndex 
   */
  onCopyTaskAndStepAdded:async(copyStepInfo,insertIndex)=>{
    await _createRelateMappingByAddAction([copyStepInfo],insertIndex);
    reloadPageDatas({
      itemInfo:routeInfo.value,
      backTargetPage:backTargetPage
    });
  },

   /**
   * 响应步骤新建事件
   * @param insertIndex 
   */
   onTaskAndStepCreated:async(itemData,insertIndex)=>{
    await _createRelateMappingByAddAction([itemData],insertIndex);
    reloadPageDatas({
      itemInfo:routeInfo.value,
      backTargetPage:backTargetPage
    });
  },
};
const pageTypeOperator = new PageTypeRelateOperator();
pageTypeOperator.init(pageHanlder);
const routeTypeOperator = new RouteTypeRelateOperator();
routeTypeOperator.init(routeHanlder);
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const routeInfo = ref(pageDatas.itemInfo ? pageDatas.itemInfo : {});
const startPageInfo = ref({});
const endPageInfo = ref({});
//步骤所在的子场景
const sencneType = ref({});
const sencne = ref({});
//默认的Task图标
const defaultIcon = ref("");
//高级操作栏的选项卡索引
const active = ref(0);

//当前任务下所关联的步骤映射集
const itemRelateMappings = ref([]);

const loadingState =ref(true);

const reloadPageDatas=async()=>{
    let copyMapping =routeInfo.value.copyMapping;
  //从云端获取最新的数据
    routeInfo.value = await routeType.getItemInfoByID(routeInfo.value.id);
    routeInfo.value.copyMapping =copyMapping;
    sencneType.value = await routeType.getItemSencneType(routeInfo.value);
    sencne.value = await routeType.getItemSencne(sencneType.value);
    if (routeInfo.value.startPageID)
        startPageInfo.value = await pageType.getItemInfoByID(routeInfo.value.startPageID);
    if (routeInfo.value.endPageID)
        endPageInfo.value = await pageType.getItemInfoByID(routeInfo.value.endPageID);
    routeTypeOperator.initRelateMappingDatas(routeInfo.value);
}

onMounted(async () => {
    defaultIcon.value = wsClient.getIconBase64Str("ROUTE_ICON");
    await reloadPageDatas();
});

const changeCommom = () => {
    routeType.saveData(routeInfo.value);
};
const onClickLeft = () => {
    router.push({
        name: backTargetPage,
        state: {
            pageDatas: JSON.stringify(backTargetPageDatas)
        }
    });
};
const getRoutePageName=(isStartPage)=>{
    let tempPageInfo = isStartPage?startPageInfo.value:endPageInfo.value;
    if(tempPageInfo.name&&tempPageInfo.id)
    {
        return tempPageInfo.name+'【'+tempPageInfo.id+'】';
    }
    else
    {
        return "未设置"
    }
}
const getItemInfoSencneString = () => {
    if (!sencne.value.sencneName || !sencneType.value.name) {
        return "加载中...";
    }
    return sencne.value.sencneName + '【' + sencneType.value.name + '】';
}
/**
 * 响应更新描述动作
 * @param {描述} newDiscripe 
 */
const onDefineSubmit = (newDiscripe) => {
    routeInfo.value.define = newDiscripe;
    routeType.saveData(routeInfo.value);
};
const onNameSubmit = (newName) => {
    routeInfo.value.name = newName;
    routeType.saveData(routeInfo.value);
}
const gotoSencneTypeSelect = () => {
    let datas = {
        sencnePageType: 1,
        eventName: "AUTOSPIRIT_PAGEINFO_SENCNETYPESELECT_EVENT",
        backTargetPage: "PageInfo",
        backTargetPageDatas: {
            itemInfo: routeInfo.value,
            backTargetPage: backTargetPage,
            backTargetPageDatas: backTargetPageDatas
        },
        selectedSencneType: sencneType.value,
        selectedSencne: sencne.value
    };
    const actionMethod = async (eventDatas) => {
        routeInfo.value.sencneTypeID = eventDatas.sencneType.id;
        let responseDatas = await routeType.saveData(routeInfo.value);
        let params = {
            message: responseDatas.message,
            position: "bottom",
        };
        showToast(params);
        if (responseDatas.resultCode == 1) {
            let pageDatas = {
                itemInfo: routeInfo.value,
                backTargetPage: backTargetPage,
                backTargetPageDatas: backTargetPageDatas
            }
            router.push({
                name: datas.backTargetPage,
                state: {
                    pageDatas: JSON.stringify(pageDatas)
                }
            });
        }
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "SencneTypeSelected",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};

const gotoWatchPageInfo = (isStartPage) => {
    let itemInfo =isStartPage?startPageInfo.value:endPageInfo.value;
    pageTypeOperator.doWatchRouteInfoAction(itemInfo);
};

/**
 * 相应添加映射关联的动作
 */
 const doAddRelateStepAndTask = async (insertIndex) => {
  //自身和选择自身的祖先任务无法被添加关联，避免循环引用
  let itemsExcludeArr = [];
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "RouteInfo",
    backTargetPageDatas:{
        itemInfo: routeInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas:backTargetPageDatas
    },
    eventName: "AUTOSPIRIT_ROUTEINFO_STEPSSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsType: ["ALL_TYPE", "TASK_TYPE", "STEP_TYPE"],
    selectedMode: true,
    itemsExclude: itemsExcludeArr,
    pageType: "0",
    pageTitle: "步骤选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let responseDatas = await _createRelateMappingByAddAction(selectedItems,insertIndex);
    //成功后返回之前的选择页面
    if (responseDatas.resultCode == 1) {
      let pageDatas = {
        itemInfo: routeInfo.value,
        backTargetPage: backTargetPage,
        //之前任务页面的backPagedatas对象
        backTargetPageDatas:backTargetPageDatas
      }
      router.push({
        name: datas.backTargetPage,
        state:{
          pageDatas:JSON.stringify(pageDatas)
        }
      });
    }
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state:{
      pageDatas:JSON.stringify(datas)
    }
  });
};

const _createRelateMappingByAddAction =async(selectedItems,insertIndex)=>{
  let queryData = {
      relateMappings: [],
      insertIndex: insertIndex
    };
    //基于选择的元素，遍历构建查询对象
    for (let selectedItem of selectedItems) {
      let tempMapping = {
        parentID: routeInfo.value.id,
        parentType:routeInfo.value.type,
        relateID: selectedItem.id,
        relateType: selectedItem.type,
        disable: false
      };
      queryData.relateMappings.push(tempMapping);
    }
    let responseDatas = await wsClient.sendActionToCloud(
      "CREATE_RELATE_MAPPINGS",
      queryData
    );
    return responseDatas;
};

/**
* 相应添加映射关联的动作
*/
const changeRelatePageItem = async (isStartPage) => {
  let itemsExcludeArr = [startPageInfo.value.key,endPageInfo.value.key];
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "RouteInfo",
    backTargetPageDatas:{
        itemInfo: routeInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas:backTargetPageDatas
    },
    eventName: "AUTOSPIRIT_ROUTEINFO_PAGESSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsExclude: itemsExcludeArr,
    itemsType: ["PAGE_TYPE"],
    selectedMode: true,
    singleSelectedMode: true,
    pageType: "1",
    pageTitle: "页面选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let itemInfo =isStartPage?startPageInfo:endPageInfo;
    let selectPageInfo = selectedItems[0];
    if(isStartPage)
    {
      routeInfo.value.startPageID = selectPageInfo.id;
    }
    else
    {
      routeInfo.value.endPageID = selectPageInfo.id;
    }
    itemInfo.value =selectPageInfo;
    routeType.saveData(routeInfo.value);
    router.push({
      name: datas.backTargetPage,
      state: {
        pageDatas: JSON.stringify(datas.backTargetPageDatas)
      }
    });
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};

</script>
<style scoped>
.itemTab {
    font-size: 15px;
}
.icon {
    align-self: center;
    align-items: center;
    display: inline-flex;
    padding-left: 5px;
}
</style>