<template>
    <ProsetCell v-if="type == 'VARIABLE_NUMBER'" :title="label ? label :title" 
        type="relateVariable" :tipInfo="tipInfo" :value="runValue" :name="name" :hanlder="hanlder"
        :isNewAction="isNewAction" :isDisabled="isDisabled" 
        @onSubmit="onVariableSelected">
    </ProsetCell>
    <ProsetCell v-if="type == 'VARIABLE_IMAGE'" :title="label ? label :title" 
        type="imageCut" :tipInfo="tipInfo" :value="runValue" :name="name" :hanlder="hanlder"
        :isNewAction="isNewAction" :isDisabled="isDisabled"
        @onSubmit="onVariableSelected"  @onClick="onFeildClick">
    </ProsetCell>
    <ProsetCell v-if="type == 'VARIABLE_PLACE_AREA'" :title="label ? label :title" 
        type="placeArea" :tipInfo="tipInfo" :value="runValue" :name="name" :hanlder="hanlder"
        :isNewAction="isNewAction" :isDisabled="isDisabled"
        @onSubmit="onVariableSelected">
    </ProsetCell>
    <ProsetCell v-if="type == 'VARIABLE_STEP'||type == 'VARIABLE_APP'" :title="label ? label :title" 
        type="relateStep" :tipInfo="tipInfo" :value="runValue" :name="name" :hanlder="hanlder"
        :isNewAction="isNewAction" :isDisabled="isDisabled"
        @onClick="onFeildClick">
    </ProsetCell>
</template>
<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, ref, watch, onMounted } from "vue";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import util from "@/utils/util";
import BaseVariableData from "../../variableType/BaseVariableData";
import NewItemVariableData from "../../variableType/NewItemVariableData";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const hanlder = {
    getStepActiopnType:()=>{
        return stepActionType.value;
    },
    getComponentData: () => {
        let val = {
            tabIndex: relateVariable.value.id ? 1 : 0,
            title: title.value,
            itemParamName: name.value,
            itemVariableType: type.value,
            isNewAction: isNewAction.value,
            eventVariable: relateVariable.value
        }
        if (type.value == "VARIABLE_PLACE_AREA" || type.value == "VARIABLE_IMAGE") {
            val.selectType = "VARIABLE";
        }
        if (isNewAction.value) {
            val.variables = util.cloneObject(itemInfo.value.variables);
            let cloneArray = [];
            for (let variable of itemInfo.value.variables) {
                let tempData = new NewItemVariableData();
                tempData.init({
                    id: variable.id,
                    variableType: variable.variableType,
                    name: variable.name,
                    value: variable.value,
                    isNewData: variable.isNewData,
                    relateID: variable.relateID,
                    relateItemType: variable.relateItemType
                }, wsClient);
                cloneArray.push(tempData);
            }
            val.variables = cloneArray;
        }
        let resultData = {
            "itemInfo": itemInfo.value,
            "conditions": val,
        }
        return resultData;
    },
    getVariableProText: (vriableData) => {
        if (vriableData.id) {
            return `[变量:${vriableData.name}]`;
        }
        else {
            let result = stepActionType.value[name.value];
            return result?result.toString():"";
        }
    }
}
const props = defineProps({
    itemInfo: {
        type: Object
    },
    stepActionType: {
        type: Object
    },
    label: {
        type: String
    },
    tipInfo: {
        type: String
    },
    title: {
        type: String
    },
    name: {
        type: String
    },
    type: {
        type: String
    },
    isNewAction: {
        type: Boolean,
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    }
});
const emit = defineEmits(["onValueChange", "onMappingChange", "onFeildClick"]);
const {
    itemInfo,
    stepActionType,
    title,
    label,
    name,
    type,
    tipInfo,
    isNewAction,
    isDisabled,
} = toRefs(props);
const relateVaraibleMapping = ref({});
const relateVariable = ref({});
const runValue = ref("");
watch(stepActionType, async () => {
    await initRelateVariableDatas();
});

const onFeildClick =()=>{
    emit("onFeildClick",runValue);
};

const initRelateVariableDatas = async () => {
    let itemTypeObj = typeUtil.getItemTypeObj(itemInfo.value.type);
    itemTypeObj.init(wsClient);
    let mapping = await itemTypeObj.getVariableProMapping(stepActionType.value, name.value);
    if (mapping) {
        relateVaraibleMapping.value = mapping;
        let variableData = await itemTypeObj.getItemVarableByID(relateVaraibleMapping.value.variableID);
        relateVariable.value = variableData ? variableData : {};
    }
    runValue.value = hanlder.getVariableProText(relateVariable.value);
};
onMounted(async () => {
    await initRelateVariableDatas();
});


/**
* 响应变量选择事件
* @param {被选择的变量对象} datas 
*/
const onVariableSelected = (datas) => {
    // console.log(datas);
    let conditions = datas.conditions;
    if (conditions) {
        let itemParamName = conditions.itemParamName;
        if (itemParamName) {
            if (itemParamName == name.value) {
                let variableData = datas.componentDatas;
                if (variableData instanceof BaseVariableData) {
                    relateVaraibleMapping.value.variableID = variableData.id;
                    relateVariable.value = variableData;
                    runValue.value = hanlder.getVariableProText(variableData);
                    emit("onMappingChange", {
                        mapping: relateVaraibleMapping.value,
                        paramName: name.value,
                        paramValue: runValue.value
                    })
                }
                else {
                    // console.log(relateVariable.value);
                    //说明之前存在映射，现在没有了
                    if (relateVariable.value.id) {
                        relateVaraibleMapping.value.variableID = variableData.id ? variableData.id : "";
                        relateVariable.value = variableData;
                        emit("onMappingChange", {
                            mapping: relateVaraibleMapping.value,
                            paramName: name.value,
                            paramValue: runValue.value
                        })
                    }
                    if (variableData instanceof Object) {
                        runValue.value = hanlder.getVariableProText(variableData);
                    }
                    else {
                        runValue.value = variableData;
                    }
                    emit("onValueChange", {
                        paramName: name.value,
                        paramValue: runValue.value
                    });
                }
            }
        }
    }
};

</script>
<style scoped></style>