<template>
    <Cell v-show="checkSingleCellShowStatus()" :title="relateMappingObj.itemInfo.name">
        <template #title>
            <div class="flex">
                <div>【{{ relateMappingObj.index }}】</div>
                <Icon class="icon padding-left" :name="(isParentForbit||relateMappingObj.disable)?itemIconGray:itemIcon" size="20"></Icon>
                <div class="padding-left">{{ relateMappingObj.itemInfo.name }}【#{{ relateMappingObj.itemInfo.id }}】</div>
                <Icon v-if="isParentForbit||relateMappingObj.disable" class="icon" :name="forbitIcon" size="20" />
                <Tag v-show="checkSingleCellTagShowStatus()" plain type="primary">首页</Tag>
            </div>
        </template>
        <template #right-icon>
            <Popover v-if="!isSelectMode" placement="top-end" v-model:show="variableTypeShow">
                <CellGroup class="width-popupGroup">
                    <Cell clickable v-for="relateOperateOption in relateMappingOperateOptions" :key="relateOperateOption.id"
                        :title="relateOperateOption.name" v-show="getOptionVisableState(relateOperateOption)"
                        @click="doRelateOperate(relateOperateOption)">
                    </Cell>
                </CellGroup>
                <template #reference>
                    <div class="flex content-vcenter"
                        v-show="!relateMappingObj.childrenItem && (elementsHideConfig ? !elementsHideConfig.operateBar : true)">
                        <Icon  class="rightIcon" name="bars" size="20" />
                    </div>
                </template>
            </Popover>
            <Switch v-if="isSelectMode" v-model="relateMappingObj.selected" @change="changeRelateState(relateMappingObj)" size="20px" />
        </template>
    </Cell>
    <CollapseItem v-show="checkComplexCellShowStatus()" :title="relateMappingObj.itemInfo.name">
        <template #title>
            <div class="flex">
                <div>【{{ relateMappingObj.index }}】</div>
                <Icon class="icon padding-left" :name="(isParentForbit||relateMappingObj.disable)?itemIconGray:itemIcon" size="20">
                </Icon>
                <div class="padding-left">{{ relateMappingObj.itemInfo.name }}【#{{ relateMappingObj.itemInfo.id }}】</div>
                <Icon v-if="isParentForbit||relateMappingObj.disable" class="icon" :name="forbitIcon" size="20" />
            </div>
        </template>
        <template #right-icon>
            <Popover v-if="!isSelectMode" placement="top-end" v-model:show="variableTypeShow">
                <CellGroup class="width-popupGroup">
                    <Cell clickable v-for="relateOperateOption in relateMappingOperateOptions" :key="relateOperateOption.id"
                        :title="relateOperateOption.name" v-show="getOptionVisableState(relateOperateOption)"
                        @click="doRelateOperate(relateOperateOption)">
                    </Cell>
                </CellGroup>
                <template #reference>
                    <div class="flex content-vcenter" @click.stop="variableTypeShow = !variableTypeShow"
                        v-show="!relateMappingObj.childrenItem && (elementsHideConfig ? !elementsHideConfig.operateBar : true)">
                        <Icon  class="rightIcon" name="bars" size="20" />
                    </div>
                </template>
            </Popover>
            <Switch v-if="isSelectMode" v-model="relateMappingObj.selected" @click.stop @change="changeRelateState(relateMappingObj)" size="20px" />
        </template>
        <RelateCell v-for="tempRelateMapping in relateMappingObj.itemInfo.relateMappings" :key="tempRelateMapping.id"
            :relateItemOperator="relateItemOperator"
            :relateMapping="tempRelateMapping"
            :isParentForbit="relateMappingObj.disable"
            :isSelectMode="isSelectMode"
            :selectedHanlder="selectedHanlder"
            >
        </RelateCell>
    </CollapseItem>
</template>
<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, onMounted, ref, watch } from "vue";
import TaskType from "@/components/app/views/common/configPane/itemType/TaskType.js";
import SpiritType from "@/components/app/views/common/configPane/itemType/SpiritType.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
    //元素显示配置项
    elementsHideConfig: {
        type: Object
    },
    relateMapping: {
        type: Object,
    },
    relateItemOperator: {
        type: Object
    },
    isParentForbit: {
        type: Boolean,
        default:false,
    },
    isSelectMode:{
        type:Boolean,
        default:false,
    },
    selectedHanlder:{
        type:Object
    }
});
const taskType = new TaskType();
const spiritType = new SpiritType();
taskType.init(wsClient);
spiritType.init(wsClient);
const emits = defineEmits(["onRelateOperate","onRelateSelected"]);
const { relateMapping, elementsHideConfig,relateItemOperator,isParentForbit,isSelectMode,selectedHanlder} = toRefs(props);
const relateMappingObj = ref(relateMapping.value);
const relateMappingOperateOptions = ref(relateMappingObj.value.operateOptions);
const itemIcon = ref("");
const itemIconGray=ref("");
const forbitIcon =ref("");
const variableTypeShow = ref(false);
// const selectedStepInfo =ref({})
const changeRelateState=(relateMappingObj)=>{
    selectedHanlder.value.changeRelateState(relateMappingObj);
};

watch(relateMapping, async(newValue) => {
   relateMappingObj.value =newValue;
   await refreshRelateMappingDatas();
   await refreshItemIcon();
});
onMounted(async () => {
   await refreshRelateMappingDatas();
   await refreshItemIcon();
});

const refreshItemIcon = async () => {
    forbitIcon.value =wsClient.getIconBase64Str("FORBIT_ICON");
    if (relateMappingObj.value.relateType == 'STEP_TYPE') {
        itemIcon.value = wsClient.getIconBase64Str("STEP_ICON");
        itemIconGray.value = wsClient.getIconBase64Str("STEP_ICON_GRAY");
    }
    else if (relateMappingObj.value.relateType == 'TASK_TYPE') {
        itemIcon.value = wsClient.getIconBase64Str("TASK_ICON");
        itemIconGray.value = wsClient.getIconBase64Str("TASK_ICON_GRAY");
    }
    else if (relateMappingObj.value.relateType == 'PAGE_TYPE') {
        itemIcon.value = wsClient.getIconBase64Str("PAGE_ICON");
        itemIconGray.value = wsClient.getIconBase64Str("PAGE_ICON_GRAY");
    }
};

const checkSingleCellShowStatus = () => {
    if (relateMappingObj.value.relateType == 'STEP_TYPE') {
        return true;
    }
    if (relateMappingObj.value.relateType == 'ROUTE_TYPE') {
        return true;
    }
    if (relateMappingObj.value.relateType == 'PAGE_TYPE') {
        return true;
    }
    return false;
};

const checkSingleCellTagShowStatus = () => {
    if (relateMappingObj.value.relateType == 'PAGE_TYPE') {
        let parentItemInfo = relateMappingObj.value.parentItemInfo;
        let itemInfo = relateMappingObj.value.itemInfo;
        return parentItemInfo.mainPageID == itemInfo.id;
    }
    return false;
}

const checkComplexCellShowStatus = () => {
    if (relateMappingObj.value.relateType == 'TASK_TYPE') {
        return true;
    }
    return false;
};

const getOptionVisableState = (relateOperateOption) => {
    return relateItemOperator.value.getOptionVisableState(relateOperateOption,relateMappingObj.value);
};

const refreshRelateMappingDatas = async () => {
    let relateMappings = [];
    if (relateMappingObj.value.relateType == "TASK_TYPE") {
        relateMappings = await taskType.getItemRelateChildMapping(relateMappingObj.value.itemInfo);
    }
    else if (relateMappingObj.value.relateType == "SPIRIT_TYPE") {
        relateMappings = await spiritType.getItemRelateChildMapping(relateMappingObj.value.itemInfo);
    }
    relateMappingObj.value.itemInfo.relateMappings = convertRelateMappingItems(relateMappingObj.value.itemInfo.sequence, relateMappings);
}

const getRelateTitleIndex = (relateMappingObj, index) => {
    if (relateMappingObj.childrenItem) {
        return relateMappingObj.parentIndex + "." + index;
    }
    else {
        return relateMappingObj.index;
    }
};


const convertRelateMappingItems = (sequence, relateItemMappings) => {
    let ids = sequence ? sequence.split(",") : [];
    let tempObj = {};
    //任务映射的缓存，如果已经有缓存则不再调用，避免两个任务相互引用时，陷入无限死循环
    for (let relateMappingObj of relateItemMappings) {
        let key = relateMappingObj.id;
        tempObj[key] = relateMappingObj;
    }
    let sortResult = [];
    let index = 1;
    for (let id of ids) {
        let tempMapping = tempObj[id];
        if (tempMapping) {
            tempMapping.parentIndex = relateMappingObj.value.index;
            tempMapping.childrenItem = true;
            tempMapping.index = getRelateTitleIndex(tempMapping, index);
            sortResult.push(tempMapping);
            index++;
        }
    }
    return sortResult;
}


const doRelateOperate = (relateOperateOption) => {
    variableTypeShow.value = false;
    let eventData = {
        operateOption: relateOperateOption,
        relateMapping: relateMapping.value,
    };
    emits("onRelateOperate", eventData);
};
</script>
<style scoped>
.icon {
    align-self: center;
    align-items: center;
    display: inline-flex;
}

.rightIcon {
    align-self: center;
    display: inline-flex;
}
</style>