<template>
  <!--子场景页面-->
  <div class="bg-lightGrey height-full">
    <BackableNavBar title="子场景管理" leftText="返回" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <List class="bg-trans border none" v-model:loading="loading" :finished="finished" finished-text="没有更多了"
          @load="onDataLoad">
          <Cell center is-link v-for="sencneType in sencneTypeList" :key="sencneType.id" :title="sencneType.name"
            value="更多信息" @click="goSencneTypeInfos(sencneType)"></Cell>
        </List>
      </div>
    </div>
    <Button id="autoSpirit_sencneSetting_addBtn" round icon="plus" type="primary" class="addBtn"
      @click="goAddSencneTypePage"></Button>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let sencne = ref(pageDatas.sencne);
const loading = ref(false);
const finished = ref(false);
const sencneTypeList = ref([]);
onMounted(() => {
  let addBtn = document.getElementById("autoSpirit_sencneSetting_addBtn");
  addBtn.style.top = window.innerHeight - addBtn.offsetHeight - 40 + "px";
  addBtn.style.left = window.innerWidth - addBtn.offsetWidth - 20 + "px";
});
const onClickLeft = () => {
  let datas = backTargetPageDatas;
  datas.sencne = sencne.value;
  router.push({
    name: "SencneInfos",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
const onDataLoad = async () => {
  let params = await wsClient.sendActionToCloud(
    "GET_SENCNETYPES",
    sencne.value
  );
  let sencneTypes = params.resultData;
  sencneTypeList.value = sencneTypes;
  loading.value = false;
  finished.value = true;
};
const goAddSencneTypePage = () => {
  let datas = {
    sencne: sencne.value,
    backTargetPageDatas: {
      backTargetPageDatas: backTargetPageDatas
    }
  };
  router.push({
    name: "NewSencneType",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
const goSencneTypeInfos = (sencneType) => {
  let datas = {
    sencneType: sencneType,
    sencne: sencne.value,
    backTargetPageDatas: {
      backTargetPageDatas: backTargetPageDatas
    }
  };
  router.push({
    name: "SencneTypeInfos",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
</script>
<style scoped>
.addBtn {
  border-radius: 50%;
  position: absolute;
}
</style>