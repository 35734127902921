<template>
  <div class="padding">
    <div class="bg-white border solid">
      <!--任务操作页签面板--快捷操作按钮栏-->
      <Cell v-show="elementsHideConfig ? !elementsHideConfig.operateBar : true" class="bg-trans" title="">
        <template #right-icon>
          <div class="flex">
            <Icon class="rightIcon color-blue content-vcenter block-inline" name="add-o" size="20"
              @click="doAddRelateItem">
              <div class="rightText">关联</div>
            </Icon>
          </div>
        </template>
      </Cell>
      <!--步骤操作页签面板--步骤列表-->
      <List class="tasksPane" v-model:loading="relateLoading" :finished="relateFinished" finished-text="没有更多了">
        <CellGroup>
          <Collapse v-model="activeNames">
            <RelateCell v-if="!isReload" v-for="tempStepItem in itemRelateMappingsObj" :key="tempStepItem.key"
              :relateMapping="tempStepItem" :elementsHideConfig="elementsHideConfig"
              :relateItemOperator="relateItemOperator"
              @onRelateOperate="doRelateOperateAction">
            </RelateCell>
          </Collapse>
        </CellGroup>
      </List>
    </div>
  </div>
</template>
<script setup>
import { nextTick } from "vue";
import { ref, defineEmits, defineExpose, defineProps, toRefs, watch, reactive } from "vue";

const emits = defineEmits(["onAddRelateItem", "onRelateOperate"]);
const props = defineProps({
  //元素显示配置项
  elementsHideConfig: {
    type: Object
  },
  itemRelateMappings: {
    type: Array,
    default: []
  },
  loadingState: {
    type: Boolean,
  },
  relateItemOperator:{
    type:Object
  }
});
const { itemRelateMappings, loadingState, elementsHideConfig,relateItemOperator} = toRefs(props);
watch([loadingState], (newValue) => {
  relateLoading.value = loadingState.value;
  relateFinished.value = !loadingState.value;
});
watch(itemRelateMappings, () => {
  isReload.value = true;
  itemRelateMappingsObj = reactive(itemRelateMappings.value);
  nextTick(() => {
    isReload.value = false;
  })
});
let itemRelateMappingsObj = reactive(itemRelateMappings.value);
const isReload = ref(false);
const activeNames = ref(["1"]);
const relateLoading = ref(true);
const relateFinished = ref(false);
relateLoading.value = loadingState.value;
relateFinished.value = !loadingState.value;
/**
 * 相应添加映射关联的动作
 */
const doAddRelateItem = () => {
  emits("onAddRelateItem", {});
};

const doRelateOperateAction = (optionData) => {
  // emits("onRelateOperate", optionData);
  relateItemOperator.value.doRelateOperateAction(optionData);
}

const getCheckedItemInfos = () => {
  let selectedInfos = [];
  for (let tempMapping of itemRelateMappings.value) {
    selectedInfos.push(tempMapping.itemInfo);
  }
  return selectedInfos;
};

defineExpose({
  getCheckedItemInfos,
});
</script>
<style scoped>
.rightText {
  font-size: 14px;
  align-self: center;
}

.rightIcon {
  align-self: center;
  display: inline-flex;
}
</style>