<template>
  <div class="height-full">
    <div class="padding">
      <div class="bg-white border solid radius">
        <Cell class="bg-trans" title="选择要关联的页面："></Cell>
        <RelateSelectPane :itemRelateMappings="itemRelateMappings" :loadingState="loadingState"
          :relateItemOperator="operator" @onAddRelateItem="doAddRelateItem" />
        <Cell class="bg-trans" title="配置页面的路径："></Cell>
        <AppPageRouteSetting :itemRelateMappings="pageRelateObjectMappings"
          :relateItemOperator="appTypeOperator"
          :loadingState="loadingState" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, toRefs, ref, defineExpose, defineProps } from "vue";
import { useRouter } from "vue-router";
import util from "@/utils/util";
import NewAppTypeRelateItemOperator from "@/components/app/views/common/configPane/itemAction/NewAppTypeRelateItemOperator.js";
import NewPageTypeRelateOperator from "@/components/app/views/common/configPane/itemAction/NewPageTypeRelateOperator.js";
import optionsConfig from "@/components/app/views/common/stepType/OptionsConfig.js";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const hanlder = {
  getRouter: () => {
    return router;
  },
  getClientServer: () => {
    return wsClient;
  },
  getItemInfo: () => {
    return parentItemInfo;
  },
  getWatchedPageName: (relateMapping) => {
    let type = relateMapping.relateType;
    //查看的是步骤，则传递相关信息到步骤页面
    if (type == "PAGE_TYPE") {
      return "PageInfo";
    }
  },
  getBackPageName: () => {
    return "NewAppConfig";
  },
  updateRelateMappings: async(relateItemMappings) => {
    itemRelateMappings.value = relateItemMappings;
    pageRelateObjectMappings.value =await appTypeOperator.convertToPageToPageObj(itemRelateMappings.value);
    loadingState.value = false;
  },
  getRelateMappings: () => {
    return itemRelateMappings.value;
  },
};
const operator = new NewAppTypeRelateItemOperator();
operator.init(hanlder);
const appTypeOperator = new NewPageTypeRelateOperator();
appTypeOperator.init(hanlder);
//当前任务下所关联的步骤映射集
const itemRelateMappings = ref([]);
const pageRelateObjectMappings = ref([]);
const loadingState = ref(false);
const props = defineProps({
  operateDatas: {
    type: Object
  }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let parentItemInfo = firsData.itemInfo ? firsData.itemInfo : {};
// const appPageRoutePane =ref(null);
/**
* 相应添加映射关联的动作
*/
const doAddRelateItem = async () => {
  let itemsExcludeArr = [];
  for (let relateMapping of itemRelateMappings.value) {
    let key = relateMapping.itemInfo.key;
    itemsExcludeArr.push(key);
  }
  //构建元素选择页面数据对象
  let datas = {
    backTargetPage: "NewAppConfig",
    eventName: "AUTOSPIRIT_NEWAPPCONFIG_PAGESSELECT_EVENT",
    elementsHideConfig: {
      badge: true,
      addBtn: true,
    },
    itemsExclude: itemsExcludeArr,
    itemsType: ["PAGE_TYPE"],
    selectedMode: true,
    pageType: "1",
    pageTitle: "页面选择",
  };
  //相应选择确认事件的方法，执行关联增加动作
  const actionMethod = async (selectedItems) => {
    let sequence = parentItemInfo.sequence;
    let ids = sequence ? sequence.split(",") : [];
    let index = ids.length + 1;
    let timestamp = new Date().getTime();
    //基于选择的元素，遍历构建查询对象
    for (let selectedItem of selectedItems) {
      let tempMapping = {
        id: selectedItem.id + "@" + selectedItem.type + "@" + timestamp,
        parentID: parentItemInfo.id,
        parentType: parentItemInfo.type,
        relateID: selectedItem.id,
        relateType: selectedItem.type,
        itemInfo: selectedItem,
        index: index,
        operateOptions: util.cloneObject(optionsConfig.newPageInfoRelateMappingOperateOptions),
        parentItemInfo: parentItemInfo,
        disable: false
      };
      itemRelateMappings.value.push(tempMapping);
      ids.push(tempMapping.id);
      index++;
    }
    parentItemInfo.sequence = util.makeSequenceStr(ids);
    router.push({
      name: datas.backTargetPage,
    });
    pageRelateObjectMappings.value =await appTypeOperator.convertToPageToPageObj(itemRelateMappings.value);
  };
  datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
  router.push({
    name: "ItemSelect",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};

const validateOnNext = () => {
  return {
    resultCode: 1,
    message: "",
  };
};
const getOperateDatas = () => {
  return {
    sequence: parentItemInfo.sequence,
    mainPageID: parentItemInfo.mainPageID,
    itemRelateMappings: itemRelateMappings.value,
    pageItemRelateMappings: pageRelateObjectMappings.value,
  };
};
const resetDatas = async () => {
  itemRelateMappings.value = [];
  pageRelateObjectMappings.value=[];
  parentItemInfo.sequence = "";
  parentItemInfo.mainPageID = "";
  appTypeOperator.resetDatas();
};
defineExpose({
  resetDatas,
  validateOnNext,
  getOperateDatas
});
</script>