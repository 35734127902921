<!--步骤执行参数面板-->
<template>
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans">
                    <template #title>
                        <div>
                            <Checkbox class="content-vcenter block-inline" v-if="!isDisabled"
                                v-model="selectedStepActionType.randomDelay" shape="square"
                                @change="onParamValueChanged('randomDelay', selectedStepActionType.randomDelay)">
                            </Checkbox>
                            <Checkbox class="content-vcenter block-inline" v-if="isDisabled" disabled
                                v-model="selectedStepActionType.randomDelay" shape="square"></Checkbox>
                            <span class="content-vcenter block-inline margin-left">延迟时间设置为随机时间</span>
                            <Icon class="content-vcenter block-inline rightIcon" name="info-o" size="20"
                                @click="showTips('勾选后，延迟时间就会变成随机时间，不勾选则是固定时间，默认是固定时间')" />
                        </div>
                    </template>
                </Cell>
                <VariableSetPane v-if="!selectedStepActionType.randomDelay" :isDisabled="isDisabled"
                    :isNewAction="isNewAction" :stepActionType="selectedStepActionType" :itemInfo="itemInfo"
                    label="延迟时间(秒)" title="延迟时间" name="delayTime" type="VARIABLE_NUMBER" tipInfo="延迟指定时间之后执行此步骤，默认0.5秒"
                    @onValueChange="onActionStepParamChanged" @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <VariableSetPane v-if="selectedStepActionType.randomDelay" :isDisabled="isDisabled"
                    :isNewAction="isNewAction" :stepActionType="selectedStepActionType" :itemInfo="itemInfo"
                    label="最小时间(秒)" title="最小时间" name="minTime" type="VARIABLE_NUMBER"
                    tipInfo="延迟指定时间之后执行此步骤，随机时间在最小时间(默认0.5秒)跟最大时间范围内随机产生一个时间" @onValueChange="onActionStepParamChanged"
                    @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <VariableSetPane v-if="selectedStepActionType.randomDelay" :isDisabled="isDisabled"
                    :isNewAction="isNewAction" :stepActionType="selectedStepActionType" :itemInfo="itemInfo"
                    label="最大时间(秒)" title="最大时间" name="maxTime" type="VARIABLE_NUMBER"
                    tipInfo="延迟指定时间之后执行此步骤，随机时间在最小时间(默认0.5秒)跟最大时间范围内随机产生一个时间" @onValueChange="onActionStepParamChanged"
                    @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <VariableSetPane :isDisabled="isDisabled" :isNewAction="isNewAction"
                    :stepActionType="selectedStepActionType" :itemInfo="itemInfo" label="重复次数" title="重复次数"
                    name="repeatTime" type="VARIABLE_NUMBER" tipInfo="此步骤重复执行的次数，默认不重复，如果勾选极速重复，则在执行步骤的时候不会进行延迟时间等待"
                    @onValueChange="onActionStepParamChanged" @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <Cell class="bg-trans">
                    <template #title>
                        <Checkbox v-if="!isDisabled" class="content-vcenter block-inline"
                            v-model="selectedStepActionType.quickRepeat" shape="square"
                            @change="onParamValueChanged('square', selectedStepActionType.quickRepeat)"></Checkbox>
                        <Checkbox disabled v-if="isDisabled" class="content-vcenter block-inline"
                            v-model="selectedStepActionType.quickRepeat" shape="square"></Checkbox>
                        <span class="content-vcenter block-inline margin-left">极速重复</span>
                        <Icon class="content-vcenter rightIcon" name="info-o" size="20"
                            @click="showTips('此步骤重复执行的次数，默认不重复，如果勾选极速重复，则在执行步骤的时候不会进行延迟时间等待')" />
                    </template>
                </Cell>
                <VariableSetPane :isDisabled="isDisabled" :isNewAction="isNewAction"
                    :stepActionType="selectedStepActionType" :itemInfo="itemInfo" label="触发概率，百分之" title="触发概率"
                    name="percent" type="VARIABLE_NUMBER" tipInfo="此步骤会执行的概率，百分比，默认是100%"
                    @onValueChange="onActionStepParamChanged" @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <Cell class="bg-trans">
                    <template #title>
                        <span class="content-vcenter block-inline">运行成功后</span>
                        <Icon class="content-vcenter rightIcon" name="info-o" size="20"
                            @click="showTips('运行成功后要做的事情')" />
                        <Popover placement="top" :actions="operateActions" v-model:show="actionPopupShow">
                            <CellGroup class="width-popupGroup">
                                <Cell clickable v-for="action in operateActions" :key="action.id"
                                    v-model="action.selected" :title="action.name"
                                    :class="action.selected ? 'selected' : ''" @click="onActionSelected(action)">
                                    <template v-if="action.selected" #right-icon>
                                        <Icon name="success" class="selected" />
                                    </template>
                                </Cell>
                            </CellGroup>
                            <template #reference>
                                <Button v-if="!isDisabled"
                                    class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertOptionName(selectedStepActionType.nextOptionBySuccess)
                                    }}</Button>
                                <Button disabled v-if="isDisabled"
                                    class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertOptionName(selectedStepActionType.nextOptionBySuccess)
                                    }}</Button>
                            </template>
                        </Popover>
                    </template>
                </Cell>
                <VariableSetPane v-if="!isNewAction&&selectedStepActionType.nextOptionBySuccess=='GOTO_OTHER_STEP'" :isDisabled="isDisabled" :isNewAction="isNewAction"
                    :stepActionType="selectedStepActionType" :itemInfo="itemInfo" label="待跳转步骤" title="跳转步骤"
                    name="nextItemIDBySuccess" type="VARIABLE_STEP" tipInfo="需要跳转的步骤，该步骤在指定任务下"
                    @onFeildClick="gotoNextStepItemSelect">
                </VariableSetPane>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted, defineProps, toRefs, defineExpose } from "vue";
import { useRouter } from "vue-router";
import { showToast } from "vant";
import util from "@/utils/util";
import optionsConfig from "../../OptionsConfig";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();

let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const tabIndex = ref(0);

const props = defineProps({
    itemInfo: {
        type: Object
    },
    isNewAction: {
        type: Boolean,
        default: false
    },
    isDisabled: {
        type: Boolean,
        default: false
    },
});
const { itemInfo, isNewAction, isDisabled } = toRefs(props);


const gotoNextStepItemSelect =()=>{
    //构建当前页面的页面信息
    let pageDatas = {
        itemInfo: itemInfo.value,
        backTargetPage: backTargetPage,
        backTargetPageDatas: backTargetPageDatas,
        tabIndex: tabIndex.value,
    }
    let datas = {
        backTargetPage: hanlder.getBackPageName(),
        backTargetPageDatas: pageDatas,
        eventName: "AUTOSPIRIT_STEPINFO_STEPNEXTSTEPITEM_SELECT_EVENT",
        stepInfo: itemInfo.value,
    };
    //处理步骤类型改变动作
    const actionMethod = async (eventDatas) => {
        let selectedStepMapping =eventDatas.selectedStepMapping;
        let itemInfo = selectedStepMapping.itemInfo;
        let typeObj = typeUtil.getItemTypeObj(itemInfo.type);
        let newValue =`【${selectedStepMapping.id}:${typeObj.name}】${itemInfo.name}`
        onParamValueChanged("nextItemIDBySuccess",newValue);
        router.push({
            name: datas.backTargetPage,
            state: {
                pageDatas: JSON.stringify(pageDatas)
            }
        });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "NextStepItemSelect",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};

/**
 * 提示信息
 * @param {*} message 
 */
const showTips = (message) => {
    showToast({
        message: message,
        position: 'top'
    });
};

const onParamValueChanged = (title, value) => {
    let eventData = {
        paramName: title,
        paramValue: value,
    }
    onActionStepParamChanged(eventData);
}

//执行成功之后的操作选项
const operateActions = util.cloneObject(optionsConfig.stepOperateOptionsBySuccess);
/**
 * 响应操作选项选择事件
 * @param {*} action 
 */
const onActionSelected = (action) => {
    if (selectedStepActionType.value.selectedAction)
        selectedStepActionType.value.selectedAction.selected = false;
    selectedStepActionType.value.selectedAction = action;
    selectedStepActionType.value.selectedAction.selected = true;
    selectedStepActionType.value.nextOptionBySuccess = action.id;
    actionPopupShow.value = false;
    onParamValueChanged("nextOptionBySuccess", action.id);
}

const convertOptionName = (id) => {
    //初始化设置默认选择的操作事件
    for (let action of operateActions) {
        if (action.id == id) {
            return action.name;
        }
    }
    return id;
}

const actionPopupShow = ref(false);

const selectedStepActionType = ref({});

let hanlder={};

const onActionStepParamChanged = (eventData) => {
    hanlder.onActionStepParamChanged(eventData);
};

const onMappingChanged=(eventData)=>{
    hanlder.onMappingChanged(eventData);
}

/**
 * 重置面板数据
 * @param {*} stepActionTypeInfo 
 */
const resetData = async (hander,index) => {
    hanlder =hander;
    tabIndex.value =index;
    selectedStepActionType.value = hanlder.getStepActionInfos();
    //初始化设置默认选择的操作事件
    for (let action of operateActions) {
        if (selectedStepActionType.value.nextOptionBySuccess==action.id) {
            selectedStepActionType.value.selectedAction = action;
            selectedStepActionType.value.selectedAction.selected = true;
        }
    }
};

const getID=()=>{
    return "AUTOSPIRIT_RUNPARAMETER";
};

defineExpose({
    resetData,
    getID,
});
</script>
<style scoped>
.rightIcon {
    line-height: 2;
}
</style>
