const util = require("@/utils/util");
const typeUtil = require("@/components/app/views/common/configPane/itemType/itemTypeUtil.js");
const { showToast } = require("vant");
module.exports = class BaseRelateItemOperator {
  id = "DEFAULT_TYPE_OPERATOR";
  constructor() {

  };
  init(handler) {
    this.handler = handler;
    return this;
  }
  /**
 * 响应关联按钮操作事件
 * @param {事件对象} eventData 
 */
  doRelateOperateAction(eventData) {
    let operateOption = eventData.operateOption;
    let relateMapping = eventData.relateMapping;
    let operateID = operateOption.id;
    switch (operateID) {
      //查看步骤信息事件
      case "RELATEMAPPING_ITEM_WATCH_INFOS":
        this.doItemWatchInfosAction(relateMapping, eventData);
        break;
      //移动当前步骤到下一步事件
      case "RELATEMAPPING_ITEM_INDEX_NEXT":
        this.doRelateMappingIndexNextAction(relateMapping, eventData);
        break;
      //移动当前步骤到上一步事件
      case "RELATEMAPPING_ITEM_INDEX_LAST":
        this.doRelateMappingIndexLastAction(relateMapping, eventData);
        break;
      //删除当前步骤映射事件  
      case "RELATEMAPPING_ITEM_DELETE":
        this.doRelateMappingDeleteAction(relateMapping, eventData);
        break;
    };
  };

 
  /**
 * 响应操作事件，更新当前映射索引至上一个索引
 * @param {映射对象} relateMapping 
 */
  doRelateMappingIndexLastAction = (relateMapping, eventData) => {
    let itemInfo = this.handler.getItemInfo();
    let sequence = itemInfo.sequence;
    let ids = sequence ? sequence.split(",") : [];
    let idsRsult = [...ids];
    //遍历索引队列，找到当前映射所在的位置
    for (let i = 0; i < ids.length; i++) {
      let tempID = ids[i];
      if (tempID == relateMapping.id) {
        //如果当前映射不是第一个位置，则进行位置交换动作，刷新云端数据并排序
        if (i >= 1) {
          let nextID = ids[i - 1];
          idsRsult[i - 1] = tempID;
          idsRsult[i] = nextID;
          let newSequence = util.makeSequenceStr(idsRsult);
          this.updateSequenceAndSort(newSequence);
        }
        break;
      }
    }
  };

  /**
 * 更新当前任务最新的映射队列，并重新排序
 * @param {映射队列字符串} newSequence 
 */
  updateSequenceAndSort = async (newSequence) => {
    let appInfo = this.handler.getItemInfo();
    appInfo.sequence = newSequence;
    let taskRelateMappings = this.handler.getRelateMappings();
    this.sortRelateMappings(newSequence, taskRelateMappings);
  }

  /**
 * 响应下移事件 将当前对象的索引往下移动
 * @param {映射对象} relateMapping 
 */
  doRelateMappingIndexNextAction = (relateMapping, eventData) => {
    let itemInfo = this.handler.getItemInfo();
    let sequence = itemInfo.sequence;
    let ids = sequence ? sequence.split(",") : [];
    let idsRsult = [...ids];
    //遍历映射队列ID集，找到当前映射的位置，然后进行位置调整
    for (let i = 0; i < ids.length; i++) {
      let tempID = ids[i];
      //找到对应映射
      if (tempID == relateMapping.id) {
        //当前映射不在最后一个位置时，进行操作，和下一个映射互换位置
        if (i <= ids.length - 1) {
          let nextID = ids[i + 1];
          idsRsult[i + 1] = tempID;
          idsRsult[i] = nextID;
          let newSequence = util.makeSequenceStr(idsRsult);
          this.updateSequenceAndSort(newSequence);
        }
        break;
      }
    }
  };

  /**
  * 响应操作事件，查看当前步骤的详情信息
  * @param {映射对象} relateMapping 
  */
  doItemWatchInfosAction = (relateMapping, eventData) => {
    let datas = {
      itemInfo: relateMapping.itemInfo,
      backTargetPage: this.handler.getBackPageName(),
      backTargetPageDatas: this.handler.getBackPageDatas()
    };
    let targetPage = this.handler.getWatchedPageName(relateMapping);
    //查看的是步骤，则传递相关信息到步骤页面
    this.handler.getRouter().push({
      name: targetPage,
      state: {
        pageDatas: JSON.stringify(datas)
      }
    });
  };

  /**
 * 基于队列字符串进行映射对象集的排序
 * @param {映射队列} sequence 
 * @param {映射对象集} relateMappings 
 */
  sortRelateMappings = (sequence, relateMappings) => {
    let ids = sequence ? sequence.split(",") : [];
    let tempObj = {};
    //遍历对象集，构建缓存
    for (let mapping of relateMappings) {
      let key = mapping.id;
      tempObj[key] = mapping;
    }
    let sortResult = [];
    let index = 1;
    //遍历映射队列，基于队列顺序取出缓存对象，构建新的映射集
    for (let id of ids) {
      let tempMapping = tempObj[id];
      if (tempMapping) {
        tempMapping.index = index;
        // tempMapping.parentItemInfo = spiritInfo.value;
        sortResult.push(tempMapping);
        index++;
      }
    }
    this.handler.updateRelateMappings(sortResult);
  };

  /**
 * 响应删除映射事件
 * @param {映射对象} relateMapping 
 */
  doRelateMappingDeleteAction = async (relateMapping, eventData) => {
    let responseRelateDatas = await this.deleteRelateMapping(relateMapping);
    this.onRelateMappingDelete(responseRelateDatas);
  };

  deleteRelateMapping = async (relateMapping) => {
    let datas = {
      relateMappings: [relateMapping],
    }
    let responseRelateDatas = await this.handler.getClientServer().sendActionToCloud(
      "DELETE_RELATE_MAPPINGS",
      datas
    );
    return responseRelateDatas;
  }

  createRelateMapping=async(queryData)=>{
    const wsClient = this.handler.getClientServer();
    let responseDatas = await wsClient.sendActionToCloud(
      "CREATE_RELATE_MAPPINGS",
      queryData
    );
    return responseDatas;
  };
  

  onRelateMappingDelete = (responseRelateDatas) => {
    //删除成功了重新加载映射
    if (responseRelateDatas.resultCode == 1) {
      let appInfo = this.handler.getItemInfo();
      appInfo.sequence = responseRelateDatas.resultData;
      this.initRelateMappingDatas(appInfo);
    }
    let params = {
      message: responseRelateDatas.message,
      position: "bottom",
    };
    showToast(params);
  };

  /**
 * 初始化精灵关联的任务映射信息集
 * @param {任务对象信息} appInfo 
 */
  initRelateMappingDatas = async (appInfo) => {
    let itemsType = typeUtil.getItemTypeObj(appInfo.type);
    itemsType.init(this.handler.getClientServer(),this.handler.getRouter());
    let responseRelateDatas = await itemsType.getItemRelateChildMapping(appInfo);
    //成功后进行排序
    this.sortRelateMappings(appInfo.sequence, responseRelateDatas);
  };

  getOptionVisableState(relateOperateOption, relateMappingObj) {
    if (relateOperateOption.id == "RELATEMAPPING_ITEM_INDEX_NEXT") {
      let sequence = relateMappingObj.parentItemInfo.sequence;
      let ids = sequence ? sequence.split(",") : [];
      return relateMappingObj.index < ids.length;
    }
    else if (relateOperateOption.id == "RELATEMAPPING_ITEM_INDEX_LAST") {
      return relateMappingObj.index > 1;
    }
    return true;
  };

}