<!--页面路径设置单元组件-->
<template>
    <CollapseItem v-show="checkComplexCellShowStatus()" :name="relateMappingObj.index">
        <template #title>
            <div class="flex">
                <div>【{{ relateMappingObj.index }}】</div>
                <Icon class="icon" :name="itemIcon" size="20"></Icon>
                <div class="padding-left">{{ relateMappingObj.itemInfo.name }}【#{{ relateMappingObj.itemInfo.id }}】</div>
                <Tag v-show="checkMainPageTagShowStatus()" plain type="primary">首页</Tag>
            </div>
        </template>
        <!--页面下路径页面到页面映射-->
        <Collapse v-model="activeNames">
            <CollapseItem v-for="tempPage2PageMapping in relateMappingObj.page2pageMapping" :key="tempPage2PageMapping.id">
                <template #title>
                    <div class="flex" v-if="!reload">
                        <Icon class="icon" v-show="getRouteInfoIconVisable(tempPage2PageMapping)" :name="routeIcon"
                            size="20"></Icon>
                        <Icon class="icon" v-show="!getRouteInfoIconVisable(tempPage2PageMapping)" :name="routeIconGrey"
                            size="20"></Icon>
                        <div class="padding-left text">{{ tempPage2PageMapping.startPage.name }}【{{ tempPage2PageMapping.startPage.id}}】</div>
                        <Icon class="icon" v-show="getRouteArrowInfoIconVisable(tempPage2PageMapping)"
                            :name="rightArrowIcon" size="20"></Icon>
                        <Icon class="icon" v-show="!getRouteArrowInfoIconVisable(tempPage2PageMapping)"
                            :name="rightArrowIconGrey" size="20"></Icon>
                        <div class="padding-left text">{{ tempPage2PageMapping.endPage.name }}【{{ tempPage2PageMapping.endPage.id}}】</div>
                        <Icon class="icon" v-show="getPassedState(tempPage2PageMapping) == 1" color="#1989fa"
                            name="passed" size="20"></Icon>
                        <Icon class="icon" v-show="getPassedState(tempPage2PageMapping) == -1" color="#f60505"
                            name="warning-o" size="20"></Icon>
                    </div>
                </template>
                <template #right-icon>
                    <Popover placement="top-end" v-model:show="tempPage2PageMapping.variableTypeShow">
                        <CellGroup class="width-popupGroup">
                            <Cell clickable v-for="relateOperateOption in relateMappingOperateOptions"
                                :key="relateOperateOption.id" :title="relateOperateOption.name"
                                v-show="getOptionVisableState(relateOperateOption, relateMappingObj, tempPage2PageMapping)"
                                @click="doRelateOperate(relateOperateOption, tempPage2PageMapping)">
                            </Cell>
                        </CellGroup>
                        <template #reference>
                            <div class="flex content-vcenter"
                                @click.stop="tempPage2PageMapping.variableTypeShow = !tempPage2PageMapping.variableTypeShow"
                                v-show="!relateMappingObj.childrenItem && elementsHideConfig ? !elementsHideConfig.operateBar : true">
                                <Icon class="rightIcon" name="bars" size="20" />
                            </div>
                        </template>
                    </Popover>
                </template>
            </CollapseItem>
        </Collapse>
    </CollapseItem>
</template>
<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, onMounted, ref, watch,nextTick} from "vue";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props = defineProps({
    //元素显示配置项
    elementsHideConfig: {
        type: Object
    },
    //关联映射，这里基本是路径的映射
    relateMapping: {
        type: Object,
    },
    //映射元素的操作器
    relateItemOperator: {
        type: Object
    }
});
const pageType = typeUtil.getItemTypeObj('PAGE_TYPE');
pageType.init(wsClient);

const emits = defineEmits(["onRelateOperate"]);
const { relateMapping, elementsHideConfig, relateItemOperator } = toRefs(props);
const relateMappingObj = ref(relateMapping.value);
// const page2pageMapping =ref([]);
const itemIcon = ref("");
const routeIcon = ref("");
const routeIconGrey = ref("");
const rightArrowIcon = ref("");
const rightArrowIconGrey = ref("");
const activeNames = ref(["1"]);
const relateMappingOperateOptions = relateItemOperator.value?relateItemOperator.value.getOperateOptions():[];
const reload =ref(false);
watch(relateMapping, (newValue) => {
    refreshItemIcon();
    refreshRelateMappingDatas();
});
onMounted(() => {
    refreshItemIcon();
    refreshRelateMappingDatas();
});

/**
 * 刷新路径标题
 */
const refreshRouteTtitle=()=>
{
    reload.value =true;
    nextTick(()=>{
        reload.value=false;
    });
}

/**
 * 刷新路径颜色，一般设置路径之后会量化
 */
const refreshItemIcon = () => {
    itemIcon.value = wsClient.getIconBase64Str("PAGE_ICON");
    routeIcon.value = wsClient.getIconBase64Str("ROUTE_ICON");
    routeIconGrey.value = wsClient.getIconBase64Str("ROUTE_ICON_GREY");
    rightArrowIcon.value = wsClient.getIconBase64Str("RIGHT_ARROW");
    rightArrowIconGrey.value = wsClient.getIconBase64Str("RIGHT_ARROW_GREY");
};

/**
 * 判断元素是否显示
 */
const checkComplexCellShowStatus = () => {
    if (relateMappingObj.value.relateType == 'PAGE_TYPE') {
        return true;
    }
    return false;
};

/**
 * 判断元素的图标是否需要显示，当有映射路径信息设置时显示
 * @param {*} tempPage2PageMapping 
 */
const getRouteInfoIconVisable = (tempPage2PageMapping) => {
    return tempPage2PageMapping.routeInfoMapping ? true : false;
};

/**
 * 判断元素路径中的箭头标签是否显示，当有映射路径设置，且路径步骤测试为通过时，亮化显示
 * @param {*} tempPage2PageMapping 
 */
const getRouteArrowInfoIconVisable = (tempPage2PageMapping) => {
    if (tempPage2PageMapping.routeInfoMapping) {
        let itemInfo = tempPage2PageMapping.routeInfoMapping.itemInfo;
        return itemInfo.passed;
    }
    else {
        return false;
    }
}

/**
 * 获取路径测试是否通过标记值
 * @param {*} tempPage2PageMapping 
 */
const getPassedState = (tempPage2PageMapping) => {
    if (tempPage2PageMapping.routeInfoMapping) {
        let itemInfo = tempPage2PageMapping.routeInfoMapping.itemInfo;
        return itemInfo.passed ? 1 : -1;
    }
    else {
        return 0;
    }
}

/**
 * 检测主页标记是否需要显示
 */
const checkMainPageTagShowStatus = () => {
    if (relateMappingObj.value.relateType == 'PAGE_TYPE') {
        let parentItemInfo = relateMappingObj.value.parentItemInfo;
        let itemInfo = relateMappingObj.value.itemInfo;
        return parentItemInfo.mainPageID == itemInfo.id;
    }
    return false;
}

/**
 * 获取操作项的显示状态
 * @param {*} relateOperateOption 
 * @param {*} relateMappingObj 
 * @param {*} tempPage2PageMapping 
 */
const getOptionVisableState = (relateOperateOption, relateMappingObj, tempPage2PageMapping) => {
    let eventData={
        relateMappingObj,
        page2PageMapping:tempPage2PageMapping
    }
    return relateItemOperator.value.getOptionVisableState(relateOperateOption, eventData);
};

/**
 * 刷新关联的映射
 */
const refreshRelateMappingDatas = async () => {
    let relateMappings = await pageType.getItemRelateChildMapping(relateMappingObj.value.itemInfo);
    relateMappingObj.value.itemInfo.relateMappings = convertRelateMappingItems(relateMappingObj.value.itemInfo.sequence, relateMappings);
}

/**
 * 获取映射的标题
 * @param {*} relateMappingObj 
 * @param {*} index 
 */
const getRelateTitleIndex = (relateMappingObj, index) => {
    if (relateMappingObj.childrenItem) {
        return relateMappingObj.parentIndex + "." + index;
    }
    else {
        return relateMappingObj.index;
    }
};


/**
 * 加工任务映射
 * @param {*} sequence 
 * @param {*} relateItemMappings 
 */
const convertRelateMappingItems = (sequence, relateItemMappings) => {
    let ids = sequence ? sequence.split(",") : [];
    let tempObj = {};
    //任务映射的缓存，如果已经有缓存则不再调用，避免两个任务相互引用时，陷入无限死循环
    for (let relateMappingObj of relateItemMappings) {
        let key = relateMappingObj.id;
        tempObj[key] = relateMappingObj;
    }
    let sortResult = [];
    let index = 1;
    for (let id of ids) {
        let tempMapping = tempObj[id];
        if (tempMapping) {
            tempMapping.parentIndex = relateMappingObj.value.index;
            tempMapping.childrenItem = true;
            tempMapping.index = getRelateTitleIndex(tempMapping, index);
            sortResult.push(tempMapping);
            index++;
        }
    }
    return sortResult;
}

/**
 * 响应关联操作项动作，触发对应事件
 * @param {} relateOperateOption 
 * @param {*} tempPage2PageMapping 
 */
const doRelateOperate = (relateOperateOption, tempPage2PageMapping) => {
    tempPage2PageMapping.variableTypeShow = false;
    let eventData = {
        operateOption: relateOperateOption,
        relateMapping: relateMappingObj.value,
        relatePage2PageMapping: tempPage2PageMapping,
        refresh:refreshRouteTtitle,
    };
    emits("onRelateOperate", eventData);
};
</script>
<style scoped>
.icon {
    align-self: center;
    align-items: center;
    display: inline-flex;
}

.text{
    overflow: hidden;
    max-width: 105px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rightIcon {
    align-self: center;
    display: inline-flex;
}
</style>