const typeUtil = require("@/components/app/views/common/configPane/itemType/itemTypeUtil.js");
module.exports = class StepActionTypeOperator {
    init(handler) {
        this.handler = handler;
    };
    getBackPageName=()=>{
        return this.handler.backPageName;
    };
    getOccusConditionMappings = () => {
        return this.handler.occusConditionMappings.value;
    };
    refreshOccusConditions = async () => {
        let actionStepTypeObj = typeUtil.getStepActionTypeObj(this.handler.stepActionInfos.value.stepActionTypeID);
        actionStepTypeObj.init(this.handler.wsClient);
        let mappings = await actionStepTypeObj.getOccusEventConditionMappings(this.handler.stepActionInfos.value);
        this.handler.occusConditionMappings.value = mappings ? mappings : [];
    };
    deleteOccusCondtionMapping = async (occusConditionMapping) => {
        let responseDatas = await this.handler.wsClient.sendActionToCloud("DELETE_OCCUSEVENTCONDITIONMAPPINGS", {
            occusConditionMappings: [occusConditionMapping]
        });
        if (responseDatas.resultCode == 1) {
            await this.refreshOccusConditions();
        }
    };
    createOccusCondtionMapping = async (occusConditionMapping) => {
        let responseDatas = await this.handler.wsClient.sendActionToCloud("CREATE_OCCUSEVENTCONDITIONMAPPINGS", {
            occusConditionMappings: [occusConditionMapping]
        });
        if (responseDatas.resultCode == 1) {
            await this.refreshOccusConditions();
        }
    };
    updateOccusCondtionMapping = async (occusConditionMapping) => {
        let responseDatas = await this.handler.wsClient.sendActionToCloud("UPDATE_OCCUSEVENTCONDITIONMAPPINGS", {
            occusConditionMappings: [occusConditionMapping]
        });
        if (responseDatas.resultCode == 1) {
            await this.refreshOccusConditions();
        }
    };
    onAddOccusEventCondition = async (eventDatas) => {
        let mapping = eventDatas.occusEventConditionMapping;
        let newOccusCondition = mapping.stepActionInfo;
        let tempID = newOccusCondition.id;
        newOccusCondition.tempStrID = tempID;
        delete newOccusCondition.id;
        let itemActionType = typeUtil.getStepActionTypeObj(newOccusCondition.stepActionTypeID);
        itemActionType.init(this.handler.wsClient);
        //创建条件判断对象
        let responseDatas = await itemActionType.createStepActionTypeInfos(newOccusCondition);
        if (responseDatas.resultCode == 1) {
            let conditionID = responseDatas.resultData.id;
            //条件映射ID
            mapping.actionStepItemID = conditionID;
            let conditionProMappings = mapping.variableProsMappings;
            //更新条件属性映射ID
            for (let variableProMapping of conditionProMappings) {
                variableProMapping.itemID = conditionID;
                delete variableProMapping.id;
            }
            //创建条件属性映射
            responseDatas = await this.handler.wsClient.sendActionToCloud("CREATE_VARIABLEPROMAPPING_INFOS", {
                variableProMappings: conditionProMappings
            });
        }
        delete mapping.id;
        responseDatas = await this.handler.wsClient.sendActionToCloud("CREATE_OCCUSEVENTCONDITIONMAPPINGS", {
            occusConditionMappings: [mapping]
        });
        return responseDatas;
    };
    //删除控制条件数据
    deleteConditionData = async (condtion) => {
        if (condtion.isNew) {
            let index = 0;
            for (let tempCondition of this.handler.conditionOperateDatas.value) {
                if (tempCondition.id == condtion.id) {
                    break;
                }
                index++;
            }
            this.handler.conditionOperateDatas.value.splice(index, 1);
        }
        else {
            let stepActionObj = typeUtil.getStepActionTypeObj(this.handler.stepActionInfos.value.stepActionTypeID);
            stepActionObj.init(this.handler.wsClient);
            await stepActionObj.deleteVariableOperateMappings([condtion]);
            await this.refreshOperateConditions();
        }
    };
    /**
     * 添加控制条件
     */
    addCondtionData = () => {
        let timestamp = new Date().getTime();
        let tempData = {
            id: timestamp,
            itemID: this.handler.stepActionInfos.value.id,
            itemType: this.handler.stepActionInfos.value.stepActionTypeID,
            variableID: "",
            variableOperateType: "VARIABLE_CONTROL_ACTION_DEFAULT",
            eventOccsType: "VARIABLE_CONTROL_EVENT_DEFAULT",
            isNew: true,
        };
        this.handler.conditionOperateDatas.value.push(tempData);
    };
    refreshOperateConditions = async () => {
        let stepActionObj = typeUtil.getStepActionTypeObj(this.handler.stepActionInfos.value.stepActionTypeID);
        stepActionObj.init(this.handler.wsClient);
        let datas = await stepActionObj.getRelateVariableOperateMappings(this.handler.stepActionInfos.value);
        this.handler.conditionOperateDatas.value = datas ? datas : [];
    };
    getConditionDataByID = (id) => {
        let result;
        for (let tempCondition of this.handler.conditionOperateDatas.value) {
            if (tempCondition.id == id) {
                result = tempCondition;
            }
        }
        return result;
    };
    onConditionValueChanged = async (eventData) => {
        let conditionData = this.getConditionDataByID(eventData.id);
        let changedParamName = eventData.changedParamName;
        let value = eventData.value;
        conditionData[changedParamName] = value;
        let stepActionObj = typeUtil.getStepActionTypeObj(this.handler.stepActionInfos.value.stepActionTypeID);
        stepActionObj.init(this.handler.wsClient);
        //如果是新建的，更新后创建，否则更新
        if (conditionData.isNew) {
            delete conditionData.id;
            await stepActionObj.createVariableOperateMappings([conditionData]);
        }
        else {
            await stepActionObj.updateVariableOperateMappings([conditionData]);
        }
        await this.refreshOperateConditions();
    };
    getVariableControlConditions = () => {
        return this.handler.conditionOperateDatas.value;
    };
    getStepActionInfos = () => {
        return this.handler.stepActionInfos.value;
    };
    onActionStepParamChanged = (eventData) => {
        let key = eventData.paramName;
        let value = eventData.paramValue;
        this.handler.stepActionInfos.value[key] = value;
        let stepActionObj = typeUtil.getStepActionTypeObj(this.handler.stepActionInfos.value.stepActionTypeID);
        stepActionObj.init(this.handler.wsClient);
        stepActionObj.saveStepActionTypeInfos(this.handler.stepActionInfos.value);
    };
    onMappingChanged = async(eventData) => {
        let key = eventData.paramName;
        let value = eventData.paramValue;
        let mapping = eventData.mapping;
        // console.log(mapping);
        this.handler.stepActionInfos.value[key] = value;
        let stepActionObj = typeUtil.getStepActionTypeObj(this.handler.stepActionInfos.value.stepActionTypeID);
        stepActionObj.init(this.handler.wsClient);
        stepActionObj.saveStepActionTypeInfos(this.handler.stepActionInfos.value);
        //如果有ID，说明这个属性之前就有变量关联，直接更新即可
        if (mapping.id) {
            //如果存在映射ID，则更新，否则删除
            if (mapping.variableID) {
                stepActionObj.updateVariableProMappings([mapping]);
            }
            else {
                stepActionObj.deleteVariableProMappings([mapping]);
            }

        }
        else {
            mapping.itemID = this.handler.stepActionInfos.value.id;
            mapping.itemType = this.handler.stepActionInfos.value.stepActionTypeID;
            mapping.variableProName = key;
            let resultData =await stepActionObj.createVariableProMappings([mapping]);
            mapping.id =resultData[0].id;
        }
    }

}