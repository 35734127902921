<template>
   <!--底部弹框输入面板，支持多种类型输入控件-->
  <Popup v-model:show="autoSpiritProsetGloblePopup" round position="bottom" :style="{ height: height }">
    <div class="width-full padding-top text-center flex center">
      <Button class="s18 block-inline button sm border none" @click="exitSetProValue">取消</Button>
      <div class="s18 block-inline width-full">{{ proSetTitle }}</div>
      <Button class="s18 block-inline button sm color-blue border none" @click="doSummit">确定</Button>
    </div>
    <div class="width-full padding-top flex center">
        <div class="width-full block-inline padding">
          <Field v-if="inputType == 'text'" @click-right-icon="clearInput" v-model="runValue"
          right-icon="cross" :placeholder="'请输入' + proSetTitle" class="newSenceField"></Field>
          <Field
          class="newSenceField"
          v-model="runValue"
          v-if="inputType == 'textarea'"
          rows="3"
          autosize
          type="textarea"
          :maxlength="maxLength"
          :placeholder="'请输入' + proSetTitle"
          @click-right-icon="clearInput"
          right-icon="cross"
          show-word-limit
        />
        <DatePicker v-if="inputType == 'datePicker'" :min-date="minDate" :formatter="formatter"
        :max-date="maxDate" :show-toolbar="false" v-model="runValue" :columns-type="columnsType"/>
        <TimePicker v-if="inputType == 'timePicker'" :show-toolbar="false" v-model="runValue" :formatter="formatter"
         :columns-type="columnsType"/>
         <component v-if="inputType == 'pageComponent'" :is="componentPath" :datas="componentDatas" @onValueUpdate="updateComponentValue"></component>
        </div>
        
      </div>
   
  </Popup>
</template>
<script setup>
import {getCurrentInstance, watch, ref,defineEmits,onMounted,onUnmounted,defineAsyncComponent,shallowRef} from "vue";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const emit = defineEmits(["onSubmit", "onCancel"]);
//标题
const proSetTitle =ref("");
//值
const proValue =ref("");
//输入区域的类型，目前分text，textare，datapicker，timepicker等，分别对应不同的输入方式
const inputType =ref("text");
//最大字符限制，textarea输入方式时生效
const maxLength =ref("100");
const height =ref("40%");
//全局显示变量，为true时面板显示
const autoSpiritProsetGloblePopup =ref(false);
let submit =null;
let cancel =null;
/**
 * 时间选择区间，为日期输入方式时生效
 */
const minDate=ref({});
const maxDate=ref({});
let currentDate;
let currentYear;
let currentMonth;
let currentDay;
let currentHour;
let currentMinute;
let currentSecond;
//时间输入控件格式化变量，为日期输入方式时生效
const columnsType =ref([]);
const formatter=ref({});
//当前正在显示的值
const runValue =ref(proValue.value);

//自定义类型组件路径
const componentPath=shallowRef({});
//自定义组件的参数
const componentDatas=shallowRef({});

/**
 * 组件的关键结果值发生变化时，响应事件，同步更新面板的结果值
 * @param {*} datas 
 */
const updateComponentValue=(datas)=>{
  // console.log(datas);
  runValue.value =datas;
};

const dateFormatter = (type, option) => {
      if (type === 'year') {
        option.text += '年';
      }
      if (type === 'month') {
        option.text += '月';
      }
      if (type === 'day') {
        option.text += '日';
      }
      return option;
    };

  
const timeFormatter = (type, option) => {
      if (type === 'hour') {
        option.text += '时';
      }
      if (type === 'minute') {
        option.text += '分';
      }
      if (type === 'second') {
        option.text += '秒';
      }
      return option;
    };

/**
 * 基于显示的格式生成当前的时间空间默认值
 * @param {*} columsType 
 */    
const makeCurrentDataByColumnType =(columsType)=>{
    let resultArr =[];
    for(let index in columsType)
    {
      let timeType =columsType[index];
      switch(timeType)
      {
        case 'year':
         resultArr.push(currentYear);
         continue;
        case 'month':
          resultArr.push(currentMonth);
          continue;
        case 'day':
          resultArr.push(currentDay);
          continue;
        case 'hour':
          resultArr.push(currentHour);
          continue;
        case 'minute':
          resultArr.push(currentMinute);
          continue;
        case 'second':
          resultArr.push(currentSecond);
          continue;
        default:
      }
    }
    return resultArr;
}

/**
 * 更新显示值的默认值状态，目前只有为日期时间输入控件时，才会默认选择当前的时间
 */
const updateDefalutRunValue=()=>{
  switch(inputType.value)
  {
    case "datePicker":
      if(columnsType.value.length==0)
      {
        columnsType.value =['year','month','day'];
      }
      if(runValue.value.length == 0)
      {
        runValue.value = makeCurrentDataByColumnType(columnsType.value);
      }
      break;
    case "timePicker":
      if(columnsType.value.length==0)
      {
        columnsType.value =['hour','minute','second'];
      }
      if(runValue.value.length == 0)
      {
        runValue.value = makeCurrentDataByColumnType(columnsType.value);
      }
      break;
    default:
  }
};
/**
 * 清空值
 */
const clearInput = () => {
  switch(inputType.value)
  {
    case "text":
    case "textarea":
      runValue.value = "";
      proValue.value ="";
      break;
    case "datePicker":
    case "timePicker":
      runValue.value = [];
      proValue.value =[];
      break;
    default:
      runValue.value = "";
      proValue.value ="";
  }
 
};
/**
 * 取消设置，取消时清空输入的数据
 */
const exitSetProValue = () => {
  emit("onCancel");
  if(cancel)cancel();
  autoSpiritProsetGloblePopup.value=false;
  clearInput();
};
/**
 * 提交输入
 */
const doSummit = () => {
  emit("onSubmit", runValue.value);
  if(submit)submit(runValue.value);
  autoSpiritProsetGloblePopup.value=false;
  clearInput();
};
watch(proValue, (newValue, oldValue) => {
  runValue.value = newValue;
  // console.log(runValue.value);
  updateDefalutRunValue();
});

/**
 * 注册的全局弹框监听响应方法
 * @param {*} eventDatas 
 */
const actionListener = (eventDatas)=>{
    // console.log(eventDatas);
    autoSpiritProsetGloblePopup.value=true;
    if(eventDatas["title"])proSetTitle.value = "设置" + eventDatas["title"];
    proValue.value = eventDatas["value"]?eventDatas["value"]:"";
    if(eventDatas["type"])inputType.value = eventDatas["type"];
    height.value = eventDatas["height"]?eventDatas["height"]:"40%";
    if(eventDatas["submit"])submit = eventDatas["submit"];
    if(eventDatas["cancel"])cancel = eventDatas["cancel"];
    if(eventDatas["columnsType"])columnsType.value = eventDatas["columnsType"];
    if(eventDatas["componentPath"])componentPath.value = defineAsyncComponent(() => import(`@/${eventDatas["componentPath"]}.vue`));
    if(eventDatas["componentDatas"])componentDatas.value = {...eventDatas,...eventDatas["componentDatas"]};
    //基于输入类型判断默认的格式
    if(inputType.value=="datePicker")
    {
      formatter.value = eventDatas["formatter"]?eventDatas["formatter"]:dateFormatter;
    }
    if(inputType.value=="timePicker")
    {
      formatter.value = eventDatas["formatter"]?eventDatas["formatter"]:timeFormatter;
    }
    updateDateCaches();
  };
  
/**
 * 更新数据控件的缓存数据
 */
const updateDateCaches = () => {
  switch (inputType.value) {
    case "datePicker":
      currentDate = new Date();
      currentYear = currentDate.getFullYear();
      currentMonth = currentDate.getMonth() + 1;
      currentDay = currentDate.getDay();
      minDate.value = new Date(currentYear - 100, currentMonth, currentDay);
      maxDate.value = new Date(currentYear + 100, currentMonth, currentDay);
      break;
    case "timePicker":
      currentDate = new Date();
      currentHour =currentDate.getHours();
      currentMinute =currentDate.getMinutes();
      currentSecond =currentDate.getSeconds();
      break;
    default:
  }

};
let prosetPaneHandler;
onMounted(async () => {
  prosetPaneHandler= wsClient.addEventListener("AUTOSPIRIT_PROSETPANE_ACTION",actionListener);
  updateDateCaches();
  updateDefalutRunValue();
});
onUnmounted(()=>{
  wsClient.removeEventListener("AUTOSPIRIT_PROSETPANE_ACTION",prosetPaneHandler);
});
</script>
<style scoped>

.newSenceField {
  background-color: #f0f0f0;
}
</style>
