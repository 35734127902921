<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar title="场景管理" leftText="返回" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <List class="border none" v-model:loading="loading" :finished="finished" finished-text="没有更多了"
          @load="onDataLoad">
          <CellGroup>
            <Cell center is-link v-for="sencne in sencneList" :key="sencne.id" :title="sencne.sencneName" label="描述信息"
            @click="goSencneInfos(sencne)"></Cell>
          </CellGroup>
        </List>
      </div>
    </div>
    <Button id="autoSpirit_sencneSetting_addBtn" round icon="plus" type="primary" class="addBtn"
      @click="goAddSencnePage"></Button>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import {
  useRouter,
} from 'vue-router';
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
let pageDatas = history.state.pageDatas;
pageDatas = pageDatas ? JSON.parse(pageDatas) : {};
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const router = useRouter();
const loading = ref(false);
const finished = ref(false);
const sencneList = ref([]);
onMounted(() => {
  let addBtn = document.getElementById("autoSpirit_sencneSetting_addBtn");
  addBtn.style.top = window.innerHeight - addBtn.offsetHeight - 40 + "px";
  addBtn.style.left = window.innerWidth - addBtn.offsetWidth - 20 + "px";
});
const onClickLeft = () => {
  router.push({
    name: "Content",
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
const onDataLoad = async () => {
  let datas = {
    userAccount: wsClient.getItemFromStorage("phone",true),
    type: pageDatas.type
  };
  let params = await wsClient.sendActionToCloud("GET_USER_SENCNES", datas);
  let sencnes = params.resultData;
  sencneList.value = sencnes;
  loading.value = false;
  finished.value = true;
};
const goAddSencnePage = () => {
  let datas = {
    type: pageDatas.type,
    backTargetPageDatas: {
      type: pageDatas.type,
      backTargetPageDatas: backTargetPageDatas
    }
  }
  router.push({
    name: "NewSencne",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
const goSencneInfos = (sencne) => {
  let datas = {
    sencne: sencne,
    backTargetPageDatas: {
      type: pageDatas.type,
      backTargetPageDatas: backTargetPageDatas
    }
  }
  router.push({
    name: "SencneInfos",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
</script>
<style scoped>
.addBtn {
  border-radius: 50%;
  position: absolute;
}
</style>