
<template>
    <!---->
    <div class="height-full">
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础配置："></Cell>
                    <ProsetCell title="步骤名称" :value="stepInfo.name" @onSubmit="changeStepName"></ProsetCell>
                    <ProsetCell title="步骤描述" :value="stepInfo.define" type="textarea" @onSubmit="changeStepDefine">
                    </ProsetCell>
                    <Cell v-show="sencneType.name != '常用'" v-bind:title="stepInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
                        <template #right-icon>
                            <Switch v-model="stepInfo.commom" size="24" />
                        </template>
                    </Cell>
                    <Cell v-show="sencneType.name == '常用'" v-bind:title="stepInfo.commom ? '常用状态：开启' : '常用状态：关闭'"
                        class="bg-trans">
                        <template #right-icon>
                            <Switch disabled v-model="stepInfo.commom" size="24" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景配置："></Cell>
                    <Cell center is-link title="场景类别" :value="sencneTitle" @click="gotoSencneTypeSelect"></Cell>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, defineProps, watch, reactive, onMounted } from "vue";
import {
    useRouter,
} from 'vue-router';

const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
let sencne = pageDatas.sencne ? pageDatas.sencne : {};
const sencneType = ref(pageDatas.sencneType ? pageDatas.sencneType : {});
let stepInfo = reactive(pageDatas.itemInfo ? pageDatas.itemInfo : { type: "STEP_TYPE" });
stepInfo.sencneTypeID = sencneType.value.id;
const sencneTitle = ref("");
const router = useRouter();
const props = defineProps({
    operateDatas: {
        type: Object
    }
});

onMounted(async () => {
    sencneTitle.value = getItemInfoSencneString();
    stepInfo.commom=(sencneType.value.name=="常用");
});

const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.value.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.value.name + '】';
}
const changeStepName = (newValue) => {
    stepInfo.name = newValue;
};
const changeStepDefine = (newValue) => {
    stepInfo.define = newValue;
};
const gotoSencneTypeSelect = () => {
    let datas = {
        sencnePageType: 0,
        backTargetPage: "NewStepConfig",
        eventName: "AUTOSPIRIT_STEPCONFIG_SENCNETYPESELECT_EVENT",
        selectedSencneType: sencneType.value,
        selectedSencne: sencne
    };
    const actionMethod = (eventDatas) => {
        sencne = eventDatas.sencne;
        sencneType.value = eventDatas.sencneType;
        taskInfo.sencneTypeID = sencneType.value.id;
        sencneTitle.value = getItemInfoSencneString();
        router.push({
            name: datas.backTargetPage,
        });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "SencneTypeSelected",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};
const validateOnNext = () => {
    let isPass = stepInfo.name ? true : false;
    return {
        resultCode: isPass ? 1 : 0,
        message: isPass ? "" : "请先输入步骤名称！",
    };
};
const getOperateDatas = () => {
    let resultData = {
        itemInfo: stepInfo,
        sencne: sencne,
        sencneType: sencneType.value
    };
    return resultData;
};

const resetDatas = async () => {
    stepInfo.name = "";
    stepInfo.define = "";
    stepInfo.commom = false;
    sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : { type: "STEP_TYPE" };
    sencne = pageDatas.sencne ? pageDatas.sencne : {};
    stepInfo.sencneTypeID = sencneType.value.id;
    stepInfo.commom=(sencneType.value.name=="常用");
    sencneTitle.value = getItemInfoSencneString();
};
const updateDatas = () => {
    let pageDatas = history.state.pageDatas;
    if (pageDatas)pageDatas = JSON.parse(pageDatas);
    sencne = pageDatas.sencne ? pageDatas.sencne : {};
    sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : {};
    stepInfo.sencneTypeID = sencneType.value.id;
    stepInfo.commom=(sencneType.value.name=="常用");
    sencneTitle.value = getItemInfoSencneString();
};

defineExpose({
    updateDatas,
    resetDatas,
    validateOnNext,
    getOperateDatas
});

</script>
<style scoped></style>