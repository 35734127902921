import { createRouter,createWebHistory  } from "vue-router"; 
import Login from "../components/app/frame/Login.vue";
import Content from "../components/app/frame/Content.vue";
import Regist from "../components/app/frame/Regist.vue";
import SencneSetting from "../components/app/views/setting/SencneSetting.vue";
import PersonalSetting from "../components/app/views/my/PersonalSetting.vue";
import NewSencne from "../components/app/views/setting/NewSencne.vue";
import SencneInfos from "../components/app/views/setting/SencneInfos.vue";
import SencneTypeSetting from "../components/app/views/setting/SencneTypeSetting.vue";
import SencneTypeInfos from "../components/app/views/setting/SencneTypeInfos.vue";
import NewSencneType from "../components/app/views/setting/NewSencneType.vue";
import NewSpirit from "../components/app/views/setting/NewSpirit.vue";
import SencneTypeSelected from "../components/app/views/common/SencneTypeSelected.vue";
import SencneTypeImageSelected from "@/components/app/views/setting/newSpirit/SencneTypeImageSelected.vue";
import SpiritInfo from "@/components/app/views/setting/SpiritInfo.vue";
import TaskInfo from "@/components/app/views/setting/TaskInfo.vue";
import ManagerSetting from "@/components/app/views/my/manager/ManagerSetting.vue";
import TaskUpload from "@/components/app/views/my/manager/TaskUpload.vue";
import TaskManagement from "@/components/app/views/my/manager/TaskManagement.vue";
import AppInfoSelect from "@/components/app/views/config/newAppInfo/AppInfoSelect.vue";
import NewAppConfig from "@/components/app/views/config/NewAppConfig.vue";
import NewPageConfig from "@/components/app/views/config/NewPageConfig.vue";
import NewRouteConfig from "@/components/app/views/config/NewRouteConfig.vue";
import NewStepConfig from "../components/app/views/setting/NewStepConfig.vue";
import NewTaskConfig from "../components/app/views/setting/NewTaskConfig.vue";
import AppInfo from "@/components/app/views/config/AppInfo.vue";
import PageInfo from "@/components/app/views/config/PageInfo.vue";
import RouteInfo from "@/components/app/views/config/RouteInfo.vue";
import StepInfo from "@/components/app/views/setting/StepInfo.vue";
import StepActionTypeSelected from "../components/app/views/common/StepActionTypeSelected.vue";
import OccusEventConditionSelect from "@/components/app/views/common/stepType/tabOptionViews/OccusEventConditionSelect.vue";
import NextStepItemSelect from "@/components/app/views/common/stepType/tabOptionViews/NextStepItemSelect.vue";
import ItemSelect from "@/components/app/views/common/ItemSelect.vue";
import HomePage from "@/homePages/HomePage.vue";

const routes =[
    {
        path:'/',
        redirect: 'HomePage'
    },
    {
        name:"HomePage",
        component: HomePage
    },
    {
        path:"/login",
        name: 'Login',
        component: Login
    },
    {
        name: 'Regist',
        component: Regist
    },
    {
        path:"/autospiritx",
        name: 'Content',
        component: Content,
        meta:{
            keepAlive:true,
        }
    },
    {
        name: 'SencneSetting',
        component: SencneSetting
    },
    {
        name: 'PersonalSetting',
        component: PersonalSetting 
    },
    {
        name: 'NewSencne',
        component: NewSencne
    },
    {
        name: 'SencneInfos',
        component: SencneInfos
    },
    {
        name: 'SencneTypeSetting',
        component: SencneTypeSetting
    },
    {
        name: 'StepActionTypeSelected',
        component: StepActionTypeSelected
    },
    {
        name: 'OccusEventConditionSelect',
        component: OccusEventConditionSelect
    },
    {
        name: 'ItemSelect',
        component: ItemSelect
    },
    {
        name: 'SencneTypeInfos',
        component: SencneTypeInfos
    },
    {
        name: 'NewSencneType',
        component: NewSencneType
    },
    {
        name: 'NewSpirit',
        component: NewSpirit,
        meta:{
            keepAlive:true,
        }
    },
    {
        name: 'SencneTypeSelected',
        component: SencneTypeSelected
    },
    {
        name: 'SencneTypeImageSelected',
        component: SencneTypeImageSelected
    },
    {
        name: 'SpiritInfo',
        component: SpiritInfo
    },
    {
        name: 'ManagerSetting',
        component: ManagerSetting
    },
    {
        name: 'TaskUpload',
        component: TaskUpload
    },
    {
        name: 'TaskInfo',
        component: TaskInfo,
    },
    {
        name: 'TaskManagement',
        component: TaskManagement
    },
    {
        name: 'AppInfoSelect',
        component: AppInfoSelect
    },
    {
        name: 'NewAppConfig',
        component: NewAppConfig,
        meta:{
            keepAlive:true,
        }
    },
    {
        name: 'NewPageConfig',
        component: NewPageConfig,
        meta:{
            keepAlive:true,
        }
    },
    {
        name: 'NewRouteConfig',
        component: NewRouteConfig,
        meta:{
            keepAlive:true,
        }
    },
    {
        name: 'NewStepConfig',
        component: NewStepConfig,
        meta:{
            keepAlive:true,
        }
    },
    {
        name: 'NewTaskConfig',
        component: NewTaskConfig,
        meta:{
            keepAlive:true,
        }
    },
    {
        name: 'AppInfo',
        component: AppInfo
    },
    {
        name: 'PageInfo',
        component: PageInfo
    },
    {
        name: 'RouteInfo',
        component: RouteInfo
    },
    {
        name: 'StepInfo',
        component: StepInfo
    },
    {
        name: 'NextStepItemSelect',
        component: NextStepItemSelect
    }
];

const router =createRouter({
    history:createWebHistory(),
    routes
});

// // 设置默认路径.
// router.beforeEach((to, from, next) => {
//     if (!from.name) {
//       console.log(from);
//       next('/home') ;// 重定向到默认路径
//     } else {
//       next();
//     }
//   });
export default router;

