<!--步骤执行参数面板-->
<template>
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans">
                    <template #title>
                        <span class="content-vcenter block-inline">大小屏幕适配</span>
                        <Icon class="content-vcenter rightIcon" name="info-o" size="20" @click="showTips('选择图片适配的模式')" />
                        <Popover placement="top" :actions="screemMatchTypeOption" v-model:show="screemMatchactionPopupShow">
                            <CellGroup class="width-popupGroup">
                                <Cell clickable v-for="action in screemMatchTypeOption" :key="action.id" v-model="action.selected"
                                    :title="action.name" :class="action.selected ? 'selected' : ''"
                                    @click="onScreemMatchActionSelected(action)">
                                    <template v-if="action.selected" #right-icon>
                                        <Icon name="success" class="selected" />
                                    </template>
                                </Cell>
                            </CellGroup>
                            <template #reference>
                                <Button v-if="!isDisabled" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertScreemMatchOptionName(selectedStepActionType.screemMatchType) }}</Button>
                                <Button disabled v-if="isDisabled" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertScreemMatchOptionName(selectedStepActionType.screemMatchType) }}</Button>
                            </template>
                        </Popover>
                    </template>
                </Cell>
                <VariableSetPane :isDisabled="isDisabled"
                    :isNewAction="isNewAction" :stepActionType="selectedStepActionType" :itemInfo="itemInfo"
                    label="选择截图" title="选择截图" name="iconDataBase64" type="VARIABLE_IMAGE" 
                    tipInfo="脚本要匹配的图片，建议限制识别区域"
                    @onFeildClick="doScreemShortcutAction"
                    @onValueChange="onActionStepParamChanged" @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <Cell class="bg-trans">
                    <template #title>
                        <span class="content-vcenter block-inline">图片前置处理</span>
                        <Icon class="content-vcenter rightIcon" name="info-o" size="20" @click="showTips('选择图片适配的模式')" />
                        <Popover placement="top" :actions="imagePreActionTypeOption" v-model:show="imagePreActionPopupShow">
                            <CellGroup class="width-popupGroup">
                                <Cell clickable v-for="action in imagePreActionTypeOption" :key="action.id" v-model="action.selected"
                                    :title="action.name" :class="action.selected ? 'selected' : ''"
                                    @click="onImagePreActionSelected(action)">
                                    <template v-if="action.selected" #right-icon>
                                        <Icon name="success" class="selected" />
                                    </template>
                                </Cell>
                            </CellGroup>
                            <template #reference>
                                <Button v-if="!isDisabled" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertImagePreOptionName(selectedStepActionType.imagePreActionType) }}</Button>
                                <Button disabled v-if="isDisabled" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertImagePreOptionName(selectedStepActionType.imagePreActionType) }}</Button>
                            </template>
                        </Popover>
                    </template>
                </Cell>
                <VariableSetPane  :isDisabled="isDisabled"
                    :isNewAction="isNewAction" :stepActionType="selectedStepActionType" :itemInfo="itemInfo"
                    label="识别区域" title="识别区域" name="matchArea" type="VARIABLE_PLACE_AREA" 
                    tipInfo="设置在识别图片的时候，限制识别的区域，提示识别的速度"
                    @onValueChange="onActionStepParamChanged" @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <VariableSetPane  :isDisabled="isDisabled"
                    :isNewAction="isNewAction" :stepActionType="selectedStepActionType" :itemInfo="itemInfo"
                    label="相似度" title="相似度" name="matchPercent" type="VARIABLE_NUMBER" 
                    tipInfo="识别图片的时候只有相似度最少要达到百分比多少，才算匹配成功，默认是90%"
                    @onValueChange="onActionStepParamChanged" @onMappingChange="onMappingChanged">
                </VariableSetPane>
                <Cell class="bg-trans">
                    <template #title>
                        <span class="content-vcenter block-inline">测试匹配效果</span>
                        <Icon class="content-vcenter rightIcon" name="info-o" size="20"
                            @click="showTips('测试当前选择的图片是否能够匹配，达到稳定运行的效果')" />
                        <Button v-if="!isDisabled&&isImageTest" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small" @click="doImageTest">测试</Button>
                        <Button disabled v-if="isDisabled||!isImageTest" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">测试</Button>
                        
                    </template>
                </Cell>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import {getCurrentInstance,ref,reactive,onMounted,defineProps,toRefs,defineExpose} from "vue";
import { showToast } from "vant";
import util from "@/utils/util";
import optionsConfig from "../../OptionsConfig";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props =defineProps({
    itemInfo:{
        type:Object
    },
    isNewAction:{
        type:Boolean,
        default:false
    },
    isDisabled:{
        type:Boolean,
        default:false
    },
});
const { itemInfo,isNewAction,isDisabled} = toRefs(props);

/**
 * 提示信息
 * @param {*} message 
 */
const showTips = (message) => {
    showToast({
        message: message,
        position: 'top'
    });
};
//执行成功之后的操作选项
const screemMatchTypeOption = util.cloneObject(optionsConfig.screemMatchTypeOption);

const imagePreActionTypeOption = util.cloneObject(optionsConfig.imagePreActionType);

const screemMatchactionPopupShow =ref(false);

const imagePreActionPopupShow =ref(false);

const selectedStepActionType=ref({});

const isImageTest =ref(false);

const isImageCanTest=()=>{
    let check1 =selectedStepActionType.value.iconDataBase64?true:false;
    let check2 =selectedStepActionType.value.matchArea?true:false;
    let check3 =selectedStepActionType.value.deviceCondition?true:false;
    let flg = check1&&check2&&check3;
    return flg;
};

const doScreemShortcutAction = async (source) => {
    let resultDatas = await wsClient.sendActionToUI("AUTOSPIRIT_OPERATE_EVENT_ACTION", {
        opeatorType: "AutoSpiritOperator",
        eventKey: "openScreemImageCut",
        eventDatas:{
            stepActionType:hanlder.getStepActionInfos()}
        });
    onParamValueChanged("iconDataBase64",resultDatas.imageBase64);
    onParamValueChanged("deviceCondition",resultDatas.deviceCondition);
    source.value=resultDatas.imageBase64;
};

const doImageTest =async()=>{
    let src= util.getImageDataUrlFromBase64(selectedStepActionType.value.iconDataBase64);
    let base64= selectedStepActionType.value.iconDataBase64;
    let eventDatas={
        imageBase64:base64,
        imageSrc:src,
        matchArea:selectedStepActionType.value.matchArea,
        matchPercent:selectedStepActionType.value.matchPercent,
        imagePreActionOptions:imagePreActionTypeOption,
        screemMatchType:selectedStepActionType.value.screemMatchselectedAction.value,
        deviceCondition:selectedStepActionType.value.deviceCondition,
    };
    await wsClient.sendActionToUI("AUTOSPIRIT_OPERATE_EVENT_ACTION", {
        opeatorType: "AutoSpiritOperator",
        eventKey: "openScreemImageTest",
        eventDatas:eventDatas
    });
};

const onParamValueChanged = (title, value) => {
    let eventData = {
        paramName: title,
        paramValue: value,
    }
    onActionStepParamChanged(eventData);
}

/**
 * 响应操作选项选择事件
 * @param {*} action 
 */
const onScreemMatchActionSelected=(action)=>{
    if(selectedStepActionType.value.screemMatchselectedAction)
        selectedStepActionType.value.screemMatchselectedAction.selected =false;
    selectedStepActionType.value.screemMatchselectedAction =action;
    selectedStepActionType.value.screemMatchselectedAction.selected =true;
    selectedStepActionType.value.screemMatchType =action.id;
    screemMatchactionPopupShow.value =false;
    onParamValueChanged("screemMatchType", action.id);
};

/**
 * 响应操作选项选择事件
 * @param {*} action 
 */
 const onImagePreActionSelected=(action)=>{
    if(selectedStepActionType.value.screemMatchselectedAction)
        selectedStepActionType.value.imagePreselectedAction.selected =false;
    selectedStepActionType.value.imagePreselectedAction =action;
    selectedStepActionType.value.imagePreselectedAction.selected =true;
    selectedStepActionType.value.imagePreActionType =action.id;
    imagePreActionPopupShow.value =false;
    onParamValueChanged("imagePreActionType", action.id);
};

const convertScreemMatchOptionName=(id)=>{
      //初始化设置默认选择的操作事件
      for(let action of screemMatchTypeOption)
      {
        if(action.id ==id)
        {
           return action.name;
        }
      }
      return id;
};

const convertImagePreOptionName=(id)=>{
      //初始化设置默认选择的操作事件
      for(let action of imagePreActionTypeOption)
      {
        if(action.id ==id)
        {
           return action.name;
        }
      }
      return id;
};

let hanlder={};

const onActionStepParamChanged = (eventData) => {
    hanlder.onActionStepParamChanged(eventData);
    isImageTest.value=isImageCanTest();
};

const onMappingChanged=(eventData)=>{
    hanlder.onMappingChanged(eventData);
}

/**
 * 重置面板数据
 * @param {*} stepActionTypeInfo 
 */
const resetData=async(tempHandler,tabIndex)=>{
    hanlder = tempHandler;
    selectedStepActionType.value = hanlder.getStepActionInfos();
    isImageTest.value=isImageCanTest();
    for(let action of screemMatchTypeOption)
      {
        if(selectedStepActionType.value.screemMatchType==action.id)
        {
            selectedStepActionType.value.screemMatchselectedAction =action;
        }
      }
      for(let action of imagePreActionTypeOption)
      {
        if(selectedStepActionType.value.imagePreActionType==action.id)
        {
            selectedStepActionType.value.imagePreselectedAction =action;
        }
      }
};

const getID=()=>{
    return "AUTOSPIRIT_IMAGEMATCH_PARAMETER";
};

defineExpose({
    resetData,
    getID,
});
</script>
<style scoped>
.rightIcon {
    line-height: 2;
}
</style>

