<template>
  <div class="operatePane" :border="false">
    <Grid clickable class="operateGrid" 
    :column-num="buttonInfos.length"
    icon-size="20"
    >
        <GridItem class="operateItem" v-for="buttonInfo in buttonInfos"
         :key="buttonInfo.id"
         :icon ="buttonInfo.iconName"
         :text="buttonInfo.name"
         @click="onBottonClicked(buttonInfo)"
         />
    </Grid>
  </div>
</template>
<script>
import { getCurrentInstance, ref, toRefs } from "vue";
import { Grid, GridItem } from 'vant';
export default {
  name: "OperatePane",
  components: {
      Grid,
      GridItem,
  },
  props: {
    buttonInfos: Array,
  },
  setup(props) {
    const {buttonInfos} = toRefs(props);
    return {
        buttonInfos,
    };
  },
  methods: {
    onBottonClicked(buttonInfo) {
       if(buttonInfo.onBottonClicked)
       {
         buttonInfo.onBottonClicked();
       }
    },
  },
};
</script>
<style scoped>
.operatePane{
    overflow:auto;
}
.operatePane::-webkit-scrollbar{
    display: none;
}
.operatePane .operateGrid{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-wrap: nowrap;
}
.operatePane .operateItem{
    min-width: 20%;
}
.operatePane :after{
    border: none;
}
</style>