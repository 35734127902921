<!--步骤执行参数面板-->
<template>
    <div class="padding">
        <div class="bg-white border solid radius">
            <CellGroup>
                <Cell class="bg-trans">
                    <template #title>
                        <span class="content-vcenter block-inline">匹配成功后</span>
                        <Icon class="content-vcenter rightIcon" name="info-o" size="20" @click="showTips('选择匹配成功后的动作')" />
                        <Popover placement="top" :actions="actionDataByMatchOption" v-model:show="matchactionPopupShow">
                            <CellGroup class="width-popupGroup">
                                <Cell clickable v-for="action in actionDataByMatchOption" :key="action.id" v-model="action.selected"
                                    :title="action.name" :class="action.selected ? 'selected' : ''"
                                    @click="onMatchActionSelected(action)">
                                    <template v-if="action.selected" #right-icon>
                                        <Icon name="success" class="selected" />
                                    </template>
                                </Cell>
                            </CellGroup>
                            <template #reference>
                                <Button v-if="!isDisabled" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertSuccessMatchOptionName(selectedStepActionType.actionTypeByMatch) }}</Button>
                                <Button disabled v-if="isDisabled" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertSuccessMatchOptionName(selectedStepActionType.actionTypeByMatch) }}</Button>
                            </template>
                        </Popover>
                    </template>
                </Cell>
                <Cell class="bg-trans">
                    <template #title>
                        <span class="content-vcenter block-inline">匹配失败后</span>
                        <Icon class="content-vcenter rightIcon" name="info-o" size="20" @click="showTips('选择匹配失败之后的动作')" />
                        <Popover placement="top" :actions="actionDataByUnMatchOption" v-model:show="unMatchactionPopupShow">
                            <CellGroup class="width-popupGroup">
                                <Cell clickable v-for="action in actionDataByUnMatchOption" :key="action.id" v-model="action.selected"
                                    :title="action.name" :class="action.selected ? 'selected' : ''"
                                    @click="onUnMatchActionSelected(action)">
                                    <template v-if="action.selected" #right-icon>
                                        <Icon name="success" class="selected" />
                                    </template>
                                </Cell>
                            </CellGroup>
                            <template #reference>
                                <Button v-if="!isDisabled" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertUnMatchOptionName(selectedStepActionType.actionDataByUnMatch) }}</Button>
                                <Button disabled v-if="isDisabled" class="content-vcenter block-inline margin-left width-popupGroup" type="primary"
                                    size="small">{{ convertUnMatchOptionName(selectedStepActionType.actionDataByUnMatch) }}</Button>
                            </template>
                        </Popover>
                    </template>
                </Cell>
            </CellGroup>
        </div>
    </div>
</template>
<script setup>
import {getCurrentInstance,ref,reactive,onMounted,defineProps,toRefs,defineExpose} from "vue";
import { showToast } from "vant";
import util from "@/utils/util";
import optionsConfig from "../../OptionsConfig";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const props =defineProps({
    itemInfo:{
        type:Object
    },
    isNewAction:{
        type:Boolean,
        default:false
    },
    isDisabled:{
        type:Boolean,
        default:false
    },
});
const { itemInfo,isNewAction,isDisabled} = toRefs(props);

/**
 * 提示信息
 * @param {*} message 
 */
const showTips = (message) => {
    showToast({
        message: message,
        position: 'top'
    });
};
//执行成功之后的操作选项
const actionDataByMatchOption = util.cloneObject(optionsConfig.actionDataByMatchOption);

const actionDataByUnMatchOption = util.cloneObject(optionsConfig.actionDataByUnMatchOption);

const matchactionPopupShow =ref(false);

const unMatchactionPopupShow =ref(false);

let changedParamMappings={};

let mappingCache={};
/**
 * 响应操作选项选择事件
 * @param {*} action 
 */
const onMatchActionSelected=(action)=>{
    selectedStepActionType.value.matchselectedAction.selected =false;
    selectedStepActionType.value.matchselectedAction =action;
    selectedStepActionType.value.matchselectedAction.selected =true;
    selectedStepActionType.value.actionTypeByMatch =action.id;
    matchactionPopupShow.value =false;
    onParamValueChanged("actionTypeByMatch", action.id);
}

/**
 * 响应操作选项选择事件
 * @param {*} action 
 */
 const onUnMatchActionSelected=(action)=>{
    selectedStepActionType.value.unMatchselectedAction.selected =false;
    selectedStepActionType.value.unMatchselectedAction =action;
    selectedStepActionType.value.unMatchselectedAction.selected =true;
    selectedStepActionType.value.actionDataByUnMatch =action.id;
    unMatchactionPopupShow.value =false;
    onParamValueChanged("actionDataByUnMatch", action.id);
}

const onParamValueChanged = (title, value) => {
    let eventData = {
        paramName: title,
        paramValue: value,
    }
    onActionStepParamChanged(eventData);
}

const convertSuccessMatchOptionName=(id)=>{
      //初始化设置默认选择的操作事件
      for(let action of actionDataByMatchOption)
      {
        if(action.id ==id)
        {
           return action.name;
        }
      }
      return id;
}

const convertUnMatchOptionName=(id)=>{
      //初始化设置默认选择的操作事件
      for(let action of actionDataByUnMatchOption)
      {
        if(action.id ==id)
        {
           return action.name;
        }
      }
      return id;
}

const selectedStepActionType=ref({});

onMounted(()=>{
      //初始化设置默认选择的操作事件
      for(let action of actionDataByMatchOption)
      {
        if(action.selected)
        {
            selectedStepActionType.value.matchselectedAction =action;
        }
      }
      for(let action of actionDataByUnMatchOption)
      {
        if(action.selected)
        {
            selectedStepActionType.value.unMatchselectedAction =action;
        }
      }
});


let hanlder={};

const onActionStepParamChanged = (eventData) => {
    hanlder.onActionStepParamChanged(eventData);
};

const onMappingChanged=(eventData)=>{
    hanlder.onMappingChanged(eventData);
}

/**
 * 重置面板数据
 * @param {*} stepActionTypeInfo 
 */
const resetData=async(tempHandler,tabIndex)=>{
    hanlder = tempHandler;
    selectedStepActionType.value = hanlder.getStepActionInfos();
    //初始化设置默认选择的操作事件
    for(let action of actionDataByMatchOption)
      {
        if(action.selected)
        {
            selectedStepActionType.value.matchselectedAction =action;
        }
      }
      for(let action of actionDataByUnMatchOption)
      {
        if(action.selected)
        {
            selectedStepActionType.value.unMatchselectedAction =action;
        }
      }
};


const getID=()=>{
    return "AUTOSPIRIT_MATCH_CONTORL";
};

defineExpose({
    resetData,
    getID
});
</script>
<style scoped>
.rightIcon {
    line-height: 2;
}
</style>

