const util = require("@/utils/util");
const BaseRelateItemOperator = require('./BaseRelateItemOperator');
module.exports = class NewRelateItemOperator extends BaseRelateItemOperator {
  id = "NEWITEM_TYPE_OPERATOR";
  /**
* 响应删除映射事件
* @param {映射对象} relateMapping 
*/
  doRelateMappingDeleteAction = async (relateMapping, eventData) => {
    this.onRelateMappingDelete(relateMapping);
  };

  onRelateMappingDelete = (relateMapping) => {
    let itemInfo = this.handler.getItemInfo();
    let ids = itemInfo.sequence ? itemInfo.sequence.split(",") : [];
    let index = ids.indexOf(relateMapping.id);
    if (index >= 0) {
      ids.splice(index, 1);
      let newSequence = util.makeSequenceStr(ids);
      this.updateSequenceAndSort(newSequence);
    }
  };

  /**
* 响应操作事件，查看当前步骤的详情信息
* @param {映射对象} relateMapping 
*/
  doItemWatchInfosAction = (relateMapping, eventData) => {
    let datas = {
      itemInfo: relateMapping.itemInfo,
      backTargetPage: this.handler.getBackPageName(),
    };
    let targetPage = this.handler.getWatchedPageName(relateMapping);
    //查看的是步骤，则传递相关信息到步骤页面
    this.handler.getRouter().push({
      name: targetPage,
      state: {
        pageDatas: JSON.stringify(datas)
      }
    });
  };


}