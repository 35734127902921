<template>
  <div class="padding">
    <div class="bg-white border solid">
      <!--任务操作页签面板--快捷操作按钮栏-->
      <Cell v-show="elementsHideConfig ? !elementsHideConfig.operateBar : true" class="bg-trans" title="">
        <template #right-icon>
          <div class="flex">
            <Icon class="rightIcon color-blue content-vcenter block-inline" name="exchange" size="20" @click="doGetPagePicture">
              <div class="rightText">刷新</div>
            </Icon>
            <Icon class="rightIcon padding-left color-blue content-vcenter block-inline" name="add-o" size="20" @click="doClosePagePicture">
              <div class="rightText">添加</div>
            </Icon>
            <Icon class="rightIcon padding-left color-blue content-vcenter block-inline" name="play-circle-o" size="20">
              <div class="rightText">测试</div>
            </Icon>
          </div>
        </template>
      </Cell>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance,ref, defineEmits, defineExpose, defineProps, toRefs, watch, reactive } from "vue";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const emits = defineEmits(["onAddRelateItem", "onRelateOperate"]);
const props = defineProps({
  //元素显示配置项
  elementsHideConfig: {
    type: Object
  },
});
const { elementsHideConfig} = toRefs(props);

const doGetPagePicture =async()=>{
  let result =await wsClient.sendActionToUI("AUTOSPIRIT_OPERATE_EVENT_ACTION", {
    opeatorType:"AutoSpiritOperator",
    eventKey:"openScreemShortCut",
  });
};

const doClosePagePicture =()=>{
  wsClient.sendActionToUI("AUTOSPIRIT_OPERATE_EVENT_ACTION", {
    opeatorType:"AutoSpiritOperator",
    eventKey:"closeScreemShortCut",
  });
};

</script>
<style scoped>
.rightText {
  font-size: 14px;
  align-self: center;
}

.rightIcon {
  align-self: center;
  display: inline-flex;
}
</style>