<template>
  <!--步骤类型选择面板-->
  <div class="bg-lightGrey height-full">
    <BackableNavBar class="border none bg-trans" leftText="返回" title="步骤信息" @onBack="onClickLeft">
    </BackableNavBar>
    <div class="padding">
      <div class="bg-white border solid radius">
        <Cell class="bg-trans" title="选择步骤类型:"></Cell>
         <!--步骤类型选择列表-->
        <List class="border none bg-trans" v-model:loading="loading" :finished="finished" finished-text="没有更多了">
          <Cell center is-link v-for="stepActionType in stepActionTypeList" :key="stepActionType.id" :title="stepActionType.name" @click="stepActionTypeSelectedChanged(stepActionType)">
            <template #title>
              <span class="content-vcenter">{{ stepActionType.name }}</span>
              <Icon name="question-o" size="18" class="margin-left content-vcenter"
                @click.stop="showTips(stepActionType.define)">
              </Icon>
            </template>
            <template #right-icon>
              <Switch v-model="stepActionType.selected" @click="stepActionTypeSelectedChanged(stepActionType)" size="20px" />
            </template>
          </Cell>
        </List>
      </div>
    </div>
    <div id="autoSpirit_sencneSetting_sureBtn">
      <div>
        <div v-if="buttomDisabled">
          <Button disabled type="primary" class="sureBtn">确定</Button>
        </div>
        <div v-else>
          <Button type="primary" class="sureBtn" @click="dealStepActionTypeSelectedAction">确定</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, watch, onMounted,onUnmounted} from "vue";
import { useRouter } from "vue-router";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import { showToast } from "vant";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const stepActionTypeChangeEventName = pageDatas.eventName ? pageDatas.eventName : "";
const listenerID =pageDatas.listenerID ? pageDatas.listenerID : "";
//当前步骤初始选择的步骤类型
const currentSelectedType = pageDatas.selectedStepActionType ? pageDatas.selectedStepActionType : { id: "DEFAULT_STEP_TYPE" };
//操作后当前正好选择的步骤类型
const selectedStepActionType = ref({ ...currentSelectedType });
const loading = ref(false);
const finished = ref(false);
const stepActionTypeList = ref([]);
const buttomDisabled = ref(true);
onUnmounted(()=>{
  wsClient.removeEventListener(stepActionTypeChangeEventName,listenerID);
});
/**
 * 检测选择对象的变化，更新按钮的显示状态
 */
watch([selectedStepActionType], () => {
  //默认为灰化
  buttomDisabled.value = true;
  //当初始的选择不等于当前选择时，亮化
  if (currentSelectedType.id != selectedStepActionType.value.id) {
    buttomDisabled.value = false;
  }
  //当初始的选择等于当前选择时
  else {
    //当初始的选择等于当前选择时，选择状态不相同时，亮化
    if (currentSelectedType.selected != selectedStepActionType.value.selected) {
      buttomDisabled.value = false;
    }
  }
});

/**
 * 加载时刷新按钮位置，并且从配置文件中加载步骤选项
 */
onMounted(async () => {
  let btnWapper = document.getElementById("autoSpirit_sencneSetting_sureBtn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
  let stepActionTypeArr = [];
  //从配置文件中克隆对象
  let configs = typeUtil.getActionTypes();
  //遍历添加步骤类型选项
  for (let key in configs) {
    stepActionTypeArr.push(configs[key]);
    if(selectedStepActionType.value.id==configs[key].id)
    {
      selectedStepActionType.value = configs[key];
      selectedStepActionType.value.selected = true;
    }
  }
  stepActionTypeList.value = stepActionTypeArr;
  loading.value = false;
  finished.value = true;
});

/**
 * 响应步骤类型发生变化事件
 */
const stepActionTypeSelectedChanged = (stepActionType) => {
  //状态改变
  //当前选择的步骤类型和当前新触发的事件的步骤类型不同时
  if (selectedStepActionType.value.id != stepActionType.id) {
    //原来的清空为未选择
    selectedStepActionType.value.selected = false;
    selectedStepActionType.value = stepActionType;
    selectedStepActionType.value.selected = true;
  }
};

/**
 * 响应按钮点击选中事件，触发事件通知其他页面监听响应相关动作
 */
const dealStepActionTypeSelectedAction = () => {
  wsClient.broadcastEvents(stepActionTypeChangeEventName, {
    selectStepActionType: getSelectedStepActionType(),
  });
};

/**
 * 响应弹窗tip提示
 */
const showTips = (message) => {
  showToast({
    message: message,
    position: 'top'
  });
};

/***
 * 响应回退
 */
const onClickLeft = () => {
  router.push({
    name: backTargetPage,
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};

/**
 * 遍历所有步骤类型，获取当前选中的步骤类型
 */
const getSelectedStepActionType = () => {
  let result;
  for (let sencneType of stepActionTypeList.value) {
    if (sencneType.selected) {
      result = sencneType;
      break;
    }
  }
  return result;
};
</script>
<style scoped>
#autoSpirit_sencneSetting_sureBtn {
  position: absolute;
}

.sureBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>