module.exports = class ActionManager {
    configPaneDefaultActions =
        {
            DELETE_ITEM: {
                id: "DELETE_ITEM",
                name: "删除",
                iconName: "delete-o",
                operateInfos: {
                    queryInfos: {
                        queryType: "AUTOSPIRIT_CONFIRM",
                        queryMessage: "此操作将删除选定元素，确认继续操作？"
                    },
                }
            },
            MOVE_ITEM: {
                id: "MOVE_ITEM",
                name: "移动",
                iconName: "share-o",
                operateInfos: {
                    operateParamsHanlder: (pageType, onSuccess) => {
                        let datas = {
                            sencnePageType: pageType,
                            backTargetPage: "Content",
                            backTargetPageDatas: {
                                active: pageType==0 ? 1 : 2,
                            },
                            eventName: "AUTOSPIRIT_ITEMSMOVE_SENCNETYPESELECT_EVENT",
                        };
                        const actionMethod = async (eventDatas) => {
                            let selectSencneType = eventDatas.sencneType;
                            onSuccess(selectSencneType);
                            this.router.push({
                                name: datas.backTargetPage,
                                state: {
                                    pageDatas: JSON.stringify(datas.backTargetPageDatas)
                                }
                            });
                        };
                        datas.listenerID= this.wsClient.addEventListener(datas.eventName, actionMethod);
                        this.router.push({
                            name: "SencneTypeSelected",
                            state: {
                                pageDatas: JSON.stringify(datas)
                            }
                        });
                    },
                }
            },
        };
    constructor(wsClient,router) {
        this.wsClient = wsClient;
        this.router =router;
    }
    isActionValid = (key) => {
        return this.configPaneDefaultActions[key] ? true : false;
    }
    initItemsTypeCustomerActions = (itemsType) => {
        let tempActions = { ...this.configPaneDefaultActions };
        if (itemsType.length > 0) {
            for (let tempItemType of itemsType) {
                let tempButtonActions = tempItemType.initOptionButtonActions();
                tempActions = { ...tempActions, ...tempButtonActions };
            }

        }

        this.configPaneDefaultActions = tempActions;
        return tempActions;
    }
    refreshSelectedItemsOperateKey = (mutiSelectedItem, itemsType, refreshCallBack, pageType) => {
        let tempData = {};
        let resultKeys = [];
        if (mutiSelectedItem.length > 0) {
            for (let tempItem of mutiSelectedItem) {
                let itemType = tempItem["type"];
                if (tempData[itemType]) {
                    tempData[itemType].push(tempItem);
                }
                else {
                    let tempArr = [tempItem];
                    tempData[itemType] = tempArr;
                }
            }
        }
        if (itemsType.length > 0) {
            for (let tempItemType of itemsType) {
                let type = tempItemType.id;
                if (tempData[type]) {
                    let tempKeys = tempItemType.refreshSelectedItemsOperateKey(mutiSelectedItem, tempData[type]);
                    resultKeys = [...resultKeys, ...tempKeys];
                    resultKeys = [...new Set(resultKeys)];
                }
            }
        }
        let tempActions = [];
        if (resultKeys.length > 0) {
            for (let optionKey of resultKeys) {
                if (this.isActionValid(optionKey)) {
                    let tempAction = this.configPaneDefaultActions[optionKey];
                    tempAction.onBottonClicked = async () => {
                        console.log(`事件ID:${tempAction.id},选择对象数:${mutiSelectedItem.length}`);
                        let { showDialog, showConfirmDialog } = require("vant");
                        let operateInfos = tempAction["operateInfos"];
                        if (operateInfos) {
                            let queryMessage = "";
                            let queryInfos = operateInfos["queryInfos"];
                            let queryType = queryInfos ? queryInfos["queryType"] : "";
                            switch (queryType) {
                                case "AUTOSPIRIT_CONFIRM":
                                    queryMessage = queryInfos["queryMessage"] ? queryInfos["queryMessage"] : "确认要进行此操作？"
                                    showConfirmDialog({
                                        message: queryMessage,
                                    }).then(async () => {
                                        if (operateInfos.operateParamsHanlder) {
                                            operateInfos.operateParamsHanlder(pageType, async (params) => {
                                                await this.doItemAction(optionKey, tempData, itemsType, params);
                                                refreshCallBack();
                                            });
                                        }
                                        else {
                                            await this.doItemAction(optionKey, tempData, itemsType);
                                            refreshCallBack();
                                        }
                                    }).catch(() => {

                                    });
                                    break;
                                case "AUTOSPIRIT_INFO":
                                    showDialog({
                                        message: tempAction["queryMessage"],
                                    }).then(async () => {
                                        if (operateInfos.operateParamsHanlder) {
                                            operateInfos.operateParamsHanlder(pageType, async (params) => {
                                                await this.doItemAction(optionKey, tempData, itemsType, params);
                                                refreshCallBack();
                                            });
                                        }
                                        else {
                                            await this.doItemAction(optionKey, tempData, itemsType);
                                            refreshCallBack();
                                        }
                                    });
                                    break;
                                default:
                                    if (operateInfos.operateParamsHanlder) {
                                        operateInfos.operateParamsHanlder(pageType, async (params) => {
                                            await this.doItemAction(optionKey, tempData, itemsType, params);
                                            refreshCallBack();
                                        });
                                    }
                                    else {
                                        await this.doItemAction(optionKey, tempData, itemsType);
                                        refreshCallBack();
                                    }
                            }
                        }


                    }
                    tempActions.push(this.configPaneDefaultActions[optionKey]);
                }
            }
        }
        return tempActions;
    }
    doItemAction = async (actionKey, actionItemsTypeDatas, itemsType,params) => {
        for (let itemType of itemsType) {
            let typeKey = itemType.id;
            let itemsDatas = actionItemsTypeDatas[typeKey];
            if (itemsDatas) {
                await itemType.doItemAction(itemsDatas, actionKey,params);
            }
        }
    }
    async doSencneDatasRereshAction(pageType) {
        let datas = {
            userAccount: this.wsClient.getItemFromStorage("phone",true),
            type: pageType
        };
        let params = await this.wsClient.sendActionToCloud(
            "GET_USER_SENCNES",
            datas
        );
        let resultDatas = params.resultData ? params.resultData : [];
        return resultDatas;
    }
    async doSencneTypeDatasRereshAction(sencne) {
        let params = await this.wsClient.sendActionToCloud(
            "GET_SENCNETYPES",
            sencne
        );
        let resultDatas = params.resultData ? params.resultData : [];
        let fixSenceTypes = [];
        let otherTypes = [];
        for (let sencneType of resultDatas) {
            if (sencneType.name == "常用") {
                fixSenceTypes[0] = sencneType;
            }
            else if (sencneType.name == "未分类") {
                fixSenceTypes[1] = sencneType;
            }
            else {
                otherTypes.push(sencneType);
            }
        }
        let results = [...fixSenceTypes, ...otherTypes];
        return results;
    }
    async doItemDatasRereshAction(sencneType, itemType, itemsType) {
        let resultItems = [];
        if (itemType.sourceAction) {
            resultItems = await itemType.sourceAction(sencneType, itemsType);
        }
        return resultItems;
    }
}