<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar leftText="返回" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell center title="新建子场景"></Cell>
          <ProsetCell title="名称" :value="sencneTypeName" :validate="validateSencneTypeName" @onSubmit="setSencneTypeName"></ProsetCell>
        </CellGroup>
      </div>
    </div>
    <div id="autospirit_newSencne_Btn">
      <div>
        <div v-if="!sencneTypeName">
          <Button disabled type="primary" class="newSencneBtn" @click="doNewSencneType">确定</Button>
        </div>
        <div v-else>
          <Button type="primary" class="newSencneBtn" @click="doNewSencneType">确定</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import { showToast } from "vant";
import {
  useRouter,
} from 'vue-router';
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let sencne = ref(pageDatas.sencne);
const sencneTypeName = ref("");
onMounted(() => {
  let btnWapper = document.getElementById("autospirit_newSencne_Btn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
});
const validateSencneTypeName =(value)=>{
    if(value=="常用"||value=="未分类")
    {
      let params = {
        message: "场景名称不能和默认子场景名相同！",
        position: "bottom",
      };
      showToast(params);
      return false;
    }
    return true;
};
const onClickLeft = () => {
  backTargetPageDatas.sencne = sencne.value;
  router.push({
    name: "SencneTypeSetting",
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
const doNewSencneType = async () => {
  let responseDatas = await wsClient.sendActionToCloud("CREATE_SENCNETYPE", {
    userAccount: wsClient.getItemFromStorage("phone",true),
    name: sencneTypeName.value,
    sencneID: sencne.value.id,
    canDelete: true,
  });
  let resultCode = responseDatas.resultCode;
  if (resultCode == 1) {
    backTargetPageDatas.sencne = sencne.value;
    router.push({
      name: "SencneTypeSetting",
      state: {
        pageDatas: JSON.stringify(backTargetPageDatas)
      }
    });
  }
  let params = {
    message: responseDatas.message,
    position: "bottom",
  };
  showToast(params);
};
const setSencneTypeName = (value) => {
  sencneTypeName.value = value;
};
</script>
<style scoped>
#autospirit_newSencne_Btn {
  padding: 10px;
  text-align: center;
  position: absolute;
}

.newSencneBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>