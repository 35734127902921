<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar title="子场景信息" leftText="返回" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <div v-show="sencneType.canDelete">
            <ProsetCell title="名称" :value="sencneType.name" :validate="validateSencneTypeName" @onSubmit="setSencneTypeName"></ProsetCell>
          </div>
          <Cell center title="名称" :value="sencneType.name" v-show="!sencneType.canDelete"></Cell>
        </CellGroup>
      </div>
    </div>
    <div id="autospirit_newSencne_Btn" class="padding">
      <Button type="primary" class="newSencneBtn" @click="deleteSenceType" v-show="sencneType.canDelete">删除子场景</Button>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import {showConfirmDialog,showToast} from "vant";
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
let sencneType = ref(pageDatas.sencneType);
let sencne = ref(pageDatas.sencne);
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
onMounted(() => {
  let btnWapper = document.getElementById("autospirit_newSencne_Btn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
});

const validateSencneTypeName =(value)=>{
    if(value=="常用"||value=="未分类")
    {
      let params = {
        message: "场景名称不能和默认场景名相同！",
        position: "bottom",
      };
      showToast(params);
      return false;
    }
    return true;
};
const onClickLeft = () => {
  backTargetPageDatas.sencne = sencne.value;
  router.push({
    name: "SencneTypeSetting",
    state:
    {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
const setSencneTypeName = async (value) => {
  let querySencneType = { ...sencneType.value };
  querySencneType.name = value;
  let responseDatas = await wsClient.sendActionToCloud(
    "UPDATE_SENCNETYPE_NAME_BY_ID",
    querySencneType
  );
  let resultCode = responseDatas.resultCode;
  if (resultCode == 1) {
    sencneType.value.name = value;
  } else {
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
  }
};
const deleteSenceType = async () => {
  showConfirmDialog({
    message: "当前子场景下的元素会被自动移动到该主场景的未分类子场景，请确认执行此操作？",
  }).then(async () => {
    let responseDatas = await wsClient.sendActionToCloud(
      "DELETE_SENCNETYPE",
      sencneType.value
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      backTargetPageDatas.sencne = sencne.value;
      router.push({
        name: "SencneTypeSetting",
        state: {
          pageDatas: JSON.stringify(backTargetPageDatas)
        }
      });
    } else {
      let params = {
        message: responseDatas.message,
        position: "bottom",
      };
      showToast(params);
    }
  }).catch(() => {

  });
};
</script>
<style scoped>
#autospirit_newSencne_Btn {
  text-align: center;
  position: absolute;
}

.newSencneBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>