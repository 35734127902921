<template>
  <div class="autospirit_my_taskUpload_content">
    <BackableNavBar class="personalNavbar" title="任务上传" leftText="返回" @onBack="onClickLeft" />
    <div class="personalTitle">已选择任务清单</div>
    <List v-model:loading="loading" :finished="finished" finished-text="没有更多了">
      <Collapse v-model="activeNames">
        <CollapseItem v-for="appInfo in selectedAppInfos" :key="appInfo.id" :name="appInfo.name"
          class="autospirit_my_taskUpload_list">
          <template #title>
            <Icon class="autospirit_my_taskUpload_list_icon" :name="appInfo.iconData" size="20"></Icon>
            {{ appInfo.name }}
          </template>
          <List>
            <Cell center v-for="task in appInfo.tasks" :key="task.name" :title="task.fileName" value="未上传"></Cell>
          </List>
        </CollapseItem>
      </Collapse>
    </List>
    <CellGroup>
      <Field name="uploader" label="请选择任务脚本">
        <template #input>
          <Uploader v-model="fileList" :beforeRead="checkFileType" />
        </template>
      </Field>
    </CellGroup>
    <div id="autoSpirit_taskupload_sureBtn">
      <div>
        <div v-if="fileList.length == 0">
          <Button disabled type="primary" class="sureBtn">确定</Button>
        </div>
        <div v-else>
          <Button type="primary" class="sureBtn" @click="uploadSelectedFiles">确定</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCurrentInstance, ref } from "vue";
import {
  Cell,
  List,
  Field,
  Uploader,
  CellGroup,
  Button,
  showToast,
  Collapse,
  CollapseItem,
  Icon,
} from "vant";
import BackableNavBar from "@/components/app/views/common/BackableNavBar.vue";
import { useEventListener } from "@vant/use";
export default {
  name: "TaskUpload",
  components: {
    List,
    Field,
    BackableNavBar,
    Uploader,
    Cell,
    CellGroup,
    Button,
    Collapse,
    CollapseItem,
    Icon,
  },
  watch: {
    fileList: {
      async handler(newValue) {
        if (newValue.length > 0) {
          let fileParams = [];
          for (let param of this.fileList) {
            let tempParam = {
              content: param.content,
              name: param.file.name,
            };
            fileParams.push(tempParam);
          }
          this.loading = true;
          this.finished = false;
          // 通过本地服务获取信息，需要加载本地脚本文件
          let resultDatas = await this.wsClient.sendActionToNode(
            "autospirit_getTaskInfoByUrl_event",
            fileParams
          );
          if (resultDatas.resultCode == 0) {
            this.selectedAppInfos = resultDatas.datas;
            for (let selectedAppInfo of this.selectedAppInfos) {
              selectedAppInfo.iconData = "data:image/png;base64," + window.btoa(selectedAppInfo.iconData);
              this.activeNames.push(selectedAppInfo.name);
            }
          } else {
            let params = {
              message: resultDatas.message,
              position: "bottom",
            };
            showToast(params);
          }
          this.loading = false;
          this.finished = true;
        }
        else {
          this.selectedAppInfos = [];
        }
      },
      deep: true,
    },
  },
  setup() {
    const selectedAppInfos = ref([]);
    const fileList = ref([]);
    const loading = ref(false);
    const finished = ref(true);
    const activeNames = ref(["1"]);
    return {
      selectedAppInfos,
      fileList,
      loading,
      finished,
      activeNames,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({
        name: "ManagerSetting",
      });
    },
    checkFileType(file) {
      if (file.type !== "text/javascript") {
        let params = {
          message: "请上传 javascript 格式文件！",
          position: "bottom",
        };
        showToast(params);
        return false;
      }
      return true;
    },
    async uploadSelectedFiles() {
      let appinfos = [];
      for (let selectedAppInfo of this.selectedAppInfos) {
        let tempAppInfo = {
          name: selectedAppInfo.name,
          appID: selectedAppInfo.id,
          appInfoIconData: selectedAppInfo.iconData,
        }
        let tasks = selectedAppInfo.tasks;
        let resultTasks = [];
        for (let tempTask of tasks) {
          let resultTask = {
            name: tempTask.name,
            appID: tempTask.appID,
            aliasName: tempTask.aliasName,
            isNecessaryTask: tempTask.isNecessary,
            fileBase64: tempTask.fileBase64,
          }
          resultTasks.push(resultTask);
        }
        tempAppInfo.tasks =resultTasks;
        appinfos.push(tempAppInfo);
      }
      let datas = {
        appInfos: appinfos,
      }
      let resultDatas = await this.wsClient.sendActionToCloud(
        "UPLOAD_TASK_FILE",
        datas
      );
      if (resultDatas.resultCode == 1) {
        let success = resultDatas.successTasks;
        let failed = resultDatas.failedTasks;
        if (failed.length == 0) {
          let params = {
            message: "任务全部上传成功！",
            position: "bottom",
          };
          showToast(params);
          this.fileList = [];
        } else if (success.length > 0) {
          let params = {
            message: "部分任务上传成功！请查看结果",
            position: "bottom",
          };
          showToast(params);
        } else {
          let params = {
            message: "任务上传失败！",
            position: "bottom",
          };
          showToast(params);
        }
      }
    },
    adjustButton() {
      let btnWapper = document.getElementById("autoSpirit_taskupload_sureBtn");
      btnWapper.style.top =
        window.innerHeight - btnWapper.offsetHeight - 40 + "px";
      btnWapper.style.left =
        (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
    },
  },
  unmounted() {
  },
  mounted() {
    this.adjustButton();
    this.wsClient =
      getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
  },
  created() {
    useEventListener("resize", () => {
      this.adjustButton();
    });
  },
};
</script>
<style scoped>
.autospirit_my_taskUpload_list {
  background-color: #ecf5ff;
}

.autospirit_my_taskUpload_list .autospirit_my_taskUpload_list_icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.autospirit_my_taskUpload_content {
  background-color: #fcfcfc;
  height: 100%;
}

.autospirit_my_taskUpload_content .personalNavbar {
  background-color: transparent;
  border: none;
}

.autospirit_my_taskUpload_content .personalTitle {
  padding: 15px;
}

#autoSpirit_taskupload_sureBtn {
  position: absolute;
}

#autoSpirit_taskupload_sureBtn .sureBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>