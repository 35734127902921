const BaseRelateItemOperator = require('./BaseRelateItemOperator');
const typeUtil = require("@/components/app/views/common/configPane/itemType/itemTypeUtil.js");
/**
 * 任务对象的关联操作响应器
 */
module.exports = class TaskTypeRelateOperator extends BaseRelateItemOperator {
  id = "TASKINFO_TYPE_OPERATOR";

  copyStepInfo=null;

  constructor()
  { 
    super();
  };

    /**
   * 更新当前任务最新的映射队列，并重新排序
   * @param {映射队列字符串} newSequence 
   */
  updateSequenceAndSort = async (newSequence) => {
    let appInfo =this.handler.getItemInfo();
    appInfo.sequence = newSequence;
    let taskRelateMappings = this.handler.getRelateMappings();
    let queryData = {
      id: appInfo.id,
      sequence: newSequence
    };
    let responseDatas = await this.handler.getClientServer().sendActionToCloud(
      "UPDATE_TASK_INFO_SEQUENCE",
      queryData
    );
    if (responseDatas.resultCode == 1) {
      appInfo.sequence = newSequence;
      this.sortRelateMappings(newSequence, taskRelateMappings);
    }
  }

    /**
  * 响应操作事件，查看当前步骤的详情信息
  * @param {映射对象} relateMapping 
  */
    doItemWatchInfosAction = (relateMapping, eventData) => {
      let datas = {
        itemInfo: relateMapping.itemInfo,
        backTargetPage: this.handler.getBackPageName(),
        backTargetPageDatas: this.handler.getBackPageDatas()
      };
      let type = relateMapping.relateType;
      if (type == "TASK_TYPE") {
        this.handler.reloadItemInfos(datas);
      }
      else
      {
        let targetPage = this.handler.getWatchedPageName(relateMapping);
         //查看的是步骤，则传递相关信息到步骤页面
         this.handler.getRouter().push({
          name: targetPage,
          state: {
            pageDatas: JSON.stringify(datas)
          }
        });
      }
    };

    doRelateMappingCopyAction(relateMapping,eventData){
      this.handler.onMappingCopy(relateMapping.itemInfo);
    }

    doUpdateMappingDisableStatusAction=async(relateMapping,flag)=>{
      relateMapping.disable =flag;
      let resultDatas = {};
      let responseRelateDatas = await this.handler.getClientServer().sendActionToCloud(
          "UPDATE_RELATE_MAPPING_DISABLE_BY_PARENTID",
          relateMapping
      );
      if (responseRelateDatas.resultCode == 1) {
          resultDatas = responseRelateDatas.resultData;
      }
      return resultDatas;
    }

    doInsertStepBeforeAction(relateMapping){
      let insertIndex =relateMapping.index-1;
      this.handler.onTaskAndStepAdded(insertIndex);
    }

    doCopyStepBeforeAction(relateMapping){
      let insertIndex =relateMapping.index-1;
      let copyStepInfo =this.handler.getCopiedMapping();
      if(copyStepInfo)
        this.handler.onCopyTaskAndStepAdded(copyStepInfo,insertIndex);
    }

    doCopyStepAfterAction(relateMapping){
      let insertIndex =relateMapping.index;
      let copyStepInfo =this.handler.getCopiedMapping();
      if(copyStepInfo)
        this.handler.onCopyTaskAndStepAdded(copyStepInfo,insertIndex);
    }

    doCreateDefaultStepAction=async(relateMapping)=>{
      let stepType =typeUtil.getItemTypeObj("STEP_TYPE");
      stepType.init(this.handler.getClientServer(),this.handler.getRouter());
      let parentInfo = relateMapping.parentItemInfo;
      let defaultItemInfo ={
        name:"新建步骤",
        sencneTypeID:parentInfo.sencneTypeID,
        type:"STEP_TYPE",
      }
      let responseDatas =await stepType.createData(defaultItemInfo);
      let resultCode = responseDatas.resultCode;
      if (resultCode == 1) {
       let resultData =responseDatas.resultData;
       let insertIndex =relateMapping.index-1;
       this.handler.onTaskAndStepCreated(resultData,insertIndex);
      }
    }

    doRunStepAction=(relateMapping)=>{
      let { showDialog} = require("vant");
      showDialog({
        message: "功能开发中",
      }).then(async () => {

      });
    }

    /**
    * 响应关联按钮操作事件
    * @param {事件对象} eventData 
    */
      doRelateOperateAction(eventData) {
        let operateOption = eventData.operateOption;
        let relateMapping = eventData.relateMapping;
        let operateID = operateOption.id;
        switch (operateID) {
          //查看步骤信息事件
          case "RELATEMAPPING_STEP_COPY":
            this.doRelateMappingCopyAction(relateMapping, eventData);
            break;
          case "RELATEMAPPING_STEP_CHANGE_DISABLE":
            this.doUpdateMappingDisableStatusAction(relateMapping, true);
            break;
          case "RELATEMAPPING_STEP_CHANGE_ENABLE":
            this.doUpdateMappingDisableStatusAction(relateMapping, false);
            break;
          case "RELATEMAPPING_CREATE_STEP_BEFORE":
            this.doCreateDefaultStepAction(relateMapping);
            break; 
          case "RELATEMAPPING_INSERT_STEP_BEFORE":
            this.doInsertStepBeforeAction(relateMapping);
            break;
          case "RELATEMAPPING_INSERT_STEP_BEFORE_COPY":
            this.doCopyStepBeforeAction(relateMapping);
            break;
          case "RELATEMAPPING_INSERT_STEP_AFTER_COPY":
            this.doCopyStepAfterAction(relateMapping);
            break;
          case "RELATEMAPPING_STEP_RUN":
            this.doRunStepAction(relateMapping);
            break;
          default:
            super.doRelateOperateAction(eventData);
            break;
        };
      };

      getOptionVisableState(relateOperateOption, relateMappingObj) {
        if (relateOperateOption.id == "RELATEMAPPING_INSERT_STEP_BEFORE_COPY"||relateOperateOption.id == "RELATEMAPPING_INSERT_STEP_AFTER_COPY") {
          return this.handler.isMappingCopied()?true:false;
        }
        if (relateOperateOption.id == "RELATEMAPPING_STEP_CHANGE_DISABLE") {
          return !relateMappingObj.disable;
        }
        if (relateOperateOption.id == "RELATEMAPPING_STEP_CHANGE_ENABLE") {
          return relateMappingObj.disable;
        }
        return super.getOptionVisableState(relateOperateOption, relateMappingObj);;
      };
}