<template>
    <div class="height-full">
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础配置："></Cell>
                    <Field v-model="appInfo.name" readonly center label="应用名称：" placeholder="请选择应用"
                        :rules="[{ required: true, message: '请选择应用' }]">
                        <template #button>
                            <Button class="border radius none" type="primary" size="small"
                                @click="gotoSelectAppInfo">选择</Button>
                        </template>
                    </Field>
                    <Field v-model="appInfo.appID" readonly center disable label="APP ID：" placeholder=""
                        :rules="[{ required: true, message: '请选择应用' }]" />
                    <Cell center title="图标：">
                        <template #right-icon>
                            <div class="padding">
                                <Image :src="appIcon" class="as-avatar sm" />
                            </div>
                        </template>
                    </Cell>
                    <ProsetCell title="描述" type="textarea" :value="appInfo.define" @onSubmit="onDefineSubmit">
                    </ProsetCell>
                    <Cell v-show="sencneType.name != '常用'" :title="appInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
                        <template #right-icon>
                            <Switch v-model="appInfo.commom" size="24" />
                        </template>
                    </Cell>
                    <Cell v-show="sencneType.name == '常用'" :title="appInfo.commom ? '常用状态：开启' : '常用状态：关闭'"
                        class="bg-trans">
                        <template #right-icon>
                            <Switch disabled v-model="appInfo.commom" size="24" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景配置："></Cell>
                    <Cell center is-link title="场景类别" :value="sencneTitle" @click="gotoSencneTypeSelect"></Cell>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, defineProps, onMounted, reactive } from "vue";
import {
    useRouter,
} from 'vue-router';

const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
let appInfo = reactive(pageDatas.itemInfo ? pageDatas.itemInfo : { type: "APP_TYPE" });
let sencne = pageDatas.sencne ? pageDatas.sencne : {};
const sencneType = ref(pageDatas.sencneType ? pageDatas.sencneType : {});
appInfo.sencneTypeID = sencneType.value.id;
const sencneTitle = ref("");
const router = useRouter();
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
const appIcon= ref("");

onMounted(async () => {
    appIcon.value =appInfo.iconDataBase64 ? appInfo.iconDataBase64 : wsClient.getIconBase64Str("APP_ICON");
    sencneTitle.value = getItemInfoSencneString();
    appInfo.commom=(sencneType.value.name=="常用");
});
/**
 * 响应更新描述动作
 * @param {描述} newDiscripe 
 */
const onDefineSubmit = (newDiscripe) => {
    appInfo.define = newDiscripe;
};
const gotoSencneTypeSelect = () => {
    let datas = {
        sencnePageType: 1,
        backTargetPage: "NewAppConfig",
        eventName: "AUTOSPIRIT_APPCONFIG_SENCNETYPESELECT_EVENT",
        selectedSencneType: sencneType.value,
        selectedSencne: sencne
    };
    const actionMethod = (eventDatas) => {
        sencne = eventDatas.sencne;
        sencneType.value = eventDatas.sencneType;
        appInfo.sencneTypeID = sencneType.value.id;
        sencneTitle.value = getItemInfoSencneString();
        router.push({
            name: datas.backTargetPage,
        });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "SencneTypeSelected",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};
const gotoSelectAppInfo = () => {
    let datas = {
        backTargetPage: "NewAppConfig",
        eventName: "AUTOSPIRIT_APPCONFIG_APPINFOSELECT_EVENT",
        appInfo: appInfo
    };
    const actionMethod = (eventDatas) => {
        appInfo.name = eventDatas.itemInfo.name;
        appInfo.appID = eventDatas.itemInfo.appID;
        appInfo.iconDataBase64 = eventDatas.itemInfo.iconData;
        appIcon.value =appInfo.iconDataBase64 ? appInfo.iconDataBase64 : wsClient.getIconBase64Str("APP_ICON");
        router.push({
            name: datas.backTargetPage,
        });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "AppInfoSelect",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};
const validateOnNext = () => {
    let isPass = appInfo.name ? true : false;
    return {
        resultCode: isPass ? 1 : 0,
        message: isPass ? "" : "请先选择应用！",
    };
};
const getOperateDatas = () => {
    let resultData = {
        itemInfo: appInfo,
        sencne: sencne,
        sencneType: sencneType.value
    };
    return resultData;
};
const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.value.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.value.name + '】';
}
const resetDatas = async () => {
    appInfo.name = "";
    appInfo.appID = "";
    appInfo.define = "";
    appInfo.commom = false;
    appInfo.iconDataBase64 = wsClient.getIconBase64Str("APP_ICON");
    appIcon.value =appInfo.iconDataBase64 ? appInfo.iconDataBase64 : wsClient.getIconBase64Str("APP_ICON");
    sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : {};
    sencne = pageDatas.sencne ? pageDatas.sencne : {};
    appInfo.sencneTypeID = sencneType.value.id;
    sencneTitle.value = getItemInfoSencneString();
    appInfo.commom=(sencneType.value.name=="常用");
};
const updateDatas = () => {
    let pageDatas = history.state.pageDatas;
    if (pageDatas) pageDatas = JSON.parse(pageDatas);
    sencne = pageDatas.sencne ? pageDatas.sencne : {};
    sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : {};
    appInfo.sencneTypeID = sencneType.value.id;
    sencneTitle.value = getItemInfoSencneString();
    appInfo.commom=(sencneType.value.name=="常用");
};
defineExpose({
    resetDatas,
    validateOnNext,
    getOperateDatas,
    updateDatas
});

</script>
<style scoped></style>