 /**
  * 操作科目的配置对象集
  */
 class OptionsConfig {

    actionDataByMatchOption=[
        {
            name:"点击",
            id:"MATCH_SUCCESS_CLICK",
            selected:true,
        },
    ];

    actionDataByUnMatchOption=[
        {
            name:"执行下一步",
            id:"MATCH_FAILED_DONEXT",
            selected:true,
        },
    ];

    screemMatchTypeOption=[
        {
            name:"等比缩放",
            id:"DEVICE_MATCH_UNIFORM_SCALE",
            value:1,
            selected:true,
        },
        {
            name:"不适配",
            value:0,
            id:"DEVICE_UNMATCH",
        }
    ];

    imagePreActionType=[
        {
            name:"默认",
            id:"IMAGE_PREACTION_DEFAULT",
            value:-1,
            selected:true,
        },
        {
            name:"灰度化",
            id:"IMAGE_PREACTION_RGB2GRAY",
            value:0,
        }
    ];

    //步骤执行成功之后的下一步动作配置集
    stepOperateOptionsBySuccess=[
        {
            name:"执行下一步",
            id:"PROCESS_NEXT_STEP",
        },
        {
            name:"暂停流程",
            id:"BLOCK_CURRENT_PROCESS"
        },
        {
            name:"终止流程",
            id:"STOP_CURRENT_PROCESS"
        },
        {
            name:"终止全部流程",
            id:"STOP_ALL_PROCESS"
        },
        {
            name:"跳转到其他步骤",
            id:"GOTO_OTHER_STEP"
        },
    ];

    //步骤执行完之后基于不同的场景对变量进行不同的操作
    variableContorlEventOccusOptions=[
        {
            name:"步骤运行前",
            id:"STEP_ACTION_TYPE_RUNNING_BEFORE",
        },
        {
            name:"运行成功后",
            id:"STEP_ACTION_TYPE_RUNNING_SUCCESS",
        },
        {
            name:"运行失败后",
            id:"STEP_ACTION_TYPE_RUNNING_FAILED",
        },
        {
            name:"条件成立后",
            id:"CONDITION_CHECK_SUCCESS",
        },
        {
            name:"条件不成立后",
            id:"CONDITION_CHECK_FAILED",
        },
        {
            name:"满足触发概率时",
            id:"OCCUS_PERCENT_MATCHED",
        },
        {
            name:"不满足触发概率时",
            id:"OCCUS_PERCENT_UNMATCHED",
        },
    ];


    //变量类型配置集
    variableTypeOptions=[
        {
            name:"字符",
            id:"VARIABLE_CHAR",
            operateTypeOptions:[
                {
                    name:"改变成固定值",
                    id:"CHANG_TO_FIX_VALUE"
                },
            ]
        },
        {
            name:"数字",
            id:"VARIABLE_NUMBER",
            operateTypeOptions:[
                {
                    name:"改变成固定值",
                    id:"CHANG_TO_FIX_VALUE"
                },
                {
                    name:"加上固定值",
                    id:"ADD_OTHOR_FIX_VALUE"
                },
                {
                    name:"减去固定值",
                    id:"CUT_OTHOR_FIX_VALUE"
                },
                {
                    name:"乘以固定值",
                    id:"MULTIPLY_OTHOR_FIX_VALUE"
                },
                {
                    name:"除以固定值",
                    id:"DIVIDE_OTHOR_FIX_VALUE"
                },
            ]
        },
        {
            name:"坐标[单个]",
            id:"VARIABLE_PLACE_POINT",
            operateTypeOptions:[
                {
                    name:"改变成固定值",
                    id:"CHANG_TO_FIX_VALUE"
                },
            ]
        },
        {
            name:"坐标[多个]",
            id:"VARIABLE_PLACE_POINTS",
            operateTypeOptions:[
                {
                    name:"改变成固定值",
                    id:"CHANG_TO_FIX_VALUE"
                },
            ]
        },
        {
            name:"区域",
            id:"VARIABLE_PLACE_AREA",
            operateTypeOptions:[
                {
                    name:"改变成固定值",
                    id:"CHANG_TO_FIX_VALUE"
                },
            ]
        },
        {
            name:"图片",
            id:"VARIABLE_IMAGE",
            operateTypeOptions:[
                {
                    name:"改变成固定值",
                    id:"CHANG_TO_FIX_VALUE"
                },
            ]
        },
        {
            name:"时间",
            id:"VARIABLE_DATE",
            operateTypeOptions:[
                {
                    name:"改变成固定值",
                    id:"CHANG_TO_FIX_VALUE"
                },
            ]
        },
    ];

    //被关联步骤的操作集，一般在任务中被操作
    stepRelateMappingOperateOptions=[
        {
            name:"查看详情",
            id:"RELATEMAPPING_ITEM_WATCH_INFOS",
        },
        {
            name:"下移一步",
            id:"RELATEMAPPING_ITEM_INDEX_NEXT",
        },
        {
            name:"上移一步",
            id:"RELATEMAPPING_ITEM_INDEX_LAST",
        },
        //20240522 添加步骤
        {
            name:"在前面【创建】空步骤",
            id:"RELATEMAPPING_CREATE_STEP_BEFORE",
        },
        {
            name:"在前面【添加】关联",
            id:"RELATEMAPPING_INSERT_STEP_BEFORE",
        },
        {
            name:"在前面【粘贴】关联",
            id:"RELATEMAPPING_INSERT_STEP_BEFORE_COPY",
        },
        {
            name:"在后面【粘贴】关联",
            id:"RELATEMAPPING_INSERT_STEP_AFTER_COPY",
        },
        {
            name:"复制这个关联",
            id:"RELATEMAPPING_STEP_COPY",
        },
        {
            name:"禁用这个步骤",
            id:"RELATEMAPPING_STEP_CHANGE_DISABLE",
        },
        {
            name:"启用这个步骤",
            id:"RELATEMAPPING_STEP_CHANGE_ENABLE",
        },
        {
            name:"从这里开始运行",
            id:"RELATEMAPPING_STEP_RUN",
        },
        {
            name:"移除关联",
            id:"RELATEMAPPING_ITEM_DELETE",
        },
    ];

    //被关联任务的操作集，一般在主任务或者精灵中被操作
    taskRelateMappingOperateOptions=[
        {
            name:"查看详情",
            id:"RELATEMAPPING_ITEM_WATCH_INFOS",
        },
        {
            name:"下移一步",
            id:"RELATEMAPPING_ITEM_INDEX_NEXT",
        },
        {
            name:"上移一步",
            id:"RELATEMAPPING_ITEM_INDEX_LAST",
        },
        //20240522 添加步骤
        {
            name:"在前面【创建】空步骤",
            id:"RELATEMAPPING_CREATE_STEP_BEFORE",
        },
        {
            name:"在前面【添加】关联",
            id:"RELATEMAPPING_INSERT_STEP_BEFORE",
        },
        {
            name:"在前面【粘贴】关联",
            id:"RELATEMAPPING_INSERT_STEP_BEFORE_COPY",
        },
        {
            name:"在后面【粘贴】关联",
            id:"RELATEMAPPING_INSERT_STEP_AFTER_COPY",
        },
        {
            name:"复制这个关联",
            id:"RELATEMAPPING_STEP_COPY",
        },
        {
            name:"禁用这个步骤",
            id:"RELATEMAPPING_STEP_CHANGE_DISABLE",
        },
        {
            name:"启用这个步骤",
            id:"RELATEMAPPING_STEP_CHANGE_ENABLE",
        },
        {
            name:"从这里开始运行",
            id:"RELATEMAPPING_STEP_RUN",
        },
        {
            name:"移除关联",
            id:"RELATEMAPPING_ITEM_DELETE",
        },
    ];

    //被关联页面的操作集，一般在应用编辑中被操作
    pageInfoRelateMappingOperateOptions=[
        {
            name:"查看详情",
            id:"RELATEMAPPING_ITEM_WATCH_INFOS",
        },
        {
            name:"设置为主页",
            id:"RELATEMAPPING_PAGE_SET_MAINPAGE",
        },
        {
            name:"下移一步",
            id:"RELATEMAPPING_ITEM_INDEX_NEXT",
        },
        {
            name:"上移一步",
            id:"RELATEMAPPING_ITEM_INDEX_LAST",
        },
        {
            name:"移除关联",
            id:"RELATEMAPPING_ITEM_DELETE",
        },
    ];

    //新建被关联页面的操作集，一般在新建应用编辑中被操作
    newPageInfoRelateMappingOperateOptions=[
        {
            name:"查看详情",
            id:"RELATEMAPPING_ITEM_WATCH_INFOS",
        },
        {
            name:"设置为主页",
            id:"RELATEMAPPING_PAGE_SET_MAINPAGE",
        },
        {
            name:"下移一步",
            id:"RELATEMAPPING_ITEM_INDEX_NEXT",
        },
        {
            name:"上移一步",
            id:"RELATEMAPPING_ITEM_INDEX_LAST",
        },
        {
            name:"移除关联",
            id:"RELATEMAPPING_ITEM_DELETE",
        },
    ];

    //被关联页面路径的操作集，一般在页面编辑中被操作
    pageRouteSetOperateOptions=[
        {
            name:"查看路径",
            id:"RELATEMAPPING_ITEM_WATCH_ROUTE_INFOS",
        },
        {
            name:"设置路径",
            id:"RELATEMAPPING_ITEM_SET_ROUTE",
        },
        {
            name:"测试路径",
            id:"RELATEMAPPING_ITEM_TEST_ROUTE",
        },
    ];

    //新建被关联页面路径的操作集，一般在新建页面编辑中被操作
    newPageRouteSetOperateOptions=[
        {
            name:"查看路径",
            id:"RELATEMAPPING_ITEM_WATCH_ROUTE_INFOS",
        },
        {
            name:"设置路径",
            id:"RELATEMAPPING_ITEM_SET_ROUTE",
        },
    ];

    //新建被关联页面元素的操作集
    newItemRelateMappingOperateOptions=[
        {
            name:"查看详情",
            id:"RELATEMAPPING_ITEM_WATCH_INFOS",
        },
        {
            name:"下移一步",
            id:"RELATEMAPPING_ITEM_INDEX_NEXT",
        },
        {
            name:"上移一步",
            id:"RELATEMAPPING_ITEM_INDEX_LAST",
        },
        {
            name:"移除关联",
            id:"RELATEMAPPING_ITEM_DELETE",
        },
    ];
};
module.exports = new OptionsConfig();