<template>
    <div class="bg-lightGrey height-full">
        <BackableNavBar leftText="返回" title="页面信息" :autoControl="false" @onBack="onClickLeft" />
        <!--快捷信息面板-->
        <!-- <div class="padding">
            <div class="flex bg-white border solid radius padding">
                <div>
                    <Image class="flex center as-avatar xl" :src="defaultIcon"></Image>
                </div>
                <div>
                    <Cell center :title="pageInfo.name + '【#' + pageInfo.id + '】'" :label="pageInfo.define"></Cell>
                </div>
            </div>
        </div> -->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础配置："></Cell>
                    <ProsetCell title="名称" :value="pageInfo.name" @onSubmit="onPageNameSubmit"></ProsetCell>
                    <ProsetCell title="描述" type="textarea" :value="pageInfo.define" @onSubmit="onDefineSubmit">
                    </ProsetCell>
                    <Cell center is-link title="场景类别" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect">
                    </Cell>
                    <Cell :title="pageInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
                        <template #right-icon>
                        <Switch v-model="pageInfo.commom" size="24" @change="changeCommom" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <!-- <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景配置："></Cell>
                    <Cell center is-link title="场景类别" :value="getItemInfoSencneString()" @click="gotoSencneTypeSelect">
                    </Cell>
                </CellGroup>
            </div>
        </div> -->
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="高级信息:"></Cell>
                    <Tabs shrink v-model:active="active" background="transparent">
                         <!--任务操作页签面板-->
                         <Tab title="页面设置">
                            <template #title> <span class="taskTab">页面设置</span></template>
                             <!--任务操作页签面板--快捷操作按钮栏-->
                             <div class="padding">
                                <div class="bg-white border solid">
                                    <PageConfigPane  class="bg-trans">
                                    </PageConfigPane>
                                </div>
                            </div>
                        </Tab>
                        <!--任务操作页签面板-->
                        <Tab title="变量设置">
                            <template #title> <span class="taskTab">变量设置</span></template>
                            <!--变量设置面板-->
                            <ParameterSetting :itemInfo="pageInfo"></ParameterSetting>
                        </Tab>
                    </Tabs>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import PageType from "@/components/app/views/common/configPane/itemType/PageType.js";
import {
    useRouter,
} from 'vue-router';
import { showToast } from "vant";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
const pageType = new PageType();
pageType.init(wsClient, router);
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const pageInfo = ref(pageDatas.itemInfo ? pageDatas.itemInfo : {});
//步骤所在的子场景
const sencneType = ref({});
const sencne = ref({});
//默认的Task图标
const defaultIcon = ref("");
//高级操作栏的选项卡索引
const active = ref(0);
onMounted(async () => {
    defaultIcon.value = wsClient.getIconBase64Str("PAGE_ICON");
    //从云端获取最新的数据
    pageInfo.value = await pageType.getItemInfoByID(pageInfo.value.id);
    sencneType.value = await pageType.getItemSencneType(pageInfo.value);
    sencne.value = await pageType.getItemSencne(sencneType.value);
    // initRelateMappingDatas(pageInfo.value);
});
const changeCommom = () => {
    pageType.saveData(pageInfo.value);
};
const onClickLeft = () => {
    router.push({
        name: backTargetPage,
        state: {
            pageDatas: JSON.stringify(backTargetPageDatas)
        }
    });
};
const getItemInfoSencneString = () => {
    if (!sencne.value.sencneName || !sencneType.value.name) {
        return "加载中...";
    }
    return sencne.value.sencneName + '【' + sencneType.value.name + '】';
}
/**
 * 响应更新描述动作
 * @param {描述} newDiscripe 
 */
const onDefineSubmit = (newDiscripe) => {
    pageInfo.value.define = newDiscripe;
    pageType.saveData(pageInfo.value);
};
const onPageNameSubmit = (newName) => {
    pageInfo.value.name = newName;
    pageType.saveData(pageInfo.value);
}
const gotoSencneTypeSelect = () => {
    let datas = {
        sencnePageType: 1,
        eventName: "AUTOSPIRIT_PAGEINFO_SENCNETYPESELECT_EVENT",
        backTargetPage: "PageInfo",
        backTargetPageDatas: {
            itemInfo: pageInfo.value,
            backTargetPage: backTargetPage,
            backTargetPageDatas: backTargetPageDatas
        },
        selectedSencneType: sencneType.value,
        selectedSencne: sencne.value
    };
    const actionMethod = async (eventDatas) => {
        pageInfo.value.sencneTypeID = eventDatas.sencneType.id;
        let responseDatas = await pageType.saveData(pageInfo.value);
        let params = {
            message: responseDatas.message,
            position: "bottom",
        };
        showToast(params);
        if (responseDatas.resultCode == 1) {
            let pageDatas = {
                itemInfo: pageInfo.value,
                backTargetPage: backTargetPage,
                backTargetPageDatas: backTargetPageDatas
            }
            router.push({
                name: datas.backTargetPage,
                state: {
                    pageDatas: JSON.stringify(pageDatas)
                }
            });
        }
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "SencneTypeSelected",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};
</script>
<style scoped>
.taskTab {
  font-size: 15px;
}
.rightText {
  font-size: 14px;
  align-self: center;
}

.rightIcon {
  align-self: center;
  display: inline-flex;
}
</style>