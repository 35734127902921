const BaseItemType = require("./BaseItemType");

module.exports = class AllType extends BaseItemType {
    id = "ALL_TYPE";
    name = "全部";
    selected = false;
    canCreate =false;
    async sourceAction(sencneType, itemsType){
        let resultItems = [];
      for(let tempItemType of itemsType)
      {
        if(tempItemType.id!=this.id)
        {
          let sourceAction =tempItemType["sourceAction"];
          if(sourceAction)
          {
            let tempResults =await sourceAction(sencneType,itemsType);
            if(tempResults&&tempResults.length>0)
            {
              for(let tempResultInfo of tempResults)
              {
                resultItems.push(tempResultInfo);
              }
            }
          }
        }
      }
      return resultItems;
    };
    converItemInfoDatas(itemInfos, itemsType) {
        let resultItems = [];
        for (let tempItemInfo of itemInfos) {
            let itemTypeProxy = getItemType(tempItemInfo,itemsType);
            if (itemTypeProxy && itemTypeProxy.converItemInfoDatas) {
                let tempItemInfoResult = itemTypeProxy.converItemInfoDatas(tempItemInfo,itemsType);
                resultItems.push(tempItemInfoResult);
            }
        }
        return resultItems;
    }
}