<template>
	<!--首页页面，用于快捷操作-->
	<div class="height-full bg-lightGrey">
		<div class="padding">
			<div class="bg-white border solid radius padding">
				<div class="flex-inline content-vcenter padding">
					<Image class="as-avatar xl" round :src="logoIcon" />
				</div>
				<div class="flex-inline content-vcenter padding">
					<Cell class="bg-trans" :border="false" center :title="'用户名: '+loginUser" :label="vipMsg"></Cell>
				</div>
			</div>
		</div>
		<div>
			<div class="block padding text-center">
				<Circle v-model:current-rate="currentRate"
					:color="isStarted ? gradientColor_CanStop : (selectedSpirit.name ? gradientColor_CanStart : gradientColor_CanNotStart)"
					size="200px" :stroke-width="70">
					<div v-if="selectedSpirit.name">
						<Button class="as-vcenter bg-trans border none" :color="isStarted ? '#AE0000' : '#0080FF'" round
							plain v-bind:icon="isStarted ? 'stop' : 'play'" type="primary" @click="startSpirit">{{ isStarted
								? "停止" : "启动" }}</Button>
					</div>
					<div v-else>
						<Button disabled class="as-vcenter bg-trans border none" color="#5B5B5B" round plain
							v-bind:icon="isStarted ? 'stop' : 'play'" type="primary" @click="startSpirit">{{ isStarted ?
								"停止" : "启动" }}</Button>
					</div>
				</Circle>
			</div>
		</div>
		<div class="padding">
			<div class="bg-white border solid radius">
				<CellGroup class="block bg-trans bg-white">
					<Cell title="精灵名:" class="bg-trans" :value="selectedSpirit.name ? selectedSpirit.name : '未选择'" />
					<Cell title="工作状态：" class="bg-trans" :value="workStatus" />
				</CellGroup>
				<div class="block">
					<div class="flex center">
						<Button icon="replay" type="primary" class="width-half margin" @click="selectSpirit">手动切换精灵</Button>
					</div>
				</div>
			</div>
		</div>
		<div class="padding">
			<div class="bg-white border solid radius">
				<CellGroup class="block bg-trans">
					<Cell class="bg-trans" title="快捷操作设置："></Cell>
					<Cell clickable v-bind:title="isFloatBtnSetted ? '悬浮按钮：开启' : '悬浮按钮：关闭'
						" class="bg-trans">
						<template #right-icon>
							<Switch v-model="isFloatBtnSetted" size="24" @change="switchFloatBtnStatus" />
						</template>
					</Cell>
					<Cell clickable v-bind:title="isRunInSilence ? '静默运行：开启' : '静默运行：关闭'" class="bg-trans">
						<template #right-icon>
							<Switch v-model="isRunInSilence" size="24" @change="switchRunSilenceStatus" />
						</template>
					</Cell>
				</CellGroup>
			</div>
		</div>
	</div>
</template>
<script setup>
import {
	ref,
	getCurrentInstance,
	onMounted,
	onActivated,
	onUnmounted,
	onBeforeMount,
} from "vue";
import {
	useRouter,
} from 'vue-router';
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
const router = useRouter();
let instance = getCurrentInstance();
const wsClient = instance?.appContext.config.globalProperties.$wsClient;
const loginUser = ref("");
//用户的vip信息
const vipMsg = ref("");
//控制浮动按钮是否显示
const isFloatBtnSetted = ref(true);
//控制是否已静默形式运行精灵
const isRunInSilence = ref(false);
const currentRate = ref(100);
//任务状态
const workStatus = ref("未启动");
//标识是否已经启动
const isStarted = ref(false);
//当前选择的精灵对象
const selectedSpirit = ref({});
//可以启动状态 对应的环形框渲染参数
const gradientColor_CanStart = {
	"0%": "#00FFFF",
	"100%": "#66B3FF",
};
//禁止启动状态 对应的环形框渲染参数
const gradientColor_CanNotStart = {
	"0%": "#E0E0E0",
	"100%": "#BEBEBE",
};
//可以停止状态 对应的环形框渲染参数
const gradientColor_CanStop = {
	"0%": "#FFB5B5",
	"100%": "#FF5151",
};
const logoIcon =ref("");

let currentRuningOperateID;
/**
 * 响应开始启动精灵
 */
const startSpirit = async() => {
	isStarted.value = !isStarted.value;
	sendFloadBtnEvents();
	//如果是启动操作
	if (isStarted.value) {
		let itemType =typeUtil.getItemTypeObj(selectedSpirit.value.type);
		itemType.init(wsClient,router);
		selectedSpirit.value =await itemType.getItemInfoByID(selectedSpirit.value.id);
		let datas = {
			itemInfo:{
				name: selectedSpirit.value.name,
				define: selectedSpirit.value.define,
				sequence: selectedSpirit.value.sequence,
				sencneTypeID: selectedSpirit.value.sencneTypeID,
				id: selectedSpirit.value.id,
				type: selectedSpirit.value.type,
			},
			clientID: wsClient.clientID,
			token:wsClient.getItemFromStorage("token",true),
		};
		
		let responseDatas =await itemType.clearRunableItemInfos(selectedSpirit.value);
		if (responseDatas.resultCode == 1) {
			datas.clientKey =wsClient.serverName;
        	//发送脚本启动指令到node服务端启动精灵
			currentRuningOperateID = await wsClient.sendActionToNode("AUTOSPIRIT_STARTSPIRIT_EVENT", datas);
    	}
	} else {
		if(currentRuningOperateID)
		{
			//发送脚本停止指令到node服务端
			wsClient.sendActionToNode("AUTOSPIRIT_STOPSPIRIT_EVENT", {
				operateID:currentRuningOperateID
			});
		}
	}
};
/**
 * 切换悬浮按钮显示状态并发送指令到UI-client
 * @param {是否显示悬浮按钮状态} state 
 */
const switchFloatBtnStatus = (state) => {
	isFloatBtnSetted.value = state;
	sendFloadBtnEvents();
};
/**
 * 切换静默方式运行按钮状态并发送指令到UI-client
 * @param {是否进行静默方式状态} state 
 */
const switchRunSilenceStatus = (state) => {
	isRunInSilence.value = state;
	sendFloadBtnEvents();
};
/**
 * 响应选择精灵
 */
const selectSpirit = () => {
	let selectedPageDatas = {
		backTargetPage: "Content",
		backTargetPageDatas:{active:0},
		eventName: "AUTOSPIRIT_HOME_SPIRITSELECT_EVENT",
		/**
		 * 控件显示控制参数，选择按钮时隐藏相关控件
		 */
		elementsHideConfig:{
			badge : true,
			addBtn: true,
			},
		itemsType:["SPIRIT_TYPE"],
		selectedMode:true,
		singleSelectedMode:true,
		//排除已经选择的精灵
		itemsExclude:selectedSpirit.value.key?[selectedSpirit.value.key]:[],
		pageType:"0",
		pageTitle:"精灵选择",
	};
	/**
	 * 响应精灵选择，选择后跳转到首页，同时删除时间监听
	 * @param {已选择的对象} selectedItems 
	 */
	const actionMethod = async (selectedItems) => {
		selectedSpirit.value = selectedItems[0];
			router.push({
				name: selectedPageDatas.backTargetPage,
				state:{
					pageDatas:JSON.stringify(selectedPageDatas.backTargetPageDatas)
				}
			});
	};
	selectedPageDatas.listenerID= wsClient.addEventListener(selectedPageDatas.eventName, actionMethod);
	router.push({
		name: "ItemSelect",
		state:{
			pageDatas:JSON.stringify(selectedPageDatas)
		}
	});
};
/**
 * 发送最新的浮动框状态信息到UI-Client
 */
const sendFloadBtnEvents = () => {
	let datas = {
		"isStarted": isStarted.value,
		"isFloatBtnSetted": isFloatBtnSetted.value,
		"isSpiritSelcetd": selectedSpirit.value.name ? true : false,
		"isRunInSilence": isRunInSilence.value
	};
	//发送按钮指令到UI-Client
	wsClient.sendActionToUI("AUTOSPIRIT_SHOWFLOATBTN_EVENT", datas);
};
/**
 * 事件切换静默运行状态，事件来源UI-Client
 * @param {静默运行状态} datas 
 */
const switchSilence = (datas) => {
	isRunInSilence.value = datas.isRunInSilence;
};
/**
 * 事件切换运行状态，事件来源UI-Client
 * @param {运行状态} datas 
 */
const switchStartState = (datas) => {
	// console.log(isStarted.value != datas.isStarted);
	let isNeedStart = (isStarted.value != datas.isStarted);
	if (isNeedStart) {
		startSpirit();
	}
};
/**
 * 事件更新运行信息，事件来源UI-Client
 * @param {*} params 
 */
const changeTaskState = (params) => {
	workStatus.value = params.title;
	//当任务运行结束时
	if (params.runningState == "WORK_FINISHED") {
		isStarted.value = false;
		sendFloadBtnEvents();
	}
};
/**
 * 页面激活时刷新信息
 */
onActivated(() => {
	loginUser.value = wsClient.getItemFromStorage("phone",true);
});

let silenceHanlder;
let switchHanlder;
let taskHanlder;
/**
 * 页面卸载时注销事件监听
 */
onUnmounted(() => {
	wsClient.removeEventListener(
		"AUTOSPIRIT_SILENCEBTN_ACTION_EVENT",
		silenceHanlder
	);
	wsClient.removeEventListener(
		"AUTOSPIRIT_STARTBTN_ACTION_EVENT",
		switchHanlder
	);
	wsClient.removeEventListener(
		"AUTOSPIRIT_TASKEXECUTE",
		taskHanlder
	);

});
/**
 * 页面加载时注册事件监听
 */
onBeforeMount(() => {
	silenceHanlder=wsClient.addEventListener(
		"AUTOSPIRIT_SILENCEBTN_ACTION_EVENT",
		switchSilence
	);
	switchHanlder=wsClient.addEventListener(
		"AUTOSPIRIT_STARTBTN_ACTION_EVENT",
		switchStartState
	);
	taskHanlder=wsClient.addEventListener(
		"AUTOSPIRIT_TASKEXECUTE",
		changeTaskState
	);
});
onMounted(() => {
	loginUser.value = wsClient.getItemFromStorage("phone",true);
	logoIcon.value = wsClient.getIconBase64Str("LOGO_ICON");
	vipMsg.value = "VIP剩余时间:永久";
	sendFloadBtnEvents();
});
</script>
<style scoped>
.welcome_message {
	height: 4rem;
	line-height: 2rem;
	padding: 10px;
	display: inline-block;
	vertical-align: middle;
}
</style>