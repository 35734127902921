<template>
  <div class="bg-lightGrey height-full">
    <div class="padding">
      <div class="flex bg-white border solid radius padding">
        <div>
          <Image class="flex center as-avatar xl" :src="spiritInfo.iconDataBase64"></Image>
        </div>
        <div>
          <Cell center :title="spiritInfo.name" :label="spiritInfo.define"></Cell>
        </div>
      </div>
    </div>
    <div class="padding">
      <CellGroup>
        <Cell center title="基础信息:"></Cell>
        <Cell center title="名称" :value="spiritInfo.name"></Cell>
        <Cell center title="描述">
        </Cell>
        <Field v-show="spiritInfo.define" readonly autosize type="textarea" show-word-limit class="bg-trans"
          v-model="spiritInfo.define" />
          <Cell center title="场景归属" :value="getItemInfoSencneString()"></Cell>
          <Cell v-bind:title="spiritInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
            <template #right-icon>
							<Switch disabled v-model="spiritInfo.commom" size="24" />
						</template>
          </Cell>
      </CellGroup>
    </div>
    <!-- <div class="padding">
      <CellGroup>
        <Cell center title="场景信息:"></Cell>
        <Cell center title="场景归属" :value="getItemInfoSencneString()"></Cell>
      </CellGroup>
    </div> -->
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell class="bg-trans" title="高级信息:"></Cell>
          <Tabs shrink v-model:active="active" background="transparent">
            <!--任务操作页签面板-->
            <Tab title="任务设置">
              <template #title> <span class="taskTab">任务信息</span></template>
              <RelateSelectPane :itemRelateMappings="spiritRelateMappings" :elementsHideConfig="elementsHideConfig" />
            </Tab>
            <Tab title="变量设置">
              <template #title> <span class="taskTab">变量信息</span></template>
              <!--变量设置面板-->
              <ParameterSetting ref="variablePane" :elementsHideConfig="elementsHideConfig" :isNewAction="true"
                :itemVariables="variableDatas" :itemInfo="spiritInfo"></ParameterSetting>
            </Tab>
          </Tabs>
        </CellGroup>
      </div>
    </div>
  </div>
</template>
<script setup>
import { toRefs, ref, defineProps, defineExpose } from "vue";
const props = defineProps({
  operateDatas: {
    type: Object
  }
});
const { operateDatas } = toRefs(props);
let tempOperateDatas = operateDatas.value;
let firsData = tempOperateDatas[0];
let spiritInfo = firsData.itemInfo ? firsData.itemInfo : {};
let sencne = firsData.sencne ? firsData.sencne : {};
let sencneType = firsData.sencneType ? firsData.sencneType : {};
let secondData = tempOperateDatas[1];
spiritInfo.sequence = secondData.sequence ? secondData.sequence : "";
let thirdData = tempOperateDatas[2];
const variableDatas = ref(thirdData.variableDatas ? thirdData.variableDatas : []);
const spiritRelateMappings = ref(secondData.spiritRelateMappings ? secondData.spiritRelateMappings : []);
const active = ref(0);
const elementsHideConfig = {
  operateBar: true,
  addVariableBtn: true,
  variableOperateBtns: true,
};
const variablePane =ref(null);

const getItemInfoSencneString = () => {
  if (!sencne.sencneName || !sencneType.name) {
    return "加载中...";
  }
  return sencne.sencneName + '【' + sencneType.name + '】';
}

const validateOnFinish = () => {
  let relateMappings = [];
  for (let tempRelateMapping of spiritRelateMappings.value) {
    let tempMapping = {
      parentType: spiritInfo.type,
      relateID: tempRelateMapping.relateID,
      relateType: tempRelateMapping.relateType,
      disable: tempRelateMapping.disable
    }
    relateMappings.push(tempMapping);
  }
  let variables = [];
  for (let tempVariableData of variableDatas.value) {
    let tempVariable = {
      name: tempVariableData.name,
      value: tempVariableData.value,
      dataValue: tempVariableData.dataValue,
      type: tempVariableData.variableType.id,
      relateItemType: spiritInfo.type,
    }
    variables.push(tempVariable);
  }
  spiritInfo.relateMappings = relateMappings;
  spiritInfo.variables = variables;
  return spiritInfo;
};

const resetDatas=()=>{
  spiritRelateMappings.value=[];
  variableDatas.value=[];
  if(variablePane.value)
  {
     variablePane.value.resetDatas();
  }
}

const updateDatas = () => {
  let tempOperateDatas = operateDatas.value;
  let firsData = tempOperateDatas[0];
  spiritInfo = firsData.itemInfo ? firsData.itemInfo : {};
  sencne = firsData.sencne ? firsData.sencne : {};
  sencneType = firsData.sencneType ? firsData.sencneType : {};
  let secondData = tempOperateDatas[1];
  spiritInfo.sequence = secondData.sequence ? secondData.sequence : "";
  let thirdData = tempOperateDatas[2];
  variableDatas.value = thirdData.variableDatas ? thirdData.variableDatas : [];
  spiritRelateMappings.value = secondData.spiritRelateMappings ? secondData.spiritRelateMappings : [];
};

defineExpose({
  resetDatas,
  updateDatas,
  validateOnFinish,
});
</script>
<style scoped>
.taskTab {
  font-size: 15px;
}
</style>