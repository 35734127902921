const BaseStepActionType = require("./BaseStepActionType");
module.exports = class EmptyStepActionType extends BaseStepActionType {
    id = "AUTOSPIRIT_STEP_EMPTY";
    name = "空步骤";
    define="这是一个空步骤，一般用于进行流程控制或者参数计算";
    /**
     * 获取对应步骤所映射的步骤活动类型的服务端保存的参数
     * @param {步骤活动类型的对象ID} id 
     * @returns 
     */
    getStepActionTypeInfosByID=async(id)=>{
        let resultData={};
        //基于类型调用对应接口获取对应的数据
        let url ="GET_EMPTYSTEPTYPE_INFOS"
        let queryData={
            stepActionType:{
            id:id
        }};
        //调用服务端接口获取对应对象的数据
        let responseDatas =await this.wsClient.sendActionToCloud(
            url,
            queryData
        );
        if(responseDatas.resultCode ==1)
        {
            resultData = responseDatas.resultData;
        }
        return resultData;
    };

    /**
     * 保存对应步骤所映射的步骤活动类型的参数到服务端
     * @param {步骤类型ID} stepActionTypeID 
     * @param {步骤活动类型对象} stepActionTypeInfos 
     * @returns 
     */
    saveStepActionTypeInfos=async(stepActionTypeInfos)=>{
        let responseDatas={};
        //基于类型调用对应接口保存对应的数据
        let url="UPDATE_EMPTYSTEPTYPE_INFOS"
        responseDatas =await this.wsClient.sendActionToCloud(
            url,
            stepActionTypeInfos
        );
        return responseDatas;
    };

    /**
     * 从服务器端删除对应步骤所映射的步骤活动对象
     * @param {步骤类型ID} stepActionTypeID 
     * @param {步骤活动类型的对象ID} id 
     * @returns 
     */
    deleteStepActionTypeInfos = async(id)=>
        {
            let responseDatas={};
            let url ="DELETE_EMPTYSTEPTYPE_INFOS"
            let queryData={
                id:id
            };
            responseDatas =await this.wsClient.sendActionToCloud(
                url,
                queryData
            );
            return responseDatas;
        };

      /**
     * 创建对应的步骤类型所映射的步骤活动对象到服务器
     * @param {步骤类型ID} stepActionTypeID 
     * @param {步骤活动类型对象} stepActionTypeInfos 
     * @returns 
     */
  createStepActionTypeInfos=async(stepActionTypeInfos)=>{
    let responseDatas={};
    let url="INSERT_EMPTYSTEPTYPE_INFOS"
    responseDatas =await this.wsClient.sendActionToCloud(
        url,
        stepActionTypeInfos
    );
    return responseDatas;
};       
}