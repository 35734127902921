<template>
  <!--步骤类型选择面板-->
  <div class="bg-lightGrey height-full">
    <BackableNavBar class="border none bg-trans" leftText="返回" :userDefineTiltle="true" @onBack="onClickLeft">
      <template #title>
        <DropdownMenu active-color="#1989fa" class="bg-trans width-popupGroup">
          <DropdownItem v-model="selectedIndex" :options="occusEventConditions"
            @change="onOccusEventConditionSelected" />
        </DropdownMenu>
      </template>
    </BackableNavBar>
    <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <ProsetCell title="名称" :value="name" @onSubmit="onNameSubmit">
                    </ProsetCell>
                </CellGroup>
            </div>
        </div>
    <component ref="conditionPane" :is="selectOccusEventCondtion.occusEventCondtionViewPath" :itemInfo="itemInfo" :isNewAction="isNewAction"></component>
    <div v-show="!isWatchAction" id="autoSpirit_sencneSetting_sureBtn">
      <div>
          <Button type="primary" class="sureBtn" @click="dealTypeSelectedAction">确定</Button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, watch, onMounted, nextTick,onUnmounted} from "vue";
import { useRouter } from "vue-router";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import NewStepActionTypeOperator from "@/components/app/views/common/stepType/tabOptionViews/NewStepActionTypeOperator.js";
import StepActionTypeOperator from "@/components/app/views/common/stepType/tabOptionViews/StepActionTypeOperator.js";
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
const eventName = pageDatas.eventName ? pageDatas.eventName : "";
const listenerID = pageDatas.listenerID ? pageDatas.listenerID : "";
const itemInfo = pageDatas.itemInfo ? pageDatas.itemInfo : {};
const stepActionTypeInfo =pageDatas.stepActionTypeInfo ? pageDatas.stepActionTypeInfo : {};
const isNewAction =!pageDatas.isNewAction ? false : true;
const isWatchAction =!pageDatas.isWatchAction ? false : true;
const selectActionInfo = ref({});
const selectConditonMapping =pageDatas.selectConditonMapping ? pageDatas.selectConditonMapping : {};

const selectOccusEventCondtion = ref({});
const occusEventConditions = ref([]);
const selectedIndex = ref(0);

const conditionPane =ref(null);

const name =ref("");

const conditionOperateDatas = ref([]);

const occusConditionMappings = ref([]);

const variableProsMappings=ref([]);

const OperatorClass = isWatchAction?StepActionTypeOperator:NewStepActionTypeOperator;

const tabOperateHanlder=new OperatorClass();
tabOperateHanlder.init({
    stepActionInfos:selectActionInfo,
    conditionOperateDatas:conditionOperateDatas,
    occusConditionMappings:occusConditionMappings,
    variableProsMappings:variableProsMappings,
    wsClient:wsClient
});

let conditionTypes = typeUtil.getOccusEventConditionTypes();

const onNameSubmit=async(newName)=>{
  name.value =newName;
  if(isWatchAction)
  {
    selectConditonMapping.name = newName;
    await tabOperateHanlder.updateOccusCondtionMapping(selectConditonMapping);
  }
};

onUnmounted(()=>{
  wsClient.removeEventListener(eventName,listenerID);
});

/**
 * 加载时刷新按钮位置，并且从配置文件中加载步骤选项
 */
onMounted(() => {
  let btnWapper = document.getElementById("autoSpirit_sencneSetting_sureBtn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
  if (isWatchAction) {
    name.value =selectConditonMapping.name;
    let conditionType = typeUtil.getStepActionTypeObj(selectConditonMapping.actionStepItemType);
    conditionType.init(wsClient);
    occusEventConditions.value.push({
        text: conditionType.name,
        value: 0,
        condtion: conditionType
      });
  }
  else {
    let index = 0;
    for (let key in conditionTypes) {
      let conditionType = conditionTypes[key];
      occusEventConditions.value.push({
        text: conditionType.name,
        value: index,
        condtion: conditionType
      }
      );
      index++;
    }
  }
  onOccusEventConditionSelected(selectedIndex.value);
});

const onOccusEventConditionSelected = async(index) => {
  selectOccusEventCondtion.value = occusEventConditions.value[index].condtion;
  if(!isWatchAction)
  {
    selectActionInfo.value = selectOccusEventCondtion.value.getDefaultStepActionTypeInfos(itemInfo);
  }
  else
  {
    let wapper =await selectOccusEventCondtion.value.getStepActionTypeInfosByID(selectConditonMapping.actionStepItemID);
    selectActionInfo.value = wapper.stepActionType;
  }
  nextTick(()=>{
    conditionPane.value.resetData(tabOperateHanlder);
  });
}

/**
 * 响应按钮点击选中事件，触发事件通知其他页面监听响应相关动作
 */
const dealTypeSelectedAction = () => {
  if (!isWatchAction) {
    wsClient.broadcastEvents(eventName, {
      occusEventConditionMapping: getOccusEventConditionMapping(),
    });
  }
  else
  {
    wsClient.broadcastEvents(eventName, {});
  }
};


/***
 * 响应回退
 */
const onClickLeft = () => {
  router.push({
    name: backTargetPage,
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};

const getOccusEventConditionMapping = () => {
  let stepActionInfo =selectActionInfo.value;
  let timestamp = new Date().getTime();
  stepActionInfo.id =timestamp;
  timestamp = new Date().getTime();
  // console.log(variableProsMappings.value);
  let resultMapping ={
    id:timestamp,
    itemID:stepActionTypeInfo.id,
    itemType:stepActionTypeInfo.stepActionTypeID,
    name:name.value,
    actionStepItemID:stepActionInfo.id,
    actionStepItemType:stepActionInfo.stepActionTypeID
  };
  resultMapping.stepActionInfo =stepActionInfo;
  resultMapping.variableProsMappings=variableProsMappings.value;
  resultMapping.isNew =true;
  return resultMapping;
};
</script>
<style scoped>
#autoSpirit_sencneSetting_sureBtn {
  position: absolute;
}

.sureBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>