const TaskTypeRelateOperator = require('./TaskTypeRelateOperator');
const typeUtil = require("@/components/app/views/common/configPane/itemType/itemTypeUtil.js");
module.exports = class RouteTypeRelateOperator extends TaskTypeRelateOperator {
  id = "STEPINFO_TYPE_OPERATOR";
    /**
   * 更新当前任务最新的映射队列，并重新排序
   * @param {映射队列字符串} newSequence 
   */
  updateSequenceAndSort = async (newSequence) => {
    let appInfo =this.handler.getItemInfo();
    appInfo.sequence = newSequence;
    let taskRelateMappings = this.handler.getRelateMappings();
    let queryData = {
      id: appInfo.id,
      sequence: newSequence
    };
    let responseDatas = await this.handler.getClientServer().sendActionToCloud(
      "UPDATE_ROUTEINFOS_SEQUENCE",
      queryData
    );
    if (responseDatas.resultCode == 1) {
      appInfo.sequence = newSequence;
      this.sortRelateMappings(newSequence, taskRelateMappings);
    }
  }

  /**
* 响应操作事件，查看当前步骤的详情信息
* @param {映射对象} relateMapping 
*/
  doItemWatchInfosAction = (relateMapping, eventData) => {
    let datas = {
      itemInfo: relateMapping.itemInfo,
      backTargetPage: this.handler.getBackPageName(),
      backTargetPageDatas: this.handler.getBackPageDatas()
    };
    let targetPage = this.handler.getWatchedPageName(relateMapping);
    //查看的是步骤，则传递相关信息到步骤页面
    this.handler.getRouter().push({
      name: targetPage,
      state: {
        pageDatas: JSON.stringify(datas)
      }
    });
  };

  doCreateDefaultStepAction=async(relateMapping)=>{
    let stepType =typeUtil.getItemTypeObj("STEP_TYPE");
    stepType.init(this.handler.getClientServer(),this.handler.getRouter());
    //在路径页面创建的路径，应当归属在路径的sencne下面，默认为默认场景下的未分类
    let responseDatas = await this.handler.getClientServer().sendActionToCloud("GET_DEFAULT_STEP_SENCNETYPE", {
      userAccount: this.handler.getClientServer().getItemFromStorage("phone",true),
      type: 0
    });
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
     let stepTypeSencneType =responseDatas.resultData;
     let defaultItemInfo ={
        name:"新建步骤",
        sencneTypeID:stepTypeSencneType.id,
        type:"STEP_TYPE",
      }
      responseDatas =await stepType.createData(defaultItemInfo);
      resultCode = responseDatas.resultCode;
      if (resultCode == 1) {
       let resultData =responseDatas.resultData;
       let insertIndex =relateMapping.index-1;
       this.handler.onTaskAndStepCreated(resultData,insertIndex);
      }
    }
  }
}