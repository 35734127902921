<template>
  <div class="wizardTitleContent">
    <Steps :active="active" active-icon="success" active-color="#38f">
      <Step class="step" v-for="stepOption in stepOptions" :key="stepOption.stepName">{{ stepOption.stepName }}</Step>
    </Steps>
  </div>
  <div autosize class="wizardContent">
    <keep-alive>
      <component :is="getActiveCom(active)" ref="current" :operateDatas="resultDatas"></component>
    </keep-alive>
  </div>
  <div class="wizard_btn_columnWapper">
    <Button v-for="buttonOption in customBtns" :key="buttonOption.name" class="wizardBtn" @click="buttonOption.action">{{
      buttonOption.name }}</Button>
    <Button v-show="active != 0" class="wizardBtn" @click="doLast">上一步</Button>
    <Button v-show="active != stepOptions.length - 1" type="primary" class="wizardBtn" @click="doNext">下一步</Button>
    <Button v-show="active == stepOptions.length - 1" type="primary" class="wizardBtn" @click="doFinish">确定</Button>
  </div>
</template>
<script setup>
import { nextTick, ref, toRefs, defineProps, defineEmits, watch, defineExpose } from "vue";
import { showToast } from "vant";
const props = defineProps({
  stepOptions: {
    type: Array
  },
});
const emits = defineEmits(['onDataSubmit']);
let { stepOptions } = toRefs(props);
const active = ref(0);
let tempComs = [];
const customBtns = ref([]);
const resultDatas = ref({});
const current = ref(null);
const updateDatas = () => {
  if (tempComs.length > 0) {
    tempComs.forEach((tempCom) => {
      if (tempCom.updateDatas) {
        tempCom.updateDatas();
      }
    });
  }
  else {
    if (current.value.updateDatas) {
      current.value.updateDatas();
    }
  }
}
const resetDatas = () => {
  active.value = 0;
  resultDatas.value = {};
  if (tempComs.length > 0) {
    tempComs.forEach((tempCom) => {
      if (tempCom.resetDatas) {
        tempCom.resetDatas();
      }
    });
  }
  else {
    if (current.value.resetDatas) {
      current.value.resetDatas();
    }
  }
}
watch(active, (newValue, oldValue) => {
  tempComs[oldValue] = current.value;
  tempComs[newValue] = current.value;
  if (newValue > oldValue) {
    nextTick(()=>{
      if (current.value.updateDatas) {
        current.value.updateDatas();
      }
    });
  }
  customBtns.value = getActiveCustomBtns();
});
const getActiveCustomBtns = () => {
  if (current.value.getCustomButtomOptions) {
    return current.value.getCustomButtomOptions();
  }
  return [];
};
const getActiveCom = (activeIndex) => {
  return stepOptions.value[activeIndex].component;
};
const doLast = () => {
  let currentData = current.value;
  if (currentData.validateOnLast) {
    if (currentData.validateOnLast()) {
      active.value = active.value - 1;
    } else {
      console.log("跳转到上一个页面失败：失败原因：用户定制任务执行失败！");
    }
  }
  else {
    active.value = active.value - 1;
  }
};
const doNext = () => {
  let currentData = current.value;
  if (currentData.validateOnNext) {
    let result = currentData.validateOnNext();
    if (result.resultCode == 1) {
      let tempData = currentData.getOperateDatas ? currentData.getOperateDatas() : {};
      resultDatas.value[active.value] = tempData;
      active.value = active.value + 1;
    } else {
      let params = {
        message: result.message,
        position: "bottom",
      };
      showToast(params);
      console.log("跳转到下一个页面失败：失败原因：用户定制任务执行失败！");
    }
  }
  else {
    active.value = active.value + 1;
  }
};
const doFinish = () => {
  let currentData = current.value;
  if (currentData.validateOnFinish) {
    let resultDatas = currentData.validateOnFinish();
    emits("onDataSubmit", resultDatas);
  }
};
defineExpose({
  resetDatas,
  updateDatas
});
</script>
<style scoped>
.wizardTitleContent {
  height: auto;
}

.wizardTitleContent .step {
  font-size: 14px;
}

.wizardContent {
  height: auto;
}

.wizard_btn_columnWapper {
  padding: 10px;
  text-align: right;
  min-height: 60px;
}

.wizardBtn {
  border-radius: 10px;
  margin: 5px;
  height: 35px;
  width: 100px;
}
</style>