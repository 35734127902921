const PageTypeRelateOperator = require('./PageTypeRelateOperator');
const util = require("@/utils/util");
const optionsConfig = require("@/components/app/views/common/stepType/OptionsConfig.js");
const typeUtil = require("@/components/app/views/common/configPane/itemType/itemTypeUtil.js");
/**
 * 新建页面类型的操作控制器。
 */
module.exports = class NewPageTypeRelateOperator extends PageTypeRelateOperator {
  id = "NEW_PAGEINFO_TYPE_OPERATOR";
  //选择的关联元素的缓存
  newRouteItemSelectedCache={};

  /**
   * 获取操作选项
   * @returns 
   */
  getOperateOptions(){
    return util.cloneObject(optionsConfig.newPageRouteSetOperateOptions);
  }

  /**
   * 响应查看路径信息事件
   * @param {路径信息对象} itemInfo 
   * @param {关联映射} relateMapping 
   */
  doWatchRouteInfoAction = (itemInfo,relateMapping) => {
    let datas = {
      itemInfo: itemInfo,
      backTargetPage: this.handler.getBackPageName(),
    };
    this.handler.getRouter().push({
      name: this.handler.getWatchedPageName(relateMapping),
      state: {
        pageDatas: JSON.stringify(datas)
      }
    });
 };

  /**
   * 响应通过路径查看路径信息
   * @param {*} relateMapping 
   * @param {*} eventData 
   */
  doWatchRouteInfoActionByMapping = (relateMapping, eventData) => {
    let relatePage2PageMapping = eventData.relatePage2PageMapping;
    let datas = {
      itemInfo: relatePage2PageMapping.routeInfoMapping.itemInfo,
      backTargetPage: this.handler.getBackPageName(),
    };
    this.handler.getRouter().push({
      name: "RouteInfo",
      state: {
        pageDatas: JSON.stringify(datas)
      }
    });
  };

  /**
   * 响应页面路径设置事件
   * @param {*} relateMapping 
   * @param {*} eventData 
   */
  doItemPageRouteSetAction = (relateMapping, eventData) => {
    //元素过滤规则数组
    let itemsExcludeArr = [];
    //关联映射
    let relatePage2PageMapping = eventData.relatePage2PageMapping;
    //开始页面
    let startPageInfo = relatePage2PageMapping.startPage;
    //结束页面
    let endPageInfo = relatePage2PageMapping.endPage;
    const wsClient = this.handler.getClientServer();
    const router = this.handler.getRouter();
    //构建元素选择页面数据对象
    let datas = {
      backTargetPage: this.handler.getBackPageName(),
      eventName: "AUTOSPIRIT_APPINFO_PAGEROUTESELECT_EVENT",
      elementsHideConfig: {
        badge: true,
        addBtn: true,
      },
      itemsExclude: itemsExcludeArr,
      itemsType: ["ROUTE_TYPE"],
      selectedMode: true,
      singleSelectedMode: true,
      pageType: "1",
      pageTitle: "路径选择",
    };
    //相应选择确认事件的方法，执行关联增加动作
    const actionMethod = async (selectedItems) => {
      let selectedRouteItem = selectedItems[0];
      selectedRouteItem.startPageID = startPageInfo.id;
      selectedRouteItem.endPageID = endPageInfo.id;
      //构建新的映射对象
      let newRelateMapping = {
        parentID: relateMapping.itemInfo.id,
        parentType: relateMapping.itemInfo.type,
        relateID: selectedRouteItem.id,
        relateType: selectedRouteItem.type,
        disable: false,
        itemInfo: selectedRouteItem
      };
      //如果当前选择的银蛇对象存在，则清空原来的路径信息
      if(this.newRouteItemSelectedCache[selectedRouteItem.id])
      {
        let lastRelatePage2PageMapping = this.newRouteItemSelectedCache[selectedRouteItem.id];
        lastRelatePage2PageMapping.routeInfoMapping=null;
      }
      this.newRouteItemSelectedCache[selectedRouteItem.id] =relatePage2PageMapping;
      relatePage2PageMapping.routeInfoMapping = newRelateMapping;
      //刷新路径显示的信息，亮化
      eventData.refresh();
      router.push({
        name: datas.backTargetPage,
      });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
      name: "ItemSelect",
      state: {
        pageDatas: JSON.stringify(datas)
      }
    });
  };

  /**
   * 组装页面->页面路径显示对象
   * @param {关联的路径信息} itemRelateMappings 
   * @param {*} relateOperatorOptions 
   * @returns 
   */
  async convertToPageToPageObj(itemRelateMappings,relateOperatorOptions){
    let resultRelateMapping =[];
    const wsClient = this.handler.getClientServer();
    const router = this.handler.getRouter();
    const pageType = typeUtil.getItemTypeObj('PAGE_TYPE');
    pageType.init(wsClient,router);

    //遍历关联元素信息，这里主要是页面信息
    for(let itemRelateMapping of itemRelateMappings)
    {
      //关联映射
      let relateMappings=[];
      //页面到页面映射
      let page2pageMapping=[];
      //判断是否是页面
      if (itemRelateMapping.relateType == "PAGE_TYPE") {
          //云端获取元素映射信息，这里应该是路径信息
          relateMappings=await pageType.getItemRelateChildMapping(itemRelateMapping.itemInfo);
          //页面到页面映射信息缓存
          let routeCache ={};
          //遍历路径信息，组装路径到路径Key值，更新缓存
          for(let tempRouteMapping of relateMappings)
          {
              let key =tempRouteMapping.itemInfo.startPageID+"@"+tempRouteMapping.itemInfo.endPageID;
              routeCache[key] =tempRouteMapping;
          }
          //获取到页面的父关联对象
          let appItemInfo =itemRelateMapping.parentItemInfo;
          let parentPageMappings=itemRelateMappings;
          //再次遍历所有页面信息，生成各个页面之间的路径映射对象，如两个页面A,B，则会生成 A->B,B->A两条路径
          for(let parentPageMapping of parentPageMappings)
          {
              //不同的两个页面才生成页面路径
              if(parentPageMapping.relateID!=itemRelateMapping.itemInfo.id)
              {
                  //构建页面到页面映射对象
                  let page2pageMappingObj ={
                      id:itemRelateMapping.itemInfo.id+"@"+parentPageMapping.relateID,
                      index:itemRelateMapping.index+"."+(page2pageMapping.length+1),
                      appInfo:appItemInfo,
                      startPage:itemRelateMapping.itemInfo,
                      endPage:parentPageMapping.itemInfo,
                      variableTypeShow:false
                  }
                  let key = page2pageMappingObj.startPage.id+"@"+page2pageMappingObj.endPage.id;
                  //如果缓存中存在，说明该页面下面的页面映射原来就存在
                  if(routeCache[key])
                  {
                      let routeMapping = routeCache[key];
                      let routeItem =routeMapping.itemInfo;
                      page2pageMappingObj.oldRouteInfoMapping =routeMapping;
                      page2pageMappingObj.routeInfoMapping =routeMapping;
                      //放到路径替换缓存中
                      this.newRouteItemSelectedCache[routeItem.id]=page2pageMappingObj;
                  }
                  page2pageMapping.push(page2pageMappingObj);
              }
          }
      }
      //页面元素下关联页面到页面映射元素
      itemRelateMapping.page2pageMapping =page2pageMapping;
      resultRelateMapping.push(itemRelateMapping);
    }
    return resultRelateMapping;
  }

  /**
* 更新当前任务最新的映射队列，并重新排序
* @param {映射队列字符串} newSequence 
*/
  updateSequenceAndSort = async (newSequence) => {
    let appInfo = this.handler.getItemInfo();
    appInfo.sequence = newSequence;
    let taskRelateMappings = this.handler.getRelateMappings();
    this.sortRelateMappings(newSequence, taskRelateMappings);
  }

  /**
   * 重置信息，由于是新增页面，直接清空
   */
  resetDatas()
  {
    this.newRouteItemSelectedCache={};
  }

}