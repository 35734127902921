const BaseItemFilter = require("./BaseItemFilter");
/**
 * 元素名称过滤器
 */
module.exports = class ItemNameFilter extends BaseItemFilter {
    type="ItemNameFilter";
    itemName = '';
    filter(itemInfo) {
        //如果没有设置过滤字符，默认不过滤
        if (this.itemName) {
            let name = itemInfo.name;
            return name.indexOf(this.itemName) < 0;
        }
        else {
            return false;
        }
    }

}