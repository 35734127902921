const util = require('@/utils/util');
module.exports = class BaseStepActionType {
  id = "DEFAULT_STEP_TYPE";
  name = "默认";
  define = "这是一个基础步骤，所有步骤类的基类";
  defaultData={
    randomDelay:false,
    delayTime:"0.5",
    minTime:"0.5",
    maxTime:"",
    repeatTime:"0",
    quickRepeat:false,
    percent:"100",
    nextOptionBySuccess:"PROCESS_NEXT_STEP",
    //是否需要重新检测
    repeatRunWithoutCheck:false,
    //是否在延迟操作前检查条件
    checkBeforeDelay:false,
    //是否条件匹配规则，是部分满足还是全满足
    runWithPartCondition:false,
    //是否检查条件直到成功
    runUntilConditionChecked:false,
    //条件匹配规则值
    partConditionCount:"",
    //重复检查次数
    conditionCheckTime:"0",
    //每次检查的间隔
    conditionCheckInterval:"2",
    nextOptionByFailed:"PROCESS_NEXT_STEP"
  };
  //可供操作的页签分类，每个不用活动的页签会存在差异
  tabOptions = [
    {
      id: "AUTOSPIRIT_RUNPARAMETER",
      name: "运行参数",
      viewPath: "RunParameter",
      index:-1,
    },
    {
      id: "AUTOSPIRIT_OCCUSCONDITIONS",
      name: "启动条件",
      viewPath: "OccusConditions",
      index:0,
    },
    {
      id: "AUTOSPIRIT_PARAMETERS",
      name: "变量控制",
      viewPath: "ParameterControlSetting",
      index:999,
    },
  ];

  init(wsClient) {
    this.wsClient = wsClient;
    return this;
  }

  /**
   * 获取对应步骤所映射的步骤活动类型的默认的参数
   * @param {对应步骤的信息} itemInfo 
   * @returns 
   */
  getDefaultStepActionTypeInfos = (itemInfo) => {
    let resultData = util.cloneObject(this.getDefaultData());
    resultData.stepID = itemInfo.id;
    resultData.stepActionTypeID=this.id;
    return resultData;
  };

  getDefaultData(){
    return this.defaultData;
  }

  getTabOptions(){
    return this.tabOptions;
  }

  /**
   * 获取对应步骤所映射的步骤活动类型的服务端保存的参数
   * @param {步骤类型ID} stepActionTypeID 
   * @param {步骤活动类型的对象ID} id 
   * @returns 
   */
  getStepActionTypeInfosByID = async (id) => {
    return {};
  };

  /**
     * 保存对应步骤所映射的步骤活动类型的参数到服务端
     * @param {步骤类型ID} stepActionTypeID 
     * @param {步骤活动类型对象} stepActionTypeInfos 
     * @returns 
     */
  saveStepActionTypeInfos = async (stepActionTypeInfos) => {
    let responseDatas = {};
    return responseDatas;
  };

  /**
    * 从服务器端删除对应步骤所映射的步骤活动对象
    * @param {步骤类型ID} stepActionTypeID 
    * @param {步骤活动类型的对象ID} id 
    * @returns 
    */
  deleteStepActionTypeInfos = async (id) => {
    let responseDatas = {};
    return responseDatas;
  };

  /**
    * 创建对应的步骤类型所映射的步骤活动对象到服务器
    * @param {步骤类型ID} stepActionTypeID 
    * @param {步骤活动类型对象} stepActionTypeInfos 
    * @returns 
    */
  createStepActionTypeInfos = async (stepActionTypeInfos) => {
    let responseDatas = {};
    return responseDatas;
  };


  getRelateVariableMappings = async (stepActionTypeInfo) => {
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_VARIABLEMAPPINGS_BY_ITEMID",
      {
        "itemID": stepActionTypeInfo.itemID,
        "itemType": stepActionTypeInfo.itemType,
      }
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  };

  getRelateVariableOperateMappings = async (stepActionTypeInfo) => {
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_VARIABLE_OPERATEMAPPINGS_BY_ITEMID",
      {
        "itemID": stepActionTypeInfo.id,
        "itemType": stepActionTypeInfo.stepActionTypeID,
      }
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  };

  createVariableOperateMappings=async(newOperateMapping)=>{
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud("INSERT_VARIABLE_OPERATEMAPIINGS", {
      varaibleOperatorMappings: newOperateMapping
  });
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  };

  updateVariableOperateMappings=async(updateOperateMapping)=>{
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud("UPDATE_VARIABLE_OPERATEMAPIINGS", {
      varaibleOperatorMappings: updateOperateMapping
  });
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  };

  deleteVariableOperateMappings=async(deleteOperateMapping)=>{
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud("DELETE_VARIABLE_OPERATEMAPIINGS", {
      varaibleOperatorMappings: deleteOperateMapping
  });
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  };

  getOccusEventConditionMappings=async(stepActionTypeInfo)=>{
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_VARIABLE_OCCUSEVENTCONDITIONMAPPINGS_BY_ITEMID",
      {
        "itemID": stepActionTypeInfo.id,
        "itemType": stepActionTypeInfo.stepActionTypeID,
      }
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  };

  updateVariableProMappings=async(updateProMappings)=>{
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud("UPDATE_VARIABLEPROMAPPING_INFOS", {
        variableProMappings: updateProMappings
    });
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  }

  createVariableProMappings = async (newProMappings) => {
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud("CREATE_VARIABLEPROMAPPING_INFOS", {
      variableProMappings: newProMappings
    });
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  }

  deleteVariableProMappings = async (deleteProMappings) => {
    let resultItem = {};
    let responseDatas = await this.wsClient.sendActionToCloud("DELETE_VARIABLEPROMAPPING_INFOS", {
      variableProMappings: deleteProMappings
    });
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  }

}

