<template>
  <div class="bg-lightGrey height-full">
    <BackableNavBar title="场景信息" leftText="返回" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <div v-show="sencne.canDelete">
            <ProsetCell title="场景名称" :value="sencne.sencneName" @onSubmit="setSencneName" :validate="validateSencneName"></ProsetCell>
          </div>
          <Cell center title="场景名称" :value="sencne.sencneName" v-show="!sencne.canDelete"></Cell>
          <Cell center is-link title="子场景数" :value="spiritClassicalValue" @click="gotoSencneTypeSetting(sencne)"></Cell>
        </CellGroup>
      </div>
    </div>
    <div id="autospirit_newSencne_Btn">
      <Button type="primary" class="newSencneBtn" @click="deleteSence" v-show="sencne.canDelete">删除场景</Button>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import { showConfirmDialog,showToast} from "vant";
import {
  useRouter,
} from 'vue-router';
const wsClient =
  getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let sencne = ref(pageDatas.sencne);
let spiritClassicalValue = ref("个分类");
let sencneTypes;
onMounted(async () => {
  let btnWapper = document.getElementById("autospirit_newSencne_Btn");
  btnWapper.style.top =
    window.innerHeight - btnWapper.offsetHeight - 40 + "px";
  btnWapper.style.left =
    (window.innerWidth - btnWapper.offsetWidth) / 2 + "px";
  let params = await wsClient.sendActionToCloud("GET_SENCNETYPES", sencne.value);
  sencneTypes = params.resultData ? params.resultData : [];
  spiritClassicalValue.value = sencneTypes.length + "个分类";
});
const onClickLeft = () => {
  router.push({
    name: "SencneSetting",
    state: {
      pageDatas: JSON.stringify(backTargetPageDatas)
    }
  });
};
const gotoSencneTypeSetting = (sencne) => {
  let datas = {
    sencne: sencne,
    backTargetPageDatas: {
      backTargetPageDatas: backTargetPageDatas
    }
  }
  router.push({
    name: "SencneTypeSetting",
    state: {
      pageDatas: JSON.stringify(datas)
    }
  });
};
const validateSencneName =(value)=>{
    if(value=="默认场景")
    {
      let params = {
        message: "场景名称不能和默认场景名相同！",
        position: "bottom",
      };
      showToast(params);
      return false;
    }
    return true;
};
const setSencneName = async (value) => {
  sencne.value.sencneName = value;
  let responseDatas = await wsClient.sendActionToCloud(
    "UPDATE_SENCNE_NAME",
    sencne.value
  );
  let resultCode = responseDatas.resultCode;
  if (resultCode != 1) {
    let params = {
      message: responseDatas.message,
      position: "bottom",
    };
    showToast(params);
  }
};
const deleteSence = async () => {
  showConfirmDialog({
    message: "当前场景下的元素会被自动移动到默认场景的未分类子场景，请确认执行此操作？",
  }).then(async () => {
    let responseDatas = await wsClient.sendActionToCloud(
      "DELETE_SENCNE",
      sencne.value
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      router.push({
        name: "SencneSetting",
        state: {
          pageDatas: JSON.stringify(backTargetPageDatas)
        }
      });
    } else {
      let params = {
        message: responseDatas.message,
        position: "bottom",
      };
      showToast(params);
    }
  }).catch(() => {

  });

};
</script>
<style scoped>
#autospirit_newSencne_Btn {
  padding: 10px;
  text-align: center;
  position: absolute;
}

.newSencneBtn {
  border-radius: 10px;
  height: 40px;
  width: 200px;
}
</style>