<template>
    <TouchablePane @onLongTouch="dealLongSelectd(itemInfo)">
        <component ref="content" :is="itemInfoType" :itemInfo="itemInfo" :singleSelectedMode="singleSelectedMode"
            :showOpetateIcon="showOpetateIcon" @click="dealSelectd(itemInfo, true)"
            @onItemSelected="dealSelectd(itemInfo, false)"></component>
    </TouchablePane>
</template>
<script setup>
import { toRefs, ref, defineProps, defineEmits } from "vue";
const props = defineProps({
    itemInfo: {
        type: Object
    },
    itemType: {
        type: String
    },
    showOpetateIcon: {
        type: Boolean
    },
    singleSelectedMode: {
        type: Boolean
    },
});
const content = ref(null);
const emits = defineEmits(["onItemSelected", "onLongSelected"]);
const dealSelectd = (itemInfo, changeState) => {
    emits("onItemSelected", {
        itemInfo: itemInfo,
        source: content.value
    });
    if (changeState)
        content.value.doItemClick();
};
const dealLongSelectd = (itemInfo) => {
    content.value.doItemClick();
    emits("onLongSelected", {
        itemInfo: itemInfo,
        source: content.value
    });
};
const {
    itemInfo,
    itemType,
    showOpetateIcon,
} = toRefs(props);
const itemInfoType = ref("AppInfoPane");
switch (itemType.value) {
    case "APP_TYPE":
        itemInfoType.value = "AppInfoPane";
        break;
    case "PAGE_TYPE":
        itemInfoType.value = "PageInfoPane";
        break;
    case "ROUTE_TYPE":
        itemInfoType.value = "RouteInfoPane";
        break;
    case "SPIRIT_TYPE":
        itemInfoType.value = "SpiritPane";
        break;
    case "TASK_TYPE":
        itemInfoType.value = "TaskInfoPane";
        break;
    case "STEP_TYPE":
        itemInfoType.value = "StepInfoPane";
        break;
};
</script>
<style scoped></style>