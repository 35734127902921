<template>
  <div class="height-full bg-lightGrey">
    <BackableNavBar class="bg-trans border none" title="管理员设置" leftText="返回" @onBack="onClickLeft" />
    <div class="padding">
      <div class="bg-white border solid radius">
        <CellGroup>
          <Cell center title="功能清单:"></Cell>
          <Cell center is-link title="任务上传" @click="goTaskUpload" icon="upgrade"></Cell>
          <Cell center is-link title="任务管理" @click="goTaskManagement" icon="manager-o"></Cell>
        </CellGroup>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  useRouter,
} from 'vue-router';
const router = useRouter();
const onClickLeft = () => {
  router.push({
    name: "Content",
  });
};
const goTaskUpload = () => {
  router.push({
    name: "TaskUpload",
  });
};
const goTaskManagement = () => {
  router.push({
    name: "TaskManagement",
  });
};
</script>
<style scoped></style>