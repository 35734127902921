const BaseVariableData = require("../../variableType/BaseVariableData");
const util = require('@/utils/util');
const optionsConfig = require("@/components/app/views/common/stepType/OptionsConfig.js");
/**
 * 基础元素类型操作器，主要用于响应主页面元素切换操作和对应元素对象的相关业务操作
 */
module.exports = class BaseItemType {
    id = "DEFAULT_TYPE";
    name = "默认";
    //选择状态
    selected = false;
    //是否能新建
    canCreate = true;
    wsClient = null;
    //事件响应操作器
    actionOperater;
    itemTypes = null;
    //初始化
    init(wsClient, router) {
        this.wsClient = wsClient;
        this.router = router;
        return this;
    }

    /**
     * 获取元素操作器缓存
     * @returns 
     */
    getItemTypeCaches() {
        if (!this.itemTypes) {
            this.initItemTypes();
        }
        return this.itemTypes;
    }

    /**
     * 初始化元素操作器
     */
    initItemTypes() {
        let types = [
            require("./AppType"),
            require("./PageType"),
            require("./RouterType"),
            require("./SpiritType"),
            require("./TaskType"),
            require("./StepType")
        ];
        let itemTypes = {};
        for (let ItemType of types) {
            let itemType = new ItemType();
            itemType.init(this.wsClient, this.router);
            itemTypes[itemType.id] = itemType;
        }
        this.itemTypes = itemTypes;
    }

    /**
     * 响应ConfigPane中的删除事件，批量删除选中的数据
     * @param {选中的需要删除的对象} selectedItem 
     * @param {操作actionkey} actionKey 
     * @returns 
     */
    doItemsDelete = async (selectedItem, actionKey) => {
        return {};
    }
    doItemsMove = async (selectedItem, sencneType) => {
        return {};
    }
    /**
     * 响应ConfigPane中的事件
     * @param {选中的对像} selectedItem 
     * @param {操作指令} actionKey 
     * @returns 
     */
    doItemAction = async (selectedItem, actionKey, params) => {
        let responseDatas;
        switch (actionKey) {
            //如果是删除指令
            case "DELETE_ITEM":
                responseDatas = await this.doItemsDelete(selectedItem, actionKey);
                break;
            case "MOVE_ITEM":
                responseDatas = await this.doItemsMove(selectedItem, params);
                break;
            default:
                break;
        }
        return responseDatas;
    }
    /**
     * 初始化操作按钮响应事件
     * @returns 
     */
    initOptionButtonActions() {
        return {};
    }
    /**
     * 获取操作按钮响应事件名
     * @returns 
     */
    refreshSelectedItemsOperateKey() {
        return ["DELETE_ITEM", "MOVE_ITEM"];
    }

    /**
     * 从云端加载对应场景类型的元素
     * @param {*} sencneType 
     * @param {*} itemsType 
     * @returns 
     */
    async sourceAction(sencneType, itemsType) {
        let resultItems = [];
        return resultItems;
    }

    /**
     * 将云端元素数据转换成web端数据
     * @param {*} itemInfos 
     * @param {*} itemsType 
     * @returns 
     */
    converItemInfoDatas(itemInfos, itemsType) {
        let resultItemInfos = [];
        for (let itemInfo of itemInfos) {
            let tempAppInfo = this.converItemInfoData(itemInfo);
            resultItemInfos.push(tempAppInfo);
        }
        return resultItemInfos;
    }
    /**
     * 获取当前元素所归属的场景
     * @param {*} sencneType 
     * @returns 
     */
    getItemSencne = async (sencneType) => {
        let resultData = {};
        let queryData = {
            id: sencneType.sencneID,
        };
        let responseDatas = await this.wsClient.sendActionToCloud(
            "GET_SENCNE_BY_ID",
            queryData
        );
        if (responseDatas.resultCode == 1) {
            resultData = responseDatas.resultData;
        }
        return resultData;
    }
    /**
     * 获取当前元素所归属的子场景
     * @param {*} itemInfo 
     * @returns 
     */
    getItemSencneType = async (itemInfo) => {
        let resultData = {};
        let queryData = {
            id: itemInfo.sencneTypeID,
        };
        let responseDatas = await this.wsClient.sendActionToCloud(
            "GET_SENCNETYPE_BY_ID",
            queryData
        );
        if (responseDatas.resultCode == 1) {
            resultData = responseDatas.resultData;
        }
        return resultData;
    }
    /**
     * 从云端获取对应对象ID的元素数据
     * @param {*} id 
     * @returns 
     */
    getItemInfoByID = async (id) => {
        return {};
    }

    /**
     * 获取该元素对象下的变量信息
     * @param {*} itemInfo 
     * @returns 
     */
    getItemVarables = async (itemInfo) => {
        let resultDatas = [];
        let queryData = {};
        queryData.relateID = itemInfo.id;
        queryData.relateItemType = itemInfo.type;
        let responseDatas = await this.wsClient.sendActionToCloud(
            "GET_VARIABLE_INFOS_BY_RELATEID",
            queryData
        );
        if (responseDatas.resultCode == 1) {
            resultDatas = this.convertToBaseVariableDatas(responseDatas.resultData);
        }
        return resultDatas;
    }

    /**
     * 获取该元素对象下的变量信息
     * @param {*} itemInfo 
     * @returns 
     */
    getItemVarableByID = async (ID) => {
        let resultDatas = [];
        let queryData = {};
        queryData.id = ID;
        let responseDatas = await this.wsClient.sendActionToCloud(
            "GET_VARIABLE_BY_ID",
            queryData
        );
        if (responseDatas.resultCode == 1) {
            resultDatas = this.convertToBaseVariableData(responseDatas.resultData);
        }
        return resultDatas;
    }


    
  /**
   * 获取该步骤活动对象所关联的变量
   */
  getVariableProMapping = async (stepActionTypeInfo,paramName) => {
    let resultItem = {};
    let datas={
        "itemID": stepActionTypeInfo.id,
        "itemType": stepActionTypeInfo.stepActionTypeID,
        "variableProName": paramName,
      };
    let responseDatas = await this.wsClient.sendActionToCloud(
      "GET_VARIABLEPROMAPPING_BY_ITEMNAME",
      datas
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      resultItem = responseDatas.resultData;
    }
    return resultItem;
  }



  

    /**
     * 获取对象关联的父关联的变量，父关联的变量可以被子关联项使用
     * 
     * @param {元素对象} itemInfo 
     * @returns 
     */
    getItemRelateParentVarables = async (itemInfo) => {
        let resultDatas = [];
        let queryData = {};
        queryData.relateID = itemInfo.id;
        queryData.relateItemType = itemInfo.type;
        let responseDatas = await this.wsClient.sendActionToCloud(
            "GET_RELATE_PARENT_VARIABLE_INFOS_BY_RELATEID",
            queryData
        );
        if (responseDatas.resultCode == 1) {
            resultDatas = this.convertToBaseVariableDatas(responseDatas.resultData);
        }
        return resultDatas;
    }

    /**
     * 将云端变量信息转换成web端变量对象
     * @param {*} resultDatas 
     * @returns 
     */
    convertToBaseVariableDatas = (resultDatas) => {
        let result = [];
        for (let resultData of resultDatas) {
            let basetempData = this.convertToBaseVariableData(resultData);
            result.push(basetempData);
        }
        return result;
    };

    /**
     * 将云端变量信息转换成web端变量对象
     * @param {*} resultDatas 
     * @returns 
     */
    convertToBaseVariableData = (resultData) => {
        if(!resultData)return {};
        let basetempData = new BaseVariableData();
        basetempData.init({
            id: resultData.id,
            variableType: basetempData.getVariableTypeByID(resultData.type),
            name: resultData.name,
            value: resultData.value,
            relateID: resultData.relateID,
            relateItemType: resultData.relateItemType,
            tempStrID: resultData.tempStrID
        }, this.wsClient);

        return basetempData;
    };

    /**
     * 获取该元素所管理的父对象的映射信息
     * @param {*} itemInfo 
     * @returns 
     */
    getItemRelateParentMappings = async (itemInfo) => {
        let resultDatas = [];
        let queryData2 = {
            relateID: itemInfo.id,
            relateType: itemInfo.type
        };
        let responseRelateDatas = await this.wsClient.sendActionToCloud(
            "GET_RELATE_MAPPING_BY_RELATEID",
            queryData2
        );
        //成功后进行排序
        if (responseRelateDatas.resultCode == 1) {
            resultDatas = await this.convertRelateMappingItems(responseRelateDatas.resultData);
        }
        return resultDatas;
    }

    /**
     * 获取该元素所关联的子对象的变量信息
     * @param {*} itemInfo 
     * @returns 
     */
    getItemRelateChildVarables = async (itemInfo) => {
        let relateMapping = await this.getItemRelateChildMapping(itemInfo);
        let varables = [];
        for (let tempMapping of relateMapping) {
            let tempItemInfo = {
                id: tempMapping.relateID,
                type: tempMapping.relateType
            }
            let tempVarables = await this.getItemVarables(tempItemInfo);
            varables = [...varables, ...tempVarables];
        }
        return varables;
    }

    /**
     * 获取该元素所关联的子关联对象
     * @param {*} itemInfo 
     * @returns 
     */
    getItemRelateChildMapping = async (itemInfo) => {
        let resultDatas = [];
        let queryData2 = {
            parentID: itemInfo.id,
            parentType: itemInfo.type
        };
        let responseRelateDatas = await this.wsClient.sendActionToCloud(
            "GET_RELATE_MAPPING_BY_PARENTID",
            queryData2
        );
        //成功后进行排序
        if (responseRelateDatas.resultCode == 1) {
            resultDatas = await this.convertRelateMappingItems(responseRelateDatas.resultData);
        }
        return resultDatas;
    }

    /**
     * 基于父映射对象获取到对应的父对象信息
     * @param {*} tempMapping 
     * @returns 
     */
    getRelateParentItemInfoByRelateMapping = async (tempMapping) => {
        let result = {};
        let itemTypes = this.getItemTypeCaches();
        let parentID = tempMapping.parentID;
        let parentType = tempMapping.parentType;
        let parentItemType = itemTypes[parentType];
        result = await parentItemType.getItemInfoByID(parentID);
        return result;
    }

    /**
     * 将云端的关联映射关系组装加工成web端可用的关联映射对象
     * @param {*} relateMappings 
     * @returns 
     */
    convertRelateMappingItems = async (relateMappings) => {
        let result = [];
        for (let relateMappingObj of relateMappings) {
            relateMappingObj.itemInfo = this.converItemInfoDataByRelateMapping(relateMappingObj);
            relateMappingObj.parentItemInfo = await this.getRelateParentItemInfoByRelateMapping(relateMappingObj);
            relateMappingObj.operateOptions = this.getRelateMappingOperateOptions(relateMappingObj);
            result.push(relateMappingObj);
        }
        return result;
    }

    /**
     * 将云端的元素信息转换成WEB端可用的元素对象
     * @param {*} itemInfo 
     * @returns 
     */
    converItemInfoData = (itemInfo) => {
        return itemInfo;
    }

    /**
     * 基于映射对象获取到对应的元素对象信息
     * @param {*} tempMapping 
     * @returns 
     */
    converItemInfoDataByRelateMapping = (tempMapping) => {
        let result = {};
        let itemTypes = this.getItemTypeCaches();
        let relateType = tempMapping.relateType;
        let relateItemType = itemTypes[relateType];
        result = relateItemType.converItemInfoData(tempMapping.itemInfo);
        return result;
    }

    /**
     * 基于映射获取到对应元素的操作信息集
     * @param {*} tempMapping 
     * @returns 
     */
    getRelateMappingOperateOptions = (tempMapping) => {
        let result = [];
        if (tempMapping.relateType == "PAGE_TYPE") {
            result = util.cloneObject(optionsConfig.pageInfoRelateMappingOperateOptions);
        }
        else if (tempMapping.relateType == "TASK_TYPE") {
            result = util.cloneObject(optionsConfig.taskRelateMappingOperateOptions);
        }
        else if (tempMapping.relateType == "STEP_TYPE") {
            result = util.cloneObject(optionsConfig.stepRelateMappingOperateOptions);
        }
        return result;
    }

    getRunableItemInfos=async(itemInfo)=>{
        let resultDatas = [];
        let queryData2 = {
            itemID: itemInfo.id,
            itemType: itemInfo.type
        };
        let responseRelateDatas = await this.wsClient.sendActionToCloud(
            "GET_RUNABLE_ITEM_INFOS_BY_ITEMID",
            queryData2
        );
        //成功后进行排序
        if (responseRelateDatas.resultCode == 1) {
            resultDatas = responseRelateDatas.resultData;
        }
        return resultDatas;
    }

    clearRunableItemInfos=async(itemInfo)=>{
        let queryData2 = {
            itemID: itemInfo.id,
            itemType: itemInfo.type
        };
        let responseRelateDatas = await this.wsClient.sendActionToCloud(
            "DELETE_RUNABLE_ITEM_INFOS_BY_ITEMID",
            queryData2
        );
        return responseRelateDatas;
    }

    /**
     * 获取当前对象的操作器
     * @param {*} itemInfo 
     * @param {*} itemsType 
     * @returns 
     */
    getItemTypes(itemInfo, itemsType) {
        let type = itemInfo.type;
        for (let tempItemType of itemsType) {
            if (tempItemType.id == type) {
                return tempItemType;
            }
        }
    }
    /**
     * 创建元素的key
     * @param {*} itemInfo 
     * @returns 
     */
    makeItemInfoKey = (itemInfo) => {
        return itemInfo.id + "@" + itemInfo.type;
    };
    /**
     * 响应元素创建事件信息
     * @param {*} sencneType 
     * @param {*} sencne 
     */
    onItemCreate = (sencneType, sencne) => {

    };
    /**
     * 响应元素保存到云端事件信息
     * @param {*} itemInfo 
     */
    saveData = (itemInfo) => {

    };

    createData=(itemInfo)=>{
        
    }
}