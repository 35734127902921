<template>
    <div class="height-full">
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="基础配置："></Cell>
                    <ProsetCell title="任务名称" :value="taskInfo.name" @onSubmit="changeTaskName"></ProsetCell>
                    <ProsetCell title="任务描述" :value="taskInfo.define" type="textarea" @onSubmit="changeTaskDefine">
                    </ProsetCell>
                    <Cell v-show="sencneType.name != '常用'" v-bind:title="taskInfo.commom ? '常用状态：开启' : '常用状态：关闭'" class="bg-trans">
						<template #right-icon>
							<Switch v-model="taskInfo.commom" size="24" />
						</template>
					</Cell>
                    <Cell v-show="sencneType.name == '常用'" v-bind:title="taskInfo.commom ? '常用状态：开启' : '常用状态：关闭'"
                        class="bg-trans">
                        <template #right-icon>
                            <Switch disabled v-model="taskInfo.commom" size="24" />
                        </template>
                    </Cell>
                </CellGroup>
            </div>
        </div>
        <div class="padding">
            <div class="bg-white border solid radius">
                <CellGroup>
                    <Cell class="bg-trans" title="场景配置："></Cell>
                    <Cell center is-link title="场景类别" :value="sencneTitle" @click="gotoSencneTypeSelect"></Cell>
                </CellGroup>
            </div>
        </div>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineExpose, defineProps, onMounted, reactive } from "vue";
import {
    useRouter,
} from 'vue-router';
const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
let sencne = pageDatas.sencne ? pageDatas.sencne : {};
const sencneType = ref(pageDatas.sencneType ? pageDatas.sencneType : {});
let taskInfo = reactive(pageDatas.itemInfo ? pageDatas.itemInfo : { type: "TASK_TYPE" });
taskInfo.sencneTypeID = sencneType.value.id;
const sencneTitle = ref("");
const router = useRouter();
const props = defineProps({
    operateDatas: {
        type: Object
    }
});
onMounted(async () => {
    sencneTitle.value = getItemInfoSencneString();
    taskInfo.commom=(sencneType.value.name=="常用");
});

const resetDatas = async () => {
    taskInfo.name = "";
    taskInfo.define = "";
    taskInfo.commom=false;
    sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : { type: "TASK_TYPE" };
    sencne = pageDatas.sencne ? pageDatas.sencne : {};
    taskInfo.sencneTypeID = sencneType.value.id;
    taskInfo.commom=(sencneType.value.name=="常用");
    sencneTitle.value = getItemInfoSencneString();
};
const getItemInfoSencneString = () => {
    if (!sencne.sencneName || !sencneType.value.name) {
        return "加载中...";
    }
    return sencne.sencneName + '【' + sencneType.value.name + '】';
}
const changeTaskName = (newValue) => {
    taskInfo.name = newValue;
};
const changeTaskDefine = (newValue) => {
    taskInfo.define = newValue;
};
const gotoSencneTypeSelect = () => {
    let datas = {
        sencnePageType: 0,
        backTargetPage: "NewTaskConfig",
        eventName: "AUTOSPIRIT_TASKCONFIG_SENCNETYPESELECT_EVENT",
        selectedSencneType: sencneType.value,
        selectedSencne: sencne
    };
    const actionMethod = (eventDatas) => {
        sencne = eventDatas.sencne,
            sencneType.value = eventDatas.sencneType,
            taskInfo.sencneTypeID = sencneType.value.id;
        sencneTitle.value = getItemInfoSencneString();
        router.push({
            name: datas.backTargetPage,
        });
    };
    datas.listenerID= wsClient.addEventListener(datas.eventName, actionMethod);
    router.push({
        name: "SencneTypeSelected",
        state: {
            pageDatas: JSON.stringify(datas)
        }
    });
};
const validateOnNext = () => {
    let isPass = taskInfo.name ? true : false;
    return {
        resultCode: isPass ? 1 : 0,
        message: isPass ? "" : "请先输入任务信息！",
    };
};
const getOperateDatas = () => {
    let resultData = {
        itemInfo: taskInfo,
        sencne: sencne,
        sencneType: sencneType.value
    };
    return resultData;
};
const updateDatas = () => {
    let pageDatas = history.state.pageDatas;
    if (pageDatas) pageDatas = JSON.parse(pageDatas);
    sencne = pageDatas.sencne ? pageDatas.sencne : {};
    sencneType.value = pageDatas.sencneType ? pageDatas.sencneType : {};
    taskInfo.sencneTypeID = sencneType.value.id;
    taskInfo.commom=(sencneType.value.name=="常用");
    sencneTitle.value = getItemInfoSencneString();
};
defineExpose({
    updateDatas,
    resetDatas,
    validateOnNext,
    getOperateDatas
});

</script>
<style scoped></style>