<template>
    <div class="bg-lightGrey height-full">
        <BackableNavBar leftText="返回" title="新建步骤配置" :autoControl="false" :listenerState="backListenerState"
            @onBack="onClickLeft" />
        <WizardPane ref="newStepWizard" :stepOptions="stepOptions" @onDataSubmit="doCreateStepInfo">
        </WizardPane>
    </div>
</template>
<script setup>
import { getCurrentInstance, ref, onActivated } from "vue";
import {
    useRouter,
} from 'vue-router';
import StepConfig from "@/components/app/views/setting/newStep/StepConfig.vue";
import StepActionConfig from "@/components/app/views/setting/newStep/StepActionConfig.vue";
import StepConfigResult from "@/components/app/views/setting/newStep/StepConfigResult.vue";
import StepFullConfig from "@/components/app/views/setting/newStep/StepFullConfig.vue";
import typeUtil from "@/components/app/views/common/configPane/itemType/itemTypeUtil.js";
import { showToast } from "vant";

const wsClient = getCurrentInstance()?.appContext.config.globalProperties.$wsClient;
const router = useRouter();
let pageDatas = history.state.pageDatas;
if (pageDatas) pageDatas = JSON.parse(pageDatas);
const backTargetPage = pageDatas.backTargetPage ? pageDatas.backTargetPage : "Content";
const backTargetPageDatas = pageDatas.backTargetPageDatas ? pageDatas.backTargetPageDatas : {};
let stepOptions = ref([]);
let getWizardDatas = () => {
    return [
        {
            stepName: "基础配置",
            component: StepConfig,
        },
        {
            stepName: "全局配置",
            component: StepFullConfig,
        },
        {
            stepName: "动作配置",
            component: StepActionConfig,
        },
        {
            stepName: "创建完毕",
            component: StepConfigResult,
        },
    ];
};
stepOptions = getWizardDatas();
const backListenerState = ref(true);
const newStepWizard = ref(null);
let isNeedPageDatasUpdate = false;
onActivated(() => {
    if (isNeedPageDatasUpdate) {
        newStepWizard.value.updateDatas();
        isNeedPageDatasUpdate = false;
    }
});
/**
 * 响应创建步骤按钮事件
 * @param datas 
 */
const doCreateStepInfo = async (datas) => {
    //步骤活动类型缓存对象
    let stepActionInfos = datas.stepActionInfos;
    //步骤活动类型属性关联变量映射集
    let variableProsMappings =datas.variableProsMappings;
    let variableOperateMappings =datas.variableOperateMappings;
    let occusConditionMappings =datas.occusConditionMappings;
    // console.log(occusConditionMappings);
    //步骤信息
    let stepInfo = datas.stepInfo;
    let responseDatas = {
        resultCode: 1
    }
    let selectedItemActionType;
    //如果当前步骤选择的类型不为空
    if (stepInfo.stepActionType) {
        selectedItemActionType = typeUtil.getStepActionTypeObj(stepInfo.stepActionType);
        selectedItemActionType.init(wsClient);
        responseDatas = await selectedItemActionType.createStepActionTypeInfos(stepActionInfos);
        if (responseDatas.resultCode == 1) {
            //更新步骤ID
            stepInfo.stepActionTypeID = responseDatas.resultData.id;
            //更新步骤活动ID
            stepActionInfos =responseDatas.resultData;
            //创建步骤
            responseDatas = await wsClient.sendActionToCloud("CREATE_STEP_INFO", stepInfo);
            //更新步骤活动关联的步骤ID
            stepActionInfos.stepID=responseDatas.resultData.id;
            //更新缓存步骤ID
            stepInfo.id =responseDatas.resultData.id;
            //更新步骤活动信息
            responseDatas = await selectedItemActionType.saveStepActionTypeInfos(stepActionInfos);
        }
        let itemInfoType = typeUtil.getItemTypeObj(stepInfo.type);
        itemInfoType.init(wsClient,router);
        //获取服务端步骤所属的缓存
        let itemVariables= await itemInfoType.getItemVarables(stepInfo);
        let itemVariablesCahce={};
        for(let variable of itemVariables)
        {
            let tempKey = variable.tempStrID;
            itemVariablesCahce[tempKey]=variable;
        }
        //更新映射关联的相关ID信息
        for(let mapping of variableProsMappings)
        {
            let variable =itemVariablesCahce[mapping.variableID];
            mapping.itemID =stepInfo.stepActionTypeID;
            if(variable)mapping.variableID=variable.id;
            delete mapping.id;
        }
        for(let mapping of variableOperateMappings)
        {
            let variable =itemVariablesCahce[mapping.variableID];
            mapping.itemID =stepInfo.stepActionTypeID;
            if(variable)mapping.variableID=variable.id;
            delete mapping.id;
        }
        for(let mapping of occusConditionMappings)
        {
            mapping.itemID =stepInfo.stepActionTypeID;
            let tempStepActionInfo =mapping.stepActionInfo;
            tempStepActionInfo.stepID=stepInfo.id;
            let tempStepActionType = typeUtil.getStepActionTypeObj(tempStepActionInfo.stepActionTypeID);
            tempStepActionType.init(wsClient);
            delete tempStepActionInfo.id;
            responseDatas = await tempStepActionType.createStepActionTypeInfos(tempStepActionInfo);
            tempStepActionInfo =responseDatas.resultData;
            mapping.actionStepItemID =tempStepActionInfo.id;
            let tempVariableProMappings =mapping.variableProsMappings;
            for(let tempMapping of tempVariableProMappings)
            {
                let variable =itemVariablesCahce[tempMapping.variableID];
                if(variable)tempMapping.variableID=variable.id;
                tempMapping.itemID=tempStepActionInfo.id;
                delete tempMapping.id;
                variableProsMappings.push(tempMapping);
            }
            delete mapping.id;
        }
        responseDatas = await wsClient.sendActionToCloud("CREATE_VARIABLEPROMAPPING_INFOS",{
            variableProMappings:variableProsMappings
        });
        responseDatas = await wsClient.sendActionToCloud("INSERT_VARIABLE_OPERATEMAPIINGS",{
            varaibleOperatorMappings:variableOperateMappings
        });
        responseDatas = await wsClient.sendActionToCloud("CREATE_OCCUSEVENTCONDITIONMAPPINGS", {
            occusConditionMappings:occusConditionMappings
        });
    }
    if (responseDatas.resultCode == 1) {
        if (responseDatas.resultCode == 1) {
            onClickLeft();
        }
    }
    let params = {
        message: responseDatas.message,
        position: "bottom",
    };
    showToast(params);
};
const onClickLeft = () => {
    newStepWizard.value.resetDatas();
    backListenerState.value = false;
    isNeedPageDatasUpdate = true;
    router.push({
        name: backTargetPage,
        state: {
            pageDatas: JSON.stringify(backTargetPageDatas)
        }
    });
};
</script>
<style scoped></style>
  