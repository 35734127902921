// const util = require("@/utils/util");
// const { showToast } = require("vant");
const BaseRelateItemOperator = require('./BaseRelateItemOperator');
module.exports = class AppTypeRelateOperator extends BaseRelateItemOperator {
  id = "APPINFO_TYPE_OPERATOR";

  constructor() {
    super();
  };
  /**
* 响应关联按钮操作事件
* @param {事件对象} eventData 
*/
  doRelateOperateAction(eventData) {
    let operateOption = eventData.operateOption;
    let relateMapping = eventData.relateMapping;
    let operateID = operateOption.id;
    switch (operateID) {
      //查看步骤信息事件
      case "RELATEMAPPING_PAGE_SET_MAINPAGE":
        this.doItemMainPageSetAction(relateMapping, eventData);
        break;
      default:
        super.doRelateOperateAction(eventData);
        break;
    };
  };

 

  doItemMainPageSetAction = async (relateMapping, eventData) => {
    let parentItemInfo = relateMapping.parentItemInfo;
    let itemInfo = relateMapping.itemInfo;
    parentItemInfo.mainPageID = itemInfo.id;
    let responseDatas = await this.handler.getClientServer().sendActionToCloud(
      "UPDATE_APPINFOS_MAINPAGEID",
      parentItemInfo
    );
    let resultCode = responseDatas.resultCode;
    if (resultCode == 1) {
      let appInfo = this.handler.getItemInfo();
      appInfo.mainPageID = responseDatas.resultData;
      this.initRelateMappingDatas(appInfo);
    }
  };

  /**
* 更新当前任务最新的映射队列，并重新排序
* @param {映射队列字符串} newSequence 
*/
  updateSequenceAndSort = async (newSequence) => {
    let appInfo = this.handler.getItemInfo();
    appInfo.sequence = newSequence;
    let taskRelateMappings = this.handler.getRelateMappings();
    let queryData = {
      id: appInfo.id,
      sequence: newSequence
    };
    let responseDatas = await this.handler.getClientServer().sendActionToCloud(
      "UPDATE_APPINFOS_SEQUENCE",
      queryData
    );
    if (responseDatas.resultCode == 1) {
      appInfo.sequence = newSequence;
      this.sortRelateMappings(newSequence, taskRelateMappings);
    }
  }

  getOptionVisableState(relateOperateOption,relateMappingObj){
    if(relateOperateOption.id == "RELATEMAPPING_PAGE_SET_MAINPAGE")
    {
        let parentItemInfo =relateMappingObj.parentItemInfo;
        let itemInfo =relateMappingObj.itemInfo;
        return parentItemInfo.mainPageID!=itemInfo.id;
    }
    return super.getOptionVisableState(relateOperateOption,relateMappingObj);
  };
}