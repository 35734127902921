<template>
  <div class="autospirit_spiritPane_content" @click="dealSelectd">
    <div class="itemsContent">
      <div class="box-left">
        <div class="imageWapper">
          <div class="imageLeft">
            <Image id="imageObj" class="image" :src="itemInfo.iconDataBase64" />
          </div>
        </div>
      </div>
      <div class="box-center">
        <div class="discripeContent">
          <div v-show="itemInfo.define" class="title"></div>
        </div>
      </div>
      <div class="imageButtom">
        <div class="operateBtn" v-if="singleSelectedMode">
          <Checkbox :name="itemInfo.key" v-model="checked" v-show="showOpetateIcon" @click.stop="onItemSelected" />
        </div>
        <div class="operateBtn" v-else>
          <Checkbox :name="itemInfo.key" shape="square" v-model="checked" v-show="showOpetateIcon" @click.stop="onItemSelected" />
        </div>
      </div>
    </div>
    <div class="messageContent">
      <div class="titleWapper">
        <div class="shortCutDiscripe">
          {{ itemInfo.name }}【#{{ itemInfo.id }}】
        </div>
      </div>
      <div v-show="itemInfo.customerMessage" class="titleWapper">
        <div class="shortCutDiscripe">
          {{ itemInfo.customerMessage }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { toRefs, ref, defineProps, defineEmits,defineExpose} from "vue";
import {
    useRouter,
} from 'vue-router';
const router =useRouter();
const props = defineProps({
    itemInfo: {
        type: Object
    },
    showOpetateIcon: {
        type: Boolean
    },
    singleSelectedMode: {
        type: Boolean
    },
});
const emits = defineEmits(["onItemSelected"]);
const onItemSelected=()=>{
    emits("onItemSelected");
};
const doPaneClick = () => {
   let datas = {
        itemInfo: itemInfo.value,
        backTargetPage:"Content",
        backTargetPageDatas:{
          active:1
        }
    }
    router.push({
      name: "SpiritInfo",
      state:
      {
        pageDatas:JSON.stringify(datas)
      }
    });
};
const doItemClick=()=>{
    checked.value=!checked.value;
}
const resetState=()=>{
    checked.value =false;
}
const {
    itemInfo,
    showOpetateIcon,
} = toRefs(props);
let checked =ref(itemInfo.value.checked?itemInfo.value.checked:false);
defineExpose({
    doPaneClick,
    doItemClick,
    resetState
});
</script>
<style scoped>
.autospirit_spiritPane_content {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.autospirit_spiritPane_content .itemsContent {
  display: flex;
  height: 75%;
}

.autospirit_spiritPane_content .messageContent {
  display: block;
  height: 25%;
}

.autospirit_spiritPane_content .imageWapper {
  height: 100%;
  width: 100%;
}

.autospirit_spiritPane_content .box-left {
  display: inline-flex;
  height: 100%;
  width: 50%;
}

.autospirit_spiritPane_content .box-center {
  display: inline-flex;
  width: 40%;
  height: 100%;
}

.autospirit_spiritPane_content .titleWapper {
  display: block;
  padding-left: 10px;
  padding-top: 1px;
  justify-content: center;
}

.autospirit_spiritPane_content .shortCutDiscripe {
  max-width: 140px;
    display: inline-block;
    align-self: flex-start;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.autospirit_spiritPane_content .discripeContent {
  display: inline-block;
  align-self: center;
  height: 100%;
}

.autospirit_spiritPane_content .title {
  display: inline-block;
  align-self: center;
}

.autospirit_spiritPane_content .imageLeft {
  height: 100%;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.autospirit_spiritPane_content .image {
  height: 48px;
  width: 48px;
  align-self: center;
}

.autospirit_spiritPane_content .imageButtom {
  display: inline-block;
  width: 20%;
}

.autospirit_spiritPane_content .operateBtn {
  position: relative;
  top: 15%;
  left: 5%;
  max-width: 40px;
  max-height: 40px;
}

.autospirit_spiritPane_content .title {
  font-size: 12px;
  padding-top: 10px;
  display: block;
}

.autospirit_spiritPane_content .discripe {
  background-color: transparent;
  font-size: 12px;
  display: block;
  word-wrap: normal;
  height: 100%;
}</style>